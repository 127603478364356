import React from "react";
import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AuditControl from "./auditcontrol";

export interface AuditDialogProps {
  isOpen: boolean;
  onClose: () => void;
}

const AuditDialog: React.FC<AuditDialogProps> = ({ isOpen, onClose }) => {
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      className="common-dialog-box audit-log"
    >
      <DialogTitle>
        Audit Log
        <IconButton
          color="inherit"
          className="close-btn"
          aria-label="close"
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <AuditControl />
      </DialogContent>
    </Dialog>
  );
};

export default AuditDialog;
