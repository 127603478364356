import { assetID } from './documentCommentSlice';
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { baseUrl, headers } from "../../config/config";
import { postApiCall } from "../../services/apiHelper";

export interface CommertialLeasing {
  investmentId: number;
  newLeases: number;
  renewals: number;
  newLeasesTotal: number;
  renewalsTotal: number;
}

interface CommertialLeasingState {
  data: CommertialLeasing |null;
  error: string;
  loading: boolean;
}

const initialState: CommertialLeasingState = {
  data: null,
  error: "",
  loading: false,
};
export type PostData = {
  assetPlanId?: string;
  investmentId: string;
};

export const getCommertialLeasingActivity = createAsyncThunk(
  "getCommertialLeasingActivity",
  async (payload: PostData) => {
    try {
      const response = await postApiCall<PostData, CommertialLeasingState>(
        `${baseUrl}Leasing/GetCommertialLeasingActivity`,
        payload,
        headers
      );
      return response.data;
    } catch (error: any) {
      console.error("Error in POST request:", error);
      throw error;
    }
  }
);

const commertialLeasingSlice = createSlice({
  name: "commertialLeasing",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCommertialLeasingActivity.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        getCommertialLeasingActivity.fulfilled,
        (state, action: PayloadAction<CommertialLeasing | null>) => {
          state.loading = false;
          state.data = action.payload;
        }
      )
      .addCase(getCommertialLeasingActivity.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Something went wrong";
      });
  },
});

export default commertialLeasingSlice.reducer;
