import React, { useState, useEffect, useRef } from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import { Collapse, IconButton } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import FroalaEditorView from "react-froala-wysiwyg/FroalaEditorView";

import { useAppSelector, useAppDispatch } from "../../../redux/hooks";
import {
  getApiCall,
  postApiCall,
  putApiCall,
} from "../../../services/apiHelper";
import { baseUrl } from "../../../config/config";
import { FroalaRichText } from "components/util/FroalaRichtext";
import { AutosaveDelay, CommentKey, CommentSectionName } from "constant";
import {
  updateDapCommentData,
  DapComment,
} from "redux/slices/updateDapCommentSlice";
import { saveDapCommentData } from "redux/slices/saveCommentSlice";
import { clearDocumentComments } from "components/util/storeManager";
import { GenericToast } from "components/util/ToastMessage";

type finalRow = {
  hAcct: number;
  acct_desc: string;
  acct_type: string;
  Y2Act: number;
  Y1Act: number;
  Y0Act: number;
  Y0Rfst: number;
  Y0Bud: number;
  Y1Bud: number;
  backgroundColor?: string | undefined;
  shiftClassName?: string | undefined;
  collapse?: string | undefined;
};

type financialRow = {
  accountTreeId: number;
  accountType: string;
  accountDescription: string;
  level: number;
  yearMinus3Actual: number;
  yearMinus2Actual: number;
  yearMinus1Actual: number;
  yearMinus1Forecast: number;
  yearMinus1Budget: number;
  yearBudget: number;
};

type finGroup = {
  is_group: boolean;
  group_name: string;
  data: finalRow;
  group_rows: finGroup[];
};

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
const finalRows: finalRow[] = [];
const backGroundColors = {
  header: "#d3e2ef",
  subHeader: "#f2f2f2",
  total: "#868d93",
  subTotal: "#cccccc",
  normal: "#ffffff",
};
const shiftStyles = { subGroup: "shift1", lineItem: "shift2" };
let currentHeader = "";
const headerStack: string[] = [];

export function HeaderRow({ budgetYear }) {
  return (
    <TableRow sx={{ backgroundColor: "#253746" }}>
      <TableCell sx={{ width: "40%" }}></TableCell>
      <TableCell
        sx={{ color: "#ffffff", width: "12%", textAlign: "right !important" }}
      >
        {budgetYear - 3} Actual
      </TableCell>
      <TableCell
        sx={{ color: "#ffffff", width: "12%", textAlign: "right !important" }}
      >
        {budgetYear - 2} Actual
      </TableCell>
      <TableCell
        sx={{ color: "#ffffff", width: "12%", textAlign: "right !important" }}
      >
        {budgetYear - 1} Budget
      </TableCell>
      <TableCell
        sx={{ color: "#ffffff", width: "12%", textAlign: "right !important" }}
      >
        {budgetYear - 1} Reforecast
      </TableCell>
      <TableCell
        sx={{ color: "#ffffff", width: "12%", textAlign: "right !important" }}
      >
        {budgetYear} Budget
      </TableCell>
    </TableRow>
  );
}
const formatValue = (input: number) => {
  return input.toLocaleString("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};
export function DataRow({ row }: { row: finalRow }) {
  return (
    <TableRow key={row.hAcct} sx={{ backgroundColor: row.backgroundColor }}>
      <TableCell
        component="td"
        sx={{ width: "40%" }}
        className={row.shiftClassName}
        scope="row"
      >
        {row.acct_desc}
      </TableCell>
      {row.acct_type === "H" ? (
        <TableCell colSpan={5}></TableCell>
      ) : (
        <>
          <TableCell
            align="right"
            sx={{ width: "12%", textAlign: "right !important" }}
          >
            {formatValue(row.Y2Act)}
          </TableCell>
          <TableCell
            align="right"
            sx={{ width: "12%", textAlign: "right !important" }}
          >
            {formatValue(row.Y1Act)}
          </TableCell>
          <TableCell
            align="right"
            sx={{ width: "12%", textAlign: "right !important" }}
          >
            {formatValue(row.Y0Bud)}
          </TableCell>
          <TableCell
            align="right"
            sx={{ width: "12%", textAlign: "right !important" }}
          >
            {formatValue(row.Y0Rfst)}
          </TableCell>
          <TableCell
            align="right"
            sx={{ width: "12%", textAlign: "right !important" }}
          >
            {formatValue(row.Y1Bud)}
          </TableCell>
        </>
      )}
    </TableRow>
  );
}

export function RenderGroup(
  {
    group,
    open,
    handleClickParent,
  }: { group: finGroup; open: Number[]; handleClickParent },
  props
) {
  //console.log(group);
  const handleClick = (clickedKey: Number) => {
    handleClickParent(clickedKey);
  };
  return (
    <>
      <TableRow
        key={group.data.hAcct}
        sx={{ backgroundColor: group.data.backgroundColor, cursor: "pointer" }}
        onClick={() => handleClick(group.data.hAcct)}
      >
        <TableCell
          component="td"
          colSpan={6}
          className={group.data.shiftClassName}
          sx={{ padding: "8px 0px !important" }}
          scope="row"
        >
          <IconButton
            aria-label="expand row"
            size="small"
            sx={{ padding: "0px" }}
          >
            {open.includes(group.data.hAcct) ? (
              <KeyboardArrowRightIcon fontSize="medium" />
            ) : (
              <KeyboardArrowDownIcon fontSize="medium" />
            )}
          </IconButton>
          {group.data.acct_desc}
        </TableCell>
      </TableRow>
      <TableRow key={group.data.hAcct + group.group_name}>
        <TableCell
          colSpan={6}
          component="td"
          sx={{ padding: "0px !important", border: "0px" }}
        >
          <Collapse
            in={!open.includes(group.data.hAcct)}
            timeout="auto"
            unmountOnExit
          >
            <Table
              aria-label="a dense table"
              className="noneditable-innertable"
            >
              <TableBody>
                {group.group_rows.map((row, index) => (
                  <>
                    {row.is_group ? (
                      <RenderGroup
                        group={row}
                        open={open}
                        handleClickParent={handleClick}
                      />
                    ) : (
                      <DataRow row={row.data} />
                    )}
                  </>
                ))}
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

export function Financials(props) {
  //console.log("FINAL GROUPED246: ", allFinGroups);
  const [open, setOpen] = useState<Number[]>([]);
  const [financials, setFinancials] = useState<finGroup[]>([]);
  const handleClick = (clickedIndex: Number) => {
    if (open.includes(clickedIndex)) {
      const openCopy = open.filter((element) => {
        return element !== clickedIndex;
      });
      setOpen(openCopy);
    } else {
      const openCopy = [...open];
      openCopy.push(clickedIndex);
      setOpen(openCopy);
    }
  };

  const markSections = ({ allRows }: { allRows: financialRow[] }) => {
    let sectionedRows = [] as finalRow[];
    allRows.forEach((row, index) => {
      const fRow: finalRow = {
        hAcct: row.accountTreeId,
        acct_desc: row.accountDescription,
        acct_type: row.accountType,
        Y2Act: row.yearMinus3Actual,
        Y1Act: row.yearMinus2Actual,
        Y0Act: row.yearMinus1Actual,
        Y0Rfst: row.yearMinus1Forecast,
        Y0Bud: row.yearMinus1Budget,
        Y1Bud: row.yearBudget,
      };
      fRow.backgroundColor =
        row.accountType === "H"
          ? backGroundColors.header
          : backGroundColors.normal;
      fRow.shiftClassName = "";
      if (row.accountType === "H") {
        currentHeader = row.accountDescription;
        //skip "H" (header) row if there is no respective "T" (total) row
        const totalRowIndex = allRows.findIndex(
          (row) => row.accountDescription === "Total " + currentHeader
        );
        if (totalRowIndex === -1) {
          console.log(
            `skipping header row because there is no respective total row - '${currentHeader}'`
          );
          return;
        }
        headerStack.push(row.accountDescription);
        fRow.backgroundColor =
          headerStack.length === 1
            ? backGroundColors.header
            : backGroundColors.subHeader;
        fRow.shiftClassName =
          headerStack.length === 1 ? "" : shiftStyles.subGroup;
        //if (headerStack.length === 1) {
        fRow.collapse = "start";
        //}
      } else if (row.accountType === "T") {
        if ("Total " + currentHeader === row.accountDescription) {
          fRow.backgroundColor =
            headerStack.length === 1
              ? backGroundColors.total
              : backGroundColors.subTotal;
          //if (headerStack.length === 1) {
          if (sectionedRows.length > 0) {
            sectionedRows[sectionedRows.length - 1].collapse = "end";
          }
          //}
          headerStack.pop();
          currentHeader =
            headerStack.length > 0 ? headerStack[headerStack.length - 1] : "";
          fRow.shiftClassName =
            headerStack.length === 0 ? "" : shiftStyles.subGroup;
        } else if (row.accountDescription.startsWith("Total")) {
          fRow.backgroundColor =
            headerStack.length === 0
              ? backGroundColors.total
              : backGroundColors.subTotal;
          fRow.shiftClassName =
            headerStack.length === 0 ? "" : shiftStyles.subGroup;
        } else {
          fRow.backgroundColor = backGroundColors.normal;
          fRow.shiftClassName = shiftStyles.lineItem;
        }
      }
      sectionedRows.push(fRow);
    });
    return sectionedRows;
  };

  const groupedFinancials = ({
    sectionedRows,
  }: {
    sectionedRows: finalRow[];
  }) => {
    let allFinGroups: finGroup[] = [];
    console.log(sectionedRows);
    let latestIndex = 0;
    //debugger;
    for (let index = 0; index < sectionedRows.length; index++) {
      const row = sectionedRows[index];
      const group = {} as finGroup;
      group.data = { ...row };
      group.is_group = false;
      if (row.collapse === "start") {
        group.is_group = true;
        group.group_name = row.acct_desc;
        group.group_rows = [] as finGroup[];
        for (
          let subIndex = index + 1;
          subIndex < sectionedRows.length;
          subIndex++
        ) {
          const subRow = sectionedRows[subIndex];
          const subGroup = {} as finGroup;
          subGroup.data = { ...subRow };
          subGroup.is_group = false;
          if (subRow.collapse === "start") {
            subGroup.is_group = true;
            subGroup.group_name = subRow.acct_desc;
            subGroup.group_rows = [] as finGroup[];
            for (
              let innerIndex = subIndex + 1;
              innerIndex < sectionedRows.length;
              innerIndex++
            ) {
              const innerRow = sectionedRows[innerIndex];
              const innerGroup = {} as finGroup;
              innerGroup.data = { ...innerRow };
              innerGroup.is_group = false;
              subGroup.group_rows.push(innerGroup);
              if (innerRow.collapse === "end") {
                latestIndex = innerIndex;
                break;
              }
            }
            subIndex = latestIndex;
            group.group_rows.push(subGroup);
          } else {
            group.group_rows.push(subGroup);
          }

          if (subRow.collapse === "end") {
            latestIndex = subIndex;
            break;
          }
        }
        index = latestIndex;
      }
      allFinGroups.push(group);
    }
    return allFinGroups;
  };

  const assetPlanId = useAppSelector(
    (state) => state.getAssetPlanId.assetPlanId
  );

  const budgetYear = useAppSelector(
    (state) => state.summary?.dapData?.budgetYear
  );
  const [isNoData, setIsNoData] = useState<boolean>(false);

  const getFinancials = () => {
    console.log("assetplanid", assetPlanId);
    var url = `${baseUrl}${assetPlanId}/financials`;
    console.log("url", url);
    const res = getApiCall(url).then((data: any) => {
      // console.log(typeof data);
      return data;
    });
    return res;
  };
  useEffect(() => {
    getFinancials().then((data: financialRow[]) => {
      const sectionedData = markSections({ allRows: data });
      const groupedData = groupedFinancials({ sectionedRows: sectionedData });
      if (groupedData?.length === 0) {
        setIsNoData(true);
      }
      setFinancials([...groupedData]);
    });
  }, [assetPlanId]);

  const comments = useAppSelector((state) => state.dapComments?.data).find(
    (x) => x.commentKey.toLowerCase() === CommentKey.FINANCIALS.toLowerCase()
  );

  const dispatch = useAppDispatch();

  // const isMounted = useRef(false);
  const handleCommentsChange = (newValue: string) => {
    const updatedcomment: DapComment = {
      id: comments ? comments.id : 0,
      commentKey: comments ? comments.commentKey : CommentKey.FINANCIALS,
      commentText: newValue || "",
      sectionName: CommentSectionName.FINANCIALS,
      assetPlanId: assetPlanId,
    };
    dispatch(updateDapCommentData(updatedcomment));
    dispatch(saveDapCommentData(updatedcomment));

    // if (isMounted.current) {
    //   dispatch(updateDapCommentData(updatedcomment));
    //   dispatch(saveDapCommentData(updatedcomment));
    // } else {
    //   isMounted.current = true;
    // }
  };

  // const savedComment = useAppSelector(
  //   (state) => state.saveComment.updateDapComments
  // );
  // const [toastMessageOpen, setToastMessageOpen] = useState(false);
  // const [toastMessage, setToastMessage] = useState<toast>({
  //   message: "",
  //   severity: "",
  // });
  // const isMountedAutosave = useRef(false);
  // useEffect(() => {
  //   if (isMountedAutosave.current) {
  //     const promises: Promise<any>[] = [];
  //     const debouncedAutosave = setTimeout(() => {
  //       if (savedComment) {
  //         console.log("com", savedComment);
  //         promises.push(
  //           postApiCall(`${baseUrl}DapComments/UpdateDapComments`, savedComment)
  //         );
  //       }
  //       clearDocumentComments(dispatch);
  //       setToastMessage({
  //         message: "Saved successfully",
  //         severity: "success",
  //       });
  //       if (promises.length > 0) {
  //         setToastMessageOpen(false);
  //         Promise.all(promises)
  //           .then((responses) => {
  //             const successResponses = responses.filter(
  //               (response: any) => response.success
  //             );
  //             if (successResponses.length === promises.length) {
  //               setToastMessage({
  //                 message: "Saved successfully",
  //                 severity: "success",
  //               });
  //               setToastMessageOpen(true);
  //             }
  //           })
  //           .catch((error) => {
  //             console.error("Error:", error);
  //             setToastMessage({
  //               message: error.message,
  //               severity: "error",
  //             });
  //             setToastMessageOpen(true);
  //           });
  //       } else {
  //         console.log("No API calls made");
  //       }
  //     }, AutosaveDelay.DELAYTIME);
  //     return () => {
  //       clearTimeout(debouncedAutosave);
  //     };
  //   } else {
  //     isMountedAutosave.current = true;
  //   }
  // }, [savedComment]);

  return (
    <div>
      {/* {toastMessageOpen && (
        <GenericToast
          message={toastMessage.message}
          severity={toastMessage.severity}
        />
      )} */}
      <div className="tab-heading text-center">
        <h3 className="heading">Summary of Budget</h3>
      </div>
      <div className="responsive-table-wrapper">
        <Table
          sx={{ minWidth: 650 }}
          size="small"
          aria-label="a dense table"
          className="noneditable-table1 responsive-table"
        >
          <TableHead>
            <HeaderRow budgetYear={budgetYear} />
          </TableHead>
          <TableBody>
            {isNoData && (
              <TableRow>
                <TableCell colSpan={6} sx={{ textAlign: "center !important" }}>
                  No rows
                </TableCell>{" "}
              </TableRow>
            )}
            {financials?.map((group, index) => (
              <>
                {group.is_group ? (
                  <>
                    <RenderGroup
                      group={group}
                      open={open}
                      handleClickParent={handleClick}
                    />
                  </>
                ) : (
                  <DataRow row={group.data} />
                )}
              </>
            ))}
          </TableBody>
        </Table>
      </div>
      <form className="m-20 lease-form">
        <fieldset>
          <legend className="lease-title">Comments</legend>
          <>
            {console.log(props)}
            {props?.mode === "edit" && (
              <FroalaRichText
                model={comments?.commentText || ""}
                handlesModelChange={handleCommentsChange}
              />
            )}

            {(props?.mode === undefined || props?.mode === "view") && (
              <FroalaEditorView model={comments?.commentText || ""} />
            )}
          </>
        </fieldset>
      </form>
    </div>
  );
}

/*
// type finalGroup = {
//     is_group: boolean,
//     group_name: string,
//     group_rows: finalRow[]
//     data: finalRow
// }

const allRows = mockData.filter(m => m.acct_type !== 'R' && m.custom_attr === 'DAP'
    && (m.acct_type === 'H' || (m.acct_type === 'T' && (Math.abs(m.Y2Act) + Math.abs(m.Y1Act) + Math.abs(m.Y0Rfst) + Math.abs(m.Y0Bud) + Math.abs(m.Y1Bud)) > 0)));

allRows.forEach((row, index) => {
    const fRow: finalRow = {
        hAcct: row.hAcct,
        acct_desc: row.acct_desc,
        acct_type: row.acct_type,
        Y2Act: row.Y2Act,
        Y1Act: row.Y1Act,
        Y0Act: row.Y0Act,
        Y0Rfst: row.Y0Rfst,
        Y0Bud: row.Y0Bud,
        Y1Bud: row.Y1Bud,
    };
    fRow.backgroundColor = row.acct_type === 'H' ? backGroundColors.header : backGroundColors.normal;
    fRow.shiftClassName = "";
    if (row.acct_type === 'H') {
        headerStack.push(row.acct_desc);
        currentHeader = row.acct_desc;
        fRow.backgroundColor = (headerStack.length === 1) ? backGroundColors.header : backGroundColors.subHeader;
        fRow.shiftClassName = (headerStack.length === 1) ? "" : shiftStyles.subGroup;
        //if (headerStack.length === 1) {
        fRow.collapse = 'start';
        //}
    }
    else if (row.acct_type === 'T') {
        if ("Total " + currentHeader === row.acct_desc) {
            fRow.backgroundColor = (headerStack.length === 1) ? backGroundColors.total : backGroundColors.subTotal;
            //if (headerStack.length === 1) {
            finalRows[index - 1].collapse = 'end';
            //}
            headerStack.pop();
            currentHeader = headerStack.length > 0 ? headerStack[headerStack.length - 1] : "";
            fRow.shiftClassName = (headerStack.length === 0) ? "" : shiftStyles.subGroup;
        }
        else if (row.acct_desc.startsWith('Total')) {
            fRow.backgroundColor = (headerStack.length === 0) ? backGroundColors.total : backGroundColors.subTotal;
            fRow.shiftClassName = (headerStack.length === 0) ? "" : shiftStyles.subGroup;
        }
        else {
            fRow.backgroundColor = backGroundColors.normal;
            fRow.shiftClassName = shiftStyles.lineItem;
        }
    }
    finalRows.push(fRow);
});
console.log("FINAL: ", finalRows);
// let allGroups: finalGroup[] = []
// for (let index = 0; index < finalRows.length; index++) {
//     const row = finalRows[index];
//     const group = {} as finalGroup;
//     group.data = { ...row };
//     group.is_group = false;
//     if (row.collapse === 'start') {
//         group.is_group = true;
//         group.group_name = row.acct_desc;
//         group.group_rows = [] as finalRow[];
//         for (let subIndex = index + 1; subIndex < finalRows.length; subIndex++) {
//             group.group_rows.push(finalRows[subIndex]);
//             if (finalRows[subIndex].collapse === 'end') {
//                 break;
//             }
//         }
//         index = index + group.group_rows.length;
//     }
//     allGroups.push(group);
// }

let allFinGroups: finGroup[] = [];
let latestIndex = 0;
for (let index = 0; index < finalRows.length; index++) {
    const row = finalRows[index];
    const group = {} as finGroup;
    group.data = { ...row };
    group.is_group = false;
    if (row.collapse === 'start') {
        group.is_group = true;
        group.group_name = row.acct_desc;
        group.group_rows = [] as finGroup[];
        for (let subIndex = index + 1; subIndex < finalRows.length; subIndex++) {
            const subRow = finalRows[subIndex];
            const subGroup = {} as finGroup;
            subGroup.data = { ...subRow };
            subGroup.is_group = false;
            if (subRow.collapse === 'start') {
                subGroup.is_group = true;
                subGroup.group_name = subRow.acct_desc;
                subGroup.group_rows = [] as finGroup[];
                for (let innerIndex = subIndex + 1; innerIndex < finalRows.length; innerIndex++) {
                    const innerRow = finalRows[innerIndex];
                    const innerGroup = {} as finGroup;
                    innerGroup.data = { ...innerRow };
                    innerGroup.is_group = false;
                    subGroup.group_rows.push(innerGroup);
                    if (innerRow.collapse === 'end') {
                        break;
                    }
                }
                subIndex = subIndex + subGroup.group_rows.length;
                group.group_rows.push(subGroup);
            }
            else {
                group.group_rows.push(subGroup);
            }

            if (subRow.collapse === 'end') {
                latestIndex = subIndex;
                break;
            }
        }
        //index = index + group.group_rows.length;
        index = latestIndex;
    }
    allFinGroups.push(group);
}
console.log("FINAL GROUPED: ", allFinGroups);

// const getSubGroups = (index: number) => {
//     const subGroups: finGroup[] = [];
//     for (let subIndex = index + 1; subIndex < finalRows.length; subIndex++) {
//         const currentRow = finalRows[subIndex];
//         if (currentRow.collapse === 'start') {
//             const groupNode = {} as finGroup;
//             groupNode.is_group = true;
//             groupNode.group_name = currentRow.acct_desc;
//             groupNode.group_rows = getSubGroups(subIndex);
//             return groupNode;
//         }

//         group.group_rows.push(finalRows[subIndex]);
//         if (finalRows[subIndex].collapse === 'end') {
//             break;
//         }
//     }
// }
*/
