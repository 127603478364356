
const NoImages = `
<html>
  <head>
    <meta name="viewport" content="width=device-width, initial-scale=1" />

  </head>
  <body>
  <table id="pdf-table-no-img">
  <h2 class="pdf-table-heading">{{headerName}}</h2>
      <tr>
          <td >            
              <i>No image available</i>
          </td>
      </tr>     
  </table>
  </body>
</html>`
export default NoImages;