import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { useState } from "react";
import {
  rejectionCommentPopup,
  rejectionComment,
  rejectionStatus,
  rejectionToast,
} from "../../redux/slices/dialogFormSlice";
import { useAppSelector, useAppDispatch } from "../../redux/hooks";
import { UpdateDapStatusRequest } from "../../layout/buttonVisibilityUtils";
import { ActionNames } from "../../constant";
import { baseUrl } from "../../config/config";
import { postApiCall } from "../../services/apiHelper";
import { useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { resetAssetPlanState } from "components/util/storeManager";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  color: theme.palette.text.secondary,
}));

export default function CommentPopup() {
  interface toast {
    message: any;
    severity: string;
  }
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const userSectorDetail = useAppSelector(
    (state) => state.userSectorDetail.data
  );
  const dapData = useAppSelector((state) => state.summary.dapData);
  const showRejectionCommentPopup = useAppSelector(
    (state) => state.dialog.dialogData.rejectionCommentPopup
  );
  const [loaderButton, setLoaderButton] = useState(false);
  const [validation, setValidation] = useState(false);

  const [textInput, setTextInput] = useState("");

  const handleTextInputChange = (event) => {
    setTextInput(event.target.value);
  };

  const handleClose = () => {
    dispatch(rejectionCommentPopup(false));
    setTextInput("");
    dispatch(rejectionComment(textInput));
  };

  const handleConfirm = async () => {
    if (textInput !== "") {
      setValidation(false);
      setLoaderButton(true);
      const request: UpdateDapStatusRequest = {
        Email: userSectorDetail?.userName ?? undefined,
        UserAction: ActionNames.REJECT,
        AssetPlanId: dapData?.assetPlanId,
        Comments: textInput,
      };
      const response: any = await postApiCall(
        `${baseUrl}UpdateData/UpdateRejectStatus`,
        request
      );
      if (response.success) {
        dispatch(rejectionCommentPopup(false));
        dispatch(
          rejectionStatus({
            message: "Rejected Successfully",
            severity: "success",
          })
        );
        dispatch(rejectionToast(true));
        setLoaderButton(false);
        resetAssetPlanState(dispatch);
        // dispatch(resetLatestRecomStateAction());
        // dispatch(resetDapCommentStateAction());
        // dispatch(resetCapitalProjectsStore());
        // dispatch(resetSaveCommentStateAction());
        // dispatch(resetSaveDataStateAction());
        // dispatch(resetLeaseExpirationAction());
        // dispatch(resetKeyTenantStateAction());
        // dispatch(resetPropertyImagesAction());
        // dispatch((resetSummaryFormAction()));

        setTimeout(() => {
          navigate("/");
          dispatch(rejectionToast(false));
        }, 1000);
      } else {
        dispatch(rejectionCommentPopup(false));
        dispatch(
          rejectionStatus({ message: response.errormessage, severity: "error" })
        );
        dispatch(rejectionToast(true));
        setLoaderButton(false);
        setTimeout(() => {
          dispatch(rejectionToast(false));
        }, 1000);
      }
    } else {
      setValidation(true);
    }
  };
  return (
    <div>
      <Typography component="span">
        <Dialog open={showRejectionCommentPopup} className="common-dialog-box">
          <DialogTitle id="alert-dialog-title">Comment</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <TextField
                id="RejectionComment"
                multiline
                fullWidth
                rows={5}
                error={validation}
                label="Rejection Comment"
                value={textInput}
                onChange={handleTextInputChange}
              />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              className="primary"
              variant="contained"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <LoadingButton
              loading={loaderButton}
              className="primary"
              variant="contained"
              onClick={handleConfirm}
            >
              Confirm
            </LoadingButton>
          </DialogActions>
        </Dialog>
      </Typography>
    </div>
  );
}
