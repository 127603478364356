const recommendationTemplate = `
<!DOCTYPE html>
<html>
  <head> </head>
  <body>
  <h2 class="pdf-table-heading">Recommendations</h2>
    <table id="table-comment">

      <tr>
        <th>
          <h3>Investment Recommendations:</h3>
        </th>
      </tr>
      <tr>
        <th><span class="table-subhead">Classification:</span> <span>{{recomendationData.Investment_Key1.text}}</span> </th>
      </tr>
      <tr>
      <tr>
        <td>
	{{{recomendationData.Investment_Key1.assetManagerRationale}}}
        </td>
      </tr>
      <tr>
      <th ><span class="table-subhead">Hold Period:</span> <span>{{recomendationData.Investment_Key2.text}}</span> </th>
      </tr>
      <tr>
      <tr>
        <td>
		{{{recomendationData.Investment_Key2.assetManagerRationale}}}
        </td>
      </tr>
      <tr>
      <th ><span class="table-subhead">Actions:</span> <span>{{recomendationData.Investment_Key3.text}}</span> </th>
      </tr>
      <tr>
      <tr>
        <td>
		{{{recomendationData.Investment_Key3.assetManagerRationale}}}
        </td>
      </tr>
      <tr>
      <tr>
        <th>
          <h3>Sustainability Recommendations:</h3>
        </th>
      </tr>
      <tr>
        <th ><span class="table-subhead">Physical Risk Considerations:</span> <span>{{recomendationData.Sustainability_Key1.text}}</span> </th>
      </tr>
      <tr>
      <tr>
        <td>
		{{{recomendationData.Sustainability_Key1.assetManagerRationale}}}
        </td>
      </tr>
      <tr>
      <th ><span class="table-subhead">Regulatory Risk Considerations:</span> <span>{{recomendationData.Sustainability_Key2.text}}</span> </th>
      </tr>
      <tr>
      <tr>
        <td>
		{{{recomendationData.Sustainability_Key2.assetManagerRationale}}}
        </td>
      </tr>
      <tr>
      <th ><span class="table-subhead">SSBB Minimum Standards:</span> <span>{{recomendationData.Sustainability_Key3.text}}</span> </th>
      </tr>
      <tr>
      <tr>
        <td>
		{{{recomendationData.Sustainability_Key3.assetManagerRationale}}}
        </td>
      </tr>
	        <th ><span class="table-subhead">Tenant/Resident Feedback Action Plan:</span> <span>{{recomendationData.Sustainability_Key4.text}}</span> </th>
      </tr>
      <tr>
      <tr>
        <td>
		{{{recomendationData.Sustainability_Key4.assetManagerRationale}}}
        </td>
      </tr>
    </table>
    <br></br>
    <table id="comment-table">
    <tbody>
      <tr>
        <th>Mission Statement</th>
      </tr>
      <tr>
        <td>
          {{{recommendationComment.commentText}}}
        </td>
      </tr>
    </tbody>
  </table>
    </body>
</html>
       `;

export default recommendationTemplate;
