import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import React, { useEffect, useRef, useState } from "react";
import { useAppSelector, useAppDispatch } from "../../../redux/hooks";
import "./styles.css";
import { useParams } from "react-router-dom";
import { CapitalPlanEditForm } from "../CapitalPlan/dap-capitalplan-edit-form";
import { RecommendationTestForm } from "../Recommendation/dap-recommendation_test";
import { RecommendationViewTestForm } from "../Recommendation/dap-recommendation_view";
import { CapitalPlanDisplayForm } from "../CapitalPlan/dap-capitalplan-display-form";
import { SwotEditForm } from "../Swot/dap-swot-edit-form";
import { SummaryEditForm } from "../Summary/dap-summary-edit-form";
import { SiteStackingEditForm } from "../SiteStacking/dap-sitestacking-edit-form";
import { SiteStackingViewForm } from "../SiteStacking/dap-sitestacking-view-form";
import { SwotDisplayForm } from "../Swot/dap-swot-display-form";
import Documents from "../Documents/Documents";
import { KeyProvision } from "../KeyProvisions/key-provision-edit-form";
import Title from "../../../layout/Title";
import { LeasingResidentialEditForm } from "../Leasing/leasing-residential-edit";
import { LeasingCommercialEditFormNew } from "../Leasing/leasing-commercial-edit-new";
import { ValuationForm } from "../Valuation/valuation";
import PostCLosing from "../PostClosingItem/Post-CLosing-Item-edit";
import {
  getInvestmentDetailsById,
  PostData,
} from "../../../redux/slices/summaryFormSlice";
import { SummaryDisplayForm } from "../Summary/dap-summary-display-form";
import { getAssetPlanId } from "../../../redux/slices/assetPlanIdSlice";
import { Financials } from "../Financials/financials";
import NewFormDialog from "../newFormDialog/newFormDialog";
import { getDapComments } from "../../../redux/slices/updateDapCommentSlice";
import { getUserData } from "../../../redux/slices/userSectorDetailSlice";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  // console.log(children);
  // console.log(value);
  // console.log(index);

  //UNUSED
  //useAppSelector is basically a UseSelector funtion that returns the Redux State

  //Why do we return empty undefined Objects?
  const showForm = useAppSelector(
    (state) => state.dialog.dialogData.showPopupForm
  );


  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1 }}>
          {/* The Entire Data box */}
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

//UNUSED
function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const DapIntroductionForm = (props) => {
  const dispatch = useAppDispatch();
  //const id = useParams()?.dataForSelectedInvestmentId ?? "";

  //Return undefined again
  const showForm = useAppSelector(
    (state) => state.dialog.dialogData.showPopupForm
  );
  const id = useParams()?.dapId ?? "";
  
  const assetPlanId = useAppSelector(
    (state) => state.getAssetPlanId.assetPlanId
  );

  const [value, setValue] = React.useState(0);
  const [callExecuted, setCallExecuted] = React.useState(false);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  //UNUSED
  const savedData = useAppSelector((state) => state.updateData);
  //UNUSED
  const dapComments = useAppSelector((state) => state.dapComments);
//Why export the above two reduc state data?
  
//Contains the data of the Asset that was Viwed or Edited
  const dapData = useAppSelector(
    (state) => state.summary.dapData
  );
  

  const postData: PostData = {
    //ID Derived prom url Params
    dapId: id,
  };

  useEffect(() => {
    if (!callExecuted) { // 
      dispatch(getInvestmentDetailsById(postData));
      dispatch(getAssetPlanId(id));
      setCallExecuted(true);
    }
  }, [postData, id]);
  useEffect(() => {
    dispatch(getDapComments(id));
  }, [id, dispatch]);

  const userEmail = ""; //bryan.morland@nuveen.com
  useEffect(() => {
    dispatch(getUserData(userEmail));
  }, [userEmail, dispatch]);
  
  return (
    <div className="dashboard-wrap">
      <Title mode={props.mode} />
      <Box sx={{ borderBottom: "none", borderColor: "divider" }}>
        <Tabs
          TabIndicatorProps={{ style: { backgroundColor: "transparent" } }}
          className="main-tab"
          value={value}
          onChange={handleChange}
          variant="scrollable"
          aria-label="basic tabs example"
          allowScrollButtonsMobile={true}
        >
          <Tab label="Summary" />
          <Tab label="Recommendations" />
          <Tab label="Site/Stacking Plan" />
          <Tab label="Financial" />
          <Tab label="Capital Plan" />
          <Tab label="SWOT" />
          <Tab label="Leasing" />
          <Tab label="Valuation" />
          <Tab label="Key Provisions" />
          <Tab label="Comments" />
          <Tab label="Documents" />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        {props.mode === "edit" && <SummaryEditForm />}
        {props.mode === "view" && <SummaryDisplayForm />}
      </TabPanel>

      <TabPanel value={value} index={1}>
        {props.mode === "edit" && <RecommendationTestForm />}
        {props.mode === "view" && <RecommendationViewTestForm />}
      </TabPanel>

      <TabPanel value={value} index={2}>
        {props.mode === "edit" && <SiteStackingEditForm />}
        {props.mode === "view" && <SiteStackingViewForm />}
      </TabPanel>

      <TabPanel value={value} index={3}>
        <Financials mode={props.mode} />
      </TabPanel>

      <TabPanel value={value} index={4}>
        {props.mode === "edit" && <CapitalPlanEditForm />}
        {props.mode === "view" && <CapitalPlanDisplayForm />}
      </TabPanel>

      <TabPanel value={value} index={5}>
        {props.mode === "edit" && <SwotEditForm />}
        {props.mode === "view" && <SwotDisplayForm />}
      </TabPanel>

      <TabPanel value={value} index={6}>
        {dapData?.propertyType === "Residential" && (
          <LeasingResidentialEditForm mode={props.mode} />
        )}
        {dapData?.propertyType === "Commertial" && (
          <>
            {/* <LeasingCommercialEditForm /> */}
            <LeasingCommercialEditFormNew mode={props.mode} />
          </>
        )}
        {dapData?.propertyType === "Mixed" && (
          <>
            <LeasingResidentialEditForm mode={props.mode} />
            <LeasingCommercialEditFormNew mode={props.mode} />
          </>
        )}
      </TabPanel>

      <TabPanel value={value} index={7}>
        <ValuationForm mode={props.mode} />
      </TabPanel>

      <TabPanel value={value} index={8}>
        <KeyProvision mode={props.mode} />
      </TabPanel>

      <TabPanel value={value} index={9}>
        <PostCLosing mode={props.mode} />
      </TabPanel>

      <TabPanel value={value} index={10}>
        {/* <FileSelections /> */}
        <Documents mode={props.mode} />
      </TabPanel>

      {showForm && <NewFormDialog />}
    </div>
  );
};

// export default DapIntroductionForm;
