import handlebars from "handlebars";
import postClosingTemplate from "../templates/postClosingTemplate";
import { CommentKey} from "../../../constant";

export const compilePostClosingTemplate = (commentData:any[]): string => {
    

    const compilePostClosingTemplate = handlebars.compile(postClosingTemplate);
    const Comment: any = commentData || [];
    const PostClosingDataComment = {
        postClosingComment:
          Comment?.find(
            (x) =>
              x.commentKey?.toLowerCase() ===
              CommentKey.POSTCLOSING.toLowerCase()
          )?.commentText || "",
      };
    return compilePostClosingTemplate(PostClosingDataComment);

};