import * as React from "react";
import { useState, useRef, useEffect } from "react";
import { Box, Grid, TextField } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import { useAppSelector, useAppDispatch } from "../../../redux/hooks";
import Carouselcomponent from "../../corousel/Carousel-component";
import { FroalaRichText } from "../../util/FroalaRichtext";
import { updateFormData } from "../../../redux/slices/summaryFormSlice";
import {
  SelectOption,
  Option,
} from "../../../redux/slices/dropdownOptionSlice";
import {
  SummaryDropdown,
  SectionNames,
  AutosaveDelay,
} from "../../../constant";
import GroupedAutocomplete from "../../util/GroupedAutocomplete";
import moment from "moment";
import {
  fetchTabPageData,
  TabPage,
} from "../../../redux/slices/tabPagesOptionsSlice";
import {
  updateSummary,
  updateAssetPlanId,
} from "../../../redux/slices/updateDataSlice";
import {
  updateDapCommentData,
  DapComment,
} from "../../../redux/slices/updateDapCommentSlice";
import { setOption } from "../../../redux/slices/dropdownStateSlice";
import { propertyImages } from "../../../redux/slices/propertyImagesSlice";

// Require Editor JS files.
import "froala-editor/js/froala_editor.pkgd.min.js";
import "froala-editor/js/plugins.pkgd.min.js";
import "froala-editor/js/third_party/embedly.min.js";
// import "froala-editor/js/plugins/fullscreen.min.js"

// Require Editor CSS files.
import "froala-editor/css/froala_style.min.css";
import "froala-editor/css/froala_editor.pkgd.min.css";
import "froala-editor/css/third_party/embedly.min.css";
import { GenericToast } from "../../util/ToastMessage";
// import "froala-editor/css/plugins/fullscreen.min.css";
import { CommentKey, CommentSectionName } from "../../../constant";
import { saveDapCommentData } from "../../../redux/slices/saveCommentSlice";
import { clearDocumentComments } from "components/util/storeManager";
import { postApiCall } from "services/apiHelper";
import { baseUrl } from "config/config";
import {dateTimeToDateFormat} from "../../util/valueFormatter";




const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  color: theme.palette.text.secondary,
}));

export const SummaryEditForm = () => {
  const ref = useRef({ editor: null });
  const [model, setModel] = useState<string>("");
  const dispatch = useAppDispatch();

  //const sectionOptions= useAppSelector((state)=>state.dropdownOptions.data) as SelectOption[];
  const [groupedOptions, setGroupedOptions] = useState<SelectOption[]>([]);
  const [selectedOptions, setSelectedOptions] = useState<{
    [key: string]: string;
  }>({});
  const [groups, setGroups] = useState<SelectOption[]>([]);
  const [selectedInvestOption, setSelectedInvestOption] =
    useState<Option | null>(null);
  const [selectedLeaseOption, setSelectedLeaseOption] = useState<Option | null>(
    null
  );
  const sectionOptions = useAppSelector((state) =>
    state.dropdownAllOptions.tabData.find(
      (tab) => tab.tabPageName === SectionNames.SUMMARY
    )
  ) as TabPage;
  const selectedList = useAppSelector(
    (state) => state.dropdownState.selectedOptions
  );
  const images = useAppSelector(
    (state) => state.propertyImages.ImagesOfProperty
  );
  const imagesStatus = useAppSelector((state) => state.propertyImages);
  const dapData: any = useAppSelector((state) => state.summary.dapData);
  const comment = useAppSelector((state) => state.dapComments?.data).find(
    (x) => x.commentKey.toLowerCase() === CommentKey.SUMMARY_KEY.toLowerCase()
  );
  const errorStatus = useAppSelector((state) => state.summary);
  const descriptionData = useAppSelector(
    (state) => state.dapComments?.data
  ).find(
    (x) => x.commentKey.toLowerCase() === CommentKey.SUMMARY_KEY.toLowerCase()
  );
  const requestForPropertyImages: any = {
    investmentId: dapData?.investmentId,
  };
  const assetPlanId = useAppSelector(
    (state) => state.getAssetPlanId.assetPlanId
  );
  useEffect(() => {
    dispatch(fetchTabPageData(SectionNames.SUMMARY));
  }, [dispatch]);

  useEffect(() => {
    if (dapData) dispatch(propertyImages(requestForPropertyImages));
  }, [dapData?.investmentId]);

  useEffect(() => {
    if (dapData?.assetPlanId) {
      dispatch(updateAssetPlanId(dapData?.assetPlanId));
    }
    if (dapData?.investmentDescription || dapData?.primaryLeaseType) {
      // Set the default value for each dropdown once the options are fetched and available in the Redux store
      //TODO check the best place o initialize
      dispatch(
        updateSummary({
          investmentDescription: dapData?.investmentDescription,
          primaryLeaseType: dapData?.primaryLeaseType,
          investmentId: dapData?.investmentId,
          assetPlanId: dapData?.assetPlanId,
          jvPartner: dapData?.jvPartner,
        })
      );
      const investDesc = dapData?.investmentDescription || " ";
      if (!selectedList[SummaryDropdown.INVESTMENT_DESC]) {
        setSelectedInvestOption({
          text: investDesc,
          value: investDesc,
          order: "0",
        });
        dispatch(
          setOption({
            dropdownId: SummaryDropdown.INVESTMENT_DESC,
            option: { text: investDesc, value: investDesc, order: "0" },
          })
        );
      } else {
        setSelectedInvestOption(selectedList[SummaryDropdown.INVESTMENT_DESC]);
      }

      const leaseType = dapData?.primaryLeaseType || " ";

      if (!selectedList[SummaryDropdown.PRIMARY_LEASE_TYPE]) {
        setSelectedLeaseOption({
          text: leaseType,
          value: leaseType,
          order: "0",
        });
        dispatch(
          setOption({
            dropdownId: SummaryDropdown.PRIMARY_LEASE_TYPE,
            option: { text: leaseType, value: leaseType, order: "0" },
          })
        );
      } else {
        setSelectedLeaseOption(
          selectedList[SummaryDropdown.PRIMARY_LEASE_TYPE]
        );
      }
    }
  }, [dapData]);

  useEffect(() => {
    if (sectionOptions) {
      const transformedGroups: SelectOption[] = Object.values(
        sectionOptions.data
      ).flatMap((groupData: any) => {
        return groupData.map((group: any) => {
          const { key, options } = group;
          const transformedOptions: Option[] = options
            ? options.map((option: any) => {
                const { text, value } = option;
                return { text, value };
              })
            : [];

          return { key, options: transformedOptions };
        });
      });
      setGroups(transformedGroups);
    }
  }, [sectionOptions]);

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    if (dapData) dispatch(updateFormData({ ...dapData, [name]: value }));
  };


  const handleSelect = (dropdownId: string, option: Option) => {
    setSelectedInvestOption(option);
    dispatch(setOption({ dropdownId, option }));
    dispatch(
      updateFormData({ ...dapData, ["investmentDescription"]: option.text })
    );
    dispatch(
      updateSummary({
        investmentDescription: option.text,
        jvPartner: dapData?.jvPartner,
        primaryLeaseType: dapData?.primaryLeaseType,
        investmentId: dapData?.investmentId,
        assetPlanId: dapData?.assetPlanId,
      })
    );
  };
  const handleSelectLeaseType = (dropdownId: string, option: Option) => {
    setSelectedLeaseOption(option);
    dispatch(setOption({ dropdownId, option }));
    dispatch(updateFormData({ ...dapData, ["primaryLeaseType"]: option.text }));
    dispatch(
      updateSummary({
        primaryLeaseType: option.text,
        jvPartner: dapData?.jvPartner,
        investmentDescription: dapData?.investmentDescription,
        investmentId: dapData?.investmentId,
        assetPlanId: dapData?.assetPlanId,
      })
    );
  };

  //const isMounted = useRef(false);
  const handleCommentChange = (model: any) => {
    const updatedcomment: DapComment = {
      id: comment ? comment.id : 0,
      commentKey: comment ? comment.commentKey : CommentKey.SUMMARY_KEY,
      commentText: model || "",
      sectionName: CommentSectionName.SUMMARY,
      assetPlanId: assetPlanId,
    };
    dispatch(updateDapCommentData(updatedcomment));
    dispatch(saveDapCommentData(updatedcomment));

    // if (isMounted.current) {
    //   dispatch(updateDapCommentData(updatedcomment));
    //   dispatch(saveDapCommentData(updatedcomment));
    // } else {
    //   isMounted.current = true;
    //}

  };

  // const savedData = useAppSelector((state) => state.updateData);
  // const savedComment = useAppSelector(
  //   (state) => state.saveComment.updateDapComments
  // );
  // const [toastMessageOpen, setToastMessageOpen] = useState(false);
  // const [toastMessage, setToastMessage] = useState<toast>({
  //   message: "",
  //   severity: "",
  // });
  // const isMountedAutosave = useRef(false);
  // useEffect(() => {
  //   if (isMountedAutosave.current) {
  //     const promises: Promise<any>[] = [];

  //     const debouncedAutosave = setTimeout(() => {
  //       let savedDataPayload = {
  //         assetPlanId: assetPlanId,
  //         capitalProjectData: null,
  //         documentData: null,
  //         occupancyCommercial: null,
  //         recommendationTab: null,
  //         status: null,
  //         summary: savedData.summary,
  //         userAction: "Save",
  //       };
  //       if (savedData) {
  //         console.log("saveddata", savedData);
  //         promises.push(
  //           postApiCall(`${baseUrl}UpdateData/SaveAllData`, {
  //             ...savedDataPayload,
  //           })
  //         );
  //       }
  //       if (savedComment) {
  //         console.log("com", savedComment);
  //         promises.push(
  //           postApiCall(`${baseUrl}DapComments/UpdateDapComments`, savedComment)
  //         );
  //       }
  //       clearDocumentComments(dispatch);
  //       setToastMessage({
  //         message: "Saved successfully",
  //         severity: "success",
  //       });
  //       if (promises.length > 0) {
  //         setToastMessageOpen(false);
  //         Promise.all(promises)
  //           .then((responses) => {
  //             const successResponses = responses.filter(
  //               (response: any) => response.success
  //             );
  //             if (successResponses.length === promises.length) {
  //               setToastMessage({
  //                 message: "Saved successfully",
  //                 severity: "success",
  //               });
  //               setToastMessageOpen(true);
  //             }
  //           })
  //           .catch((error) => {
  //             console.error("Error:", error);
  //             setToastMessage({
  //               message: error.message,
  //               severity: "error",
  //             });
  //             setToastMessageOpen(true);
  //           });
  //       } else {
  //         console.log("No API calls made");
  //       }
  //     }, AutosaveDelay.DELAYTIME);
  //     return () => {
  //       clearTimeout(debouncedAutosave);
  //     };
  //   } else {
  //     isMountedAutosave.current = true;
  //   }
  // }, [savedData, savedComment]);

  const numToMoneyFormat = (x: number) => {
    return "$" + x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  const numWithComma = (x: number) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const formatYearBuilt = (year: number | undefined) => {
    if (year === undefined) return "";
    if (year === 0) return "-";
    return year;
  };

  return (
    <div className="container ">
      <Box sx={{ width: "100%" }}>
        <div className="data-content">
          <Box sx={{ flexGrow: 1 }} className="summary-form">
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                {errorStatus.loading ? (
                  <Skeleton
                    variant="rounded"
                    width="100%"
                    height={350}
                    animation="wave"
                  />
                ) : (
                  <Item>
                    <table className="summary-table">
                      <tr>
                        <th>
                          <b>ID:</b>
                        </th>
                        <td>{dapData?.investmentId}</td>
                      </tr>
                      <tr>
                        <th>
                          <b>Fund Name:</b>
                        </th>
                        <td>{dapData?.fundName}</td>
                      </tr>
                      <tr>
                        <th>
                          <b>City / State:</b>
                        </th>
                        <td>{dapData?.city}</td>
                      </tr>
                      <tr>
                        <th>
                          <b>Year Built:</b>
                        </th>
                        <td>{formatYearBuilt(dapData?.yearBuilt)}</td>
                      </tr>
                      <tr>
                        <th>
                          <b>Date Acquired:</b>
                        </th>
                        {dapData?.dateAcquired && (
                          <td>
                            {dateTimeToDateFormat(dapData.dateAcquired)}
                          </td>
                        )}
                      </tr>
                      <tr>
                        <th>
                          <b>Purchase Price:</b>
                        </th>
                        {dapData?.purchasedPrice && (
                          <td>{numToMoneyFormat(dapData?.purchasedPrice)}</td>
                        )}
                      </tr>
                      <tr>
                        <th>
                          <b>Appraised Value:</b>
                        </th>
                        {dapData?.purchasedPrice && (
                          <td>
                            {numToMoneyFormat(
                              parseInt(dapData?.appraisedValue)
                            )}
                          </td>
                        )}
                      </tr>
                      <tr>
                        <th>
                          <b>Asset Manager:</b>
                        </th>
                        <td>
                          {dapData?.assetManager?.firstName}{" "}
                          {dapData?.assetManager?.lastName}
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <b>Portfolio Manager:</b>
                        </th>
                        <td>
                          {dapData?.portfolioManager?.firstName}{" "}
                          {dapData?.portfolioManager?.lastName}
                        </td>
                      </tr>
                    </table>
                  </Item>
                )}
              </Grid>

              <Grid item xs={12} md={6}>
                {errorStatus.loading ? (
                  <Skeleton
                    variant="rounded"
                    width="100%"
                    height={350}
                    animation="wave"
                  />
                ) : (
                  <Item>
                    <table className="summary-table">
                      {dapData?.propertyType === "Commertial" && (
                        <tr>
                          <th>
                            <b>Rentable Area (sqft):</b>
                          </th>
                          {dapData?.rentableArea && (
                            <td>{numWithComma(dapData?.rentableArea)}</td>
                          )}
                        </tr>
                      )}
                      {dapData?.propertyType === "Residential" && (
                        <tr>
                          <th>
                            <b>Units:</b>
                          </th>
                          {dapData?.units && (
                            <td>{numWithComma(dapData.units)}</td>
                          )}
                        </tr>
                      )}
                      {dapData?.propertyType === "Mixed" && (
                        <>
                          <tr>
                            <th>
                              <b>Rentable Area (sqft):</b>
                            </th>
                            {dapData?.rentableArea && (
                              <td>{numWithComma(dapData?.rentableArea)}</td>
                            )}
                          </tr>
                          <tr>
                            <th>
                              <b>Units:</b>
                            </th>
                            {dapData?.units && (
                              <td>{numWithComma(dapData.units)}</td>
                            )}
                          </tr>
                        </>
                      )}

                      <tr>
                        <th>
                          <b>Investment Type:</b>
                        </th>
                        <td>{dapData?.investmentType}</td>
                      </tr>
                      <tr>
                        <th>
                          <b>Investment Subtype:</b>
                        </th>
                        <td>{dapData?.investmentSubtype}</td>
                      </tr>
                      <tr>
                        <th>
                          <b>Debt:</b>
                        </th>
                        <td>{dapData?.debt === "1" ? "Yes" : "No"}</td>
                      </tr>
                      <tr>
                        <th>
                          <b>Ownership Type:</b>
                        </th>
                        <td>{dapData?.ownershipType}</td>
                      </tr>
                      <tr>
                        <th>
                          <b>Ownership %:</b>
                        </th>
                        {dapData?.ownerShip && (
                          <td>{dapData?.ownerShip.toFixed(2)}</td>
                        )}
                      </tr>
                      <tr>
                        <th>
                          <b>Investment Description:</b>
                        </th>
                        <td>
                          <GroupedAutocomplete
                            group={
                              groups.find(
                                (group) =>
                                  group.key === SummaryDropdown.INVESTMENT_DESC
                              ) || { key: "", options: [] }
                            }
                            selectedOption={selectedInvestOption}
                            onSelect={(option) =>
                              handleSelect(
                                SummaryDropdown.INVESTMENT_DESC,
                                option
                              )
                            }
                            label="Select Investment Description"
                          />
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <b>Primary Lease Type:</b>
                        </th>
                        <td>
                          <GroupedAutocomplete
                            group={
                              groups.find(
                                (group) =>
                                  group.key ===
                                  SummaryDropdown.PRIMARY_LEASE_TYPE
                              ) || { key: "", options: [] }
                            }
                            selectedOption={selectedLeaseOption}
                            onSelect={(option) =>
                              handleSelectLeaseType(
                                SummaryDropdown.PRIMARY_LEASE_TYPE,
                                option
                              )
                            }
                            label="Select Primary Lease Type"
                          />
                        </td>
                      </tr>
                      <tr>
                        <th
                          style={
                            {
                              padding: "6px",
                              width: "200px",
                              marginTop: "-30px",
                            } as React.CSSProperties
                          }
                        >
                          <b>JV Partner:</b>
                        </th>
                        <td>
                          <TextField
                            InputProps={{
                              style: {
                                margin: "0px",
                                fontFamily:
                                  "Roboto, Helvetica, Arial, sans-serif",
                                fontSize: "0.875rem",
                                color: "rgba(0, 0, 0, 0.6)",
                              },
                            }}
                            value={dapData?.jvPartner}
                            name="jvPartner"
                            className="m18 min-height"
                            fullWidth
                            multiline
                            onChange={handleInputChange}
                          />
                        </td>
                      </tr>
                    </table>
                  </Item>
                )}
              </Grid>
            </Grid>
          </Box>

          {/* 2nd block */}
          <Box
            sx={{ flexGrow: 1 }}
            className="summary-property-section summary-form"
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Item>
                  <h3 className="text-left pl-10">Property Images</h3>
                  <div className="clear"></div>
                  <Carouselcomponent />
                </Item>
              </Grid>
              <Grid item xs={12} md={6}>
                {/* {!dapData ? (
                  <Skeleton
                    variant="rounded"
                    width="100%"
                    height={350}
                    animation="wave"
                  />
                ) : ( */}
                <Item>
                  <h3 className="text-left pl-10">
                    Additional Property Description
                  </h3>
                  <div className="clear"></div>
                  <div style={{ marginLeft: "12px" }}>
                    <FroalaRichText
                      model={comment?.commentText || ""}
                      handlesModelChange={handleCommentChange}
                    />
                  </div>
                </Item>
                {/* )} */}
              </Grid>
            </Grid>
          </Box>
        </div>
      </Box>
      {errorStatus.error && (
        <GenericToast message={errorStatus.error} severity="error" />
      )}
      {/* {toastMessageOpen && (
        <GenericToast
          message={toastMessage.message}
          severity={toastMessage.severity}
        />
      )} */}
    </div>
  );
};
