import React, { useEffect } from "react";

import Skeleton from "@mui/material/Skeleton";

import { getLeasingResidential } from "../../../../redux/slices/leasingResidentialSlice";
import { useAppSelector, useAppDispatch } from "../../../../redux/hooks";
import { TableColumn, TableRowData } from "../tableComponent";
import TableComponent from "../tableComponent";
import { GenericToast } from "../../../util/ToastMessage";

import { AssetPlanParams } from "../../../../types/assetplanid";
import { percentage, numberWithComma } from "../../../util/valueFormatter";

const ResidentialLeasingActivityNew: React.FC = () => {
  const leasingColumns: TableColumn[] = [
    { key: "totalUnits", label: "Total Units:", editable: false },
    { key: "rentableSqft", label: "Rentable SqFt:", editable: false },
    {
      key: "physicalOccupancyPC",
      label: "Projected Avg Physical Occupancy:",
      editable: false,
    },
    { key: "leaseRenewalPC", label: "Lease Renewal%:", editable: false },
  ];

  const id = "1092";
  
  const dispatch = useAppDispatch();
  const leasingActivity = useAppSelector(
    (state) => state.leasingResidential.data
  );
  const leasingActivityStatus = useAppSelector(
    (state) => state.leasingResidential
  );
  const assetPlanId = useAppSelector((state) => state.getAssetPlanId.assetPlanId);
  const postData: AssetPlanParams = {
    assetPlanId: assetPlanId,
  };

  const handleCellChange = (newValue: string, key: string) => {
    // Update the table data in the parent component's state or dispatch an action in Redux
    //const updatedTableData = { ...tableData, [key]: newValue };
    //setTableData(updatedTableData);
  };
  useEffect(() => {
    if (leasingActivity===null) {
      dispatch(getLeasingResidential(postData));
    }
  }, [assetPlanId]);
  const tableData: TableRowData = leasingActivity
    ? {
      totalUnits: numberWithComma(leasingActivity.totalUnits),
      rentableSqft: numberWithComma(leasingActivity.rentableSqft),
      physicalOccupancyPC: percentage(leasingActivity.physicalOccupancyPC),
      leaseRenewalPC: percentage(leasingActivity.leaseRenewalPC),
      }
    : {};

  return (
    <div>
      {leasingActivityStatus.loading ? (
        <Skeleton
          variant="rounded"
          width="100%"
          height={150}
          animation="wave"
        />
      ) : (
        <TableComponent
          tableData={tableData}
          columns={leasingColumns}
          onCellChange={handleCellChange}
        />
      )}
       {leasingActivityStatus.error && (
        <GenericToast
          message={leasingActivityStatus.error}
          severity="error"
        />
      )}
    </div>
  );
};

export default ResidentialLeasingActivityNew;
