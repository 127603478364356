import React, { useRef, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Button from "@mui/material/Button";
import { useAppSelector, useAppDispatch } from "../../../redux/hooks";
import { GenericToast } from "../../util/ToastMessage";
import { changeStatus } from "../../../redux/slices/onChangeSlice";
//import { Rootstate } from '../redux/rootReducer.types';

import {
  FileUploadContainer,
  FormField,
  DragDropText,
  UploadFileBtn,
  FilePreviewContainer,
  ImagePreview,
  PreviewContainer,
  PreviewList,
  FileMetaData,
  RemoveFileIcon,
  InputLabel,
} from "./file-upload.styles";

const KILO_BYTES_PER_BYTE = 1000;
const DEFAULT_MAX_FILE_SIZE_IN_BYTES = 100000000;

const convertNestedObjectToArray = (nestedObj) =>
  Object.keys(nestedObj).map((key) => nestedObj[key]);

const convertBytesToKB = (bytes) => Math.round(bytes / KILO_BYTES_PER_BYTE);

const ReactFileUpload = ({
  updateFilesCb,
  maxFileSizeInBytes = DEFAULT_MAX_FILE_SIZE_IN_BYTES,
  ...otherProps
}) => {
  const dispatch = useAppDispatch();
  const docOnChangeStatus: boolean = useAppSelector(
    (state) => state.onchangeFlagForDocComponent.isChanged
  );
  const fileInputField = useRef(null);
  const [files, setFiles] = useState({});
  const [siteStackingImage, setSiteStackingImage] = useState(false);
  const [fileNameValidation, setFileNameValidation] = useState(false);
  const [approvedFileType, setApprovedFileType] = useState(false);
  const [toast, setToast] = useState(false);
  const [toastMessage, setToastMessage] = useState<any>({
    message: "",
    severity: "",
  });
  const handleUploadBtnClick = () => {
    alert("test");
    //fileInputField.current.click();
  };

  const addNewFiles = (newFiles) => {
    var format = /[!@#$%^&*()+\=\[\]{};':"\\|,<>\/?]+/;
    var ApprovedfileType = ["pdf", "ppt", "pptx", "zip", "jpg", "xlsx", "xlsm", "xlsb", "xls","doc","docx","jpeg","gif", "png","bmp","tiff","txt","msg","csv"]
    for (let file of newFiles) {
      const fileType = file.name.split(".").slice(-1)[0]?.toLowerCase();
      if(format.test(file.name))
      {
        setToast(true);
        setToastMessage({
          message: "Please remove any special characters from file name",
          severity: "error",
        });
        setTimeout(() => setToast(false), 4000);
      }
      else{
      if(!(ApprovedfileType.includes(fileType))){
        setToast(true);
        setToastMessage({
          message: "Please upload file with valid extension",
          severity: "error",
        });
        setTimeout(() => setToast(false), 4000);
      }
      else{
      let isImageFile = file.type.split("/")[0] === "image";
      if (
        (otherProps.sectionValue === "SitePlan" ||
          otherProps.sectionValue === "StackingPlan") &&
        !isImageFile
      ) {
        setToast(true);
        setToastMessage({
          message: "Please upload only images in this section" ,
          severity: "error",
        });
        setTimeout(() => setToast(false), 4000);
      } else {
        if (file.size <= maxFileSizeInBytes) {
          if (!otherProps.multiple) {
            return { file };
          }
          files[file.name] = file;
        }
      }
    }
  }
}
    return { ...files };
  };

  const callUpdateFilesCb = (files) => {
    const filesAsArray = convertNestedObjectToArray(files);
    updateFilesCb(filesAsArray);
  };

  const handleNewFileUpload = (e) => {
    const { files: newFiles } = e.target;
    if (newFiles.length) {
      let updatedFiles = addNewFiles(newFiles);
      setFiles(updatedFiles);
      callUpdateFilesCb(updatedFiles);
    }
  };

  const removeFile = (fileName) => {
    delete files[fileName];
    setFiles({ ...files });
    callUpdateFilesCb({ ...files });
  };

  if (docOnChangeStatus) {
    setFiles({});
    callUpdateFilesCb({});
    dispatch(changeStatus(false));
  }

  return (
    <>
      <FileUploadContainer className="upload-file-wrap">
        <CloudUploadIcon />
        <DragDropText>Drop files to attach, or browse</DragDropText>
        <FormField
          type="file"
          ref={fileInputField}
          onChange={handleNewFileUpload}
          title=""
          value=""
          {...otherProps}
        />
      </FileUploadContainer>
      <FilePreviewContainer>
        <PreviewList>
          {Object.keys(files).map((fileName, index) => {
            let file = files[fileName];
            let isImageFile = file.type.split("/")[0] === "image";
            return (
              <PreviewContainer key={fileName}>
                <div>
                  <a className="remove-image">
                    <CloseIcon onClick={() => removeFile(fileName)} />
                  </a>
                  {isImageFile && (
                    <ImagePreview
                      src={URL.createObjectURL(file)}
                      alt={`file preview ${index}`}
                    />
                  )}
                  <FileMetaData isImageFile={isImageFile}>
                    <span>{file.name}</span>
                    <aside>
                      <span>{convertBytesToKB(file.size)} kb</span>
                      <RemoveFileIcon
                        className="fas fa-trash-alt"
                        onClick={() => removeFile(fileName)}
                      />
                    </aside>
                  </FileMetaData>
                </div>
              </PreviewContainer>
            );
          })}
        </PreviewList>
      </FilePreviewContainer>
      {toast && (
        <GenericToast
          message = {toastMessage.message}
          severity="error"
        />
      )}
      {/* {fileNameValidation && (
        <GenericToast
          message="Please remove any special characters from file name"
          severity="error"
        />
      )}
      {approvedFileType && (
        <GenericToast
          message="File type is not valid"
          severity="error"
        />
      )} */}
      {/* <Button
                onClick={handleSubmit}
                variant="contained"
                component="span"
              >
                Upload Documents
              </Button> */}
    </>
  );
};

export default ReactFileUpload;
