
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {Option} from "./dropdownOptionSlice";

interface DropdownState {
    selectedOptions: { [dropdownId: string]: Option | null };
  }
  
  const initialState: DropdownState = {
    selectedOptions: {},
  };

  const dropdownStateSlice = createSlice({
    name: 'dropdown',
    initialState,
    reducers: {
      setOption: (state, action: PayloadAction<{ dropdownId: string; option: Option }>) => {
        const updatedOptions = { ...state.selectedOptions };
      // Update the specific option
      updatedOptions[action.payload.dropdownId] = action.payload.option;
      // Return the modified state
      return {
        ...state,
        selectedOptions: updatedOptions,
      };
        // const updatedOptions = { ...state.selectedOptions };
        // console.log("dropdown id from slice", action.payload);
        // updatedOptions[action.dropdownId] = action.option;
        // const { dropdownId, option } = action.payload;
        // state.selectedOptions[dropdownId] = option;
      },
    },
  });

export const { setOption } = dropdownStateSlice.actions;

//export const selectSelectedOption = (dropdownId: string | number) => (state: { dropdown: { selectedOptions: { [x: string]: any; }; }; }) => state.dropdown.selectedOptions[dropdownId];
export const selectSelectedOption = (dropdownId: string) => (state): Option | null =>
  state.dropdownState.selectedOptions[dropdownId];
export default dropdownStateSlice.reducer;

