import React from "react";

import Skeleton from "@mui/material/Skeleton";

import { useAppSelector, useAppDispatch } from "../../../../redux/hooks";
import { TableColumn, TableRowData } from "../tableComponent";
import TableComponent from "../tableComponent";
import { GenericToast } from "../../../util/ToastMessage";

import { AssetPlanParams } from "../../../../types/assetplanid";
import { percentage, numberWithComma } from "../../../util/valueFormatter";

const ResidentialOccupancyNew: React.FC = () => {
  const budgetYear= useAppSelector((state)=>state.summary?.dapData?.budgetYear);
  const occupancyColumns: TableColumn[] = [
    { key: "startYearLeaseUnit", label: `#Unit Leased 1/1/${budgetYear}:`, editable: false },
    {
      key: "startYearLeasePC",
      label: `% Leased 1/1/${budgetYear}:`,
      editable: false,
    },
    { key: "newLease", label: `New Leases ${budgetYear}:`, editable: false },
    {
      key: "endYearLeasePC",
      label: `% Leased 12/31/${budgetYear}:`,
      editable: false,
    },
  ];

  const id = "1092";
  const assetPlanId = useAppSelector((state) => state.getAssetPlanId.assetPlanId);
  const postData: AssetPlanParams = {
    assetPlanId: assetPlanId,
  };

  const dispatch = useAppDispatch();
  const occupancy = useAppSelector((state) => state.leasingResidential.data);
  const occupancyStatus = useAppSelector((state) => state.leasingResidential);

  const handleCellChange = (newValue: string, key: string) => {
    // Update the table data in the parent component's state or dispatch an action in Redux
    //const updatedTableData = { ...tableData, [key]: newValue };
    //setTableData(updatedTableData);
  };
  // useEffect(() => {
  //   if (!occupancy) {
  //     dispatch(getresidentialOccupancy(postData));
  //   }
  // }, [dispatch, occupancy, postData]);
  const tableData: TableRowData = occupancy
    ? {
      startYearLeaseUnit: numberWithComma(occupancy.startYearLeaseUnit),
      newLease: numberWithComma(occupancy.newLease),
      startYearLeasePC: percentage(occupancy.startYearLeasePC),
      endYearLeasePC: percentage(occupancy.endYearLeasePC),
    }
    : {};

  return (
    <div>
      {occupancyStatus.loading ? (
        <Skeleton
          variant="rounded"
          width="100%"
          height={150}
          animation="wave"
        />
      ) : (
        <TableComponent
          tableData={tableData}
          columns={occupancyColumns}
          onCellChange={handleCellChange}
        />
      )}
      {occupancyStatus.error && (
        <GenericToast message={occupancyStatus.error} severity="error" />
      )}
    </div>
  );
};

export default ResidentialOccupancyNew;
