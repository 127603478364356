import React, { FC, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Collapse from "@mui/material/Collapse";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import DialogContent from '@mui/material/DialogContent';
import CircularProgress from '@mui/material/CircularProgress';
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Cancel';
import Fab from '@mui/material/Fab';
import { green } from '@mui/material/colors';
import { TransitionProps } from '@mui/material/transitions';
import { Dialog, AppBar, Toolbar, IconButton, Grid, Slide, Checkbox, Typography, DialogActions, DialogContentText, DialogTitle } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { getApiCall, postApiCall } from "../../services/apiHelper";
import { baseUrl } from "../../config/config";
import { CloneData } from "./CloneData";



const columns = [
  {
    field: "",
    headerName: "",
    width: "1%",
  },
  // {
  //   field: "request_id",
  //   headerName: "Request ID",
  //   width: 150,
  // },
  {
    field: "investment_id",
    headerName: "ID",
    width: "10%",
  },
  {
    field: "from_year",
    headerName: "From Year",
    width: "7%",
  },
  {
    field: "to_year",
    headerName: "To Year",
    width: "7%",
  },
  {
    field: "dap_status",
    headerName: "DAP Status",
    width: "15%",
  },
  {
    field: "mode",
    headerName: "Mode",
    width: "10%",
  },
  {
    field: "clone_status",
    headerName: "Clone Status",
    width: "10%",
  },
  {
    field: "timestamp",
    headerName: "Timestamp",
    width: "15%",
  },
  {
    field: "notes",
    headerName: "Notes",
    width: "20%",
  },
];
//to get header of collapse table
export const HeaderRow = ({ columnsName }) => {

  return (
    <TableRow>
      {columnsName?.map((row, index) => (
        <TableCell align="center" width={row.width} >{row.headerName}</TableCell>
      ))}
    </TableRow>
  );
};

//to get body of collapse table

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const TableContent = ({ childRow, index, parentCallback }) => {

  const [logDate, setLogDate] = useState("0/0/0");
  const [logTime, setLogTime] = useState("0:0:0");
  const [checked, setChecked] = React.useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
    parentCallback(childRow.investmentId, childRow.fromYear, childRow.toYear, childRow.dapStatus, event.target.checked);
  };

  useEffect(() => {
    const splitResult = childRow.timeStamp.split('T');
    const splitResultTime = splitResult[1].split('.');

    setLogDate(splitResult[0]);
    setLogTime(splitResultTime[0]);

  }, [childRow])

  return (
    <React.Fragment>
      <TableRow hover key={index}>
        {/* <TableCell>&nbsp;</TableCell>*/}
        {childRow.cloneStatus === "Fail" ?
          <TableCell width={"5%"} >  <Checkbox
            checked={checked}
            onChange={handleChange}
            inputProps={{ 'aria-label': 'controlled' }}
          /></TableCell> : <TableCell >&nbsp;</TableCell>
        }
        <TableCell width={"10%"} align="center">{childRow.investmentId}</TableCell>
        <TableCell width={"7%"} align="center">{childRow.fromYear}  </TableCell>
        <TableCell width={"7%"} align="center">{childRow.toYear}  </TableCell>
        <TableCell width={"15%"} align="center">{childRow.dapStatus}  </TableCell>
        <TableCell width={"10%"} align="center">{childRow.mode == null ? "-" : childRow.mode}</TableCell>
        <TableCell width={"10%"} align="center">{childRow.cloneStatus}  </TableCell>
        <TableCell width={"15%"} align="center">{logDate + " " + logTime}  </TableCell>
        <TableCell width={"20%"} align="center">{childRow.notes == null ? "-" : childRow.notes}</TableCell>
      </TableRow>
    </React.Fragment>
  )
}

export const BodyRows = ({ rows, tableValue, parentCallback }) => {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);


  };

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>

        {tableValue == "1" ?
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell> :
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {rows.containsFail ? (open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />) : null}
            </IconButton>
          </TableCell>}

        <TableCell scope="row" colSpan={10}>
          <Grid style={{ display: "flex", width: "100%", justifyContent: "space-between", margin: "0.5em 0" }} >
            <Grid item >
              <Typography style={{ fontWeight: 800 }} >
                {rows.requestID}
              </Typography>
            </Grid>
            {tableValue == "2" && !rows.containsFail ? <Grid item>
              <Button variant="contained" style={{ fontWeight: 800 }} color="primary"
                // onClick={() => recloneHandler(rows.requestID)} 
                disabled={!rows.containsFail} >
                Cloned Successfully
              </Button>
            </Grid> : null}
          </Grid>
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box>
              <Table size="small" aria-label="">
                {tableValue == "1" ? (<TableBody>
                  {rows.data.map((childRow, index) => {
                    if (childRow.cloneStatus == "Pass")
                      return (<TableContent childRow={childRow} index={index} key={index} parentCallback={parentCallback} />)
                  })}</TableBody>) : (<TableBody>
                    {rows.data.map((childRow, index) => {
                      if (childRow.cloneStatus == "Fail")
                        return (<TableContent childRow={childRow} index={index} key={index} parentCallback={parentCallback} />)
                    })}
                  </TableBody>)}
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export const CloneDataGrid = () => {
  const [value, setValue] = React.useState("1");
  const [fetchedData, setFetchData] = useState<any>([]);
  const [failedFetchedData, setFailedFetchData] = useState<any>([]);
  const [open, setOpen] = React.useState(false);
  const [reCloneDialogopen, setReCloneDialogOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [reCloningResult, setReCloningResult] = useState({ msg: "Recloning in progress...", status: false });
  const [listIsEmpty, setListIsEmpty] = useState(false);

  const buttonSx = {
    ...(loading && {
      bgcolor: green[500],
      '&:hover': {
        bgcolor: green[700],
      },
    }),
  };



  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpenReclone = () => {
    setReCloneDialogOpen(true);
  };

  const handleCloseReclone = () => {
    setReCloneDialogOpen(false);
    window.location.reload();
  };

  const changeMessage = (newMessage) => {
    // setData(newMessage);
    console.log(newMessage);
  }

  let checkedArray = [{ investmentId: "", fromYear: "", toYear: "", dapStatus: "" }];
  const handleCallback = (investmentId, fromYear, toYear, dapStatus, isChecked) => {
    let avar = { investmentId, fromYear, toYear, dapStatus };

    if (!isChecked) {
      const removeIndex = checkedArray.findIndex(item => item.investmentId === investmentId && item.dapStatus === dapStatus);
      console.log(removeIndex);
      checkedArray.splice(removeIndex, 1);
    } else {
      checkedArray.push(avar);
    }
  }

  const reCloneHandler = async () => {
    checkedArray.splice(0, 1);
    let recloneArray = [{}];
    setLoading(true);

    let cloneRequestList = {
      StartInvestmentId: "",
      EndInvestmentId: "",
      FromYear: new Date().getFullYear(),
      ToYear: new Date().getFullYear() + 1,
      StatusList: [""],
      InvestmentId: [""]
    };

    checkedArray.forEach(recloneID => {
      cloneRequestList.InvestmentId = [recloneID.investmentId];
      cloneRequestList.FromYear = parseInt(recloneID.fromYear);
      cloneRequestList.ToYear = parseInt(recloneID.toYear);
      cloneRequestList.StatusList = [`${recloneID.dapStatus.toLocaleLowerCase()}`]

      recloneArray.push(cloneRequestList);

      cloneRequestList = {
        StartInvestmentId: "",
        EndInvestmentId: "",
        FromYear: new Date().getFullYear(),
        ToYear: new Date().getFullYear() + 1,
        StatusList: [""],
        InvestmentId: [""]
      };
    });

    recloneArray.splice(0, 1);

    console.log(recloneArray);


    if (recloneArray.length == 0) {
      setListIsEmpty(true)
      setReCloningResult({ msg: "Please select the DAP to be recloned.", status: true });
      handleClickOpenReclone();
    } else {
      handleClickOpenReclone();
      try {
        const response = await postApiCall(`${baseUrl}CloneDap/recloneInvestmentIDs`, recloneArray)
          .then((res: any) => {
            console.log(res);
            if (res.isSuccess) {
              setReCloningResult({ msg: "The specified Asset Plans do not exist", status: true });
            } else {
              setReCloningResult({ msg: "The selected Asset Plans were re-cloned!", status: false });
            }

          })
          .then(() => {
            setLoading(false);
          });
        return response;
      } catch (error: any) {
        console.error("Error in POST request:", error);
        setReCloningResult({ msg: `Error in POST request: ${error}`, status: true });
        throw error;
      }
    }

  }

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const response: any = await getApiCall(`${baseUrl}CloneDap/GetAllDapCloneLogModels`)
          .then((res: any) => {
            let logData = [{}];
            let logdataFailed = [{}];
            let checkIfContainsFail = false;

            res.forEach(logModel => {
              logModel.data.forEach(element => {
                if (element.cloneStatus === "Fail") {
                  checkIfContainsFail = true;
                }
              });

              if (checkIfContainsFail === false) {
                logData.push({ ...logModel, containsFail: checkIfContainsFail });

              }


              if (checkIfContainsFail === true) {
                logdataFailed.push({ ...logModel, containsFail: checkIfContainsFail });

              }
              checkIfContainsFail = false;
            });
            logData.splice(0, 1);
            logdataFailed.splice(0, 1);
            setFetchData(logData);

            setFailedFetchData(logdataFailed);
            setLoading(false)

          })
        return response?.data;
      } catch (error: any) {
        setLoading(false)
        console.error("Error in GET request:", error.message);
      }
    }
    fetchData();
  }, [])

  return (
    <>
      <Box sx={{ width: "100%", padding: "15px 5px" }}>
        <Stack direction="row" sx={{ float: "right", margin: "10px 1em" }}>
          {value == "2" ? <Button variant="contained" onClick={reCloneHandler}>
            Re Clone Selected Items
          </Button> : null}

        </Stack>

        <Stack direction="row" sx={{ float: "right", marginTop: "10px" }}>
          <Button variant="contained" className="primary" onClick={handleClickOpen} >
            Add New Clone
          </Button>

        </Stack>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              onChange={handleChange}
              aria-label=""
              sx={{
                ".Mui-selected:hover": {
                  color: `white`,
                },
              }}
            >
              <Tab label="Cloned DAP" value="1" />
              <Tab label="Failed Clones" value="2" />
            </TabList>
          </Box>
          {!loading ? <> <TabPanel
            value="1"
            sx={{ padding: "10px 0px" }}
            className="responsive-table-wrapper"
          >
            <Table className="clone-data-grid responsive-table ">
              <TableHead>
                <HeaderRow columnsName={columns} />
              </TableHead>
              <TableBody>
                {fetchedData.map((clone) => (
                  <BodyRows key={clone.requestID} rows={clone} tableValue={value} parentCallback={handleCallback} />
                ))}
              </TableBody>
            </Table>
          </TabPanel>
            <TabPanel
              value="2"
              sx={{ padding: "10px 0px" }}
              className="responsive-table-wrapper"
            >
              <Table className="clone-data-grid responsive-table ">
                <TableHead>
                  <HeaderRow columnsName={columns} />
                </TableHead>
                {/* {failedFetchedData == undefined ?  */}
                <TableBody>
                  {failedFetchedData?.map((clone) => (
                    <BodyRows key={clone.requestID} rows={clone} tableValue={value} parentCallback={handleCallback} />
                  ))}
                </TableBody>
                {/* //  null} */}
              </Table>
            </TabPanel></> : <>
            <Grid container style={{ width: "100%", textAlign: "center",display:"flex" }}>
              <Grid item style={{ width: "100%", textAlign: "center",margin:"2em"}} >
              <CircularProgress />
              </Grid>
              
            </Grid>

          </>}


        </TabContext>
        <Dialog
          fullScreen
          open={open}
          onClose={handleClose}
          TransitionComponent={Transition}
        >
          <AppBar sx={{ position: 'fixed' }} style={{ backgroundColor: "#253746" }} >
            <Toolbar>
              <Grid item style={{ display: "flex", justifyContent: "space-between", width: "100%" }} >
                <div className="header">
                  <h2 className="app-title"><div className="logo"></div>| Digital Asset Plan</h2>
                  <div className="header-right">
                  </div>
                </div>
                <IconButton
                  size="large"
                  edge="start"
                  color="inherit"
                  aria-label="close"
                  onClick={handleClose}
                  sx={{ mr: 2 }}
                ><CloseIcon />
                </IconButton>
              </Grid>
            </Toolbar>
          </AppBar>
          <CloneData changeMessage={changeMessage} handleClose={handleClose} />
        </Dialog>

      </Box>

      <Dialog
        open={reCloneDialogopen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseReclone}
        aria-describedby="alert-dialog-slide-description"
        className="common-dialog-box"
      >
        <DialogTitle>{reCloningResult.msg}</DialogTitle>
        <DialogContent>
          {/* <DialogContentText id="alert-dialog-slide-description">
            {reCloningResult}
          </DialogContentText> */}

          <DialogContentText id="alert-dialog-slide-description" style={{ display: "flex", justifyContent: "center" }} >

            {!loading ?
              (!reCloningResult.status ? <Fab
                aria-label="save"
                color="primary"
                sx={buttonSx}
              >
                <CheckIcon />
              </Fab> : <Fab
                aria-label="save"
                color="warning"
                sx={buttonSx}
              >
                <CancelIcon />
              </Fab>) : (!listIsEmpty ? <CircularProgress /> : <DialogContentText id="alert-dialog-slide-description">
                No Asset Plan selected
              </DialogContentText>)}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseReclone}>Continue</Button>
        </DialogActions>
      </Dialog></>
  );
};
