
//  import { Paper } from "@mui/material";
 import React, { FC } from "react"

 interface img{
     image: string;

 }
const Imageitem:FC<img> = (props:img) =>{
 return (<>
 
     <img src={props.image} style={{width:"100%", height:"45vh", margin:"auto",padding: "10px"}}/>

 </>)
 }

export default Imageitem;