import handlebars from "handlebars";
import propertyImagesTemplate from "../templates/imagesWithHeader";
import imagesTemplate from "../templates/images";
import noImages from "../templates/noImages";
import { sliceIntoChunks } from "../util/helper";

export const compilePropertyImagesTemplate = (
    propertyImages: any[]
): any[] => {



    const compiledImagesWithHeaderTemplate = handlebars.compile(propertyImagesTemplate);
    const compiledImagesTemplate = handlebars.compile(imagesTemplate);

    const compiledTemplates: any[] = [];
    const formmattedImages = formatImagesData(propertyImages);

    if (propertyImages == null || propertyImages.length === 0) {
        const compiledNoImagesTemplate = handlebars.compile(noImages);
        compiledTemplates.push(compiledNoImagesTemplate({headerName: "Property Images"}));
        return compiledTemplates;
    };

    //split array into chunks of size 2 (number of images per page in pdf)
    const chunks = sliceIntoChunks(formmattedImages, 2);

    compiledTemplates.push(compiledImagesWithHeaderTemplate({ headerName: "Property Images", images: [...chunks[0]] }));

    for (var index = 1; index < chunks.length; index++) {
        compiledTemplates.push(compiledImagesTemplate({ images: [...chunks[index]] }));
    }

    // Return the compiled HTML
    return compiledTemplates;
};

const formatImagesData = (images) => {
    return images.map((image) => {
         //return `data:image/jpeg;base64,${image}`;
        return `${image}`;

    });
};