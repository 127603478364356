import handlebars from 'handlebars';

const eq = (a: any, b: any) => a === b;

// Register the 'eq' helper with Handlebars
handlebars.registerHelper('eq', eq)


const valuationTemplate = `
<!DOCTYPE html>
<html>
<head>
</head>
<body>
  <!-- print table -->
  <table id="print-pdf-table">
    <h2 class="pdf-table-heading">Valuation History</h2>
    <tr>
      <th>Appraisal Date</th>
      <th>Appraisal Amt ($)</th>
      {{#if (eq propertyType 'Residential')}}
      <th>PrUnit Amt ($)</th>
      {{else}}
        {{#if (eq propertyType 'Commertial')}}
        <th>PrSqFt Amt ($)</th>
        {{else}}
        <th>PrSqFt Amt ($)</th>
        <th>PrUnit Amt ($)</th>
        {{/if}}
      {{/if}}
      <th>Implied Cap Rate (%)</th>
      <th>Exit Cap Rate (%)</th>
      <th>Discount Rate (%)</th>
    </tr>

    {{#each valuationData}}
    <tr>
      <td>{{this.appraisaL_DATE}}</td>
      <td>{{this.appraisaL_AMOUNT}}</td>
      {{#if (eq ../propertyType 'Residential')}}
      <td>{{this.peR_UNIT_APPRAISAL_AMT}}</td>
      {{else}}
        {{#if (eq ../propertyType 'Commertial')}}
        <td>{{this.peR_SQFT_APPRAISAL_AMT}}</td>
        {{else}}
        <td>{{this.peR_SQFT_APPRAISAL_AMT}}</td>
        <td>{{this.peR_UNIT_APPRAISAL_AMT}}</td>
        {{/if}}
      {{/if}}
      <td>{{this.implieD_CAP_RATE}}</td>
      <td>{{this.exiT_CAP_RATE}}</td>
      <td>{{this.discounT_RATE}}</td>
    </tr>
    {{/each}}
    
    <tr>
    </tr>
  </table>
  <br>
  <table id="comment-table">
      <tbody>
        <tr>
          <th>Comments</th>
        </tr>
        <tr>
          <td>
            {{{valuationComment.commentText}}}
          </td>
        </tr>
      </tbody>
    </table>
</body>
</html>


`;

export default valuationTemplate;
