import { ChangeEvent, useState } from 'react';
import { Autocomplete, AutocompleteRenderInputParams, TextField, AutocompleteInputChangeReason } from '@mui/material';

export type Option = {
  text: string;
  value: string;
  order: string;
};

export type Group = {
  key: string;
  options: Option[];
};

type GroupedAutocompleteProps = {
  group: Group;
  onSelect: (option: Option) => void;
  label:string,
  selectedOption :Option | null;
  readOnly?: boolean;
};

const GroupedAutocomplete = ({ group, onSelect,label,selectedOption,readOnly  }: GroupedAutocompleteProps) => {
  const [inputValue, setInputValue] = useState('');

  const handleInputChange = (event: ChangeEvent<{}>, value: string, reason: AutocompleteInputChangeReason) => {
    const newInputValue = value || '';
    setInputValue(newInputValue);
  };

  const handleSelect = (event: React.ChangeEvent<{}>, option: Option | null) => {
    console.log("step-3");
    if (option) {
      onSelect(option);
    }
  };

  return (
    <Autocomplete
      className="min-height1"
      options={group?.options}
      //groupBy={(option) => option.order}
      getOptionLabel={(option) => option.text}
      inputValue={inputValue}
      onInputChange={handleInputChange}
      onChange={handleSelect}
      value={selectedOption}
      disabled={readOnly}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField {...params} inputProps={{ ...params.inputProps, style: { fontFamily: "Roboto, Helvetica, Arial, sans-serif", fontSize: "0.875rem", color: "rgba(0, 0, 0, 0.6)" } }} variant="outlined"/>
      )}
      fullWidth
      sx={{ mb: 2 }}
    />
  );
};

export default GroupedAutocomplete;
