import { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { RecommendationData } from "./recommendationSlice";
import { LeasingCommercial } from "./leasingCommercialSlice";
import { DocumentComment } from "./documentCommentSlice";

interface Summary {
  investmentDescription?: string;
  primaryLeaseType?: string;
  jvPartner?: string;
  assetPlanId?: string;
  investmentId?: string;
}

interface RecommendationTab {
  assetPlanId?: string;
  recommendations: RecommendationData[];
}
interface CapitalProject {
  projectPriority?: string;
  esgImpact?: boolean;
  comment?: string;
  id?: number;
}

interface CapitalProjectData {
  assetPlanId?: string;
  capitalProjects: CapitalProject[];
}
interface DocumentData {
  documents: DocumentComment[];
}
interface CapitalPlanYearData {
  assetPlanId?: string;
  capitalPlanYear?: number;
}

interface UpdateDataRequest {
  assetPlanId?: string | null;
  summary?: Summary | null;
  status?: string | null;
  recommendationTab?: RecommendationTab | null;
  capitalProjectData?: CapitalProjectData | null;
  documentData?: DocumentData | null;
  capitalPlanYearData?: CapitalPlanYearData | null;
  occupancyCommercial?: LeasingCommercial | null;
  useraction?: string | null;
}

const initialState: UpdateDataRequest = {
  assetPlanId: null,
  summary: null,
  status: null,
  recommendationTab: { assetPlanId: undefined, recommendations: [] },
  capitalProjectData: { assetPlanId: undefined, capitalProjects: [] },
  documentData: { documents: [] },
  useraction: null,
  occupancyCommercial: null,
};

const updateDataSlice = createSlice({
  name: "updateData",
  initialState,
  reducers: {
    resetState: (state) => {
      state = initialState;
    },
    updateSummary: (state, action: PayloadAction<Summary>) => {
      state.summary = { ...state.summary, ...action.payload };
    },
    updateRecommendation: (
      state,
      action: PayloadAction<RecommendationData & { assetPlanId: any }>
    ) => {
      const { assetPlanId, ...recommendation } = action.payload;
      const existingIndex =
        recommendation.id === 0
          ? state.recommendationTab?.recommendations?.findIndex(
              (rec) =>
                rec.category === recommendation.category &&
                rec.key === recommendation.key
            )
          : state.recommendationTab?.recommendations?.findIndex(
              (rec) => rec.id === recommendation.id
            );

      if (existingIndex !== undefined && existingIndex !== -1) {
        state.recommendationTab!.recommendations[existingIndex] = {
          ...state.recommendationTab!.recommendations[existingIndex],
          ...recommendation,
        };
      } else {
        state.recommendationTab!.recommendations?.push(recommendation);
      }

      state.recommendationTab!.assetPlanId = assetPlanId;
    },

    updateCapitalProject: (state, action: PayloadAction<CapitalProject>) => {
      console.log("inside update slice", action.payload);
      const updatedProject = action.payload;
      const existingProjectIndex =
        state.capitalProjectData?.capitalProjects?.findIndex(
          (project) => project.id === updatedProject.id
        );

      if (existingProjectIndex !== undefined && existingProjectIndex !== -1) {
        state.capitalProjectData!.capitalProjects[existingProjectIndex] = {
          ...updatedProject,
        };
      } else {
        state.capitalProjectData!.capitalProjects.push(updatedProject);
      }
    },
    updateDocumentComment: (state, action: PayloadAction<DocumentComment>) => {
      console.log("inside document slice", action.payload);
      const updatedDoc = action.payload;

      // check if file id of comment being added is already in the slice
      //var isPresent = state.documentData?.documents.some(existingDoc=>existingDoc.id === updatedDoc.id)
      const indexOfFileToUpdate = state.documentData!.documents.findIndex(
        (oldDoc) => oldDoc.id == updatedDoc.id
      );
      //state.documentData?.documents.some(existingDoc=>console.log(existingDoc.id))
      //console.log(isPresent);

      // if item is not in slice so add it
      if (indexOfFileToUpdate === -1) {
        console.log("adding commment...");
        state.documentData!.documents?.push(updatedDoc);
      }
      //if the comment file already exists in updatedataslice, update it
      else {
        console.log("updating comment...");
        state.documentData!.documents[indexOfFileToUpdate] = { ...updatedDoc };
      }
    },

    updateCapitalPlanYear: (
      state,
      action: PayloadAction<CapitalPlanYearData>
    ) => {
      state.capitalPlanYearData = action.payload;
    },
    updateStatus: (state, action: PayloadAction<string>) => {
      console.log("check status", action.payload);
      state.status = action.payload;
    },
    updateAssetPlanId: (state, action: PayloadAction<string>) => {
      console.log("action payload", action.payload);
      state.assetPlanId = action.payload;
    },
    updateLeasingCommercial: (
      state,
      action: PayloadAction<LeasingCommercial>
    ) => {
      state.occupancyCommercial = action.payload;
    },
  },
});

export const {
  resetState: resetSaveDataStateAction,
  updateSummary,
  updateRecommendation,
  updateCapitalProject,
  updateDocumentComment,
  updateCapitalPlanYear,
  updateStatus,
  updateAssetPlanId,
  updateLeasingCommercial,
} = updateDataSlice.actions;
export default updateDataSlice.reducer;
