import React from 'react';
import { Table, TableBody, TableCell, TableRow,Box } from '@mui/material';
import { createTheme, ThemeProvider} from '@mui/material/styles';


import './DynamicTable.css';

export interface TableColumn {
  key: string;
  label: string;
  editable?: boolean;
  valueCellClassName?: string;
  format?: (value: number) => string;
}

export interface TableRowData {
  [key: string]: number | string;
}

export interface DynamicTableProps {
  tableData: TableRowData;
  columns: TableColumn[];
  onCellChange: (newValue: string, key: string) => void;
}


const TableComponent: React.FC<DynamicTableProps> = ({ tableData, columns, onCellChange }) => {
    const theme = createTheme({
        components: {
          MuiTableCell: {
            styleOverrides: {
              root: {
                // backgroundColor: 'lightblue',
                padding: '2px', // Set the desired padding value
              },
            },
          },
        },
      });
    //   const useStyles = makeStyles((theme) => ({
    //     alternateRow: {
    //       backgroundColor: '#f5f5f5', // Grey background color for alternate rows
    //     },
    //   }));


  const handleCellChange = (e: React.ChangeEvent<HTMLInputElement>, key: string) => {
    const newValue = e.target.value;
    onCellChange(newValue, key);
  };

  return (
    <ThemeProvider theme={theme}>
    <Box  className="table-container" sx={{ height: '10px' }}>
      <Table className="table">
        <TableBody>
          {columns.map(column => (
            <TableRow key={column.key}>
              <TableCell className="table-label-cell">{column.label}</TableCell>
              <TableCell className={(column.valueCellClassName ? `${column.valueCellClassName} table-data-cell` : "table-data-cell")}>
                {column.editable ? (
                  <input
                    type="text" className={(column.valueCellClassName ? `${column.valueCellClassName}` : "")}
                    defaultValue={tableData[column.key] as string}
                    onChange={(e) => handleCellChange(e, column.key)}
                  />
                ) : (
                  column.format ? column.format(tableData[column.key] as number) : tableData[column.key]
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      </Box> 
    </ThemeProvider>
  );
};

export default TableComponent;
