import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { baseUrl } from "../../config/config";
import { postApiCall } from "../../services/apiHelper";
import { RootState } from "../store";

export interface requestForPropertyImages {
  investmentId: string;
}

interface InitialState {
  ImagesOfProperty: ImagesOfProperty[];
  error: string;
  loading: boolean;
  cacheKey: string | undefined;
}
const initialState: InitialState = {
  ImagesOfProperty: [],
  error: "",
  loading: false,
  cacheKey: undefined,
};
interface ImagesOfProperty {
  original: string;
  thumbnail: string;
}

export const propertyImages = createAsyncThunk<
  ImagesOfProperty[],
  requestForPropertyImages,
  { state: RootState }
>(
  "propertyImages",
  async (requestForPropertyImages: requestForPropertyImages, { getState }) => {
    const state = getState().propertyImages;

    if (
      state.ImagesOfProperty.length >= 0 &&
      state.cacheKey === "propertyImagesCache"
    ) {
      // Return the current data if it exists in the state and cacheKey is set
      return state.ImagesOfProperty;
    }

    if (requestForPropertyImages?.investmentId == null) return [];
    const responsePropertyImages = await postApiCall(
      // `${baseUrl}Document/GetPropertyImages`,
      `${baseUrl}Document/GetPropertyImagesPresignedUrls`,
      requestForPropertyImages
    ).then((res: any) => {
      console.log(res);
      return res;
    });
    return responsePropertyImages;
  }
);

export const propertyImagesSlice = createSlice({
  name: "propertyImages",
  initialState,
  reducers: {
    resetState: (state) => {
      state.ImagesOfProperty = [];
      state.error = "";
      state.loading = false;
      state.cacheKey = undefined;
    },
  },
  extraReducers: (builder) => {
    // eslint-disable-next-line
    builder.addCase(propertyImages.pending, (state) => {
      state.loading = true;
    }),
      builder.addCase(
        propertyImages.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.ImagesOfProperty = action.payload;
          state.cacheKey = "propertyImagesCache";
          console.log("rec data", state.ImagesOfProperty);
        }
      ),
      builder.addCase(propertyImages.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "something went wrong";
      });
  },
});

export const { resetState: resetPropertyImagesAction } =
  propertyImagesSlice.actions;
export default propertyImagesSlice.reducer;