import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { baseUrl, headers } from "../../config/config";
import { postApiCall } from "../../services/apiHelper";
import {PostData} from './commertialLeasingSlice';

export interface ResidentialOccupancy {
  investmentId: number;
  unitLeasedSOY: number;
  leasedPercentageSOY: number;
  newLeases: number;
  leasedPercentageEOY:number;
}

interface ResidentialOccupancyState {
  data: ResidentialOccupancy|null;
  error: string;
  loading: boolean;
}

const initialState: ResidentialOccupancyState = {
  data: null,
  error: "",
  loading: false,
};


export const getresidentialOccupancy = createAsyncThunk(
  "getcommertialOccupancy",
  async (payload: PostData) => {
    try {
      const response = await postApiCall<PostData, ResidentialOccupancyState>(
        `${baseUrl}Leasing/GetResidentialOccupancy`,
        payload,
        headers
      );
      return response.data;
    } catch (error: any) {
      console.error("Error in POST request:", error);
      throw error;
    }
  }
);

const residentialOccupancySlice = createSlice({
  name: "residentialOccupancy",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getresidentialOccupancy.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        getresidentialOccupancy.fulfilled,
        (state, action: PayloadAction<ResidentialOccupancy|null>) => {
          state.loading = false;
          state.data = action.payload;
        }
      )
      .addCase(getresidentialOccupancy.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Something went wrong";
      });
  },
});

export default residentialOccupancySlice.reducer;
