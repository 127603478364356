import { DapRoles, DapStatus,ActionNames } from "../constant";// Import the necessary constants
import { baseUrl } from "../config/config";
import { postApiCall } from "../services/apiHelper";

export interface UpdateDapStatusRequest {
  Email: string | undefined;
  UserAction: string | undefined;
  AssetPlanId: string | undefined;
  Comments: string | undefined;
}

export function shouldDisplayButton(
  currentRole: string[]|undefined,
  currentStatus: string,
  isBelongToCurrentSector: boolean,
  buttonName: string,
  isAdmin:boolean,
  isAnalyst:boolean
): boolean {
  const lowerCaseCurrentStatus = currentStatus.split(" ").join("").toLowerCase();
  if(currentRole===undefined)
      currentRole=[];
  const hasRole = (role: string) =>(currentRole ?? []).some(r => r.toLowerCase() === role.toLowerCase());

  let isStatus= false;

  switch (buttonName) {
    case ActionNames.RECALL:
      isStatus=(lowerCaseCurrentStatus === DapStatus.PendingAM_Head.split(" ").join("").toLowerCase() ||
      lowerCaseCurrentStatus === DapStatus.PendingAPM_PM.split(" ").join("").toLowerCase()) ;
      return (
        (isAdmin && isStatus )||
        (isStatus &&
          (isAnalyst ||
            (hasRole(DapRoles.AssetManager) &&
              isBelongToCurrentSector)))
      );

    case ActionNames.SAVE:
            isStatus= (lowerCaseCurrentStatus === DapStatus.Saved.split(" ").join("").toLowerCase() ||
            lowerCaseCurrentStatus === DapStatus.PendingAM.split(" ").join("").toLowerCase() ||
            lowerCaseCurrentStatus ===
              DapStatus.RejectedByAM_Head.split(" ").join("").toLowerCase() ||
            lowerCaseCurrentStatus ===
              DapStatus.RejectedbyAPM_PM.split(" ").join("").toLowerCase() ||
            lowerCaseCurrentStatus ===
              DapStatus.RejectedByAM_Analyst.split(" ").join("").toLowerCase() ||
            lowerCaseCurrentStatus ===
              DapStatus.RecalledByAM_Analyst.split(" ").join("").toLowerCase() ||
            lowerCaseCurrentStatus === DapStatus.RevokedByAPM_PM.split(" ").join("").toLowerCase());
      return (
        (isAdmin && isStatus) ||
        (isStatus &&
          (isAnalyst ||
            (hasRole(DapRoles.AssetManager) &&
              isBelongToCurrentSector)))
      );

    case ActionNames.SUBMIT:
      isStatus= (lowerCaseCurrentStatus === DapStatus.Saved.split(" ").join("").toLowerCase() ||
      lowerCaseCurrentStatus === DapStatus.PendingAM.split(" ").join("").toLowerCase() ||
      lowerCaseCurrentStatus ===
        DapStatus.RejectedByAM_Head.split(" ").join("").toLowerCase() ||
      lowerCaseCurrentStatus ===
        DapStatus.RejectedbyAPM_PM.split(" ").join("").toLowerCase() ||
      lowerCaseCurrentStatus ===
        DapStatus.RejectedByAM_Analyst.split(" ").join("").toLowerCase() ||
      lowerCaseCurrentStatus ===
        DapStatus.RecalledByAM_Analyst.split(" ").join("").toLowerCase() ||
      lowerCaseCurrentStatus === DapStatus.RevokedByAPM_PM.split(" ").join("").toLowerCase());
      return (
        (isAdmin && isStatus)||
        (hasRole(DapRoles.AssetManager) &&
          isBelongToCurrentSector && isStatus
         )
      );

    case ActionNames.REJECT_BUDGET:
      isStatus=(
        lowerCaseCurrentStatus === DapStatus.PendingAM.split(" ").join("").toLowerCase()
      ||lowerCaseCurrentStatus===DapStatus.RecalledByAM_Analyst.split(" ").join("").toLowerCase()
      ||lowerCaseCurrentStatus===DapStatus.RejectedByAM_Head.split(" ").join("").toLowerCase()
      ||lowerCaseCurrentStatus===DapStatus.RejectedbyAPM_PM.split(" ").join("").toLowerCase()
    );

      return (
        (isAdmin && isStatus) ||
        ((isAnalyst ||
          (hasRole(DapRoles.AssetManager) &&
            isBelongToCurrentSector)) &&
          isStatus)
      );

    case ActionNames.REVOKE:
      isStatus=(lowerCaseCurrentStatus === DapStatus.Approved.split(" ").join("").toLowerCase());
      return (
        (isAdmin && isStatus) ||
        ((hasRole(
          DapRoles.AssistantPortfolioManager) ||
          hasRole(DapRoles.PortfolioManager)) &&
          isBelongToCurrentSector &&
          isStatus)
      );

    case ActionNames.APPROVE:
    case ActionNames.REJECT:
      return (
        (isAdmin && ((lowerCaseCurrentStatus === DapStatus.PendingAM_Head.split(" ").join("").toLowerCase()) ||
        lowerCaseCurrentStatus === DapStatus.PendingAPM_PM.split(" ").join("").toLowerCase())) ||
        (hasRole(DapRoles.AssetManagerHead) &&
          isBelongToCurrentSector &&
          lowerCaseCurrentStatus === DapStatus.PendingAM_Head.split(" ").join("").toLowerCase()) ||
        ((hasRole(
          DapRoles.AssistantPortfolioManager) ||
          hasRole(DapRoles.PortfolioManager)) &&
          isBelongToCurrentSector &&
          lowerCaseCurrentStatus === DapStatus.PendingAPM_PM.split(" ").join("").toLowerCase())
      );

    // Add more cases for other buttons

    default:
      return false;
  }
}

interface ApiResponse {
  success: boolean;
  
  errormessage?: string;
}

export const handleAction = async (actionName: string, request: UpdateDapStatusRequest): Promise<ApiResponse> => {
  let apiName = '';
  switch (actionName) {
    case ActionNames.RECALL:
      apiName = 'UpdateRecallStatus';
      break;
    case ActionNames.REVOKE:
      apiName = 'UpdateRevokeStatus';
      break;
    case ActionNames.APPROVE:
      apiName = 'UpdateApproveStatus';
      break;
    // case ActionNames.REJECT:
    //   apiName = 'UpdateRejectStatus';
    //   //dispatch(rejectionCommentPopup(true));
    //   break;
    case ActionNames.REJECT_BUDGET:
      apiName = 'UpdateBudgetRejectStatus';
      break;
    default:
      // Handle unknown action
      throw new Error('Unknown action');
  }

  try {
    if (apiName !== '') {
      const response = await postApiCall<UpdateDapStatusRequest, ResponseType>(`${baseUrl}UpdateData/${apiName}`, request);
      return {
        success: true,
        errormessage:''
      };
    }
    // Handle the response as needed
    // Hide loader or perform action on successful response
    throw new Error('Unknown action');
  } catch (error) {
    // Handle the error
    // Hide loader or perform action on error response
    return {
      success: false,
      errormessage:  (error as Error).message || 'Something went wrong',
    };
  }
};









