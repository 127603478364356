import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { baseUrl } from "../../config/config";
import { getApiCall } from "../../services/apiHelper";
import { RootState } from "../store";

export interface RecommendationData {
  id: number;
  category: string;
  key: string;
  value: string;
  assetManagerRationale: string;
}

interface InitialState {
  data: RecommendationData[]; // Set initial state of data as null
  error: string;
  loading: boolean;
  cacheKey:string|undefined;
}

const initialState: InitialState = {
  data: [],
  error: "",
  loading: false,
  cacheKey:undefined
};
export const selectedCacheKey = (state) => state.cacheKey;
export const getRecommmedations = createAsyncThunk<
  RecommendationData[],
  string,
  { state: RootState }
>("getRecomData", async (assetPlanId, { getState, rejectWithValue }) => {
  const state = getState().latestRecom;

  if (state.data?.length > 0 && state.cacheKey ==='RecomCacheKey') {
    // Return the current data if it exists in the state and cacheKey is set
    return state.data;
  }
  // if (state.data.length === 0 && cacheKey === assetPlanId) {
  //   // Return an empty array if there is no data in both the state and the cache
  //   return [];
  // }

  try {
    const response:any = await getApiCall(`${baseUrl}${assetPlanId}/Recommendations`)
    return response?.recommendations;
  } catch (error: any) {
    console.error("Error in GET request:", error?.message);
    return rejectWithValue("something went wrong");
  }
});

export const latestRecomSlice = createSlice({
  name: "latestRecom",
  initialState,
  reducers: {
    resetState: (state) => {
      state.data = [];
      state.error = "";
      state.loading = false;
      state.cacheKey=undefined;
    },
    updateRecommendationData(state, action: PayloadAction<RecommendationData>) {
      const recommendation = action.payload;
      if(recommendation?.value==='')
      return;
      const existingIndex =
        recommendation.id === 0
          ? state.data?.findIndex(
              (rec) =>
                rec.category === recommendation.category &&
                rec.key === recommendation.key
            )
          : state.data?.findIndex((rec) => rec.id === recommendation.id);

      if (existingIndex !== undefined && existingIndex !== -1) {
        state.data[existingIndex] = {
          ...state.data[existingIndex],
          ...recommendation,
        };
      } else {
        state.data.push(recommendation);
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getRecommmedations.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        getRecommmedations.fulfilled,
        (state, action: PayloadAction<RecommendationData[]>) => {
          state.loading = false;
          state.data = action.payload;
          state.error='';
          state.cacheKey='RecomCacheKey';
          console.log("rec data", state.data);
        }
      )
      .addCase(getRecommmedations.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "something went wrong";
      });
  },
});

export const { resetState: resetLatestRecomStateAction, updateRecommendationData } = latestRecomSlice.actions;
export default latestRecomSlice.reducer;
