import handlebars from "handlebars";
import financialsTemplate from "../templates/financials";
import { numberWithComma } from "components/util/valueFormatter";
import { CommentKey } from "constant";

export const compileFinancialsTemplate = (
  financialsData: any[],
  budgetYear,
  comments: any[]
): string => {
  console.log("financialData", financialsData);
  const compiledFinancialsTemplate = handlebars.compile(financialsTemplate);

  //const formattedData = formatFinancials(financialsData);
  const formattedData = markSections(financialsData);
  console.log("formattedData", formattedData);
  const financialsComment = comments?.find(
    (x) => x.commentKey?.toLowerCase() === CommentKey.FINANCIALS.toLowerCase()
  );
  // Data for template
  const templateData = {
    financials: [...formattedData],
    budgetYear: budgetYear,
    budgetYearMinus1: budgetYear - 1,
    budgetYearMinus2: budgetYear - 2,
    budgetYearMinus3: budgetYear - 3,
    financialsComment: financialsComment
  };
  // Return the compiled HTML
  return compiledFinancialsTemplate(templateData);
};

const formatFinancials = (financials) => {
  return financials.map((row) => ({
    accountTreeId: row.accountTreeId,
    accountType: row.accountType,
    accountDescription: row.accountDescription,
    level: row.level,
    yearMinus3Actual: numberWithComma(row.yearMinus3Actual),
    yearMinus2Actual: numberWithComma(row.yearMinus2Actual),
    yearMinus1Actual: numberWithComma(row.yearMinus1Actual),
    yearMinus1Forecast: numberWithComma(row.yearMinus1Forecast),
    yearMinus1Budget: numberWithComma(row.yearMinus1Budget),
    yearBudget: numberWithComma(row.yearBudget),
    isTotalRow: row.accountDescription.startsWith("Total") ? true : false,
  }));
};

const markSections = (financials) => {
  let sectionedRows = [] as any[];
  const headerStack: string[] = [];
  let currentHeader = "";
  const rowTypes = {
    mainHeader: "mainHeader",
    mainTotal: "mainTotal",
    subHeader: "subHeader",
    subTotal: "subTotal",
    lineItem: "lineItem",
  };
  const shiftStyles = { subGroup: "shift1", lineItem: "shift2" };
  financials.forEach((row, index) => {
    const fRow = {
      accountTreeId: row.accountTreeId,
      accountType: row.accountType,
      accountDescription: row.accountDescription,
      level: row.level,
      yearMinus3Actual: numberWithComma(row.yearMinus3Actual),
      yearMinus2Actual: numberWithComma(row.yearMinus2Actual),
      yearMinus1Actual: numberWithComma(row.yearMinus1Actual),
      yearMinus1Forecast: numberWithComma(row.yearMinus1Forecast),
      yearMinus1Budget: numberWithComma(row.yearMinus1Budget),
      yearBudget: numberWithComma(row.yearBudget),
      isTotalRow: row.accountDescription.startsWith("Total") ? true : false,
    };
    fRow["rowType"] =
      row.accountType === "H" ? rowTypes.mainHeader : rowTypes.lineItem;
    fRow["shiftClassName"] = "";
    if (row.accountType === "H") {
      currentHeader = row.accountDescription;
      //skip "H" (header) row if there is no respective "T" (total) row
      const totalRowIndex = financials.findIndex(
        (row) => row.accountDescription === "Total " + currentHeader
      );
      if (totalRowIndex === -1) {
        console.log(
          `skipping header row because there is no respective total row - '${currentHeader}'`
        );
        return;
      }
      headerStack.push(row.accountDescription);
      fRow["rowType"] =
        headerStack.length === 1 ? rowTypes.mainHeader : rowTypes.subHeader;
      fRow["shiftClassName"] =
        headerStack.length === 1 ? "" : shiftStyles.subGroup;
    } else if (row.accountType === "T") {
      if ("Total " + currentHeader === row.accountDescription) {
        fRow["rowType"] =
          headerStack.length === 1 ? rowTypes.mainTotal : rowTypes.subTotal;
        headerStack.pop();
        currentHeader =
          headerStack.length > 0 ? headerStack[headerStack.length - 1] : "";
        fRow["shiftClassName"] =
          headerStack.length === 0 ? "" : shiftStyles.subGroup;
      } else if (row.accountDescription.startsWith("Total")) {
        fRow["rowType"] =
          headerStack.length === 0 ? rowTypes.mainTotal : rowTypes.subTotal;
        fRow["shiftClassName"] =
          headerStack.length === 0 ? "" : shiftStyles.subGroup;
      } else {
        fRow["rowType"] = rowTypes.lineItem;
        fRow["shiftClassName"] = shiftStyles.lineItem;
      }
    }
    sectionedRows.push(fRow);
  });
  return sectionedRows;
};
