import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { baseUrl, headers } from "../../config/config";
import { postApiCall } from "../../services/apiHelper";
import { AssetPlanParams } from "../../types/assetplanid";

export interface LeasingResidential {
    totalUnits: number;
    rentableSqft: number;
    physicalOccupancyPC: number;
    leaseRenewalPC: number;
    startYearLeaseUnit: number;
    startYearLeasePC: number;
    newLease: number;
    endYearLeasePC: number;
}

export interface LeasingResidentialState {
    data: LeasingResidential | null;
    error: string;
    loading: boolean;
}

const initialState: LeasingResidentialState = {
    data: null,
    error: "",
    loading: false,
};


export const getLeasingResidential = createAsyncThunk(
    "getLeasingResidential",
    async (payload: AssetPlanParams) => {
        try {
            const response = await postApiCall<AssetPlanParams, LeasingResidentialState>(
                `${baseUrl}Leasing/GetOccupancyResidential`,
                payload,
                headers
            );
            return response.data;
        } catch (error: any) {
            console.error("Error in POST request:", error);
            throw error;
        }
    }
);

const leasingResidentialSlice = createSlice({
    name: "leasingResidential",
    initialState,
    reducers: {
        resetState: (state) => {
            state.data = null;
            state.error = "";
            state.loading = false;
          },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getLeasingResidential.pending, (state) => {
                state.loading = true;
            })
            .addCase(
                getLeasingResidential.fulfilled,
                (state, action: PayloadAction<LeasingResidential | null>) => {
                    state.loading = false;
                    state.data = action.payload;
                }
            )
            .addCase(getLeasingResidential.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || "Something went wrong";
            });
    },
});
export const { resetState: resetLeaseResidentialAction} = leasingResidentialSlice.actions;
export default leasingResidentialSlice.reducer;
