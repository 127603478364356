import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { baseUrl } from "../../config/config";
import { getApiCall } from "../../services/apiHelper";
import { InvBudgetYearParams } from "../../types/assetplanid";

export interface BudgetStatus {
    investmentId: string,
    budgetYear: number,
    dataType: string,
    yardiLastModifiedTimeStamp: string,
    status: string
}
interface BudgetStatusState {
    data: BudgetStatus | null;
    error: string;
    loading: boolean;
}

const initialState: BudgetStatusState = {
    data: null,
    error: "",
    loading: false,
};

export const getBudgetStatus = createAsyncThunk(
    "getBudgetStatus",
    async (payload: InvBudgetYearParams) => {
        try {
            const response = await getApiCall<BudgetStatus>(
                `${baseUrl}ABF/Status?investmentId=${payload.investmentId}&budgetYear=${payload.budgetYear}`
            );
            console.log('getBudgetStatus', response);
            return response;
        } catch (error: any) {
            console.error("Error in POST request:", error);
            throw error;
        }
    }
);

const budgetStatusSlice = createSlice({
    name: "budgetStatus",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getBudgetStatus.pending, (state) => {
                state.loading = true;
            })
            .addCase(
                getBudgetStatus.fulfilled,
                (state, action: PayloadAction<BudgetStatus | null>) => {
                    state.loading = false;
                    state.data = action.payload;
                }
            )
            .addCase(getBudgetStatus.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || "Something went wrong";
            });
    },
});

export default budgetStatusSlice.reducer;