import * as React from "react";
import { useState, useRef, useEffect } from "react";
import {
  Box,
  Grid
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import { useAppSelector, useAppDispatch } from "../../../redux/hooks";
import Carouselcomponent from "../../corousel/Carousel-component";
import {
  SelectOption,
  Option,
} from "../../../redux/slices/dropdownOptionSlice";
import { SummaryDropdown, SectionNames } from "../../../constant";
import moment from "moment";
import {
  fetchTabPageData,
  TabPage,
} from "../../../redux/slices/tabPagesOptionsSlice";
import { updateSummary } from "../../../redux/slices/updateDataSlice";
import {
  setOption,
} from "../../../redux/slices/dropdownStateSlice";
import Skeleton from "@mui/material/Skeleton";
import { propertyImages } from "../../../redux/slices/propertyImagesSlice";
import FroalaEditorView from "react-froala-wysiwyg/FroalaEditorView";
import { CommentKey } from "../../../constant";
import { GenericToast } from "../../util/ToastMessage";

// Require Editor JS files.
import "froala-editor/js/froala_editor.pkgd.min.js";
import "froala-editor/js/plugins.pkgd.min.js";
import "froala-editor/js/third_party/embedly.min.js";
// import "froala-editor/js/plugins/fullscreen.min.js"

// Require Editor CSS files.
import "froala-editor/css/froala_style.min.css";
import "froala-editor/css/froala_editor.pkgd.min.css";
import "froala-editor/css/third_party/embedly.min.css";
import {dateTimeToDateFormat} from "../../util/valueFormatter";
// import "froala-editor/css/plugins/fullscreen.min.css";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  color: theme.palette.text.secondary,
}));

export const SummaryDisplayForm = () => {
  const ref = useRef({ editor: null });
  const [model, setModel] = useState<string>("");
  const dispatch = useAppDispatch();
  const investmentDescriptionSet = [
    "Condominium",
    "Fee Simple Estate",
    "Leasehold",
  ];

  //const sectionOptions= useAppSelector((state)=>state.dropdownOptions.data) as SelectOption[];
  const [groupedOptions, setGroupedOptions] = useState<SelectOption[]>([]);
  const [selectedOptions, setSelectedOptions] = useState<{
    [key: string]: string;
  }>({});
  const [groups, setGroups] = useState<SelectOption[]>([]);
  const [selectedInvestOption, setSelectedInvestOption] =
    useState<Option | null>(null);
  const [selectedLeaseOption, setSelectedLeaseOption] = useState<Option | null>(
    null
  );
  const sectionOptions = useAppSelector((state) =>
    state.dropdownAllOptions.tabData.find(
      (tab) => tab.tabPageName === SectionNames.SUMMARY
    )
  ) as TabPage;
  const selectedList = useAppSelector(
    (state) => state.dropdownState.selectedOptions
  );
  const errorStatus = useAppSelector((state) => state.summary);
  const dapData = useAppSelector((state) => state.summary.dapData);
  const descriptionData = useAppSelector((state) => state.summary.description);
  const descriptionComment = useAppSelector(
    (state) => state.dapComments?.data
  ).find(
    (x) => x.commentKey.toLowerCase() === CommentKey.SUMMARY_KEY.toLowerCase()
  );
  const requestForPropertyImages: any = {
    investmentId: dapData?.investmentId,
  };

  //console.log("comment",descriptionComment);
  useEffect(() => {
    dispatch(fetchTabPageData(SectionNames.SUMMARY));
  }, [dispatch]);

  useEffect(() => {
    if (dapData) {
      dispatch(propertyImages(requestForPropertyImages));
    }
  }, [dapData?.investmentId]);

  useEffect(() => {
    if (dapData) {
      // Set the default value for each dropdown once the options are fetched and available in the Redux store
      //TODO check the best place o initialize
      dispatch(
        updateSummary({
          investmentDescription: dapData.investmentDescription,
          primaryLeaseType: dapData.primaryLeaseType,
          investmentId: dapData.investmentId,
          assetPlanId: dapData.assetPlanId,
          jvPartner: dapData.jvPartner,
        })
      );
      const investDesc = dapData?.investmentDescription || " ";
      if (!selectedList[SummaryDropdown.INVESTMENT_DESC]) {
        setSelectedInvestOption({
          text: investDesc,
          value: investDesc,
          order: "0",
        });
        dispatch(
          setOption({
            dropdownId: SummaryDropdown.INVESTMENT_DESC,
            option: { text: investDesc, value: investDesc, order: "0" },
          })
        );
      } else {
        setSelectedInvestOption(selectedList[SummaryDropdown.INVESTMENT_DESC]);
      }

      const leaseType = dapData?.primaryLeaseType || " ";

      if (!selectedList[SummaryDropdown.PRIMARY_LEASE_TYPE]) {
        setSelectedLeaseOption({
          text: leaseType,
          value: leaseType,
          order: "0",
        });
        dispatch(
          setOption({
            dropdownId: SummaryDropdown.PRIMARY_LEASE_TYPE,
            option: { text: leaseType, value: leaseType, order: "0" },
          })
        );
      } else {
        setSelectedLeaseOption(
          selectedList[SummaryDropdown.PRIMARY_LEASE_TYPE]
        );
      }
    }
  }, [dapData]);

  useEffect(() => {
    if (sectionOptions) {
      const transformedGroups: SelectOption[] = Object.values(
        sectionOptions.data
      ).flatMap((groupData: any) => {
        return groupData.map((group: any) => {
          const { key, options } = group;
          const transformedOptions: Option[] = options
            ? options.map((option: any) => {
                const { text, value } = option;
                return { text, value };
              })
            : [];

          return { key, options: transformedOptions };
        });
      });
      setGroups(transformedGroups);
    }
  }, [sectionOptions]);

  const numToMoneyFormat = (x: number) => {
    return "$" + x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  const numWithComma = (x: number) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const formatYearBuilt = (year: number | undefined) => {
    if (year === undefined) return "";
    if (year === 0) return "-";
    return year;
  };

  return (
    <div className="container ">
      <Box sx={{ width: "100%" }}>
        <div className="data-content">
          <Box sx={{ flexGrow: 1 }} className="summary-form">
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                {errorStatus.loading ? (
                  <Skeleton variant="rounded" width="100%" animation="wave" />
                ) : (
                  <Item>
                    <table className="summary-table">
                      <tr>
                        <th>
                          <b>ID:</b>
                        </th>
                        <td>{dapData?.investmentId}</td>
                      </tr>
                      <tr>
                        <th>
                          <b>Fund Name:</b>
                        </th>
                        <td>{dapData?.fundName}</td>
                      </tr>
                      <tr>
                        <th>
                          <b>City / State:</b>
                        </th>
                        <td>{dapData?.city}</td>
                      </tr>
                      <tr>
                        <th>
                          <b>Year Built:</b>
                        </th>
                        <td>{formatYearBuilt(dapData?.yearBuilt)}</td>
                      </tr>
                      <tr>
                        <th>
                          <b>Date Acquired:</b>
                        </th>
                        <td>
                        {dapData?.dateAcquired && dateTimeToDateFormat(dapData?.dateAcquired)}
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <b>Purchase Price:</b>
                        </th>
                        <td>
                          {dapData?.purchasedPrice &&
                            numToMoneyFormat(dapData?.purchasedPrice)}
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <b>Appraised Value:</b>
                        </th>
                        <td>
                          {dapData?.purchasedPrice &&
                            numToMoneyFormat(parseInt(dapData?.appraisedValue))}
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <b>Asset Manager:</b>
                        </th>
                        <td>
                          {dapData?.assetManager?.lastName},{" "}
                          {dapData?.assetManager?.firstName}
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <b>Portfolio Manager:</b>
                        </th>
                        <td>
                          {dapData?.portfolioManager?.lastName},{" "}
                          {dapData?.portfolioManager?.firstName}
                        </td>
                      </tr>
                    </table>
                  </Item>
                )}
              </Grid>

              <Grid item xs={12} md={6}>
                {errorStatus.loading ? (
                  <Skeleton
                    variant="rounded"
                    width="100%"
                    height={350}
                    animation="wave"
                  />
                ) : (
                  <Item>
                    <table className="summary-table">
                      {dapData?.propertyType === "Commertial" && (
                        <tr>
                          <th>
                            <b>Rentable Area (sqft):</b>
                          </th>
                          <td>
                            {dapData?.rentableArea &&
                              numWithComma(dapData?.rentableArea)}
                          </td>
                        </tr>
                      )}
                      {dapData?.propertyType === "Residential" && (
                        <tr>
                          <th>
                            <b>Units:</b>
                          </th>
                          <td>
                            {dapData?.units && numWithComma(dapData?.units)}
                          </td>
                        </tr>
                      )}
                      {dapData?.propertyType === "Mixed" && (
                        <>
                          <tr>
                            <th>
                              <b>Rentable Area (sqft):</b>
                            </th>
                            <td>
                              {dapData?.rentableArea &&
                                numWithComma(dapData?.rentableArea)}
                            </td>
                          </tr>
                          <tr>
                            <th>
                              <b>Units:</b>
                            </th>
                            <td>
                              {dapData?.units && numWithComma(dapData?.units)}
                            </td>
                          </tr>
                        </>
                      )}

                      <tr>
                        <th>
                          <b>Investment Type:</b>
                        </th>
                        <td>{dapData?.investmentType}</td>
                      </tr>
                      <tr>
                        <th>
                          <b>Investment Subtype:</b>
                        </th>
                        <td>{dapData?.investmentSubtype}</td>
                      </tr>
                      <tr>
                        <th>
                          <b>Debt:</b>
                        </th>
                        <td>{dapData?.debt === "1" ? "Yes" : "No"}</td>
                      </tr>
                      <tr>
                        <th>
                          <b>Ownership Type:</b>
                        </th>
                        <td>{dapData?.ownershipType}</td>
                      </tr>
                      <tr>
                        <th>
                          <b>Ownership %:</b>
                        </th>
                        <td>
                          {dapData?.ownerShip && dapData?.ownerShip?.toFixed(2)}
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <b>Investment Description:</b>
                        </th>
                        <td>{dapData?.investmentDescription}</td>
                      </tr>
                      <tr>
                        <th>
                          <b>Primary Lease Type:</b>
                        </th>
                        <td>{dapData?.primaryLeaseType}</td>
                      </tr>
                      <tr>
                        <th>
                          <b>JV Partner:</b>
                        </th>
                        <td>{dapData?.jvPartner}</td>
                      </tr>
                    </table>
                  </Item>
                )}
              </Grid>
            </Grid>
          </Box>

          {/* 2nd block */}
          <Box
            sx={{ flexGrow: 1 }}
            className="summary-property-section summary-form"
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Item>
                  {/* <h3 className="tlt">Property Images</h3> */}
                  <h3 className="text-left pl-10">Property Images</h3>
                  <div className="clear"></div>
                  <Carouselcomponent />
                </Item>
              </Grid>
              <Grid  item xs={12} md={6}>
                {errorStatus.loading ? (
                  <Skeleton
                    variant="rounded"
                    width="100%"
                    height={350}
                    animation="wave"
                  />
                ) : (
                  <Item>
                    {/* <h3 className="tlt">Additional Property Description</h3> */}
                    <h3 className="text-left pl-10">
                      Additional Property Description
                    </h3>
                    <div className="clear"></div>
                    <div style={{ marginLeft: "12px" }}>
                      <FroalaEditorView
                        model={descriptionComment?.commentText || ""}
                      />
                    </div>
                  </Item>
                )}
              </Grid>
            </Grid>
          </Box>
        </div>
      </Box>
      {errorStatus.error && (
        <GenericToast message={errorStatus.error} severity="error" />
      )}
    </div>
  );
};
