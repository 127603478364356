import React from "react";
import "froala-editor/js/froala_editor.pkgd.min.js";
import "froala-editor/js/plugins/font_family.min.js";
import "froala-editor/js/plugins.pkgd.min.js";
import "froala-editor/js/third_party/embedly.min.js";
import "froala-editor/css/froala_style.min.css";
import "froala-editor/css/froala_editor.pkgd.min.css";
import "froala-editor/css/third_party/embedly.min.css";
// import Froala from "react-froala-wysiwyg";
// import FroalaEditorView from "react-froala-wysiwyg/FroalaEditorView";
import FroalaEditor from "react-froala-wysiwyg";
import {froalaKey} from "../../config/config";
import "./FroalaRichtext.css";


const moreParagraphButtons = [
  "alignLeft",
  "alignCenter",
  "formatOLSimple",
  "alignRight",
  "alignJustify",
  "formatOL",
  "formatUL",
  "paragraphFormat",
  "paragraphStyle",
  "lineHeight",
  "outdent",
  "indent",
];
const moreMiscButtons = [
  "undo",
  "redo",
  "spellChecker",
  "selectAll",
];
const toolbarButtons = [
  "bold",
  "italic",
  "underline",
  "strikeThrough",
  "subscript",
  "superscript",
  "fontFamily",
  "fontSize",
  "textColor",
  "backgroundColor",
  "clearFormatting",
];

const pluginsEnabledArray = [
  "table",
  "spell",
  "quote",
  "save",
  "quickInsert",
  "paragraphFormat",
  "paragraphStyle",
  "draggable",
  "align",
  "link",
  "lists",
  "file",
  "emoticons",
  "url",
  "embedly",
  "colors",
  "entities",
  "inlineClass",
  "inlineStyle",
  "spellChecker",
  "fontFamily",
  "fontSize"
];


export const FroalaRichText = (props) => {
  return (
    <div id="froala-editor">
    <FroalaEditor
      model={props.model}
      onModelChange={props.handlesModelChange}

      tag="textarea"     
      config={{
        key: froalaKey,
        attribution: false,
        placeholder: "Start typing...",
        toolbarInline: true,
        fontSizeSelection: true,
        fontFamilySelection: true,
        fontFamily: {
          'Arial,Helvetica,sans-serif': 'Arial',
          'Georgia,serif': 'Georgia', 'Impact,Charcoal,sans-serif': 'Impact',
          'Tahoma,Geneva,sans-serif': 'Tahoma',
          "'Times New Roman',Times,serif": 'Times New Roman',
          'Verdana,Geneva,sans-serif': 'Verdana',
          "Roboto,sans-serif": 'Roboto',
          "Oswald,sans-serif": 'Oswald',
          "Montserrat,sans-serif": 'Montserrat',
          "'Open Sans Condensed',sans-serif": 'Open Sans Condensed'
        },
        toolbarButtons: {
          moreText: {
            buttons: toolbarButtons,

          },
          moreParagraph: {
            buttons: moreParagraphButtons,
          },
          moreRich: {},
          moreMisc: {
            buttons: moreMiscButtons,
            align: "right",
            buttonsVisible: 2,
          },
        },
        pluginsEnabled: pluginsEnabledArray,
        paragraphFormat: {
          N: 'Normal',
          H1: 'Heading 1',
          H2: 'Heading 2',
          H3: 'Heading 3',
          H4: 'Heading 4',
          H5: 'Heading 5',
          H6: 'Heading 6',
          align: 'left', // Set the default alignment to left
        }
      }}
    />
    </div>
  );
};
