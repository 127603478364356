import { combineReducers, configureStore } from "@reduxjs/toolkit";

import summaryFormReducer from "./slices/summaryFormSlice";
import dialogFormReducer from "./slices/dialogFormSlice";
import swotReducer from "./slices/swotSlice";
import getRecomDataReducer from "./slices/recommendationSlice";
import getDropdownOptionReducer from "./slices/dropdownOptionSlice";
import dropdownStateReducer from "./slices/dropdownStateSlice";
import tabPagesOptionsReducer from "./slices/tabPagesOptionsSlice";
import getDistinctInvestmentReducer from "./slices/distinctInvestmentSlice";
import getDisplayedYearReducer from "./slices/displayedYearSlice";
import assetPlanIdReducer from "./slices/assetPlanIdSlice";
import updateDataReducer from "./slices/updateDataSlice";
import assetPlanReducer from "./slices/assetPlanSlice";
import capitalProjectsReducer from "./slices/capitalProjectsSlice";
import capitalPlanTableReducer from "./slices/capitalPlanTableSlice";
import currentUserReducer from "./slices/currentUserSlice";
import leaseExpirationReducer from "./slices/leaseExpirationSlice";
import commertialLeasingReducer from "./slices/commertialLeasingSlice";
import residentialLeasingReducer from "./slices/residentialLeasingSlice";
import residentialOccupancyReducer from "./slices/residentialOccupancySlice";
import commertialOccupancyReducer from "./slices/commertialOccupancySlice";
import keyTenantsReducer from "./slices/keyTenantsSlice";
import propertyImagesReducer from "./slices/propertyImagesSlice";
import latestRecomReducer from "./slices/latestRecomSlice";
import missionGroupReducer from "./slices/missionGroupSlice";
import updateDapCommentReducer from "./slices/updateDapCommentSlice";
import saveCommentReducer from "./slices/saveCommentSlice";
import userSectorDetailReducer from "./slices/userSectorDetailSlice";
import leasingCommercialReducer from "./slices/leasingCommercialSlice";
import leasingResidentialReducer from "./slices/leasingResidentialSlice";
import budgetStatusReducer from "./slices/budgetStatusSlice";
import DocumentCommentSlice from "./slices/documentCommentSlice";
import onChangeSlice from "./slices/onChangeSlice";
import valuationsReducer from "./slices/valuationsSlice";

// Create the root reducer separately so we can extract the RootState type
const rootReducer = combineReducers({
  summary: summaryFormReducer,
  dialog: dialogFormReducer,
  distinctInvestment: getDistinctInvestmentReducer,
  getDisplayedYear: getDisplayedYearReducer,
  getAssetPlanId: assetPlanIdReducer,
  swot: swotReducer,
  recommendations: getRecomDataReducer,
  dropdownOptions: getDropdownOptionReducer,
  dropdownState: dropdownStateReducer,
  dropdownAllOptions: tabPagesOptionsReducer,
  updateData: updateDataReducer,
  assetPlanData: assetPlanReducer,
  capitalProjects: capitalProjectsReducer,
  capitalPlanTable: capitalPlanTableReducer,
  currentUser: currentUserReducer,
  leasingCommercial: leasingCommercialReducer,
  leasingResidential: leasingResidentialReducer,
  leaseExpirations: leaseExpirationReducer,
  commertialLeasing: commertialLeasingReducer,
  residentialLeasing: residentialLeasingReducer,
  commertialOccupancy: commertialOccupancyReducer,
  residentialOccupancy: residentialOccupancyReducer,
  keyTenants: keyTenantsReducer,
  budgetStatus: budgetStatusReducer,
  propertyImages: propertyImagesReducer,
  latestRecom: latestRecomReducer,
  missionGroups: missionGroupReducer,
  dapComments: updateDapCommentReducer,
  saveComment: saveCommentReducer,
  documentComment: DocumentCommentSlice,
  userSectorDetail: userSectorDetailReducer,
  onchangeFlagForDocComponent: onChangeSlice,
  valuations: valuationsReducer,
});

export const makeStore = (preloadedState?: Partial<RootState>) => {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
  });
};

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof makeStore>
export type AppDispatch = AppStore['dispatch']

// export type RootState = ReturnType<typeof store.getState>;
// export type AppDispatch = typeof store.dispatch;
export const store = makeStore();
