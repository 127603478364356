const keyProvisionsTemplate = `
<!DOCTYPE html>
<html>
  <head> </head>
  <body>

    
    <table id="table-comment"> 
    <h2 class="pdf-table-heading">Key Provisions</h2>
    <tr><th><h3>Management Agreement:</h3></th></tr>
      <tr>
        <th>Name/ Parties/ Date</th>
      </tr>
      <tr>
        <td>
        {{{mangementComment_date}}}
        </td>
      </tr>
      <tr>
        <th >Management Fees</th>
      </tr>
      <tr>
        <td>
        {{{mangementComment_fee}}}
        </td>
      </tr>
      <tr>
        <th>Other Fees</th>
      </tr>
      <tr>
        <td>
        {{{mangementComment_otherFee}}}
        </td>
      </tr>
      <tr><th><h3>Leasing Broker:</h3></th></tr>
      <tr>
      <th style="text-align: left; padding: 5px">Name</th>
    </tr>
    <tr>
      <td>
      {{{leasebroker_name}}}
      </td>
    </tr>
    <tr>
      <th>Management Fees</th>
    </tr>
    <tr>
      <td>
      {{{leasebroker_fee}}}
      </td>
    </tr>
    <tr>
      <th >Other Fees</th>
    </tr>
    <tr>
      <td>
      {{{leasebroker_otherfee}}}
      </td>
    </tr>
    <tr><th><h3>JV Agreement:</h3></th></tr>
    <tr>
    <th>Key Major Decisions</th>
  </tr>
  <tr>
    <td>
    {{{jvagreement_KeyDecision}}}
    </td>
  </tr>
  <tr>
    <th>Fees</th>
  </tr>
  <tr>
    <td>
    {{{jvagreement_fee}}}
    </td>
  </tr>
  <tr>
    <th>Liquidity Provisions</th>
  </tr>
  <tr>
    <td>
    {{{jvagreement_liquidProvision}}}
    </td>
  </tr>
  <tr>
    <th>Other Key Provisions</th>
  </tr>
  <tr>
    <td>
    {{{jvagreement_Keyprovision}}}
    </td>
  </tr>
    </table>
  </body>
  
</html>

`;

export default keyProvisionsTemplate;
