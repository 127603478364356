const leasingCommertialTemplate = `
<!DOCTYPE html>
<html>
  <head>
  
  </head>
  <body>
    <div class="row leasing-table-heading">
      <h2 class="pdf-table-heading">Leasing</h2>
      <h3>Commercial</h3>
      <!-- left table -->
      <div class="column">
        <h3>{{budgetYear}} Projected Leasing Activity</h3>
        <table id="leasing-table" class="leasing-table-head">
          <tr>
            <th>Leasing Activity</th>
            <th></th>
            <th>Total SQFT</th>
          </tr>
          <tr>
            <td>New Leases</td>
            <td>{{commertialData.newLease}}</td>
            <td>{{commertialData.newLeaseTotalSqft}}</td>
          </tr>
          <tr>
            <td>Renewals</td>
            <td>{{commertialData.renewLease}}</td>
            <td>{{commertialData.renewLeaseTotalSqft}}</td>
          </tr>
        </table>
      </div>
      <!-- right table -->
      <div class="column">
        <h3>{{budgetYear}} Occupancy Schedule</h3>
        <table id="leasing-table">
          <tr>
            <td>GLA Leased 1/1/{{budgetYear}}</td>
            <td>{{commertialData.startYearGLALeased}}</td>
          </tr>
          <tr>
            <td>Total GLA</td>
            <td>{{commertialData.totalGLA}}</td>
          </tr>
          <tr>
            <td>% Leased 1/1/{{budgetYear}}</td>
            <td>{{commertialData.startYearLeasedPC}}</td>
          </tr>
          <tr>
            <td>YE GLA Leased</td>
            <td>{{commertialData.endYearGLALeased}}</td>
          </tr>
          <tr>
            <td>% Leased 12/31/{{budgetYear}}</td>
            <td>{{commertialData.endYearLeasedPC}}</td>
          </tr>
          <tr>
            <td>Net Absorption</td>
            <td>{{commertialData.netAbsorptionOcc}}</td>
          </tr>
        </table>
      </div>
    </div>

    <div class="row">
      <!-- Lease Expirations table -->
      <h2 class="pdf-table-heading">Lease Expirations</h2>
      <table id="print-pdf-table">
        <tr>
          <th></th>
          <th>SF Expiring</th>
          <th>% Expiring</th>
          <th>% Cumulative</th>
        </tr>
        {{#each expirationsData}}
        <tr>
          <td>{{this.expiryYear}}</td>
          <td>{{this.expiringUnit}}</td>
          <td>{{this.expiringPercentage}}</td>
          <td>{{this.cumulativePercentage}}</td>
        </tr>
        {{/each}}
      </table>
    </div>
    <div class="row">
      <!-- Key Tenants table-->
      <h2 class="pdf-table-heading">Key Tenants</h2>
      <table id="print-pdf-table">
        <tr>
          <th>Key Tenant</th>
          <th>Total SF</th>
          <th>% of Investment</th>
          <th>Rent ($PSF)</th>
          <th>Expiration Date</th>
          <th>"Total Sales ($)</th>
          <th>Options</th>
          <th>Sales Period</th>
        </tr>
        {{#each keyTenantsData}}
        <tr>
          <td>{{this.keyTenant}}</td>
          <td>{{this.totalSF}}</td>
          <td>{{this.investmentPercent}}</td>
          <td>{{this.rent}}</td>
          <td>{{this.expirationDate}}</td>
          <td>{{this.totalSales}}</td>
          <td>{{this.options}}</td>
          <td>{{this.salesPeriod}}</td>
        </tr>
        {{/each}}
      </table>
    </div>
    <div class ="row">
    <br>
    <table id="comment-table">
      <tbody>
        <tr>
          <th>Comments</th>
        </tr>
        <tr>
          <td>
            {{{commertialComment.commentText}}}
          </td>
        </tr>
      </tbody>
    </table>
    </tr>
  </table>
    </div>
  </body>
</html>
`;

export default leasingCommertialTemplate;
