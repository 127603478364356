import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { baseUrl } from "../../config/config";
import { SummaryDropdown,SectionNames,RecommendDropdown } from "../../constant";
import {SelectOption,OptionRequest} from './dropdownOptionSlice';
import {postApiCall} from "../../services/apiHelper";

const cache={};

// Define the initial state
 export interface TabPage {
  tabPageName: string;
  data:SelectOption[] ; // Replace 'any' with the actual type of your data
}

interface SliceState {
  tabData: TabPage[];
  loading: boolean;
  error: string | null;
}

const initialState: SliceState = {
  tabData: [],
  loading: false,
  error: null,
};

// Define the async thunk action
export const fetchTabPageData = createAsyncThunk(
  'tabSlice/fetchTabPageData',
  async (section: string, { dispatch, rejectWithValue }) => {
    // try {
      // Make your API call here and retrieve the data for the specific tab page
      const request:OptionRequest={} as OptionRequest;
      if(cache[section])
      {
        return cache[section];
      }
      switch (section) {
          case SectionNames.SUMMARY:
              request.keyList=[SummaryDropdown.INVESTMENT_DESC,SummaryDropdown.PRIMARY_LEASE_TYPE];
            break;
          case SectionNames.RECOMMENDATION:
                request.keyList=[RecommendDropdown.INVESTMENT_KEY1,RecommendDropdown.INVESTMENT_HOLDPERIOD,RecommendDropdown.INVESTMENT_KEY3,RecommendDropdown.SUSTAIN_PHYSICALRISK,
                  RecommendDropdown.SUSTAIN_REGULATORYRISK,RecommendDropdown.SUSTAIN_TWREPORT,RecommendDropdown.MISSIONSTATEMENT,RecommendDropdown.SUSTAIN_TENANT_FEEDBACK,RecommendDropdown.SUSTAIN_NETZERO] ;
            break;
      }
      try {
        const response = await postApiCall<string[], SelectOption[]>(`${baseUrl}Options/GetByKeys`, request.keyList);
        console.log("dropdown data", response);
        cache[section]=response;
        return { section, response };
      } catch (error: any) {
        console.error('Error in POST request:', error);
        throw error;
      }
      
  }
);

export const tabPagesOptionsSlice = createSlice({
  name: 'tabPagesOptions',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTabPageData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchTabPageData.fulfilled,
        (state, action: PayloadAction<{ section: string; response: SelectOption[] }>) => {
          const { section, response } = action.payload;
          state.loading = false;
          state.error = null;
          state.tabData.push({
            tabPageName: section,
            data: response,
          });
          state.error='';
        },
      )
      .addCase(fetchTabPageData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

export default tabPagesOptionsSlice.reducer;

