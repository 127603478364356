import React, { useEffect } from "react";
import { useAppSelector, useAppDispatch } from "../../../../redux/hooks";
import { GenericDataGrid, ColumnConfig } from "../../../util/GenericDataGrid";
import columnConfigJson from "../columnConfig.json";
import {
  getLeaseExpirations,
  LeaseExpiration,
} from "../../../../redux/slices/leaseExpirationSlice";

import { GenericToast } from "../../../util/ToastMessage";
import Skeleton from "@mui/material/Skeleton";

interface GridValueFormatterParams<T> {
  value: T;
}

type ValueFormatter<T> = (
  params: GridValueFormatterParams<T>,
  currency?: string,
  decimalPosition?: number
) => string;

const currencyFormatter: ValueFormatter<number> = (
  params,
  currency = "$",
  decimalPosition = 2
) => {
  const value = params.value;
  if (typeof value === "number") {
    return `${currency}${value.toFixed(decimalPosition)}`;
  }
  return "";
};

const commaSeparatedFormatter: ValueFormatter<number> = (
  params,
  currency,
  decimalPosition
) => {
  const value = params.value;
  if (typeof value === "number") {
    return value.toLocaleString();
  }
  return "";
};

const decimalFormatter: ValueFormatter<number> = (
  params,
  currency,
  decimalPosition = 2
) => {
  const value = params.value;
  if (typeof value === "number") {
    return value.toFixed(decimalPosition);
  }

  return "";
};

const columnConfig = columnConfigJson.map((column) => {
  const { valueFormatter, currency, decimalPosition, ...rest } = column;

  let formatter;
  if (valueFormatter === "currencyFormatter") {
    formatter = (params) =>
      currencyFormatter(params, currency, decimalPosition);
  } else if (valueFormatter === "commaSeparatedFormatter") {
    formatter = (params) =>
      commaSeparatedFormatter(params, decimalPosition?.toString());
  } else if (valueFormatter === "decimalFormatter") {
    formatter = (params) =>
      decimalFormatter(params, decimalPosition?.toString());
  }

  return {
    ...rest,
    ...(formatter && { valueFormatter: formatter }),
  };
});

const CommertialLeaseExpirations: React.FC = () => {
  const dispatch = useAppDispatch();
  const leaseExpirations = useAppSelector(
    (state) => state.leaseExpirations.data
  );
  const leaseExpirationsStatus = useAppSelector(
    (state) => state.leaseExpirations
  );
  const investmentId = useAppSelector(
    (state) => state.summary?.dapData?.investmentId
  );
  const assetPlanId = useAppSelector(
    (state) => state.summary?.dapData?.assetPlanId
  );
  
  useEffect(() => {
    if (!leaseExpirations) {
      dispatch(
        getLeaseExpirations({
          assetPlanId: assetPlanId || "",
          investmentId: investmentId || "",
        })
      );
    }
  }, [dispatch, investmentId]);
  const getRowId = (row: LeaseExpiration) => row.expiryYear;
  console.log("Lease data: " + leaseExpirations);
  console.log("Lease investment: " + investmentId);
  return (
    <div>
      {leaseExpirationsStatus.loading ? (
        <Skeleton
          variant="rounded"
          width="100%"
          height={150}
          animation="wave"
        />
      ) : (
        <GenericDataGrid
          rows={leaseExpirations || []}
          columnConfig={columnConfig}
          getRowId={getRowId}
          hideFooter={true}
        />
      )}
      {leaseExpirationsStatus.error && (
        <GenericToast message={leaseExpirationsStatus.error} severity="error" />
      )}
    </div>
  );
};

export default CommertialLeaseExpirations;
