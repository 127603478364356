import React, { useState } from "react";
import "./App.css";
import { Header } from "./layout/header";
import { AssetPlanDashboard } from "./components/appContent/assetPlanDashboard";
import { Route, Routes, Navigate } from "react-router-dom";
import { BrowserRouter as Router } from "react-router-dom";
import Footer from "./layout/footer";
import { Nav } from "./layout/navigation";
import UserRoleMapDashboard from "./features/userRoleMap/components/UserRoleMapDashboard";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import { UserRoleMapcomponent } from "./features/userRoleMap/components/userRoleMap-component";
import { DocumentComponent } from "./features/document/components/document-component";
import { LicenseInfo } from "@mui/x-license-pro";
import { DapIntroductionForm } from "./components/form/introductionForm/DapIntroductionForm";
import { muiKey } from "./config/config";
import { useMsal, MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";

import {
  updateCurrentUser,
  CurrentUser,
} from "./redux/slices/currentUserSlice";
import { useAppDispatch } from "./redux/hooks";
import useConfig from "./hooks/common-hooks";
import { Configuration, PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { AppBreadcrumbs } from "./layout/Breadcrumbs";
import { SessionTimeoutPopup } from "components/notification/sessionTimeoutPopup";
import { CloneDataGrid } from "./features/clone/CloneDataGrid";

LicenseInfo.setLicenseKey(muiKey);
//Licensing Material UI

const AuthProvider = (props: any) => {
  const [config] = useConfig();
  
  if (!config?.msalConfig?.auth) return <>Loading config...</>;

  // MSAL configuration
  const configuration: Configuration = { ...config.msalConfig };

  const pca = new PublicClientApplication(configuration);

  return <MsalProvider instance={pca}>{props.children}</MsalProvider>;
};

// MsalProvider must be rendered somewhere higher up in the component tree
export const Main = () => {
  const config = {};
  const { accounts, instance } = useMsal();
  const [userLoadComplete, setUserLoadComplete] = useState(false);

  // console.log(instance);
  
  const dispatch = useAppDispatch();

  if (accounts && accounts.length > 0) {
    const account = accounts[0];
    instance.setActiveAccount(account);

    const currentUser: CurrentUser = {
      name: account?.name,
      email: account?.username,
      tenantId: account?.tenantId,
    };


    // console.log(currentUser);
    dispatch(updateCurrentUser(currentUser));
  }

  return (
    <div className="main-container">
      <Header />
      <SessionTimeoutPopup />
      <Router>
        <Nav />
        <React.Fragment>
          <CssBaseline />
          <Container maxWidth={false}>
            <AppBreadcrumbs />
            {/* <SessionTimeoutPopup /> */}
            <Routes>
              <Route path="/" element={<Navigate to="/dashboard" />} />
              <Route path="/dashboard" element={<AssetPlanDashboard />} />
              <Route
                path="/dashboard/form/edit/:dapId"
                element={<DapIntroductionForm mode={"edit"} />}
              />
              <Route
                path="/dashboard/form/view/:dapId"
                element={<DapIntroductionForm mode={"view"} />}
              />
              <Route
                path={"/userrolemap/list"}
                Component={UserRoleMapDashboard}
              />
              <Route path="/documents" Component={DocumentComponent} />
              <Route
                path="/userRole/:page/:userRoleId"
                element={<UserRoleMapcomponent />}
              />
              <Route
                path="/userRole/:page"
                element={<UserRoleMapcomponent />}
              />
              <Route
                path={"/userrolemap/list"}
                Component={UserRoleMapDashboard}
              />
              <Route path={"/clone/data"} Component={CloneDataGrid} />
              <Route path="*" element={<AssetPlanDashboard />} />
            </Routes>
          </Container>
        </React.Fragment>
      </Router>
      <Footer />
    </div>
  );
};

export const App = () => {
  function ErrorComponent({ error }) {
    return <p>An Error Occurred: {error}</p>;
  }

  function LoadingComponent() {
    return <p>Authentication in progress...</p>;
  }
  return (
    <AuthProvider>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        errorComponent={ErrorComponent}
        loadingComponent={LoadingComponent}
      >
        {/* <p>This will only render if a user is signed-in.</p> */}
        <Main />
      </MsalAuthenticationTemplate>
    </AuthProvider>
  );
};
