import React from "react";
import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AbfStatusControl from "./abfStatusControl";

export interface AbfStatusDialogProps {
  isOpen: boolean;
  onClose: () => void;
}

const AbfStatusDialog: React.FC<AbfStatusDialogProps> = ({ isOpen, onClose }) => {
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      className="common-dialog-box AbfStatus-log"
    >
      <DialogTitle>
        Portfolio ABF Status
        <IconButton
          color="inherit"
          className="close-btn"
          aria-label="close"
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <AbfStatusControl />
      </DialogContent>
    </Dialog>
  );
};

export default AbfStatusDialog;
