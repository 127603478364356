import * as React from "react";
import { useEffect, useState } from "react";
import moment from "moment";
import Box from "@mui/material/Box";
import { DocumentServices } from "../services/document-services";

import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import { baseUrl, headers } from "../../../config/config";
import Button from "@mui/material/Button";
import {
  getApiCall,
  postApiCall,
} from "../../../services/apiHelper";
import {
  DataGridPro,
  GridColDef,
  GridActionsCellItem,
  GridRowId
} from "@mui/x-data-grid-pro";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useAppSelector, useAppDispatch } from "../../../redux/hooks";
import {
  DocumentComment,
  getAllDocumentComment,
  ensureDocumentComments,
  // getDocumentsBySectionAndAssetPlanId
} from "redux/slices/documentCommentSlice";
import { GenericToast } from "components/util/ToastMessage";
import { updateDocumentComment } from "redux/slices/updateDataSlice";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

interface DocumentComponentProps {
  sectionName?: string;
  assetPlanId?: string;
  document?: any[];
  mode?: string;
}

interface toast {
  message: any;
  severity: string;
}
declare const window: any;

interface GridValueFormatterParams<T> {
  value: T;
}

type ValueFormatter<T> = (
  params: GridValueFormatterParams<T>,
  currency?: string,
  decimalPosition?: number
) => string;

const isDefaultDateTime = (date: Date | undefined): boolean => {
  if (!date) {
    return true; // If the date is undefined, consider it as default
  }

  const defaultDateTime = moment("0001-01-01T00:00:00");
  const inputDate = moment(date);
  return inputDate.isSame(defaultDateTime);
}

const dateFormatter: ValueFormatter<string> = (params) => {
  const value = params.value;
  if(value===null|| value===undefined)
  {
    return '';
  }
  let  uploadedDate= new Date();
  if(moment(params?.value).isValid())
    {
      uploadedDate= new Date(params.value);
      if(!isDefaultDateTime(uploadedDate))
      {
        return moment(params?.value).format("MMM-DD-YYYY HH:mm:ss");
      }
    }
    return '';
};

export function DocumentComponent({
  assetPlanId,
  sectionName,
  document,
  mode,
}: DocumentComponentProps) {
  const dispatch = useAppDispatch();

  const documentServices = new DocumentServices();
  const docOnChangeStatus: boolean = useAppSelector(
    (state) => state.onchangeFlagForDocComponent.isChanged
  );

  const documentComments: DocumentComment[] = useAppSelector(
    (state) => state.documentComment.allDocuments[sectionName ?? ""]
  );

  const [deleteId, setDeleteId] = useState<GridRowId>();
  const [isClosedClicked, setIsClosedClicked] = useState(false);
  const [documentData, setDocumentData] = useState([]);
  const [documentCommentData, setDocumentCommentData] = useState([]);
  const [toastMessageOpen, setToastMessageOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState<toast>({
    message: "",
    severity: "",
  });

  // console.log(document);
  // const payload = {
  //       section: "introduction",
  //       assetPlanId: "b850febb-1014-48db-8c70-c3cf04acc90d",
  //     }

  const payload = {
    section: sectionName,
    assetPlanId: assetPlanId,
  };

  const editColumns: GridColDef[] = [
    {
      field: "fileKey",
      headerName: "Document",
      editable: false,
      //width: 463,
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <a
              className="downloadLink"
              onClick={() => downloadFilePresignedUrl(params)}
            >
              {params.row.displayName}
            </a>
          </>
        );
      },
    },

    {
      field: "comments",
      headerName: "Comments",
      description: "This column has a value getter and is not sortable.",
      editable: true,
      //width: 772,
      flex: 1,
      // renderCell: (params) =>{
      //  return <><TextField  defaultValue={params.row.comments} /> </>
      // }
    },
    {
      field: "uploadedDate",
      headerName: "Uploaded Date",
       valueFormatter: (params) =>
      dateFormatter(params),
      flex: 1,
    },
    {
      field: "action",
      headerName: "Action",
      type: "action",
      //width: 309,
      flex: 1,
      editable: false,
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <>
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              onClick={() => handleDeleteClicked(params.row.id)}
              color="inherit"
            />
          </>
        );
      },
    },
  ];

  const viewColumns: GridColDef[] = [
    {
      field: "fileKey",
      headerName: "Document",
      editable: false,
      //width: 463,
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <a
              className="downloadLink"
              onClick={() => downloadFilePresignedUrl(params)}
            >
              {params.row.displayName}
            </a>
          </>
        );
      },
    },

    {
      field: "comments",
      headerName: "Comments",
      description: "This column has a value getter and is not sortable.",
      editable: false,
      //width: 772,
      flex: 1,
      // renderCell: (params) =>{
      //  return <><TextField  defaultValue={params.row.comments} /> </>
      // }
    },
    {
      field: "uploadedDate",
      headerName: "Uploaded Date",
      valueFormatter: (params) =>
      dateFormatter(params),
      flex: 1,
    },
  ];
  // console.log(mode);
  useEffect(() => {
    fetchData();
    //getAllDocumentComment(payload.assetPlanId);
    // console.log(sectionName)
  }, [document]);

  const docData: DocumentComment[] = useAppSelector(
    (state) => state.documentComment.documentComments
  );
  //console.log(docData);

  const downloadFilePresignedUrl = (params: any) => {
    var fileStatus = "inactive";
    var fileUploadStatus = CheckIfFileIsUploaded(params.row.id).then(
      (res: any) => {
        fileStatus = res;
        console.log("response " + fileStatus);
        if (fileStatus === "active") {
          var fileKey = params.row.fileKey;
          postApiCall(`${baseUrl}Document/getPreSignedUrl`, { fileKey }).then(
            (res: any) => {
              const url = res.fileUrl;
              console.log(url);
              const fileName = params.row.displayName;

              const link = window.document.createElement("a");
              link.href = url;
              link.setAttribute("style", "display:none;"); // Set the desired filename
              window.document.body.appendChild(link);
              link.download = fileName; //+ params.row.fileType;
              // Programmatically trigger the download
              link.click();
              // Cleanup
              link.parentNode?.removeChild(link);
              //download_file(url, fileName);
            }
          );
        } else {
          setToastMessage({
            message: fileStatus,
            severity: "info",
          });
          setToastMessageOpen(true);
          setTimeout(() => {
            setToastMessageOpen(false);
          }, 4000);
        }
      }
    );
  };

  const CheckIfFileIsUploaded = (id) => {
    setToastMessageOpen(false);
    try {
      const response = getApiCall(
        `${baseUrl}Document/GetFileUploadStatus/` + id
      ).then((res: any) => res);
      return response;
    } catch (error: any) {
      console.error("Error in POST request:", error);
      setToastMessage({
        message: error.message,
        severity: "error",
      });
      setToastMessageOpen(true);
      setTimeout(() => {
        setToastMessageOpen(false);
      }, 4000);
      throw error;
    }
  };

  function download_file(fileURL, fileName) {
    // for non-IE
    if (!window.ActiveXObject) {
      var save = window.document.createElement("a");
      save.href = fileURL;
      save.target = "_blank";
      var filename = fileURL.substring(fileURL.lastIndexOf("/") + 1);
      save.download = fileName || filename;
      if (
        navigator.userAgent.toLowerCase().match(/(ipad|iphone|safari)/) &&
        navigator.userAgent.search("Chrome") < 0
      ) {
        window.document.location = save.href;
        // window event not working here
      } else {
        var evt = new MouseEvent("click", {
          view: window,
          bubbles: true,
          cancelable: false,
        });
        save.dispatchEvent(evt);
        (window.URL || window.webkitURL).revokeObjectURL(save.href);
      }
    }
    // for IE < 11
    else if (!!window.ActiveXObject && window.document.execCommand) {
      var _window = window.open(fileURL, "_blank");
      _window?.document.close();
      _window?.document.execCommand("SaveAs", true, fileName || fileURL);
      _window?.close();
    }
  }

  // Function to download a file
  async function downloadFileFromPresignedUrl(url, filename) {
    try {
      // Fetch the file
      const response = await fetch(url);

      // Check if the request was successful
      if (response.status !== 200) {
        throw new Error(
          `Unable to download file. HTTP status: ${response.status}`
        );
      }

      // Get the Blob data
      const blob = await response.blob();

      // Create a download link
      const downloadLink = window.document.createElement("a");
      downloadLink.href = URL.createObjectURL(blob);
      downloadLink.download = filename;

      // Trigger the download
      window.document.body.appendChild(downloadLink);
      downloadLink.click();

      // Clean up
      setTimeout(() => {
        URL.revokeObjectURL(downloadLink.href);
        window.document.body.removeChild(downloadLink);
      }, 100);
    } catch (error) {
      console.error("Error downloading the file:");
    }
  }

  const downloadFile = async (params: any) => {
    let promises: Promise<any>[] = [];
    var fileSize = params.row.fileSize;
    var fileKey = params.row.fileKey;
    if (fileSize <= 100) {
      promises.push(
        postApiCall(`${baseUrl}Document/GetDocumentToDownload`, { fileKey })
      );
    } else {
      var chunkRange = 200;
      var startRange = 0;
      var endRange = chunkRange;
      var chunkCount = Math.ceil(fileSize / chunkRange);

      while (chunkCount > 0) {
        promises.push(
          postApiCall(`${baseUrl}Document/GetChunksCountForDocument`, {
            fileKey,
            startRange,
            endRange,
          })
        );
        startRange = endRange;
        endRange = endRange + chunkRange;
        chunkCount--;
      }
    }

    Promise.all(promises).then((res: any[]) => {
      var data = "";
      // const byteArrays = []
      // res.map((val) => byteArrays.push(val.byteArray));
      // console.log(byteArrays);

      res.forEach((chunk: any) => {
        if (res.length > 1) {
          data = data + chunk.base64String;
        } else {
          data = chunk;
        }
      });
      console.log(data);

      // const sortedBuffers = res.map((item) => item.base64String);
      // let data = ''.concat(...sortedBuffers);
      // res.forEach((chunk:any) => {
      //   chunk.base64String = base64ToArrayBuffer(chunk.base64String);
      // });
      // const fileName = params.row.displayName;
      // const sortedBuffers = res.map((item) => new Uint8Array(res));
      // const ConcatenatedBuffers = concatenate(sortedBuffers);
      // saveAs(fileName,ConcatenatedBuffers);
      // console.log(res.length);
      //     // console.log(data);
      const fileName = params.row.displayName;
      // Create a temporary anchor element
      const link = window.document.createElement("a");
      link.href = "data:application/octet-stream;base64," + data;
      link.setAttribute("style", "display:none;"); // Set the desired filename
      window.document.body.appendChild(link);
      link.download = fileName; //+ params.row.fileType;
      // Programmatically trigger the download
      link.click();
      // Cleanup
      link.parentNode?.removeChild(link);
    });
  };

  const fetchData = () => {
    // documentServices
    //   .getDocumentsBySectionAndAssetPlanId(payload)
    //   .then((data: any) => {
    //     setDocumentData(data.documents);
    //     // console.log(data);
    //     // console.log(data.documents);
    //   });
    console.log(payload);
    dispatch(getAllDocumentComment(payload));
    //setDocumentData(documentComments);
  };

  // async function download({ url, chunkSize, poolLimit = 1 }) {
  //   const contentLength = await getContentLength(url);
  //   const chunks = typeof chunkSize === "number" ? Math.ceil(contentLength / chunkSize) : 1;
  //   const results = await asyncPool(
  //     poolLimit,
  //     [...new Array(chunks).keys()],
  //     (i) => {
  //       let start = i * chunkSize;
  //       let end = i + 1 == chunks ? contentLength - 1 : (i + 1) * chunkSize - 1;
  //       return getBinaryContent(url, start, end, i);
  //     }
  //   );
  //   const sortedBuffers = results
  //     .map((item) => new Uint8Array(item.buffer));
  //   return concatenate(sortedBuffers);
  // }
  function concatenate(arrays) {
    if (!arrays.length) return null;
    let totalLength = arrays.reduce((acc, value) => acc + value.length, 0);
    let result = new Uint8Array(totalLength);
    let length = 0;
    for (let array of arrays) {
      result.set(array, length);
      length += array.length;
    }
    return result;
  }
  function saveAs(name: any, buffers: any, mime = "application/octet-stream") {
    var blob = new Blob([buffers], { type: mime });
    const blobUrl = URL.createObjectURL(blob);
    const a = window.document.createElement("a");
    a.download = name || Math.random();
    a.href = blobUrl;
    a.click();
    URL.revokeObjectURL(blobUrl);
  }
  function base64ToArrayBuffer(base64) {
    var binaryString = atob(base64);
    var bytes = new Uint8Array(binaryString.length);
    for (var i = 0; i < binaryString.length; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
  }
  const handelEditCommentold = (newRow: any, e) => {
    var docArray = [newRow];
    console.log("testing");
  };

  const handelEditComment = (newRow: any, e) => {
    let docDataStateCopy: any = [...documentComments];
    const itemIndex = docDataStateCopy.findIndex((c) => c?.id == newRow?.id);
    docDataStateCopy[itemIndex] = newRow;
    console.log(docDataStateCopy);
    sendDocumentToUpdateDataSlice(docDataStateCopy[itemIndex]);
    //dispatch(addDocumentComments(docDataStateCopy));
  };

  const sendDocumentToUpdateDataSlice = (doc: DocumentComment) => {
    const commentPayload = {
      assetPlanID: doc.assetPlanID,
      id: doc.id,
      comments: doc.comments,
      fileKey: doc.fileKey,
      displayName: doc.displayName,
      uploadedDate: doc.uploadedDate,
      sectionName: payload.section,
      fileSize: doc.fileSize,
      fileType: doc.fileType,
      status: "active",
      uploadStatus: "Active",
    };
    dispatch(ensureDocumentComments(commentPayload));
    dispatch(updateDocumentComment(commentPayload));
  };
  // console.log(documentData);

  const handleDelete = (id) => {
    setToastMessageOpen(false);
    try {
      const response = postApiCall(
        `${baseUrl}Document/DeleteDocById/` + id,
        headers
      ).then((res: any) => res);

      response.finally(() => {
        fetchData();
        setToastMessage({
          message: "File Deleted successfully",
          severity: "success",
        });
        setToastMessageOpen(true);
        setTimeout(() => {
          setToastMessageOpen(false);
        }, 4000);
      });
      return response;
    } catch (error: any) {
      console.error("Error in POST request:", error);
      setToastMessage({
        message: error.message,
        severity: "success",
      });
      setToastMessageOpen(true);
      setTimeout(() => {
        setToastMessageOpen(false);
      }, 4000);
      throw error;
    }
  };

  const handleDeleteClicked = (id: any) => {
    setIsClosedClicked(true);
    // console.log(id);
    setDeleteId(id);
  };

  const handleClose = () => {
    setIsClosedClicked(false);
  };

  const handleConfirm = () => {
    // var response = handleDelete(deleteId);
    // return response;
    // console.log(deleteId);
    handleDelete(deleteId);
    setIsClosedClicked(false);
    handleClose();
  };
  const docArrData = documentComments ?? [];
  return (
    <>
      {toastMessageOpen && (
        <GenericToast
          message={toastMessage.message}
          severity={toastMessage.severity}
        />
      )}
      {mode == "edit" && (
        <Box sx={{ width: "100%" }}>
          <DataGridPro
            sx={{
              backgroundColor: "white",
              "& .MuiDataGrid-columnHeader": {
                backgroundColor: "#253746",
                color: "white",
              },
              "& .MuiDataGrid-sortIcon": {
                color: "white",
              },
              "& .MuiDataGrid-filterIcon": {
                color: "white",
              },
            }}
            disableColumnMenu={true}
            rows={documentComments ?? []}
            columns={editColumns}
            autoHeight={true}
            hideFooter={docArrData.length ? false : true}
            className={docArrData.length ? "" : "table-no-row-found"}
            // initialState={{
            //     pagination: {
            //         paginationModel: {
            //             pageSize: 5,
            //         },
            //     },
            // }}
            //pageSizeOptions={[5]}
            //checkboxSelection
            // onCellEditStart={(e) => {console.log(e);}}
            processRowUpdate={(newRow: any, e) => {
              handelEditComment(newRow, e);
            }}
            onProcessRowUpdateError={(error: any) => {
              console.log(error);
            }}
            // disableRowSelectionOnClick
          />
        </Box>
      )}
      {mode == "view" && (
        <Box sx={{ width: "100%" }}>
          <DataGridPro
            sx={{
              backgroundColor: "white",
              "& .MuiDataGrid-columnHeader": {
                backgroundColor: "#253746",
                color: "white",
              },
              "& .MuiDataGrid-sortIcon": {
                color: "white",
              },
              "& .MuiDataGrid-filterIcon": {
                color: "white",
              },
            }}
            disableColumnMenu={true}
            rows={documentComments ?? []}
            columns={viewColumns}
            autoHeight={true}
            hideFooter={docArrData.length ? false : true}
            className={docArrData.length ? "" : "table-no-row-found"}
            // initialState={{
            //     pagination: {
            //         paginationModel: {
            //             pageSize: 5,
            //         },
            //     },
            // }}
            //pageSizeOptions={[5]}
            //checkboxSelection
            // onCellEditStart={(e) => {console.log(e);}}
            processRowUpdate={(newRow: any, e) => {
              handelEditComment(newRow, e);
            }}
            onProcessRowUpdateError={(error: any) => {
              console.log(error);
            }}
            // disableRowSelectionOnClick
          />
        </Box>
      )}
      <Dialog
        open={isClosedClicked}
        onClose={handleClose}
        className="common-dialog-box"
      >
        <DialogTitle id="alert-dialog-title">
          {"Delete Confirmation"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete the file?
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button className="primary" variant="contained" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            className="primary"
            variant="contained"
            onClick={handleConfirm}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
