import React, { useEffect } from "react";
import { useAppSelector, useAppDispatch } from "../../../../redux/hooks";
import { GenericDataGrid, ColumnConfig } from "../../../util/GenericDataGrid";
import keyTenantColumnConfig from "../keyTenantColumn.json";
import moment from "moment";
import {
  getKeyTenants,
  KeyTenant,
} from "../../../../redux/slices/keyTenantsSlice";
import { GenericToast } from "../../../util/ToastMessage";
import Skeleton from "@mui/material/Skeleton";

interface GridValueFormatterParams<T> {
  value: T;
}

type ValueFormatter<T> = (
  params: GridValueFormatterParams<T>,
  currency?: string,
  decimalPosition?: number
) => string;

const currencyFormatter: ValueFormatter<number> = (
  params,
  currency = "$",
  decimalPosition = 2
) => {
  const value = params.value;
  if (typeof value === "number") {
    return `${currency}${value.toFixed(decimalPosition)}`;
  }
  return "";
};

const commaSeparatedFormatter: ValueFormatter<number> = (
  params,
  currency,
  decimalPosition
) => {
  const value = params.value;
  if (typeof value === "number") {
    return value.toLocaleString();
  } else {
    const num = parseInt(value);
    if (!isNaN(num)) {
      return num.toLocaleString();
    }
  }
  return "";
};
const dateFormatter: ValueFormatter<string> = (params) => {
  const value = params.value;
  if(value===null|| value===undefined)
  {
    return "";
  }
  if (typeof value === "string") {
      const formattedDate = moment(value?.trim()).format("MMM-DD-YYYY");
      return formattedDate;
    }
   
  return "";
};
const decimalFormatter: ValueFormatter<number> = (
  params,
  currency,
  decimalPosition = 2
) => {
  const value = params.value;
  if (typeof value === "number") {
    return value.toFixed(decimalPosition);
  }

  return "";
};

const columnConfig = keyTenantColumnConfig.map((column) => {
  const { valueFormatter, currency, decimalPosition, ...rest } = column;

  let formatter;
  if (valueFormatter === "currencyFormatter") {
    formatter = (params) =>
      currencyFormatter(params, currency, decimalPosition);
  } else if (valueFormatter === "commaSeparatedFormatter") {
    formatter = (params) =>
      commaSeparatedFormatter(params, decimalPosition?.toString());
  } else if (valueFormatter === "decimalFormatter") {
    formatter = (params) =>
      decimalFormatter(params, decimalPosition?.toString());
  } else if (valueFormatter === "dateFormatter") {
    formatter = dateFormatter; // Use the new date formatter
  }

  return {
    ...rest,
    ...(formatter && { valueFormatter: formatter }),
  };
});

const CommertialKeyTenant: React.FC = () => {
  const dispatch = useAppDispatch();
  const keyTenants = useAppSelector((state) => state.keyTenants.keyTenants);
  const keyTenantsStatus = useAppSelector((state) => state.keyTenants);
  const investmentId = useAppSelector(
    (state) => state.summary?.dapData?.investmentId
  );
  const assetPlanId = useAppSelector(
    (state) => state.summary?.dapData?.assetPlanId
  );

  useEffect(() => {
    if (!keyTenants) {
      dispatch(
        getKeyTenants({
          assetPlanId: assetPlanId || "",
          investmentId: investmentId || "",
        })
      );
    }
  }, [dispatch, investmentId]);

  const getRowId = (row: KeyTenant) => row.keyTenant;
  return (
    <div className="data-grid-table">
      {keyTenantsStatus.loading ? (
        <Skeleton
          variant="rounded"
          width="100%"
          height={150}
          animation="wave"
        />
      ) : (
        <GenericDataGrid
          rows={keyTenants || []}
          columnConfig={columnConfig}
          getRowId={getRowId}
          hideFooter={true}
        />
      )}
      {keyTenantsStatus.error && (
        <GenericToast message={keyTenantsStatus.error} severity="error" />
      )}
    </div>
  );
};

export default CommertialKeyTenant;
