import React, { FC, useEffect, useState } from "react";
import { useAppSelector, useAppDispatch } from "../../redux/hooks";
import "../../../node_modules/react-image-gallery/styles/css/image-gallery.css";
import Imageitem from "../../Imageitem";
import Carousel from "react-material-ui-carousel";
import Skeleton from "@mui/material/Skeleton";


interface ImagesOfProperty {
  original: string;
  thumbnail: string;
}
const Carouselcomponent: FC = () => {
  const dispatch = useAppDispatch();
  const [imagesOfProperty, setImagesOfProperty] = useState<any>([]);
  const [empty, setEmpty] = useState(false);
  const dapDataStatus = useAppSelector((state) => state.summary);
  const images = useAppSelector(
    (state) => state.propertyImages.ImagesOfProperty
  );
  const imagesStatus = useAppSelector((state) => state.propertyImages);

  const getImages = async () => {
    if (Object.keys(images).length === 0) {
      setEmpty(true)
    }
    else {
      for (var i = 0; i < Object.keys(images).length; i++) {
        const image = document.createElement("img");
        // image.src = `data:image/jpeg;base64,${images[i]}`;
        image.src = `${images[i]}`;

        setImagesOfProperty((current) => [
          ...current,
          {
            original: image.src,
            thumbnail: image.src,
          },
        ]);
      }
    }
  };
  useEffect(() => {
    setImagesOfProperty([]);
    getImages();

    //  else if(Object.keys(images).length === 0 && !imagesStatus.loading ){
    //   setIsLoading(false);
    //  }
  }, [Object.keys(images).length]);
  return (
    <>
    
      {dapDataStatus.loading || imagesStatus.loading ? (
        <Skeleton
          variant="rectangular"
          width="100%"
          height={300}
          animation="wave"
        />
      ) : (
        <>
        {Object.keys(images).length === 0 ? (
          <>
          <div style={{display: "flex", marginLeft: "50%",marginRight: "50%",marginTop:"20%",marginBottom:"20%", justifyContent : "center", alignItems : "center"}}>
          <h3 style={{textAlign: "center"}}><i>No image available</i></h3>
          </div>
          </>
        ) : (
        <Carousel autoPlay={true}>
          {imagesOfProperty.map((item) => (
            <Imageitem image={item.original} />
          ))}
        </Carousel>
        
      )}</>)}
    </>
  );
};

export default Carouselcomponent;
