import {useEffect, useState} from "react";
const useConfig = () => {
    const [config, setConfig] = useState<any>();
  
    useEffect(() => {
      const loadAsync = async () => {
        const sessionConfig = localStorage.getItem("config");
        if (sessionConfig) {
          setConfig(JSON.parse(sessionConfig));
          return;
        }
  
        const response = await fetch('/appsettings.json');
        console.log(response)
        const json = await response.json();
        setConfig(json);
        localStorage.setItem("config", JSON.stringify(json));
      };
      loadAsync();
    }, []);
  
    return [config];
  };
  
  export default useConfig;