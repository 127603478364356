import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { baseUrl } from "../../config/config";
import { SummaryDropdown,SectionNames,RecommendDropdown } from "../../constant";
import {postApiCall} from "../../services/apiHelper";


 export interface Option
{
    text:string,
    value:string,
    order:string
}

export interface SelectOption
{
  key:string,
  options:Option[]

}
interface InitialState{
    data:SelectOption[],
    error:string,
    loading:boolean
}
const initialState:InitialState=
{
    data:[],
    error:'',
    loading:false
}
export type OptionRequest= {
    keyList: string[]
  }

export const getOptions= createAsyncThunk("getDropdownOptions",async(section:SectionNames)=> 
{  
    //Create request object as per the section
    const request:OptionRequest={} as OptionRequest;
    switch (section) {
        case SectionNames.SUMMARY:
            request.keyList=[SummaryDropdown.INVESTMENT_DESC,SummaryDropdown.PRIMARY_LEASE_TYPE];
          break;
        case SectionNames.RECOMMENDATION:
              request.keyList=[RecommendDropdown.INVESTMENT_KEY1,RecommendDropdown.INVESTMENT_HOLDPERIOD,RecommendDropdown.INVESTMENT_KEY3,RecommendDropdown.SUSTAIN_PHYSICALRISK,
                RecommendDropdown.SUSTAIN_REGULATORYRISK,RecommendDropdown.SUSTAIN_TWREPORT,RecommendDropdown.SUSTAIN_TENANT_FEEDBACK] ;
          break;
    }
    try {
      const response = await postApiCall<string[], SelectOption[]>(`${baseUrl}Options/GetByKeys`, request.keyList);
      console.log("dropdown data", response);
      return response;
    } catch (error: any) {
      console.error('Error in POST request:', error);
      throw error;
    }
    // const response= await axios.post<SelectOption[]>(`${baseUrl}Options/GetByKeys`,request.keyList).then(res=>res.data);
    // console.log("dropdown data", response);
    // return response;
})

export const getDropdownOptionsSlice=createSlice(
    {
        name: 'getDropdownOptions',
        initialState,
        reducers:{},
        extraReducers:(builder)=>
        {
          // eslint-disable-next-line   
            builder.addCase(getOptions.pending,(state)=>
            {
              state.loading=true;
            }),
            builder.addCase(getOptions.fulfilled,(state,action:PayloadAction<SelectOption[]>)=>
            {
              state.loading=false;
              state.data=action.payload;
              console.log('dropdown options',state.data);
            }),
            builder.addCase(getOptions.rejected,(state,action)=>
            {
              state.loading=false;
              state.error=action.error.message ||'something went wrong';
            })
        }  
        
    }
);

export const selectOptions = (state) => state.data;
export default getDropdownOptionsSlice.reducer;