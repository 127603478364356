import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { baseUrl } from "../../config/config";
import {getApiCall} from "../../services/apiHelper";


export interface RecommendationData
{
  
    id: number,
    category: string,
    key: string,
    value: string,
    assetManagerRationale: string
  
}

interface InitialState{
    data:RecommendationData[],
    error:string,
    loading:boolean
}
const initialState:InitialState=
{
    data:[],
    error:'',
    loading:false
}

export const getRecommmedations= createAsyncThunk("getRecomData",async(assetPlanId:any)=>
{
  try {
    const response = await getApiCall<RecommendationData[]>(`${baseUrl}${assetPlanId}/Recommendations`);
    return response;
  } catch (error:any) {
    console.error('Error in GET request:', error?.message);
    throw error;
  }
})

export const getRecomDataSlice=createSlice(
    {
        name: 'getRecomData',
        initialState,
        reducers:{},
        extraReducers:(builder)=>
        {
          // eslint-disable-next-line   
            builder.addCase(getRecommmedations.pending,(state)=>
            {
              state.loading=true;
            }),
            builder.addCase(getRecommmedations.fulfilled,(state,action:PayloadAction<RecommendationData[]>)=>
            {
              state.loading=false;
              state.data=action.payload;
              state.error='';
              console.log('rec data',state.data);
            }),
            builder.addCase(getRecommmedations.rejected,(state,action)=>
            {
              state.loading=false;
              state.error=action.error.message ||'something went wrong';
            })
        }  
        
    }
);

export default getRecomDataSlice.reducer;