import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface Option {
  text: string;
  value: string;
  order :string;
}

interface MissionStatementState {
  missionStatement: Option[];
}

const initialState: MissionStatementState = {
  missionStatement: [],
};

const missionGroupSlice = createSlice({
  name: 'missionGroup',
  initialState,
  reducers: {
    resetState: (state) => {
      state.missionStatement = [];
    },
    setMissionGroup: (state, action: PayloadAction<Option[]>) => {
      state.missionStatement = action.payload;
    },
    updateMissionOption: (state, action: PayloadAction<Option>) => {
        const { text, value, order } = action.payload;
        const index = state.missionStatement.findIndex((option) => option.text === text);
        if (index !== -1) {
          state.missionStatement[index] = { text, value, order };
        }
    },
  },
});

export const { setMissionGroup, updateMissionOption, resetState: resetMissionStatementAction } = missionGroupSlice.actions;

export default missionGroupSlice.reducer;
