import React from "react";
import { jsPDF } from "jspdf";
import { useAppSelector } from "redux/hooks";
import { LoadingButton } from "@mui/lab";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { useState } from "react";
import headerLogoBase64 from "components/print/templates/headerlogo";
import { compileFinancialsTemplate } from "components/print/compilers/financials";
import { compilePropertyImagesTemplate } from "components/print/compilers/propertyImages";
import { compileSiteStackTemplate } from "components/print/compilers/siteStackPlanImages";
import { compileSummaryTemplate } from "components/print/compilers/summary";
import { compileRecommendationTemplate } from "components/print/compilers/recommendations";
import { compileSwotTemplate } from "components/print/compilers/swot";
import { compileKeyProvisionTemplate } from "components/print/compilers/keyProvisions";
import { compilePostClosingTemplate } from "components/print/compilers/postClosing";
import { compileValuationTemplate } from "components/print/compilers/valuation";
import { compileLeasingTemplate } from "components/print/compilers/leasing";
import { compileCapitalPlanTemplate } from "components/print/compilers/capitalPlan";
import { fetchAssetPlanData } from "components/print/util/printapiCallhelper";
import moment from "moment";
import { useAppDispatch } from "../../redux/hooks";
import {
  rejectionStatus,
  rejectionToast,
} from "../../redux/slices/dialogFormSlice";

const PrintToPDF: React.FC = () => {
  const dispatch = useAppDispatch();
  const [printLoaderButton, setPrintLoaderButton] = useState(false);
  const investmentId =
    useAppSelector((state) => state.summary?.dapData?.investmentId) || "";
  const propertyType =
    useAppSelector((state) => state.summary?.dapData?.propertyType) || "";

  const budgetYear =
    useAppSelector((state) => state.summary?.dapData?.budgetYear) || "";
  const assetPlanId =
    useAppSelector((state) => state.summary?.dapData?.assetPlanId) || "";
  const investmentName =
    useAppSelector((state) => state.summary?.dapData?.dealName) || "";

  const margins = { top: 70, right: 40, bottom: 40, left: 40 };

  const handlePrint = () => {
    setPrintLoaderButton(true);
    generateDapPdf();
  };

  const addSectionToPdf = (
    index,
    htmlContents,
    pdfInstance,
    pageHeight,
    width,
    assetPlanDetails
  ) => {
    const htmlContent = htmlContents[index];
    pdfInstance.html(htmlContent, {
      callback: (doc) => {
        index++;
        if (index < htmlContents.length) {
          addSectionToPdf(
            index,
            htmlContents,
            doc,
            pageHeight,
            width,
            assetPlanDetails
          );
        } else {
          generateFinalPdf(doc, assetPlanDetails);
        }
      },
      x: 0,
      y:
        index === 0
          ? 0
          : getPositionY(pdfInstance.internal.pages.length, pageHeight),
      autoPaging: "text",
      width: width - (margins.left + margins.right), //target width in the PDF document
      windowWidth: width, //window width in CSS pixels
      margin: Object.values(margins),
    });
  };

  const generateDapPdf = async () => {
    var pdfInstance = new jsPDF("p", "pt");
    var width = pdfInstance.internal.pageSize.getWidth();

    const assetPlanDetails = {
      assetPlanId: assetPlanId,
      investmentId: investmentId,
      budgetYear: budgetYear,
      investmentName: investmentName,
      propertyType: propertyType,
    };

    fetchAssetPlanData(assetPlanDetails).then((assetPlanData) => {
      if (assetPlanData.error) {
        setPrintLoaderButton(false);
        dispatch(rejectionToast(true));
        dispatch(
          rejectionStatus({
            message: "Export to pdf failed. Please try again!",
            severity: "error",
          })
        );
        setTimeout(() => {
          dispatch(rejectionToast(false));
        }, 4000);
      } else {
        const htmlContents = getHtmlContents(assetPlanData);
        const pageHeight = pdfInstance.internal.pageSize.getHeight();
        addSectionToPdf(
          0,
          htmlContents,
          pdfInstance,
          pageHeight,
          width,
          assetPlanDetails
        );
      }
    });
  };

  //for escape element inline style
  const escapeInlineStyle = (str) => {
    let finalStr = str.replaceAll("style=", "");
    return finalStr;
  };

  const getHtmlContents = (assetPlanData) => {
    //compile templates
    var financialsHtml = compileFinancialsTemplate(
      assetPlanData.financials,
      budgetYear,
      assetPlanData.comments
    );
    var propertyImagesHtml = compilePropertyImagesTemplate(
      assetPlanData.propertyImages
    );
    var sitePlanHtml = compileSiteStackTemplate(
      assetPlanData.sitePlans,
      "SitePlan"
    );
    var stackingPlanHtml = compileSiteStackTemplate(
      assetPlanData.stackingPlans,
      "StackingPlan"
    );
    var summaryHtml = compileSummaryTemplate(
      assetPlanData.summaryData,
      assetPlanData.comments
    );
    var recommendationHtml = compileRecommendationTemplate(
      assetPlanData.recomData,
      assetPlanData.comments,
      parseInt(budgetYear)
    );
    var swotHtml = compileSwotTemplate(assetPlanData.comments);
    var keyProvisionHtml = compileKeyProvisionTemplate(assetPlanData.comments);
    var postClosingHtml = compilePostClosingTemplate(assetPlanData.comments);
    var valuationHtml = compileValuationTemplate(
      assetPlanData.valuationData,
      propertyType,
      assetPlanData.comments
    );
    console.log("inside pdf", assetPlanData.commertial);
    var leasingHtml = compileLeasingTemplate(
      assetPlanData.residential,
      assetPlanData.leaseExpirations,
      budgetYear,
      assetPlanData.commertial,
      assetPlanData.keyTenants,
      assetPlanData.comments,
      propertyType
    );
    const escapedLeasingHtmlArray: string[] = leasingHtml.map((html: string) =>
      escapeInlineStyle(html)
    );
    var capitalPlanHtml = compileCapitalPlanTemplate(
      assetPlanData.capitalPlanTable1,
      assetPlanData.capitalPlanTable2,
      budgetYear,
      assetPlanData.comments
    );

    return [
      escapeInlineStyle(summaryHtml),
      ...propertyImagesHtml,
      escapeInlineStyle(recommendationHtml),
      ...sitePlanHtml,
      ...stackingPlanHtml,
      financialsHtml,
      capitalPlanHtml,
      escapeInlineStyle(swotHtml),
      ...escapedLeasingHtmlArray,
      escapeInlineStyle(valuationHtml),
      escapeInlineStyle(keyProvisionHtml),
      escapeInlineStyle(postClosingHtml),
    ];
  };

  const getPositionY = (numberOfPages: number, pageHeight: number) => {
    console.log(numberOfPages, pageHeight);
    const positionY =
      (numberOfPages - 1) * (pageHeight - (margins.top + margins.bottom));
    console.log(positionY);
    return positionY;
  };

  const generateFinalPdf = (finalDoc, assetPlanDetails) => {
    addHeaderFooters(finalDoc, assetPlanDetails);
    finalDoc.save(getPdfFileName(assetPlanDetails));
    setPrintLoaderButton(false);
  };

  const getPdfFileName = (assetPlanDetails) => {
    const currentTime = new Date();
    return `DAP ${assetPlanDetails.budgetYear} - ${
      assetPlanDetails.investmentId
    } - ${moment().format("MMM-DD-YYYY hhmm A")}.pdf`;
  };

  const addHeaderFooters = (doc, assetPlanDetails) => {
    const pageCount = doc.internal.getNumberOfPages();
    console.log(doc.internal.pageSize.width, doc.internal.pageSize.height);
    for (var i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      addHeader(doc, assetPlanDetails);
      doc.setFontSize(8);
      doc.setTextColor("black");
      doc.text(
        "Page " + String(i) + " of " + String(pageCount),
        doc.internal.pageSize.width / 2,
        820,
        {
          align: "center",
        }
      );
      // doc.text('HEADER TEMPLATE', doc.internal.pageSize.width / 2, 20, {
      //   align: 'center'
      // });
    }
  };

  const addHeader = (doc, assetPlanDetails) => {
    if (headerLogoBase64) {
      doc.addImage(headerLogoBase64, "PNG", margins.left, 10, 80, 40);
    }
    doc.setFontSize(18);
    //doc.setTextColor(0, 0, 255);
    doc.setTextColor("green");
    //doc.setFontStyle('normal');
    doc.text(
      `${assetPlanDetails.budgetYear} Digital Asset Plan`,
      doc.internal.pageSize.width / 2,
      25,
      { align: "center" }
    );
    doc.setFontSize(14);
    doc.text(
      `${assetPlanDetails.investmentId} - ${assetPlanDetails.investmentName}`,
      doc.internal.pageSize.width / 2,
      45,
      { align: "center" }
    );
    //doc.line(3, 60, doc.internal.pageSize.width - 3, 60); // horizontal line
  };

  return (
    <div
      style={{
        position: "relative",
        display: "inline-block",
        whiteSpace: "nowrap",
      }}
    >
      <LoadingButton
        loading={printLoaderButton}
        variant="contained"
        onClick={handlePrint}
        className="primary"
        size="small"
        sx={{ lineHeight: 2.2 }}
      >
        <PictureAsPdfIcon sx={{ mr: "2.5px" }} />
        Export to PDF
      </LoadingButton>
    </div>
  );
};

export default PrintToPDF;
