import { getApiCall, putApiCall } from "./apiHelper";
import { baseUrl } from "config/config";

export interface capitalProject {
  projectPriority: string;
  esgImpact: boolean;
  projectId: string;
  currentBudget: number;
  futureBudget: number;
  totalBudget: number;
  projectDescription: string;
  assetPlanId: string;
  id: number;
  comment: string;
}

export interface capitalProjectResponse {
  capitalProjects: capitalProject[];
}

export class CapitalPlanService {
  updateCapitalProject(assetPlanId: string, formData: capitalProject[]) {
    var url = `${baseUrl}` + assetPlanId + "/CapitalProjects"
    const res = putApiCall(url, formData).then((data: any) => data);
    return res;
   }

  getDropdownItems() {
    var url = `${baseUrl}` + "GetDropdownItems";
    const res = getApiCall(url).then((data: any) => data);
    return res;
  }

  getAllCapitalProjects(assetPlanId: any): Promise<capitalProject[]>  {
    var url = `${baseUrl}` + assetPlanId + "/CapitalProjects";
    return getApiCall<capitalProjectResponse>(url).then((data) => data.capitalProjects);
  }
}
