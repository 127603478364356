import handlebars from "handlebars";
import keyProvisionsTemplate from "../templates/keyProvisionsTemplate";
import { CommentKey} from "../../../constant";

export const compileKeyProvisionTemplate = (commentData:any[]): string => {
    

    const compileKeyProvisionTemplate = handlebars.compile(keyProvisionsTemplate);
    const Comment: any = commentData || [];
    const KeyProvisionComment = {
        mangementComment_date:
          Comment?.find(
            (x) =>
              x.commentKey?.toLowerCase() ===
              CommentKey.MANAGEMENTAGREEMENT_DATE.toLowerCase()
          )?.commentText || "",
        mangementComment_fee:
          Comment?.find(
            (x) =>
              x.commentKey?.toLowerCase() ===
              CommentKey.MANAGEMENTAGREEMENT_FEE.toLowerCase()
          )?.commentText || "",
        mangementComment_otherFee:
          Comment?.find(
            (x) =>
              x.commentKey?.toLowerCase() ===
              CommentKey.MANAGEMENTAGREEMENT_OTHERFEE.toLowerCase()
          )?.commentText || "",
        leasebroker_name:
          Comment?.find(
            (x) =>
              x.commentKey?.toLowerCase() ===
              CommentKey.LEASEBROKER_NAME.toLowerCase()
          )?.commentText || "",
        leasebroker_fee:
          Comment?.find(
            (x) =>
              x.commentKey?.toLowerCase() ===
              CommentKey.LEASEBROKER_FEE.toLowerCase()
          )?.commentText || "",
        leasebroker_otherfee:
          Comment?.find(
            (x) =>
              x.commentKey?.toLowerCase() ===
              CommentKey.LEASEBROKER_OTHERFEE.toLowerCase()
          )?.commentText || "",
        jvagreement_KeyDecision:
          Comment?.find(
            (x) =>
              x.commentKey?.toLowerCase() ===
              CommentKey.JVAGREEMENT_KEYDECISION.toLowerCase()
          )?.commentText || "",
        jvagreement_liquidProvision:
          Comment?.find(
            (x) =>
              x.commentKey?.toLowerCase() ===
              CommentKey.JVAGREEMENT_LIQUIDITYPROVISION.toLowerCase()
          )?.commentText || "",
        jvagreement_fee:
          Comment?.find(
            (x) =>
              x.commentKey?.toLowerCase() ===
              CommentKey.JVAGREEMENT_FEE.toLowerCase()
          )?.commentText || "",
        jvagreement_Keyprovision:
          Comment?.find(
            (x) =>
              x.commentKey?.toLowerCase() ===
              CommentKey.JVAGREEMENT_KEYPROVISION.toLowerCase()
          )?.commentText || "",
      };
    return compileKeyProvisionTemplate(KeyProvisionComment);

};