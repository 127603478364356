import handlebars from "handlebars";
import leasingResidentialTemplate from "../templates/leasingResidentialTemplate";
import leasingCommertialTemplate from "../templates/leasingCommertialTemplate";
import { CommentKey } from "constant";
import {formatDate} from "./valuation";
import {
  percentage,
  numberWithComma,
  formatValue
} from "../../util/valueFormatter";

export const compileResidentialTemplate = (
  residentialData: any,
  leaseExpirations: any,
  budgetYear:string,
  comments:any[]
): string => {
  const compiledLeasingTemplate = handlebars.compile(
    leasingResidentialTemplate
  );
  const residentialComment = comments?.find(
    (x) => x.commentKey?.toLowerCase() === CommentKey.RESIDENTIAL.toLowerCase()
  );

  //format leaseExpirationsData
  const leasingFormattedData = leaseExpirations?.data?.map((data) => ({
    expiryYear: data.expiryYear,
    expiringUnit: numberWithComma(parseFloat(data.expiringUnit),2),
    expiringPercentage: formatValue(parseFloat(data.expiringPercentage),2),
    cumulativePercentage: formatValue(parseFloat(data.cumulativePercentage),2)
  }));

  const residentialFormattedData={
      totalUnits: numberWithComma(parseFloat(residentialData?.data?.totalUnits)),
      rentableSqft: numberWithComma(parseFloat(residentialData?.data?.rentableSqft)),
      physicalOccupancyPC: percentage(parseFloat(residentialData?.data?.physicalOccupancyPC)),
      leaseRenewalPC: percentage(parseFloat(residentialData?.data?.leaseRenewalPC)),
      startYearLeaseUnit: numberWithComma(parseFloat(residentialData?.data?.startYearLeaseUnit)),
      newLease: numberWithComma(parseFloat(residentialData?.data?.newLease)),
      startYearLeasePC: percentage(parseFloat(residentialData?.data?.startYearLeasePC)),
      endYearLeasePC: percentage(parseFloat(residentialData?.data?.endYearLeasePC)),
  };
 
  const residentialTemplateData = {
    residentialData: residentialFormattedData,
    expirationsData: leasingFormattedData,
    budgetYear: budgetYear,
    residentialComment: residentialComment
  };
  return compiledLeasingTemplate(residentialTemplateData);
};




export const compileCommertialTemplate = (
  commertialData: any,
  leaseExpirations: any,
  keyTenantsData: any,
  budgetYear:string,
  comments:any[]
): string => {
  const compiledLeasingTemplate = handlebars.compile(leasingCommertialTemplate);
  const commertialComment = comments?.find(
    (x) => x.commentKey?.toLowerCase() === CommentKey.COMMERTIAL.toLowerCase()
  );

  const leasingFormattedData = leaseExpirations?.data?.map((data) => ({
    expiryYear: data.expiryYear,
    expiringUnit: numberWithComma(parseFloat(data.expiringUnit),2),
    expiringPercentage: formatValue(parseFloat(data.expiringPercentage),2),
    cumulativePercentage: formatValue(parseFloat(data.cumulativePercentage),2)
  }));

  const keyTenantsFormattedData=keyTenantsData?.keyTenants?.map((data) => ({
    keyTenant: data.keyTenant,
    totalSF: numberWithComma(parseFloat(data.totalSF),2),
    investmentPercent: formatValue(parseFloat(data.investmentPercent),2),
    rent: formatValue(parseFloat(data.rent),2),
    expirationDate:formatDate(data.expirationDate),
    totalSales:data.totalSales,
    options:data.options,
    salesPeriod:data.salesPeriod
  }));


  const commertialFormattedData=
  {
    totalGLA : numberWithComma(commertialData?.data?.totalGLA, 0),
    startYearGLALeased:numberWithComma(commertialData?.data?.startYearGLALeased, 0),
   startYearLeasedPC :percentage(commertialData?.data?.startYearLeasedPC),
   endYearGLALeased : numberWithComma(commertialData?.data?.endYearGLALeased, 0),
    endYearLeasedPC : percentage(commertialData?.data?.endYearLeasedPC),
    netAbsorptionOcc : numberWithComma(commertialData?.data?.netAbsorptionOcc, 0),
    newLease:commertialData?.data?.newLease.toLocaleString(),
    newLeaseTotalSqft:commertialData?.data?.newLeaseTotalSqft.toLocaleString(),
    renewLease:commertialData?.data?.renewLease.toLocaleString(),
    renewLeaseTotalSqft:commertialData?.data?.renewLeaseTotalSqft.toLocaleString()

  }

  console.log("formmattedData",commertialFormattedData);

  const commertialTemplateData = {
    commertialData: commertialFormattedData,
    expirationsData: leasingFormattedData,
    keyTenantsData: keyTenantsFormattedData,
    budgetYear: budgetYear,
    commertialComment: commertialComment
  };
  return compiledLeasingTemplate(commertialTemplateData);
};


export const compileMixedLeasingTemplate =  (
  residentialData: any,
  leaseExpirations: any,
  budgetYear:string,
  commertialData: any,
  keyTenantsData: any,
  comments:any[]
): string[] => {
  try {
    // Call the required functions to get the data
   
    const residentialComment = comments?.find(
      (x) => x.commentKey?.toLowerCase() === CommentKey.RESIDENTIAL.toLowerCase()
    );
    const commertialComment = comments?.find(
      (x) => x.commentKey?.toLowerCase() === CommentKey.COMMERTIAL.toLowerCase()
    );
    // const compiledResidentialLeasingTemplate = handlebars.compile(leasingResidentialTemplate);
    // const compiledCommertialLeasingTemplate= handlebars.compile(leasingCommertialTemplate)
    // const commertialTemplateData = {
    //   commertialData: commertialData?.data,
    //   expirationsData: leaseExpirations?.data,
    //   keyTenantsData: keyTenantsData?.data,
    //   budgetYear: budgetYear,
    //   commertialComment: commertialComment
    // };
    // const residentialTemplateData = {
    //   residentialData: residentialData?.data,
    //   expirationsData: leaseExpirations?.data,
    //   budgetYear: budgetYear,
    // residentialComment: residentialComment
    // };

    const residentialHtml= compileResidentialTemplate(residentialData, leaseExpirations, budgetYear,comments);
    const commercialHtml= compileCommertialTemplate(commertialData, leaseExpirations,keyTenantsData, budgetYear,comments)
    return [residentialHtml, commercialHtml];
    
  } catch (error) {
    console.error('Error in compileCommertialTemplate:', error);
    throw error;
  }
};




  export const compileLeasingTemplate =  (
    residentialData: any,
    leaseExpirations: any,
    budgetYear:string,
    commertialData: any,
    keyTenantsData: any,
    comments:any[],
    propertyType:string
  ): string[] => {
    try {
      if (propertyType === 'Residential') {
        const template =  compileResidentialTemplate(residentialData, leaseExpirations, budgetYear,comments);
        return [template];
      } else if (propertyType === 'Commertial') {
        const template =  compileCommertialTemplate(commertialData, leaseExpirations,keyTenantsData, budgetYear,comments);
        return [template];
      } else if (propertyType === 'Mixed') {
        const template =  compileMixedLeasingTemplate(residentialData, leaseExpirations, budgetYear,commertialData,keyTenantsData,comments);
        return template;
      }
      return [];
  
    } catch (error) {
      console.error('Error in compileLeasingTemplate:', error);
      throw error;
    }
  };
  

  
