import * as React from "react";
import { useEffect, useState, useRef } from "react";
import { useMsal } from "@azure/msal-react";

import { useAppSelector, useAppDispatch } from "redux/hooks";
import { baseUrl } from "config/config";
import { postApiCall } from "services/apiHelper";
import { updateStatus } from "redux/slices/updateDataSlice";
import { ActionNames } from "constant";
import { clearDocumentComments } from "components/util/storeManager";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";

const eventTypes = [
  "keypress",
  "mousemove",
  "mousedown",
  "scroll",
  "touchmove",
  "pointermove",
];
const addEventListeners = (listener) => {
  eventTypes.forEach((type) => {
    window.addEventListener(type, listener, false);
  });
};
const removeEventListeners = (listener) => {
  if (listener) {
    eventTypes.forEach((type) => {
      window.removeEventListener(type, listener, false);
    });
  }
};

export const SessionTimeoutPopup = () => {
  const dispatch = useAppDispatch();
  const savedData = useAppSelector((state) => state.updateData);
  const recomData = useAppSelector((state) => state.latestRecom.data);
  const dapData = useAppSelector((state) => state.summary.dapData);
  const savedComment = useAppSelector(
    (state) => state.saveComment.updateDapComments
  );
  const { instance } = useMsal();
  const [isWarningPopupOpen, setWarningPopupOpen] = useState(false);

  // Prepend `0` for one digit numbers.
  const padTime = (time) => {
    return String(time).length === 1 ? `0${time}` : `${time}`;
  };
  const format = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${padTime(seconds)}`;
  };
  const timeoutCountdown = 300; // 5 min for popup countdown to signout
  const [counter, setCounter] = useState(timeoutCountdown);

  //call this function when timer reaches 0
  function signOutHandler(instance) {
    const logoutRequest = {
      postLogoutRedirectUri: "/",
    };
    //instance.logoutRedirect({ account: account });
    instance.logoutRedirect(logoutRequest);
  }

  function saveDataAPICalls(
    savedData: any,
    savedComment: any,
    userAction: string
  ) {
    if (savedData) {
      postApiCall(`${baseUrl}UpdateData/SaveAllData`, {
        ...savedData,
        assetPlanId: dapData?.assetPlanId,
        useraction: userAction,
        recommendationTab: {
          assetPlanId: dapData?.assetPlanId,
          recommendations: recomData,
        },
      });
    }
    if (savedComment) {
      postApiCall(`${baseUrl}DapComments/UpdateDapComments`, savedComment);
    }
    clearDocumentComments(dispatch);
  }

  useEffect(() => {
    const popupDelay = 600000; // 10 mins to popup open
    let timer;

    const initiatePopup = () =>
      setTimeout(() => {
        setWarningPopupOpen(true);
      }, popupDelay);

    const initiateSignout = () =>
      setTimeout(() => {
        // decrement timer
        if (counter > 0) {
          if (isWarningPopupOpen) {
            timer = setTimeout(() => setCounter((c) => c - 1), 1000);
          }
        }
        // call signout function when timer reaches 0
        if (counter === 0) {
          console.log("logging out");
          signOutHandler(instance);
        }
        // save data on popup open
        if (counter === timeoutCountdown) {
          if (dapData?.assetPlanId) {
            console.log(dapData?.assetPlanId);
            dispatch(updateStatus("Saved"));
            saveDataAPICalls(
              savedData,
              savedComment,
              ActionNames.SAVE.toString()
            );
          }
        }
      }, 0);

    const listener = () => {
      if (!isWarningPopupOpen) {
        clearTimeout(timeout);
        clearTimeout(timer);
        timeout = initiatePopup();
      }
    };

    // Initialization
    let timeout = isWarningPopupOpen ? initiateSignout() : initiatePopup();
    addEventListeners(listener);

    // Cleanup
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
      removeEventListeners(listener);
      clearTimeout(timeout);
    };
  }, [isWarningPopupOpen, counter]);

  const resetTimeOut = () => {
    setWarningPopupOpen(false);
    setCounter(timeoutCountdown); // reset timer to 5 min
  };
  return (
    <div>
      {isWarningPopupOpen && (
        <div>
          <Dialog
            open={isWarningPopupOpen}
            onClose={resetTimeOut}
            aria-labelledby="alert-dialog-title-duplicate"
            aria-describedby="alert-dialog-description-duplicate"
            id="duplicate modal alert"
            className="common-dialog-box"
          >
            <DialogTitle sx={{ m: 0, p: 2 }}>
              Session Timeout{" "}
              <IconButton
                edge="start"
                color="inherit"
                onClick={resetTimeOut}
                aria-label="close"
                className="close-btn"
              >
                <CloseIcon sx={{ mt: "-6px" }} onClick={resetTimeOut} />
              </IconButton>
            </DialogTitle>
            <DialogContent sx={{ padding: "0px" }} className="m-20 p-10">
              <AccessAlarmIcon
                fontSize="large"
                sx={{
                  color: "#2b8818",
                  display: "flex",
                  flexDirection: "column",
                  m: "auto",
                  width: "fit-content",
                }}
              />
              <DialogContentText
                id="alert-dialog-description"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  m: "auto",
                  width: "fit-content",
                }}
              >
                Your session is about to expire.
              </DialogContentText>

              <Box
                noValidate
                component="form"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  m: "auto",
                  width: "fit-content",
                  fontSize: "20px",
                }}
              >
                {" "}
                {format(counter)}{" "}
              </Box>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={resetTimeOut}
                className="primary"
                variant="contained"
              >
                Reset
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      )}
    </div>
  );
};
