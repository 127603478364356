import handlebars from 'handlebars';
const eq = (a: any, b: any) => a === b;

// Register the 'eq' helper with Handlebars
handlebars.registerHelper('eq', eq)

const imagesWithHeaderTemplate = `
<!DOCTYPE html>
<html>  
  <body>
    <!-- print table -->
    <table style="width: 100%;">
    <h2 class="pdf-table-heading" >{{headerName}}</h2>
    {{#each images}}
        <tr>
            <td >
                <div style="text-align: center;">
                <img height="370" crossorigin="anonymous" src={{this}} style="border:0;outline:none;text-decoration:none;height:370;font-size:14px;" width="550">
                </div>
            </td>
        </tr>
    {{/each}}        
    </table>
  </body>
</html>
`;

export default imagesWithHeaderTemplate;