import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";

import { useAppSelector } from "../../../redux/hooks";
import { CommentKey } from "../../../constant";

// Require Editor JS files.
import "froala-editor/js/froala_editor.pkgd.min.js";
import "froala-editor/js/plugins.pkgd.min.js";
import "froala-editor/js/third_party/embedly.min.js";

// Require Editor CSS files.
import "froala-editor/css/froala_style.min.css";
import "froala-editor/css/froala_editor.pkgd.min.css";
import "froala-editor/css/third_party/embedly.min.css";
import FroalaEditorView from "react-froala-wysiwyg/FroalaEditorView";

import Skeleton from "@mui/material/Skeleton";
import { GenericToast } from "../../util/ToastMessage";

// for grid
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  color: theme.palette.text.secondary,
}));

export const SwotDisplayForm = () => {
  const swotStatus = useAppSelector((state) => state.dapComments);
  const strengthComment = useAppSelector(
    (state) => state.dapComments?.data
  ).find(
    (x) => x.commentKey.toLowerCase() === CommentKey.STRENGTH.toLowerCase()
  );
  const weaknessComment = useAppSelector(
    (state) => state.dapComments?.data
  ).find(
    (x) => x.commentKey.toLowerCase() === CommentKey.WEAKNESS.toLowerCase()
  );
  const oppurtunitiesComment = useAppSelector(
    (state) => state.dapComments?.data
  ).find(
    (x) => x.commentKey.toLowerCase() === CommentKey.OPPURTINITIES.toLowerCase()
  );
  const threatsComment = useAppSelector(
    (state) => state.dapComments?.data
  ).find(
    (x) => x.commentKey.toLowerCase() === CommentKey.THREATS.toLowerCase()
  );
  return (
    <div>
      <div className="tab-heading text-center">
        <h3 className="heading">SWOT ANALYSIS</h3>
      </div>
      <h3 className="text-left sub-heading">
        Asset/Market/Tenancy/Competition
      </h3>
      {/* <Button
            variant="contained"
            className="primary"
            size="small"
            onClick={submitSwot}
          >
            Save
          </Button> */}

      <Box sx={{ flexGrow: 1 }} className="summary-form m-20">
        <Grid container spacing={2}>
          {/* block1 */}
          <Grid item xs={12} md={3}>
            {swotStatus.loading ? (
              <Skeleton
                variant="rounded"
                width="100%"
                height={250}
                animation="wave"
              />
            ) : (
              <Item>
                <h3 className="text-left">Strengths</h3>
                <div className="grid-item-box">
                  <FroalaEditorView
                    model={strengthComment?.commentText || ""}
                  />
                </div>
              </Item>
            )}
          </Grid>

          <Grid item xs={12} md={3}>
            {swotStatus.loading ? (
              <Skeleton
                variant="rounded"
                width="100%"
                height={250}
                animation="wave"
              />
            ) : (
              <Item>
                <h3 className="text-left">Weakness</h3>
                <div className="grid-item-box">
                  <FroalaEditorView
                    model={weaknessComment?.commentText || ""}
                  />
                </div>
              </Item>
            )}
          </Grid>

          <Grid item xs={12} md={3}>
            {swotStatus.loading ? (
              <Skeleton
                variant="rounded"
                width="100%"
                height={250}
                animation="wave"
              />
            ) : (
              <Item>
                <h3 className="text-left">Opportunities</h3>
                <div className="grid-item-box">
                  <FroalaEditorView
                    model={oppurtunitiesComment?.commentText || ""}
                  />
                </div>
              </Item>
            )}
          </Grid>

          {/* block3 */}
          <Grid item xs={12} md={3}>
            {swotStatus.loading ? (
              <Skeleton
                variant="rounded"
                width="100%"
                height={250}
                animation="wave"
              />
            ) : (
              <Item>
                <h3 className="text-left">Threats</h3>
                <div className="grid-item-box">
                  <FroalaEditorView model={threatsComment?.commentText || ""} />
                </div>
              </Item>
            )}
          </Grid>
        </Grid>
      </Box>
      {swotStatus.error && (
        <GenericToast message={swotStatus.error} severity="error" />
      )}
    </div>
  );
};
