import * as React from "react";
import { useEffect } from "react";

import moment from 'moment';

import { useAppSelector, useAppDispatch } from "../redux/hooks";
import { getBudgetStatus } from "../redux/slices/budgetStatusSlice";
import { InvBudgetYearParams } from "../types/assetplanid";

export default function BudgetStatus() {
    const dispatch = useAppDispatch();
    const budgetYear = useAppSelector((state) => state.summary?.dapData?.budgetYear);
    const investmentId = useAppSelector((state) => state.summary?.dapData?.investmentId);
    const assetPlanId = useAppSelector((state) => state.summary?.dapData?.assetPlanId);
    const budgetStatus = useAppSelector((state) => state.budgetStatus?.data);
    console.log(budgetStatus);
    useEffect(() => {
        let year = parseInt(budgetYear || '');
        const postData: InvBudgetYearParams = {
            investmentId: investmentId || '',
            budgetYear: isNaN(year) ? 0 : year
        };
        dispatch(getBudgetStatus(postData));
    }, [assetPlanId]);

    const getFormattedTimeStamp = (timeStamp: string) => {
        //TODO: fix timezone issue
        return moment(timeStamp).format('MMM-DD-YYYY hh:mm A');
    }

    return (
        <>
            {budgetStatus && <p className="txt">Last budget update (EST) {getFormattedTimeStamp(budgetStatus.yardiLastModifiedTimeStamp)}</p>}
        </>
    )
}