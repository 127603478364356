import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { baseUrl } from "../../config/config";
import {postApiCall} from "../../services/apiHelper";

type Swot = {
    assetPlanID: string;
    strengths: string;
    weakness: string;
    opportunities: string;
    threats: string;
    physicalClimateChange: string;
    climateRegulation: string;
    sectorFundESGPriorities: string;
    tenantResidentFeedbackActionPlan: string;
  }

type InitialState={
    loading:boolean,
    swot:Swot,
    error:string
}
interface assetID {
  assetPlanID:string;
}
const initialState:InitialState={
    loading:false,
    swot:{} as Swot,
    error:''
}

export const getSwotData = createAsyncThunk("getSwotData",async(payload:assetID)=>
{
    const response= await postApiCall(`${baseUrl}Swot/GetSwotByAssetPlanID`,payload).then((res:any) => 
    res);
    return response;
});


export const getSwotDataSlice=createSlice(
    {
        name: 'getSwotData',
        initialState,
        reducers:{
          editSwot(state, action: PayloadAction<Swot>) {
            state.swot = action.payload;
          },
        },
        extraReducers:(builder)=>
        {
          // eslint-disable-next-line   
            builder.addCase(getSwotData.pending,(state)=>
            {
              state.loading=true;
            }),
            builder.addCase(getSwotData.fulfilled,(state,action:PayloadAction<Swot>)=>
            {
              state.loading=false;
              state.swot=action.payload;
            }),
            builder.addCase(getSwotData.rejected,(state,action)=>
            {
              state.loading=false;
              state.error=action.error.message ||'something went wrong';
            })
           
        }  
        
    }
);
export const {editSwot} = getSwotDataSlice.actions;
export default getSwotDataSlice.reducer;
