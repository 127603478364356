import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { baseUrl, headers } from "../../config/config";
import { postApiCall } from "../../services/apiHelper";
import {PostData} from './commertialLeasingSlice';

export interface ResidentialLeasing {
  investmentId: number;
  totalUnits: number;
  rentableSqft: number;
  averagePhyOccupancy: number;
  leaseRenewal:number;
}

interface ResidentialLeasingState {
  data: ResidentialLeasing|null;
  error: string;
  loading: boolean;
}

const initialState: ResidentialLeasingState = {
  data: null,
  error: "",
  loading: false,
};


export const getResidentialLeasing = createAsyncThunk(
  "getResidentialLeasing",
  async (payload: PostData) => {
    try {
      const response = await postApiCall<PostData, ResidentialLeasingState>(
        `${baseUrl}Leasing/GetResidentialLeasing`,
        payload,
        headers
      );
      return response.data;
    } catch (error: any) {
      console.error("Error in POST request:", error);
      throw error;
    }
  }
);

const residentialLeasingSlice = createSlice({
  name: "residentialLeasing",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getResidentialLeasing.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        getResidentialLeasing.fulfilled,
        (state, action: PayloadAction<ResidentialLeasing|null>) => {
          state.loading = false;
          state.data = action.payload;
        }
      )
      .addCase(getResidentialLeasing.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Something went wrong";
      });
  },
});

export default residentialLeasingSlice.reducer;
