import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';

import { Configuration, PublicClientApplication } from '@azure/msal-browser'

const getToken = async (): Promise<string> => {
  //debugger;

  const sessionConfig = localStorage.getItem("config");
  if (sessionConfig === null) {
    console.log("App settings (msal) are not loaded into the session store yet!");
    return "";
  }

  const appSettings = JSON.parse(sessionConfig);
  const msalConfig = appSettings.msalConfig;

  const protectedResources = appSettings.protectedResources;
  if (protectedResources === null || protectedResources === undefined) {
    console.log("App settings (protected resources) are not loaded into the redux store yet!");
    return "";
  }
  const dapApi = protectedResources.dapApi;

  const pca = new PublicClientApplication(msalConfig);
  const accounts = pca.getAllAccounts();
  const currentAccount = accounts[0];
  const accessTokenRequest = {
    scopes: dapApi.scopes,
    account: currentAccount,
  };

  const resp = await pca.acquireTokenSilent(accessTokenRequest);
  return resp.accessToken;
}

interface ApiResponse<T> {
  data: T;
  status: number;
  statusText: string;
  headers: any;
  config: any;
}


// export function postApiCall<T, R>(url: string, data: T): Promise<R> {
//   return axios.post<T, AxiosResponse<R>>(url, data)
//     .then(response => response.data)
//     .catch((error: AxiosError) => {
//       console.error('Error in POST request:', error);
//       throw error;
//     });
// }
export function postApiCallNoToken<T, R>(url: string, data: T, headers?: Record<string, string>): Promise<R> {
  return axios.post<T, AxiosResponse<R>>(url, data, { headers })
    .then(response => response.data)
    .catch((error: AxiosError) => {
      console.error('Error in POST request:', error);
      throw error;
    });
}

export function postApiCall<T, R>(url: string, data: T, headers?: Record<string, string>): Promise<R> {
  return getToken().then(token => {
    // console.log('post', token);
    headers = { 'Authorization': token };
    return axios.post<T, AxiosResponse<R>>(url, data, { headers })
      .then(response => {
        console.log(response);
        
        return response.data
      })
      .catch((error: AxiosError) => {
        console.error('Error in POST request:', error);
        throw error;
      });
  });
}

export function postDeleteApiCall<T, R>(url: string, headers?: Record<string, string>): Promise<R> {
  return getToken().then(token => {
    //console.log('post', token);
    headers = { 'Authorization': token };
    return axios.post<T, AxiosResponse<R>>(url, { headers })
      .then(response => response.data)
      .catch((error: AxiosError) => {
        console.error('Error in POST request:', error);
        throw error;
      });
  });
}

export function putApiCall<T, R>(url: string, data: T): Promise<R> {
  return axios.put<T, AxiosResponse<R>>(url, data)
    .then(response => response.data)
    .catch((error: AxiosError) => {
      console.error('Error in PUT request:', error);
      throw error;
    });
}

export function deleteApiCall<T, R>(url: string, data: any): Promise<R> {
  return axios.delete<T, AxiosResponse<R>>(url, data)
    .then(response => response.data)
    .catch((error: AxiosError) => {
      console.error('Error in DELETE request:', error);
      throw error;
    });
}

export function getApiCallNoToken<T>(url: string): Promise<T> {
  return axios.get<T>(url)
    .then((response: AxiosResponse<T>) => response.data)
    .catch((error: AxiosError) => {
      console.error('Error in GET request:', error.message);
      throw error;
    });
}

export function getApiCall<T>(url: string): Promise<T> {
  return getToken().then(token => {
    //console.log(token);
    const headers = { headers: { 'Authorization': token, 'x-apigw-api-id': 'frose4q0sg' } };
    return axios.get<T>(url, headers)
      .then((response: AxiosResponse<T>) => {
        // console.log(response.data+".......................................")
        return response.data
      })
      .catch((error: AxiosError) => {
        console.error('Error in GET request:', error.message);
        throw error;
      });
  });

}
export function getApiCallWithRetryLogic<T>(url: string,maxRetries: number = 3): Promise<T> {
  return getApiattemptRequest(url,maxRetries);
}
 
function getApiattemptRequest<T>(url: string,maxRetries: number): Promise<T> {
  return getToken().then(token => {
    //console.log(token);
    const headers = { headers: { 'Authorization': token, 'x-apigw-api-id': 'frose4q0sg' } };
    return axios.get<T>(url, headers)
      .then((response: AxiosResponse<T>) => response.data)
      .catch((error: AxiosError) => {
        if (maxRetries > 0) {
          return getApiattemptRequest(url, maxRetries - 1);
        } else {
          throw error;
        }
      });
  });
}
export function postApiCallWithRetryLogic<T, R>(url: string, data: T, headers?: Record<string, string>, maxRetries: number = 3): Promise<R> {
  return attemptRequest(url, data, headers, maxRetries);
}
 
function attemptRequest<T, R>(url: string, data: T, headers: Record<string, string> | undefined, maxRetries: number): Promise<R> {
  return getToken()
    .then(token => {
      headers = { 'Authorization': token };
      return axios.post<T, AxiosResponse<R>>(url, data, { headers})
        .then(response => {
          return response.data;
        })
        .catch((error: AxiosError) => {
          if (maxRetries > 0) {
            return attemptRequest(url, data, headers, maxRetries - 1);
          } else {
            throw error;
          }
        });
    });
}

