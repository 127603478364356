import React from "react";
import { useNavigate } from "react-router-dom";

import Breadcrumbs from "@mui/material/Breadcrumbs";
import Chip from "@mui/material/Chip";
import HomeIcon from "@mui/icons-material/Home";
import { styled } from "@mui/material/styles";
import { useAppSelector, useAppDispatch } from "../redux/hooks";
import { resetAssetPlanState } from "components/util/storeManager";
const StyledBreadcrumb = styled(Chip)(({ theme }) => {
  const backgroundColor =
    theme.palette.mode === "light"
      ? theme.palette.grey[100]
      : theme.palette.grey[800];
  return {
    backgroundColor,
    height: theme.spacing(3),
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightRegular,
  };
}) as typeof Chip;

export const AppBreadcrumbs = () => {
  // const routes = [
  //   { path: "/dashboard", name: "Dashboard" },
  //   { path: "/dashboard/form/edit/:dapId", name: "PlanDetails" },
  //   {
  //     path: "/dashboard/form/view/:dapId",
  //     name: "PlanDetails",
  //   },
  //   {
  //     path: "/userrolemap/list",
  //     name: " User Role / Dashboard",
  //   },
  //   {
  //     path: "/userRole/:page/:userRoleId",
  //     name: "Form",
  //   }
  // ];
  const dispatch = useAppDispatch();
  const nav = useNavigate();

  const budgetYear = useAppSelector(
    (state) => state.summary?.dapData?.budgetYear
  );
  const investmentId = useAppSelector(
    (state) => state.summary?.dapData?.investmentId
  );
  const goToDashboard = () => {
    // dispatch(resetPropertyImagesAction());
    // dispatch(resetLatestRecomStateAction());
    // dispatch(resetDapCommentStateAction());
    // dispatch(resetSaveCommentStateAction());
    // dispatch(resetSaveDataStateAction());
    // dispatch(resetCapitalProjectsStore());
    // dispatch(resetLeaseExpirationAction());
    // dispatch(resetKeyTenantStateAction());
    // dispatch(resetSummaryFormAction());
    resetAssetPlanState(dispatch);
    // setTimeout(() => {
    //   nav("/");
    // }, 1000);
    setTimeout(() => {
      nav("/");
    }, 0);
  };

  const pathnames = window.location.pathname.split("/").filter((x) => x);

  console.log(pathnames.length);

  const breadcrumbNameMap: { [key: string]: string } = {
    "/dashboard": " Dashboard",
    "/userrolemap/list": "User Role / Dashboard",
    "/clone/data": "Clone Data / Dashboard",
    //"/delegation/data": "Delegation Data / Dashboard",
    // "/dashboard/form/edit/": " Dashboard / PlanDetails",
    // "/dashboard/form/view/": " Dashboard / PlanDetails",
    // "/userRole/view/": " User Role / Dashboard / Form",
    // "/userRole/edit/": " User Role / Dashboard / Form"
  };

  const formBreadcrumb =
    investmentId == null || budgetYear == null
      ? " "
      : "/" + investmentId + " - " + budgetYear;

  const id = pathnames.slice(-1)[0];
  breadcrumbNameMap["/dashboard/form/edit/" + id] =
    " Dashboard" + formBreadcrumb;
  breadcrumbNameMap["/dashboard/form/view/" + id] =
    " Dashboard" + formBreadcrumb;
  breadcrumbNameMap["/userRole/view/" + id] = " User Role/Dashboard/Form";
  breadcrumbNameMap["/userRole/edit/" + id] = " User Role/Dashboard/Form";

  // const crumbs = routes
  //   Get all routes that contain the current one.
  //   .filter(({ path }) => currentPath.match(path));
  //   Swap out any dynamic routes with their param values.
  //   E.g. "/pizza/:pizzaId" will become "/pizza/1"
  //   .map(({ path, ...rest }) => ({
  //     path: Object.keys(props.match.params).length
  //       ? Object.keys(props.match.params).reduce(
  //           (path, param) =>
  //             path.replace(`:${param}`, props.match.params[param]),
  //           path
  //         )
  //       : path,
  //     ...rest,
  //   }));
  //   console.log(crumbs);
  return (
    <div role="presentation" className="mt-07">
      <Breadcrumbs aria-label="breadcrumb" className="brd">
        <StyledBreadcrumb
          //component="a"
          label="DAP"
          onClick={() => goToDashboard()}
          icon={<HomeIcon fontSize="small" className="hm" />}
        />
        {pathnames.map((name, index) => {
          if (index + 1 == pathnames.length) {
            const goTo = `/${pathnames.slice(0, index + 1).join("/")}`;
            console.log(goTo, "==goto===");
            const mappedKey = Object.keys(breadcrumbNameMap).filter((key) =>
              goTo.includes(key)
            );
            console.log(mappedKey, "==mappedKey==");
            const mappedValue = breadcrumbNameMap[goTo];
            return mappedValue
              .split("/")
              .filter((x) => x)
              .map((n, i) => <StyledBreadcrumb label={n} component="a" />);
          }
        })}
      </Breadcrumbs>
    </div>
  );
};
