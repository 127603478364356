import "../../../../node_modules/react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from "react-image-gallery";
import React, { useEffect, useState } from "react";
import {
  postApiCall
} from "../../../services/apiHelper";
import { useAppSelector, useAppDispatch } from "../../../redux/hooks";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/DeleteOutline";
import { baseUrl } from "../../../config/config";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import FullscreenIcon from "@mui/icons-material/Fullscreen";

interface fileKey {
  fileKey: string;
}

export const SiteStackingEditForm = () => {
  const refImg: any = React.createRef();
  const refImg2: any = React.createRef();
  const [isLoading, setIsLoading] = useState(true);
  const [imagesOfSite, setImagesOfSite] = useState<any>([]);
  const [imagesOfStacking, setImagesOfStacking] = useState<any>([]);
  const [fileKeyOfSite, setFileKeyOfSite] = useState<any>([]);
  const [fileKeyOfStacking, setFileKeyOfStacking] = useState<any>([]);
  const [openPopUp, setOpenPopUp] = useState(false);
  const [noSiteImg, setnoSiteImg] = useState(false);
  const [noStackingImg, setnoStackingImg] = useState(false);
  const [choosenImage, setChoosenImage] = useState<any>([]);
  const [fileKeyResponse, setFileKeyResponse] = useState<any>([]);
  const assetPlanId = useAppSelector(
    (state) => state.getAssetPlanId.assetPlanId
  );

  const handlePopUpOpen = () => {
    setOpenPopUp(true);
  };
  const handlePopUpClose = () => {
    setOpenPopUp(false);
  };

  const handleDeleteImage = () => {
    setOpenPopUp(false);
    if (choosenImage.plan == "Site") {
      setImagesOfSite((products) =>
        products.filter((_, index) => index !== choosenImage.imageIndex)
      );
      sendFileKeyResponse(fileKeyOfSite[choosenImage.imageIndex]);
    } else if (choosenImage.plan == "Stacking") {
      setImagesOfStacking((products) =>
        products.filter((_, index) => index !== choosenImage.imageIndex)
      );
      sendFileKeyResponse(fileKeyOfStacking[choosenImage.imageIndex]);
    }
  };

  const requestForSite = {
    assetPlanId: assetPlanId,
    section: "SitePlan",
  };

  const requestForStacking = {
    assetPlanId: assetPlanId,
    section: "StackingPlan",
  };

  const sendFileKeyResponse = async (FileKeydata) => {
    var data: fileKey = {
      fileKey: FileKeydata.fileKey,
    };
    await postApiCall(`${baseUrl}Document/Delete`, data);
  };

  const getImages = async () => {
    const responseForSite = await postApiCall(
      `${baseUrl}Document/GetImagesForSitePlan`,
      requestForSite
    ).then((res: any) => {
      return res;
    });

    if (responseForSite.length == 0) {
      setnoSiteImg(true);
    } else {
      for (var i = 0; i < responseForSite.length; i++) {
        const image = document.createElement("img");
        image.src = `data:image/jpeg;base64,${responseForSite[i].image}`;
        const fileKey = responseForSite[i].fileKey;
        setImagesOfSite((current) => [
          ...current,
          {
            original: image.src,
            thumbnail: image.src,
          },
        ]);
        setFileKeyOfSite((current) => [
          ...current,
          {
            fileKey: fileKey,
          },
        ]);
      }
    }
    const responseForStacking = await postApiCall(
      `${baseUrl}Document/GetImagesForSitePlan`,
      requestForStacking
    ).then((res: any) => {
      return res;
    });
    if (responseForStacking.length == 0) {
      setnoStackingImg(true);
    } else {
      for (var i = 0; i < responseForStacking.length; i++) {
        const image = document.createElement("img");
        image.src = `data:image/jpeg;base64,${responseForStacking[i].image}`;
        const fileKey = responseForStacking[i].fileKey;
        setImagesOfStacking((current) => [
          ...current,
          {
            original: image.src,
            thumbnail: image.src,
          },
        ]);
        setFileKeyOfStacking((current) => [
          ...current,
          {
            fileKey: fileKey,
          },
        ]);
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getImages();
  }, []);

  // For thee dot icon
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const siteMenu = () => {
    return (
      <div>
        {imagesOfSite.length !== 0 && (
          <div>
            <IconButton
              aria-label="more"
              id="long-button"
              aria-controls={open ? "long-menu" : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-haspopup="true"
              onClick={handleClick}
            >
              <MoreVertIcon fontSize="large" />
            </IconButton>

            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem
                onClick={() => {
                  setChoosenImage({
                    imageIndex: refImg.current.state.currentIndex,
                    plan: "Site",
                  });
                  handlePopUpOpen();
                  handleClose();
                }}
              >
                <DeleteIcon /> Delete
              </MenuItem>
              <MenuItem
                onClick={() => {
                  refImg.current.toggleFullScreen();
                  handleClose();
                }}
              >
                <FullscreenIcon /> Fullscreen
              </MenuItem>
            </Menu>
          </div>
        )}
      </div>
    );
  };

  const [anchorE2, setAnchorE2] = React.useState<null | HTMLElement>(null);
  const openStacking = Boolean(anchorE2);
  const handleStackingClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorE2(event.currentTarget);
  };
  const handleStackingClose = () => {
    setAnchorE2(null);
  };

  const stackingMenu = () => {
    return (
      <div>
        {imagesOfStacking.length !== 0 && (
          <div>
            <IconButton
              aria-label="more"
              id="long-button"
              aria-controls={openStacking ? "long-menu" : undefined}
              aria-expanded={openStacking ? "true" : undefined}
              aria-haspopup="true"
              onClick={handleStackingClick}
            >
              <MoreVertIcon fontSize="large" />
            </IconButton>
            <Menu
              id="basic-menu"
              anchorEl={anchorE2}
              open={openStacking}
              onClose={handleStackingClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem
                onClick={() => {
                  setChoosenImage({
                    imageIndex: refImg2.current.state.currentIndex,
                    plan: "Stacking",
                  });
                  handlePopUpOpen();
                  handleStackingClose();
                }}
              >
                <DeleteIcon /> Delete
              </MenuItem>
              <MenuItem
                onClick={() => {
                  refImg2.current.toggleFullScreen();
                  handleStackingClose();
                }}
              >
                <FullscreenIcon /> Fullscreen
              </MenuItem>
            </Menu>
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      <div>
        <div className="tab-heading text-center">
          <h3 className="heading">Site Plan/Photos/Other</h3>
        </div>
        {isLoading ? (
          <>
            <Skeleton
              variant="rectangular"
              width="30%"
              height={350}
              animation="wave"
              style={{ marginLeft: "35%" }}
            />
            <br></br>
            <Skeleton
              variant="rectangular"
              width="30%"
              height={100}
              animation="wave"
              style={{ marginLeft: "35%" }}
            />
          </>
        ) : (
          <>
            {noSiteImg ? (
              <>
                <div
                  style={{
                    display: "flex",
                    marginLeft: "50%",
                    marginRight: "50%",
                    marginTop: "5%",
                    marginBottom: "5%",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <h3
                    style={{ textAlign: "center", color: "rgba(0, 0, 0, 0.6)" }}
                  >
                    <i>No image available</i>
                  </h3>
                </div>
              </>
            ) : (
              <Container maxWidth="sm">
                <Box>
                  <ImageGallery
                    ref={refImg}
                    items={imagesOfSite}
                    showPlayButton={false}
                    originalHeight={350}
                    showFullscreenButton={false}
                    renderCustomControls={siteMenu}
                  />
                </Box>
              </Container>
            )}
          </>
        )}

        <div className="tab-heading text-center">
          <h3 className="heading">Stacking Plan</h3>
        </div>
        {isLoading ? (
          <>
            <Skeleton
              variant="rectangular"
              width="30%"
              height={350}
              animation="wave"
              style={{ marginLeft: "35%" }}
            />
            <br></br>
            <Skeleton
              variant="rectangular"
              width="30%"
              height={100}
              animation="wave"
              style={{ marginLeft: "35%" }}
            />
          </>
        ) : (
          <>
            {noStackingImg ? (
              <>
                <div
                  style={{
                    display: "flex",
                    marginLeft: "50%",
                    marginRight: "50%",
                    marginTop: "5%",
                    marginBottom: "5%",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <h3
                    style={{ textAlign: "center", color: "rgba(0, 0, 0, 0.6)" }}
                  >
                    <i>No image available</i>
                  </h3>
                </div>
              </>
            ) : (
              <Container maxWidth="sm">
                <Box>
                  <ImageGallery
                    ref={refImg2}
                    items={imagesOfStacking}
                    showPlayButton={false}
                    renderCustomControls={stackingMenu}
                    showFullscreenButton={false}
                  />
                </Box>
              </Container>
            )}
          </>
        )}
        <div>
          <Dialog
            open={openPopUp}
            onClose={handlePopUpClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="common-dialog-box"
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Do You Want To Delete This Item?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDeleteImage}>Yes</Button>
              <Button onClick={handlePopUpClose}>No</Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>
    </>
  );
};
