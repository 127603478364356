import { resetLatestRecomStateAction } from "redux/slices/latestRecomSlice";
import { resetDapCommentStateAction } from "redux/slices/updateDapCommentSlice";
import { resetSaveCommentStateAction } from "redux/slices/saveCommentSlice";
import { resetSaveDataStateAction } from "redux/slices/updateDataSlice";
import { resetCapitalProjectsStore } from "redux/slices/capitalProjectsSlice";
import { resetLeaseExpirationAction } from "redux/slices/leaseExpirationSlice";
import { resetKeyTenantStateAction } from "redux/slices/keyTenantsSlice";
import { resetPropertyImagesAction } from "redux/slices/propertyImagesSlice";
import { resetSummaryFormAction } from "redux/slices/summaryFormSlice";
import { resetMissionStatementAction } from "redux/slices/missionGroupSlice";
import { resetLeaseCommercialAction } from "redux/slices/leasingCommercialSlice";
import { resetLeaseResidentialAction } from "redux/slices/leasingResidentialSlice";
import { resetDocumentComments } from "redux/slices/documentCommentSlice";
import {resetValuationsStateAction} from "redux/slices/valuationsSlice";

export const resetAssetPlanState = (dispatch) => {
    dispatch(resetPropertyImagesAction());
    dispatch(resetLatestRecomStateAction());
    dispatch(resetDapCommentStateAction());
    dispatch(resetSaveCommentStateAction());
    dispatch(resetSaveDataStateAction());
    dispatch(resetCapitalProjectsStore());
    dispatch(resetLeaseExpirationAction());
    dispatch(resetKeyTenantStateAction());
    dispatch(resetSummaryFormAction());
    dispatch(resetMissionStatementAction());
    dispatch(resetLeaseCommercialAction());
    dispatch(resetLeaseResidentialAction());
    dispatch(resetDocumentComments());
    dispatch(resetValuationsStateAction());
    
    //navigate("/");
};

export const clearDocumentComments = (dispatch) => {
    dispatch(resetDocumentComments());
}

