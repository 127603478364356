import { Checkbox } from "@mui/material";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import FroalaEditorView from "react-froala-wysiwyg/FroalaEditorView";

import React, { useState, useEffect } from "react";
import { CapitalPlanService } from "../../../services/CapitalPlanService";
import { useAppSelector, useAppDispatch } from "../../../redux/hooks";
import {
  CapitalProject,
  getCapitalProjects,
} from "../../../redux/slices/capitalProjectsSlice";
import {
  getCapitalPlanTableData,
  CapitalPlanTableData,
} from "../../../redux/slices/capitalPlanTableSlice";


import Skeleton from "@mui/material/Skeleton";
import { GenericToast } from "../../util/ToastMessage";
import { currency, numberWithComma } from "../../util/valueFormatter";
import { CommentKey } from "constant";

interface RowWiseData {
  [key: string]: Array<string | number | number | number | number | number>;
}

const label = { inputProps: { "aria-label": "Checkbox demo" } };
export const CapitalPlanDisplayForm = () => {
  const rowWiseData: RowWiseData = {};
  const dispatch = useAppDispatch();

  const assetPlanId = useAppSelector(
    (state) => state.getAssetPlanId.assetPlanId
  );
  const budgetYear = Number(
    useAppSelector((state) => state.summary.dapData?.budgetYear)
  );

  const capitalPlanTableData: CapitalPlanTableData[] = useAppSelector(
    (state) => state.capitalPlanTable.capitalPlan
  );
  const capitalPlanTableDataStatus = useAppSelector(
    (state) => state.capitalPlanTable
  );
  //const assetPlanId: string = "c592630a-c679-4b9c-b72e-eac417743b17";
  // const [capitalProjectData, setCapitalProjectData] = useState<
  //   capitalProject[]
  // >([]);
  const capitalProjectData: CapitalProject[] = useAppSelector(
    (state) => state.capitalProjects.data
  );
  const isNoData: boolean = useAppSelector(
    (state) => state.capitalProjects.isNoData
  );

  const [yearRangeSelected, setYearRangeSelected] = useState<number>(1);
  const capitalPlanService = new CapitalPlanService();
  const columns: string[] = [
    "year",
    "biCapital",
    "leasingCostCommercial",
    "turnCostResidential",
    "totalCapital",
  ];
  const CPColumnNameArray: string[] = [
    "BI Capital",
    "Leasing Cost (Commercial)",
    "Turn Cost (Residential)",
    "Total Capital",
    "Projected NOI 2023",
  ];

  const columnNames: string[] = [
    //change the year from the elements of array with year which is comming from redux store.
    `${budgetYear} Capital`,
    `% of ${budgetYear} NOI`,
    `${budgetYear + 1} Capital`,
    `${budgetYear + 2} Capital`,
    `${budgetYear + 3} Capital`,
    `${budgetYear + 4} Capital`,
  ];

  useEffect(() => {
    dispatch(getCapitalPlanTableData({ assetPlanID: assetPlanId }));
    //if (!capitalProjectData) {
    dispatch(getCapitalProjects({ assetPlanId: assetPlanId }));
    //}
    // capitalPlanService.getAllCapitalProjects(assetPlanId).then((data: any) => {
    //   // use assetPlanId variable instead of assetPlanID for dynamic functionality
    //   setCapitalProjectData(data);
    //   dispatch(setCapitalProjectsStore(data));
    // });
  }, [dispatch, yearRangeSelected]);

  const columnDataToRowData = () => {
    var projectedNoi: number = capitalPlanTableData[0]?.projectedNOI;
    console.log(projectedNoi);

    capitalPlanTableData?.forEach((obj) => {
      Object.keys(obj).forEach((key) => {
        if (!rowWiseData[key]) {
          rowWiseData[key] = [];
        }

        rowWiseData[key].push(obj[key]);
      });
    });

    columns.forEach((name) => {
      var num = rowWiseData[name]?.at(0);
      if (num) {
        rowWiseData[name]?.splice(1, 0, (Number(num) / projectedNoi) * 100);
      }

      console.log(rowWiseData[name]);
    });

    capitalPlanTableData?.forEach((obj) => {
      Object.keys(obj).forEach((key) => {
        rowWiseData[key][0] = currency(rowWiseData[key][0]);
        rowWiseData[key][1] = numberWithComma(rowWiseData[key][1]);
      });
    });
  };
  columnDataToRowData();

  console.log(rowWiseData);
  console.log(capitalPlanTableData);

  const numToMoneyFormat = (x: number) => {
    return "$" + x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const renderTableData = () => {
    return capitalProjectData?.map((val, i) => (
      <TableRow key={i}>
        <TableCell sx={{ width: "10%" }}>{val.projectPriority}</TableCell>
        <TableCell sx={{ width: "5%", textAlign: "center !important" }}>
          <Checkbox checked={val.esgImpact} disabled />
        </TableCell>
        <TableCell sx={{ width: "11%" }}>{val.projectId}</TableCell>
        <TableCell sx={{ width: "8.5%", textAlign: "right !important" }}>
          {currency(val.currentBudget)}
        </TableCell>
        <TableCell sx={{ width: "15.5%" }}>{val.projectDescription}</TableCell>
        <TableCell sx={{ width: "20%" }}>{val.comment}</TableCell>
      </TableRow>
    ));
  };

  // const renderCapitalPlanTableData = () =>{

  // }
  const comments = useAppSelector((state) => state.dapComments?.data).find(
    (x) => x.commentKey.toLowerCase() === CommentKey.CAPITALPLAN.toLowerCase()
  );

  return (
    <div>
      <div className="tab-heading text-center">
        <h3 className="heading">Detailed Building Capital Plan</h3>
      </div>
      {capitalPlanTableDataStatus.loading ? (
        <Skeleton
          variant="rounded"
          width="100%"
          height={250}
          animation="wave"
        />
      ) : (
        <div className="responsive-table-wrapper">
          <table id="customers" className="responsive-table">
            <tr>
              <th>Project Priority</th>
              <th style={{ textAlign: "center" }}>{budgetYear <= 2024? `ESG Impact `: `Contributes to SSBB feature`}</th>
              <th>Project ID</th>
              <th style={{ textAlign: "right" }}>{budgetYear} Budget</th>
              <th>Project Description</th>
              <th>Comments</th>
            </tr>
            <tbody>{renderTableData()}</tbody>
            {isNoData && (
              <TableRow>
                <TableCell colSpan={6} sx={{ textAlign: "center !important" }}>
                  No rows
                </TableCell>{" "}
              </TableRow>
            )}
          </table>
        </div>
      )}
      <br></br>
      <div className="tab-heading text-center">
        <h3 className="heading">Capital Plan </h3>
      </div>

      <div>
        {capitalPlanTableDataStatus.loading ? (
          <Skeleton
            variant="rounded"
            width="100%"
            height={250}
            animation="wave"
          />
        ) : (
          <table id="customers">
            <tr>
              <th></th>

              {columnNames
                ?.filter((_, i) => i < 2)
                .map((ele, index) => (
                  <th style={{ textAlign: "right" }} key={index}>
                    {ele}
                  </th>
                ))}
            </tr>
            <tbody>
              <tr>
                <th className="grey-bg">BI Capital</th>
                {rowWiseData.biCapital
                  ?.filter((_, i) => i < 2)
                  .map((ele, index) => (
                    <td style={{ textAlign: "right" }} key={index}>
                      {ele}
                    </td>
                  ))}
              </tr>
              <tr>
                <th className="grey-bg">Leasing Cost (Commercial)</th>
                {rowWiseData.leasingCostCommercial
                  ?.filter((_, i) => i < 2)
                  .map((ele, index) => (
                    <td style={{ textAlign: "right" }} key={index}>
                      {ele}
                    </td>
                  ))}
              </tr>
              <tr>
                <th className="grey-bg">Turn Cost (Residential)</th>
                {rowWiseData.turnCostResidential
                  ?.filter((_, i) => i < 2)
                  .map((ele, index) => (
                    <td style={{ textAlign: "right" }} key={index}>
                      {ele}
                    </td>
                  ))}
              </tr>
              <tr>
                <th className="grey-bg">Total Capital</th>
                {rowWiseData.totalCapital
                  ?.filter((_, i) => i < 2)
                  .map((ele, index) => (
                    <td style={{ textAlign: "right" }} key={index}>
                      {ele}
                    </td>
                  ))}
              </tr>
              <tr>
                <th className="grey-bg">Projected NOI {budgetYear}</th>
                {rowWiseData.projectedNOI
                  ?.filter((_, i) => i < 2)
                  .map((ele, index) => (
                    <td style={{ textAlign: "right" }} key={index}>
                      {ele}
                    </td>
                  ))}
              </tr>
            </tbody>
          </table>
        )}
      </div>
      {capitalPlanTableDataStatus.error && (
        <GenericToast
          message={capitalPlanTableDataStatus.error}
          severity="error"
        />
      )}
      <form className="m-20 lease-form">
        <fieldset>
          <legend className="lease-title">Comments</legend>
          <FroalaEditorView model={comments?.commentText || ""} />
        </fieldset>
      </form>
    </div>
  );
};
