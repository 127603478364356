import handlebars from "handlebars";
import capitalPlanTemplate from "../templates/capitalPlanTemplate";
import capitalPlanTemplate2025 from "../templates/capitalPlanTemplate2025";
import {
  currency,
  numberWithComma,
} from "../../util/valueFormatter";
import { CommentKey } from "constant";

export const compileCapitalPlanTemplate = (
  firstTableData: any,
  secondTableData: any,
  budgetYear: string,
  comments: any[]
): string => {

  const formattedCapitalProjects = firstTableData?.capitalProjects?.map((project) => ({
    ...project,
    currentBudget: currency(project.currentBudget),
    esgImpact: project.esgImpact ? 'Yes' : 'No'
  })) || [];


  if (parseInt(budgetYear) > 2024) {
    var compiledCapitalPlanTemplate = handlebars.compile(capitalPlanTemplate2025);
  } else {
    compiledCapitalPlanTemplate = handlebars.compile(capitalPlanTemplate);
  }

  let firstYearRecord;
  let firstColumData;
  let dividedValues;
  if (secondTableData.length > 0) {
    // Create a copy of the first year's record
    firstYearRecord = { ...secondTableData[0] };

    // Create a new object with the first year's record where values are divided by 100
    dividedValues = { year: firstYearRecord.year };
    firstColumData = { year: firstYearRecord.year };
    for (const key in firstYearRecord) {
      if (key !== 'year') {
        // Check if the value is numeric before dividing
        if (typeof firstYearRecord[key] === 'number') {
          if (key !== 'projectedNOI') {
            firstColumData[key] = numberWithComma(firstYearRecord[key]);
            dividedValues[key] = numberWithComma((firstYearRecord[key] / firstYearRecord['projectedNOI']) * 100);
          }
          else {
            firstColumData[key] = numberWithComma(firstYearRecord[key]);
          }

        }
      }
    }
  }
  const capitalPlanComment = comments?.find(
    (x) => x.commentKey?.toLowerCase() === CommentKey.CAPITALPLAN.toLowerCase()
  );
  var capitalTemplateData =
  {
    table1Data: formattedCapitalProjects,
    firstYearRecord: firstColumData,
    dividedValues: dividedValues,
    budgetYear: budgetYear,
    capitalPlanComment: capitalPlanComment

  }


  return compiledCapitalPlanTemplate(capitalTemplateData);
};