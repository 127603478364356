export enum SummaryDropdown {
  INVESTMENT_DESC = "InvestmentDescription",
  PRIMARY_LEASE_TYPE = "PrimaryLeaseType",
}

export enum SectionNames {
  SUMMARY = "SUMMARY",
  RECOMMENDATION = "RECOMMENDATION",
  FileSection = "FileSection",
}

export enum RecommendDropdown {
  INVESTMENT_KEY1 = "Investment_Key1",
  INVESTMENT_HOLDPERIOD = "Investment_HoldPeriod",
  INVESTMENT_KEY3 = "Investment_Key3",
  SUSTAIN_PHYSICALRISK = "Sustainability_PhysicalRisk",
  SUSTAIN_REGULATORYRISK = "Sustainability_RegularotyRisk",
  SUSTAIN_TWREPORT = "Sustainability_TWReport",
  MISSIONSTATEMENT = "MissionStatement",
  SUSTAIN_TENANT_FEEDBACK = "Sustainability_TenantFeedback",
  SUSTAIN_NETZERO = "Sustainability_NetZero",
}

export enum Category {
  INVESTMENTCATEGORY = "Investment",
  SUSTAINABILITYCATEGORY = "Sustainability",
}

export enum Key {
  Key1 = "Key1",
  Key2 = "Key2",
  Key3 = "Key3",
  Key4 = "Key4",
  Key5 = "Key5",
}

export enum DapStatus {
  Saved = "Saved",
  Approved = "Approved",
  RejectedByAM_Analyst = "Rejected by AM/Analyst",
  RejectedbyAPM_PM = "Rejected by APM/PM",
  RejectedByAM_Head = "Rejected by AM Head",
  PendingAPM_PM = "Pending APM/PM",
  PendingAM_Head = "Pending AM Head",
  PendingAM = "Pending AM",
  RevokedByAPM_PM = "Revoked by APM/PM",
  RecalledByAM_Analyst = "Recalled by AM/Analyst",
}

export enum DapRoles {
  Analyst = "Analyst",
  Admin = "Admin",
  AssetManagementTeam = "Asset Management Team",
  AssetManager = "Asset Manager",
  AssetManagerHead = "Asset Manager Head",
  AssistantPortfolioManager = "Assistant Portfolio Manager",
  PortfolioManager = "Portfolio Manager",
}

export enum ActionNames {
  APPROVE = "Approve",
  REJECT = "Reject",
  SAVE = "Save",
  SUBMIT = "Submit",
  RECALL = "Recall",
  REJECT_BUDGET = "RejectBudget",
  REVOKE = "Revoke",
}

export enum CommentKey {
  SUMMARY_KEY = "SummaryComment",
  MISSIONSTATEMENT = "MissionStatement",
  RESIDENTIAL = "Residential",
  COMMERTIAL = "Commercial",
  STRENGTH = "Strength",
  WEAKNESS = "Weakness",
  OPPURTINITIES = "Oppurtinities",
  THREATS = "Threats",
  VALUATION = "Valuation",
  POSTCLOSING = "Postclosing",
  MANAGEMENTAGREEMENT_DATE = "ManagementAgreementNameDate",
  MANAGEMENTAGREEMENT_FEE = "ManagementAgreementFee",
  MANAGEMENTAGREEMENT_OTHERFEE = "ManagementAgreementOtherFee",
  LEASEBROKER_NAME = "LeaseBrokerName",
  LEASEBROKER_FEE = "LeaseBrokerFee",
  LEASEBROKER_OTHERFEE = "LeaseBrokerOtherFee",
  JVAGREEMENT_KEYDECISION = "JvAgreementKeyDecision",
  JVAGREEMENT_FEE = "JvAgreementFee",
  JVAGREEMENT_LIQUIDITYPROVISION = "JvAgreementLiquidityProvision",
  JVAGREEMENT_KEYPROVISION = "JvAgreementKeyProvision",
  MISSION_VALUE = "Value",
  MISSION_FOUNDATIONAL = "Foundational",
  MISSION_SELL = "Sell",
  MISSION_OTHER = "Other",
  FINANCIALS = "Financials",
  CAPITALPLAN = "CapitalPlan",
}

export enum CommentSectionName {
  KEYPROVISIONS = "KeyProvision",

  VALUATION = "Valuation",

  SUMMARY = "Summary",

  MISSIONSTATEMENT = "Missionstatement",

  FINANCIALS = "Financials",

  CAPITALPLAN = "CapitalPlan",
}

export enum AutosaveDelay {
  DELAYTIME = 1500,
}
