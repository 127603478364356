import handlebars from 'handlebars';
const eq = (a: any, b: any) => a === b;

// Register the 'eq' helper with Handlebars
handlebars.registerHelper('eq', eq)
const summaryTemplate = `
<!DOCTYPE html>
<html>
  <head>      
  </head>
  <body>
    <div class="row summary-info-table">
      <h2 class="pdf-table-heading">Investment Summary</h2>
      <!-- left table -->
      <div class="column">
        
        <table id= "leasing-table">
          <tr>
            <td>ID:</td>
            <td>{{summaryData.investmentId}}</td>
          </tr>
          <tr>
            <td>Fund Name:</td>
            <td>{{summaryData.fundName}}</td>
          </tr>
          <tr>
            <td>City / State:</td>
            <td>{{summaryData.city}}</td>
          </tr>
          <tr>
            <td>Year Built:</td>
            <td>{{formattedData.yearBuilt}}</td>
          </tr>
          <tr>
            <td>Date Acquired:</td>
            <td>{{formattedData.dateAcquired}}</td>
          </tr>
          <tr>
            <td>Purchase Price:</td>
            <td>{{formattedData.purchasedPrice}}</td>
          </tr>
          <tr>
            <td>Appraised Value:</td>
            <td>{{formattedData.appraisedValue}}</td>
          </tr>
          <tr>
            <td>Asset Manager:</td>
            <td>{{summaryData.assetManager.lastName}}, {{summaryData.assetManager.firstName}}</td>
          </tr>
          <tr>
            <td nowrap>Portfolio Manager:</td>
            <td>{{summaryData.portfolioManager.lastName}}, {{summaryData.portfolioManager.firstName}}</td>
          </tr>
        </table>
      </div>
      <!-- right table -->
      <div class="column">
       
        <table id= "leasing-table">

		    {{#if (eq summaryData.propertyType 'Residential')}}
			<tr>
        <td>Units:</td>
        <td>{{formattedData.units}}</td>
		</tr>
  {{else if (eq summaryData.propertyType 'Commertial')}}
  <tr>
        <td>Rentable Area (sqft):</td>
        <td>{{formattedData.rentableArea}}</td>
		</tr>
  {{else if (eq summaryData.propertyType 'Mixed')}}
<tr>
        <td>Units:</td>
        <td>{{formattedData.units}}</td>
      </tr>
      <tr>
        <td>Rentable Area (sqft):</td>
        <td>{{formattedData.rentableArea}}</td>
      </tr>
  {{/if}}

      <tr>
        <td>Investment Type:</td>
        <td>{{summaryData.investmentType}}</td>
      </tr>
      <tr>
        <td>Investment Subtype:</td>
        <td>{{summaryData.investmentSubtype}}</td>
      </tr>
      <tr>
      <td>Debt:</td>
      <td>{{formattedData.debt}}</td>
    </tr>
      <tr>
        <td>Ownership Type:</td>
        <td>{{summaryData.ownershipType}}</td>
      </tr>
      <tr>
        <td>Ownership %:</td>
        <td>{{formattedData.ownerShip}}</td>
      </tr>
      <tr>
        <td>Investment Description:</td>
        <td>{{summaryData.investmentDescription}}</td>
      </tr>
      <tr>
        <td>Primary Lease Type:</td>
        <td>{{summaryData.primaryLeaseType}}</td>
      </tr>
      <tr>
        <td>JV Partner:</td>
        <td>{{summaryData.jvPartner}}</td>
      </tr>
        </table>
      </div>
    </div>

    <table id="table-comment">
      <tr>
        <th>
          Additional Property Description
        </th>
      </tr>
      <tr>
        <td>
          {{{summaryComment.commentText}}}
        </td>
      </tr>
    </table>
  </body>
</html>

`;
export default summaryTemplate;