import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { baseUrl } from "../../config/config";
import { postApiCallWithRetryLogic } from "../../services/apiHelper";
import { PostData } from "./commertialLeasingSlice";

export interface LeaseExpiration {
  investmentId: number;
  expiryYear: number;
  expiringUnit: number;
  expiringPercentage: number;
  cumulativePercentage: number;
}

interface LeaseExpirationState {
  data: LeaseExpiration[] | null;
  error: string;
  loading: boolean;
  hasDataBeenRequested: boolean;
}

const initialState: LeaseExpirationState = {
  data: null,
  error: "",
  loading: false,
  hasDataBeenRequested: false

};

export const getLeaseExpirations = createAsyncThunk(
  "getLeaseExpirations",
  async (payload: PostData) => {
    try {
      const response = await postApiCallWithRetryLogic<PostData, LeaseExpirationState>(
        `${baseUrl}Leasing/GetLeaseExpirations`,
        payload
      );
      return response.data;
    } catch (error: any) {
      console.error("Error in POST request:", error);
      throw error;
    }
  }
);

const leaseExpirationSlice = createSlice({
  name: "leaseExpirations",
  initialState,
  reducers: {
  resetState: (state) => {
    state.data = null;
    state.error = "";
    state.loading = false;
    state.hasDataBeenRequested = false;
  },
},
  extraReducers: (builder) => {
    builder
      .addCase(getLeaseExpirations.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        getLeaseExpirations.fulfilled,
        (state, action: PayloadAction<LeaseExpiration[] | null>) => {
          state.loading = false;
          state.data = action.payload;
          state.hasDataBeenRequested = true;
        }
      )
      .addCase(getLeaseExpirations.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Something went wrong";
      });
  },
});
export const { resetState: resetLeaseExpirationAction} = leaseExpirationSlice.actions;
export default leaseExpirationSlice.reducer;
