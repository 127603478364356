import React, { useEffect, useRef, useState } from "react";

import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { FroalaRichText } from "../../util/FroalaRichtext";
import { useAppSelector, useAppDispatch } from "../../../redux/hooks";
import FroalaEditorView from "react-froala-wysiwyg/FroalaEditorView";

import {
  AutosaveDelay,
  CommentKey,
  CommentSectionName,
} from "../../../constant";
import { saveDapCommentData } from "../../../redux/slices/saveCommentSlice";
import {
  updateDapCommentData,
  DapComment,
} from "../../../redux/slices/updateDapCommentSlice";

// for grid
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  color: theme.palette.text.secondary,
}));

export const KeyProvision = (props) => {
  var dispatch = useAppDispatch();
  const keyProvisionsComment = useAppSelector(
    (state) => state.dapComments?.data
  );
  const assetPlanId = useAppSelector(
    (state) => state.getAssetPlanId.assetPlanId
  );

  const mangementComment_date =
    keyProvisionsComment.find(
      (x) =>
        x.commentKey.toLowerCase() ===
        CommentKey.MANAGEMENTAGREEMENT_DATE.toLowerCase()
    )?.commentText || "";
  const mangementComment_fee =
    keyProvisionsComment.find(
      (x) =>
        x.commentKey.toLowerCase() ===
        CommentKey.MANAGEMENTAGREEMENT_FEE.toLowerCase()
    )?.commentText || "";
  const mangementComment_otherFee =
    keyProvisionsComment.find(
      (x) =>
        x.commentKey.toLowerCase() ===
        CommentKey.MANAGEMENTAGREEMENT_OTHERFEE.toLowerCase()
    )?.commentText || "";
  const leasebroker_name =
    keyProvisionsComment.find(
      (x) =>
        x.commentKey.toLowerCase() === CommentKey.LEASEBROKER_NAME.toLowerCase()
    )?.commentText || "";
  const leasebroker_fee =
    keyProvisionsComment.find(
      (x) =>
        x.commentKey.toLowerCase() === CommentKey.LEASEBROKER_FEE.toLowerCase()
    )?.commentText || "";
  const leasebroker_otherfee =
    keyProvisionsComment.find(
      (x) =>
        x.commentKey.toLowerCase() ===
        CommentKey.LEASEBROKER_OTHERFEE.toLowerCase()
    )?.commentText || "";
  const jvagreement_KeyDecision =
    keyProvisionsComment.find(
      (x) =>
        x.commentKey.toLowerCase() ===
        CommentKey.JVAGREEMENT_KEYDECISION.toLowerCase()
    )?.commentText || "";
  const jvagreement_liquidProvision =
    keyProvisionsComment.find(
      (x) =>
        x.commentKey.toLowerCase() ===
        CommentKey.JVAGREEMENT_LIQUIDITYPROVISION.toLowerCase()
    )?.commentText || "";
  const jvagreement_fee =
    keyProvisionsComment.find(
      (x) =>
        x.commentKey.toLowerCase() === CommentKey.JVAGREEMENT_FEE.toLowerCase()
    )?.commentText || "";
  const jvagreement_Keyprovision =
    keyProvisionsComment.find(
      (x) =>
        x.commentKey.toLowerCase() ===
        CommentKey.JVAGREEMENT_KEYPROVISION.toLowerCase()
    )?.commentText || "";

  console.log("keyproviison comment", keyProvisionsComment);

  //const isMounted = useRef(false);
  const handleCommentChange = (model: any, commentKey: any) => {
    console.log("check model", model);
    const getCurrentComment = keyProvisionsComment.find(
      (x) => x.commentKey.toLowerCase() === commentKey.toLowerCase()
    );
    const updatedcomment: DapComment = {
      id: getCurrentComment ? getCurrentComment.id : 0,
      commentKey: getCurrentComment ? getCurrentComment.commentKey : commentKey,
      commentText: model || "",
      sectionName: CommentSectionName.KEYPROVISIONS,
      assetPlanId: assetPlanId,
    };
    console.log("update keyprovisionsComment", updatedcomment);
    dispatch(updateDapCommentData(updatedcomment));
    dispatch(saveDapCommentData(updatedcomment));

    // if (isMounted.current) {
    //   dispatch(updateDapCommentData(updatedcomment));
    //   dispatch(saveDapCommentData(updatedcomment));
    // } else {
    //   isMounted.current = true;
    // }
  };

  //getting the data on frontend to flora editor

  // const savedComment = useAppSelector(
  //   (state) => state.saveComment.updateDapComments
  // );
  // const [toastMessageOpen, setToastMessageOpen] = useState(false);
  // const [toastMessage, setToastMessage] = useState<toast>({
  //   message: "",
  //   severity: "",
  // });
  // const isMountedAutosave = useRef(false);
  // useEffect(() => {
  //   if (isMountedAutosave.current) {
  //     const promises: Promise<any>[] = [];
  //     const debouncedAutosave = setTimeout(() => {
  //       if (savedComment) {
  //         console.log("com", savedComment);
  //         promises.push(
  //           postApiCall(`${baseUrl}DapComments/UpdateDapComments`, savedComment)
  //         );
  //       }
  //       clearDocumentComments(dispatch);
  //       setToastMessage({
  //         message: "Saved successfully",
  //         severity: "success",
  //       });
  //       if (promises.length > 0) {
  //         setToastMessageOpen(false);
  //         Promise.all(promises)
  //           .then((responses) => {
  //             const successResponses = responses.filter(
  //               (response: any) => response.success
  //             );
  //             if (successResponses.length === promises.length) {
  //               setToastMessage({
  //                 message: "Saved successfully",
  //                 severity: "success",
  //               });
  //               setToastMessageOpen(true);
  //             }
  //           })
  //           .catch((error) => {
  //             console.error("Error:", error);
  //             setToastMessage({
  //               message: error.message,
  //               severity: "error",
  //             });
  //             setToastMessageOpen(true);
  //           });
  //       } else {
  //         console.log("No API calls made");
  //       }
  //     }, AutosaveDelay.DELAYTIME);
  //     return () => {
  //       clearTimeout(debouncedAutosave);
  //     };
  //   } else {
  //     isMountedAutosave.current = true;
  //   }
  // }, [savedComment]);

  const getRowId = (row: any) => row.appraisaL_DATE;
  return (
    <div>
      {/* {toastMessageOpen && (
        <GenericToast
          message={toastMessage.message}
          severity={toastMessage.severity}
        />
      )} */}
      <Box sx={{ flexGrow: 1 }} className="summary-form">
        <div className="tab-heading text-center">
          <h3 className="heading">Key Provisions</h3>
        </div>
        <h3 className="text-left sub-heading">Management Agreement</h3>
        <Grid container spacing={2}>
          {/* block1 */}
          <Grid item xs={12} md={4}>
            <Item>
              <h3 className="text-left">Name/ Parties/ Date</h3>
              <div className="grid-item-box">
                {props?.mode === "edit" && (
                  <FroalaRichText
                    model={mangementComment_date}
                    handlesModelChange={(model: any) =>
                      handleCommentChange(
                        model,
                        CommentKey.MANAGEMENTAGREEMENT_DATE
                      )
                    }
                  />
                )}
                {(props?.mode === undefined || props?.mode === "view") && (
                  <FroalaEditorView model={mangementComment_date} />
                )}
              </div>
            </Item>
          </Grid>

          <Grid item xs={12} md={4}>
            <Item>
              <h3 className="text-left">Management Fees</h3>
              <div className="grid-item-box">
                {props?.mode === "edit" && (
                  <FroalaRichText
                    model={mangementComment_fee}
                    handlesModelChange={(model) =>
                      handleCommentChange(
                        model,
                        CommentKey.MANAGEMENTAGREEMENT_FEE
                      )
                    }
                  />
                )}
                {(props?.mode === undefined || props?.mode === "view") && (
                  <FroalaEditorView model={mangementComment_fee} />
                )}
              </div>
            </Item>
          </Grid>

          <Grid item xs={12} md={4}>
            <Item>
              <h3 className="text-left">Other Fees</h3>
              <div className="grid-item-box">
                {props?.mode === "edit" && (
                  <FroalaRichText
                    model={mangementComment_otherFee}
                    handlesModelChange={(model) =>
                      handleCommentChange(
                        model,
                        CommentKey.MANAGEMENTAGREEMENT_OTHERFEE
                      )
                    }
                  />
                )}
                {(props?.mode === undefined || props?.mode === "view") && (
                  <FroalaEditorView model={mangementComment_otherFee} />
                )}
              </div>
            </Item>
          </Grid>
        </Grid>

        <h3 className="text-left sub-heading">Leasing Broker</h3>
        <Grid container spacing={2}>
          {/* block1 */}
          <Grid item xs={12} md={4}>
            <Item>
              <h3 className="text-left">Name</h3>
              <div className="grid-item-box">
                {props?.mode === "edit" && (
                  <FroalaRichText
                    model={leasebroker_name}
                    handlesModelChange={(model) =>
                      handleCommentChange(model, CommentKey.LEASEBROKER_NAME)
                    }
                  />
                )}
                {(props?.mode === undefined || props?.mode === "view") && (
                  <FroalaEditorView model={leasebroker_name} />
                )}
              </div>
            </Item>
          </Grid>

          <Grid item xs={12} md={4}>
            <Item>
              <h3 className="text-left">Management Fees</h3>
              <div className="grid-item-box">
                {props?.mode === "edit" && (
                  <FroalaRichText
                    model={leasebroker_fee}
                    handlesModelChange={(model) =>
                      handleCommentChange(model, CommentKey.LEASEBROKER_FEE)
                    }
                  />
                )}
                {(props?.mode === undefined || props?.mode === "view") && (
                  <FroalaEditorView model={leasebroker_fee} />
                )}
              </div>
            </Item>
          </Grid>

          <Grid item xs={12} md={4}>
            <Item>
              <h3 className="text-left">Other Fees</h3>
              <div className="grid-item-box">
                {props?.mode === "edit" && (
                  <FroalaRichText
                    model={leasebroker_otherfee}
                    handlesModelChange={(model) =>
                      handleCommentChange(
                        model,
                        CommentKey.LEASEBROKER_OTHERFEE
                      )
                    }
                  />
                )}
                {(props?.mode === undefined || props?.mode === "view") && (
                  <FroalaEditorView model={leasebroker_otherfee} />
                )}
              </div>
            </Item>
          </Grid>
        </Grid>

        <h3 className="text-left sub-heading">JV Agreement</h3>
        <Grid container spacing={2}>
          {/* block1 */}
          <Grid item xs={12} md={3}>
            <Item>
              <h3 className="text-left">Key Major Decisions</h3>
              <div className="grid-item-box">
                {props?.mode === "edit" && (
                  <FroalaRichText
                    model={jvagreement_KeyDecision}
                    handlesModelChange={(model) =>
                      handleCommentChange(
                        model,
                        CommentKey.JVAGREEMENT_KEYDECISION
                      )
                    }
                  />
                )}
                {(props?.mode === undefined || props?.mode === "view") && (
                  <FroalaEditorView model={jvagreement_KeyDecision} />
                )}
              </div>
            </Item>
          </Grid>

          <Grid item xs={12} md={3}>
            <Item>
              <h3 className="text-left">Fees</h3>
              <div className="grid-item-box">
                {props?.mode === "edit" && (
                  <FroalaRichText
                    model={jvagreement_fee}
                    handlesModelChange={(model) =>
                      handleCommentChange(model, CommentKey.JVAGREEMENT_FEE)
                    }
                  />
                )}
                {(props?.mode === undefined || props?.mode === "view") && (
                  <FroalaEditorView model={jvagreement_fee} />
                )}
              </div>
            </Item>
          </Grid>

          <Grid item xs={12} md={3}>
            <Item>
              <h3 className="text-left">Liquidity Provisions</h3>
              <div className="grid-item-box">
                {props?.mode === "edit" && (
                  <FroalaRichText
                    model={jvagreement_liquidProvision}
                    handlesModelChange={(model) =>
                      handleCommentChange(
                        model,
                        CommentKey.JVAGREEMENT_LIQUIDITYPROVISION
                      )
                    }
                  />
                )}
                {(props?.mode === undefined || props?.mode === "view") && (
                  <FroalaEditorView model={jvagreement_liquidProvision} />
                )}
              </div>
            </Item>
          </Grid>

          <Grid item xs={12} md={3}>
            <Item>
              <h3 className="text-left">Other Key Provisions</h3>
              <div className="grid-item-box">
                {props?.mode === "edit" && (
                  <FroalaRichText
                    model={jvagreement_Keyprovision}
                    handlesModelChange={(model) =>
                      handleCommentChange(
                        model,
                        CommentKey.JVAGREEMENT_KEYPROVISION
                      )
                    }
                  />
                )}
                {(props?.mode === undefined || props?.mode === "view") && (
                  <FroalaEditorView model={jvagreement_Keyprovision} />
                )}
              </div>
            </Item>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};
