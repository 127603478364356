import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import {
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  TextField,
  Typography,
} from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import DialogActions from "@mui/material/DialogActions";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CircularProgress from "@mui/material/CircularProgress";
import CheckIcon from "@mui/icons-material/Check";
import CancelIcon from "@mui/icons-material/Cancel";
import Fab from "@mui/material/Fab";
import { green } from "@mui/material/colors";

import { postApiCall } from "../../services/apiHelper";
import { baseUrl } from "../../config/config";

import { DapStatus } from "../../constant";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const CloneData = ({ changeMessage, handleClose }) => {
  const yearItems = [2020, 2021, 2022, 2023, 2024, 2025, 2026];
  const [open, setOpen] = React.useState(false);

  const [cloneInputData, setCloneInputData] = React.useState<{
    StartInvestmentId: string;
    EndInvestmentId: string;
    FromYear: number;
    ToYear: number;
    StatusList: Array<{ val: string; check: boolean }>;
    InvestmentId: any;
  }>({
    StartInvestmentId: "",
    EndInvestmentId: "",
    FromYear: new Date().getFullYear(),
    ToYear: new Date().getFullYear() + 1,
    StatusList: [{ val: "", check: false }],
    InvestmentId: "",
    //create this as an aray
  });

  const [formIsValid, setFormIsValid] = React.useState(false);
  const [statusDataArray, setStatusDataArray] = React.useState(["values"]);
  const [loading, setLoading] = React.useState(false);
  const [cloningResult, setCloningResult] = useState({
    message: "",
    status: false,
  });

  const buttonSx = {
    ...(loading && {
      bgcolor: green[500],
      "&:hover": {
        bgcolor: green[700],
      },
    }),
  };

  useEffect(() => {
    let statusArray = [{ val: "", check: false }];

    let fetchedDapStatus = [""];

    let result = Object.entries(DapStatus);
    result.map((data) => fetchedDapStatus.push(data[1]));
    fetchedDapStatus.splice(0, 1);

    setStatusDataArray(fetchedDapStatus);

    fetchedDapStatus.map((data: any) => {
      statusArray.push({ val: data, check: false });
    });

    statusArray.splice(0, 1);
    setCloneInputData({ ...cloneInputData, StatusList: statusArray });
  }, []);

  //
  const initialState = statusDataArray.reduce(
    (o, key) => ({ ...o, [key]: false }),
    {}
  );

  const [checkedAll, setCheckedAll] = React.useState(false);
  const [checked, setChecked] = React.useState(initialState);

  const toggleCheck = (inputName) => {
    setChecked((prevState) => {
      const newState = { ...prevState };
      newState[inputName] = !prevState[inputName];
      return newState;
    });
  };

  const selectAll = (value) => {
    setCheckedAll(value);
    setChecked((prevState) => {
      const newState = { ...prevState };

      for (const inputName in newState) {
        newState[inputName] = value;
      }
      return newState;
    });
  };

  const handleChange = (event) => {
    let { name, type, checked } = event.target;
    if (type !== "checkbox") {
      if (name === "InvestmentId") {
        setCloneInputData({
          ...cloneInputData,
          [name]: event.target.value,
        });

        console.log(cloneInputData);
      } else {
        setCloneInputData({
          ...cloneInputData,
          [name]: event.target.value,
        });
      }
    } else if (type === "checkbox" && name !== "selectAll") {
      let tempCloneData = cloneInputData;
      tempCloneData.StatusList.map((data, index) => {
        let checkStatus = data.check;
        if (data.val === name) {
          data.check = !checkStatus;
        }
      });
      setCloneInputData(tempCloneData);
    } else if (name === "selectAll") {
      let tempCloneData = cloneInputData;
      tempCloneData.StatusList.map((data) => {
        data.check = checked;
      });
      setCloneInputData(tempCloneData);
    }
  };

  useEffect(() => {
    let allFieldsAreValid = {
      InvestmetnIDtextfields: false,
      InvestmetnIDFromtextfields: false,
      InvestmetnIDTotextfields: false,
      dropDowns: false,
      checkBoxes: false,
    };

    if (cloneInputData.InvestmentId.length > 0) {
      allFieldsAreValid.InvestmetnIDtextfields = true;
    }

    if (
      cloneInputData.StartInvestmentId.length > 0 &&
      cloneInputData.EndInvestmentId.length > 0
    ) {
      allFieldsAreValid.InvestmetnIDFromtextfields = true;
      allFieldsAreValid.InvestmetnIDTotextfields = true;
    }

    if (cloneInputData.FromYear < cloneInputData.ToYear) {
      allFieldsAreValid.dropDowns = true;
    }

    const dapStatusIsSelected = cloneInputData.StatusList.map(
      (data) => data.check === true
    );

    if (dapStatusIsSelected.includes(true)) {
      allFieldsAreValid.checkBoxes = true;
    }

    if (
      allFieldsAreValid.InvestmetnIDtextfields &&
      allFieldsAreValid.dropDowns
    ) {
      setFormIsValid(true);
    } else if (
      allFieldsAreValid.InvestmetnIDFromtextfields &&
      allFieldsAreValid.InvestmetnIDTotextfields &&
      allFieldsAreValid.dropDowns &&
      allFieldsAreValid.checkBoxes
    ) {
      setFormIsValid(true);
    } else {
      setFormIsValid(false);
    }
  }, [handleChange]);

  const postData = async () => {
    let emptArr = [""];

    if (cloneInputData.InvestmentId.length > 0) {
      emptArr.push(cloneInputData.InvestmentId);
    }

    emptArr.splice(0, 1);
    let postPayload = {
      StartInvestmentId: cloneInputData.StartInvestmentId,
      EndInvestmentId: cloneInputData.EndInvestmentId,
      FromYear: cloneInputData.FromYear,
      ToYear: cloneInputData.ToYear,
      StatusList: [""],
      InvestmentId: emptArr,
    };

    let statusListStrings = [""];

    cloneInputData.StatusList.map((statusData) => {
      if (statusData.check) {
        statusListStrings.push(statusData.val.toLocaleLowerCase());
      }

      if (cloneInputData.StartInvestmentId.length === 0) {
        statusListStrings.push(statusData.val.toLocaleLowerCase());
      }
    });

    statusListStrings.splice(0, 1);
    postPayload.StatusList = statusListStrings;
    setLoading(true);
    setOpen(true);

    setCloningResult({ message: "Cloning", status: false });

    try {
      const response = await postApiCall(
        `${baseUrl}CloneDap/CloneMultipleDap`,
        postPayload
      )
        .then((res: any) => {
          console.log("responses");
          changeMessage(res);
          if (res.clonedInvestmentIdDetails.length === 0) {
            setCloningResult({
              message: "The specified Asset Plan does not exist!",
              status: true,
            });
          } else {
            setCloningResult({ message: "Asset Plan Cloned!", status: false });
            setTimeout(() => {
              handleClose();
              window.location.reload();
            }, 5000);
          }
        })
        .then(() => {
          setLoading(false);
        });
      return response;
    } catch (error: any) {
      console.error("Error in POST request:", error);
      setCloningResult({ message: `Error :${error}`, status: true });
      throw error;
    }
  };

  return (
    <Container component="main" sx={{ p: 8 }}>
      <h2 className="heading">Clone Data</h2>
      <Grid container spacing={2} maxWidth="md">
        <Grid item xs={12} sm={12}>
          <TextField
            required
            id="Investment_id"
            name="InvestmentId"
            label="ID"
            variant="outlined"
            autoComplete="off"
            fullWidth
            onChange={(e) => handleChange(e)}
            disabled={
              cloneInputData.StartInvestmentId.length > 0 ||
              cloneInputData.EndInvestmentId.length > 0
            }
            helperText={
              cloneInputData.StartInvestmentId.length > 0
                ? "Disabled (Only a single ID Field can be set at a time)"
                : ""
            }
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="Investment_id_from"
            name="StartInvestmentId"
            label="ID From"
            autoComplete="off"
            variant="outlined"
            fullWidth
            onChange={(e) => handleChange(e)}
            disabled={cloneInputData.InvestmentId.length > 0}
            helperText={
              cloneInputData.InvestmentId.length > 0
                ? "Disabled (Only a single ID Field can be set at a time)"
                : ""
            }
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="Investment_id_to"
            name="EndInvestmentId"
            label="ID To"
            autoComplete="off"
            variant="outlined"
            fullWidth
            onChange={(e) => handleChange(e)}
            disabled={cloneInputData.InvestmentId.length > 0}
            helperText={
              cloneInputData.InvestmentId.length > 0 ? "Disabled" : ""
            }
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth component="fieldset" variant="filled">
            <InputLabel>Year From</InputLabel>
            <Select
              value={cloneInputData.FromYear}
              name="FromYear"
              label="From"
              onChange={(e) => handleChange(e)}
            >
              {yearItems.map((element) => (
                <MenuItem value={element} key={element}>
                  {element}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth component="fieldset" variant="filled">
            <InputLabel>Year To</InputLabel>
            <Select
              value={cloneInputData.ToYear}
              name="ToYear"
              label="To"
              onChange={(e) => handleChange(e)}
            >
              {yearItems.map((element) => (
                <MenuItem value={element} key={element}>
                  {element}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} style={{ margin: "1em 0 0 0" }}>
          <FormControl component="fieldset" variant="standard">
            <FormLabel component="legend">Status of DAPs?</FormLabel>
            <FormGroup>
              <Grid container>
                <Grid item xs={3}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="selectAll"
                        onChange={(e) => {
                          handleChange(e);
                          selectAll(e.target.checked);
                        }}
                        checked={checkedAll}
                        disabled={cloneInputData.InvestmentId.length > 0}
                      />
                    }
                    label="Select All"
                  />
                </Grid>

                {statusDataArray.map((data, index) => {
                  return (
                    <Grid item xs={3}>
                      <FormControlLabel
                        key={index}
                        control={
                          <Checkbox
                            name={data}
                            onChange={(e) => {
                              handleChange(e);
                              toggleCheck(data);
                            }}
                            checked={cloneInputData.StatusList[index].check}
                            disabled={cloneInputData.InvestmentId.length > 0}
                          />
                        }
                        label={data}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </FormGroup>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <Button
            variant="contained"
            size="medium"
            color="success"
            disabled={!formIsValid}
            onClick={postData}
            style={{ padding: "0.5em 2em", fontSize: "0.9em", fontWeight: 900 }}
          >
            Clone
          </Button>
        </Grid>

        {/* {!formIsValid && <Grid item xs={12} style={{ paddingTop: 0 }} >
          <Typography variant="caption" display="block" gutterBottom style={{ color: "gray", marginTop: 0 }} >
            *Please select all the fields for cloning
          </Typography>
        </Grid>} */}
      </Grid>

      <React.Fragment>
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
          className="common-dialog-box"
        >
          <DialogTitle>{cloningResult.message}</DialogTitle>
          <DialogContent>
            <DialogContentText
              id="alert-dialog-slide-description"
              style={{ display: "flex", justifyContent: "center" }}
            >
              {!loading ? (
                !cloningResult.status ? (
                  <Fab
                    aria-label="save"
                    color="primary"
                    sx={buttonSx}
                    //onClick={handleButtonClick}
                  >
                    <CheckIcon />
                  </Fab>
                ) : (
                  <Fab
                    aria-label="save"
                    color="warning"
                    sx={buttonSx}
                    //onClick={handleButtonClick}
                  >
                    <CancelIcon />
                  </Fab>
                )
              ) : (
                <CircularProgress />
              )}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button disabled={loading} onClick={() => window.location.reload()}>
              Continue
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    </Container>
  );
};
