import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface DapComment {
  id: number;
  assetPlanId: string;
  sectionName: string;
  commentKey: string;
  commentText: string;
}

interface InitialState {
  updateDapComments: DapComment[]; // Set initial state of data as null
}

const initialState: InitialState = {
  updateDapComments: [],
};

export const saveCommentSlice = createSlice({
  name: "saveDapComment",
  initialState,
  reducers: {
    resetState: (state) => {
      state.updateDapComments = [];
    },
    saveDapCommentData(state, action: PayloadAction<DapComment>) {
      const comment = action.payload;
      let existingIndex=-1||undefined;
      if(comment.sectionName.toLowerCase()==='missionstatement')
      {
          existingIndex=state.updateDapComments?.findIndex((record) => record.id === comment.id 
          && record.sectionName.toLowerCase() === comment.sectionName.toLowerCase());
      }
      else
      {
      existingIndex =
        comment.id === 0
          ? state.updateDapComments?.findIndex(
              (record) =>
                record.commentKey.toLowerCase() === comment.commentKey.toLowerCase() &&
                record.sectionName.toLowerCase() === comment.sectionName.toLowerCase()
            )
          : state.updateDapComments?.findIndex((record) => record.id === comment.id);
      }

      
      if (existingIndex !== undefined && existingIndex !== -1) {
        state.updateDapComments[existingIndex] = {
          ...state.updateDapComments[existingIndex],
          ...comment,
        };
      } else {
        if(comment.id===0 && comment.commentText==='')
        {
          return;
        }
        state.updateDapComments.push(comment);
      }
    }
  
  }
});

export const { resetState: resetSaveCommentStateAction, saveDapCommentData } = saveCommentSlice.actions;
export default saveCommentSlice.reducer;
