import axios from "axios";
import { AxiosResponse } from "axios";
import {baseUrl, headers} from "../../../config/config"
import { postApiCall } from "services/apiHelper";

export class DocumentServices {

    getDocumentsBySectionAndAssetPlanId(payload){
        return postApiCall(`${baseUrl}Document/GetDocumentsBySectionAndAssetPlanId`,payload)
        .catch((err) => {
          console.log(err);
        });
    }

    GetDocumentsByAssetPlanId(payload){
        return postApiCall(`${baseUrl}Document/GetDocumentsByAssetPlanId`,payload)
        .catch((err) => {
          console.log(err);
        });
    }

    GetDownloadDocumentByFileKey(payload){
      return postApiCall(`${baseUrl}Document/GetDocumentToDownload`,payload)
      .catch((err) => {
        console.log(err);
      });
      
    }
    GetPreSignedUrlByFileKey(payload){
      return postApiCall(`${baseUrl}Document/getPreSignedUrl`,payload)
      .catch((err) => {
        console.log(err);
      });
    }
    GetDownloadDocumentById(payload){
      return postApiCall(`${baseUrl}Document/GetChunksCountForDocument/` + payload, headers)
      .catch((err) => {
        console.log(err);
      });
      
    }
    GetDownloadDocumentByFileKeyAndPreSignedUrl(url,payload){
      return postApiCall(url,payload)
      .catch((err) => {
        console.log(err);
      });
    }

}
