import React, { useRef } from "react";
import { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import columnConfigJson from "./columnConfig.json";
import ResidentialOccupancyNew from "./residential/residentialOccupancy-new";
import ResidentialLeasingActivityNew from "./residential/residentialLeaseActivity-new";
import CommertialLeaseExpirations from "./commertial/commertialLeaseExpirations";
import "./commertialLeasingActivity.css";
import "./commertiialOccupancy.css";
import { useAppSelector, useAppDispatch } from "../../../redux/hooks";
import { FroalaRichText } from "../../util/FroalaRichtext";
import FroalaEditorView from "react-froala-wysiwyg/FroalaEditorView";
import {
  updateDapCommentData,
  DapComment,
} from "../../../redux/slices/updateDapCommentSlice";
import { AutosaveDelay, CommentKey } from "../../../constant";
import { saveDapCommentData } from "../../../redux/slices/saveCommentSlice";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

interface Data {
  year: number;
  sfExpiring: number;
  percentageExpiring: number;
  percentageCumulative: number;
}

interface GridValueFormatterParams<T> {
  value: T;
}

type ValueFormatter<T> = (
  params: GridValueFormatterParams<T>,
  currency?: string,
  decimalPosition?: number
) => string;

const currencyFormatter: ValueFormatter<number> = (
  params,
  currency = "$",
  decimalPosition = 2
) => {
  const value = params.value;
  if (typeof value === "number") {
    return `${currency}${value.toFixed(decimalPosition)}`;
  }
  return "";
};

const commaSeparatedFormatter: ValueFormatter<number> = (
  params,
  currency,
  decimalPosition
) => {
  const value = params.value;
  if (typeof value === "number") {
    return value.toLocaleString();
  }
  return "";
};

const decimalFormatter: ValueFormatter<number> = (
  params,
  currency,
  decimalPosition = 2
) => {
  const value = params.value;
  if (typeof value === "number") {
    return value.toFixed(decimalPosition);
  }

  return "";
};

const columnConfig = columnConfigJson.map((column) => {
  const { valueFormatter, currency, decimalPosition, ...rest } = column;

  let formatter;
  if (valueFormatter === "currencyFormatter") {
    formatter = (params) =>
      currencyFormatter(params, currency, decimalPosition);
  } else if (valueFormatter === "commaSeparatedFormatter") {
    formatter = (params) =>
      commaSeparatedFormatter(params, decimalPosition?.toString());
  } else if (valueFormatter === "decimalFormatter") {
    formatter = (params) =>
      decimalFormatter(params, decimalPosition?.toString());
  }

  return {
    ...rest,
    ...(formatter && { valueFormatter: formatter }),
  };
});

export const LeasingResidentialEditForm = (props) => {
  const dispatch = useAppDispatch();
  const residentialComment = useAppSelector(
    (state) => state.dapComments?.data
  ).find(
    (x) => x.commentKey.toLowerCase() === CommentKey.RESIDENTIAL.toLowerCase()
  );
  const assetPlanId = useAppSelector(
    (state) => state.getAssetPlanId.assetPlanId
  );
  // const isMounted = useRef(false);
  const handleCommentChange = (model: any) => {
    const updatedcomment: DapComment = {
      id: residentialComment ? residentialComment.id : 0,
      commentKey: residentialComment
        ? residentialComment.commentKey
        : CommentKey.RESIDENTIAL,
      commentText: model || "",
      sectionName: "Leasing",
      assetPlanId: assetPlanId,
    };
    dispatch(updateDapCommentData(updatedcomment));
    dispatch(saveDapCommentData(updatedcomment));

    // if (isMounted.current) {
    //   dispatch(updateDapCommentData(updatedcomment));
    //   dispatch(saveDapCommentData(updatedcomment));
    // } else {
    //   isMounted.current = true;
    // }
  };

  const budgetYear = Number(
    useAppSelector((state) => state.summary.dapData?.budgetYear)
  );

  // const savedComment = useAppSelector(
  //   (state) => state.saveComment.updateDapComments
  // );
  // const [toastMessageOpen, setToastMessageOpen] = useState(false);
  // const [toastMessage, setToastMessage] = useState<toast>({
  //   message: "",
  //   severity: "",
  // });
  // const isMountedAutosave = useRef(false);
  // useEffect(() => {
  //   if (isMountedAutosave.current) {
  //     const promises: Promise<any>[] = [];
  //     const debouncedAutosave = setTimeout(() => {
  //       if (savedComment) {
  //         console.log("com", savedComment);
  //         promises.push(
  //           postApiCall(`${baseUrl}DapComments/UpdateDapComments`, savedComment)
  //         );
  //       }
  //       clearDocumentComments(dispatch);
  //       setToastMessage({
  //         message: "Saved successfully",
  //         severity: "success",
  //       });
  //       if (promises.length > 0) {
  //         setToastMessageOpen(false);
  //         Promise.all(promises)
  //           .then((responses) => {
  //             const successResponses = responses.filter(
  //               (response: any) => response.success
  //             );
  //             if (successResponses.length === promises.length) {
  //               setToastMessage({
  //                 message: "Saved successfully",
  //                 severity: "success",
  //               });
  //               setToastMessageOpen(true);
  //             }
  //           })
  //           .catch((error) => {
  //             console.error("Error:", error);
  //             setToastMessage({
  //               message: error.message,
  //               severity: "error",
  //             });
  //             setToastMessageOpen(true);
  //           });
  //       } else {
  //         console.log("No API calls made");
  //       }
  //     }, AutosaveDelay.DELAYTIME);
  //     return () => {
  //       clearTimeout(debouncedAutosave);
  //     };
  //   } else {
  //     isMountedAutosave.current = true;
  //   }
  // }, [savedComment]);

  return (
    <div>
      {/* {toastMessageOpen && (
        <GenericToast
          message={toastMessage.message}
          severity={toastMessage.severity}
        />
      )} */}
      <div className="text-center">
        <h2 className="mt-0">Residential</h2>
      </div>

      <Box sx={{ width: "100%" }}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
          {/* first block */}
          <Grid item xs={6}>
            <Item>
              <div className="container">
                <div className="text-center">
                  <h2 className="mt-0">
                    {budgetYear} Projected Leasing Activity
                  </h2>
                </div>
                <div className="occupancy-container">
                  {/* <ResidentialLeasingActivity/> */}
                  <ResidentialLeasingActivityNew />
                </div>
              </div>
            </Item>
          </Grid>
          {/* 2nd block */}
          <Grid item xs={6}>
            <Item>
              <div className="container">
                <div className="text-center">
                  <h2 className="mt-0">{budgetYear} Occupancy Schedule</h2>
                </div>
                <div className="occupancy-container">
                  {/* <ResidentialOccupancy/> */}
                  <ResidentialOccupancyNew />
                </div>
              </div>
            </Item>
          </Grid>
          {/* 3rd block */}
        </Grid>
      </Box>

      <div className="mt-20">
        <div className="text-center">
          <div style={{ marginTop: "100px" }}></div>
          <h2>Lease Expirations</h2>
        </div>
        <CommertialLeaseExpirations />
      </div>

      <form className="m-20 lease-form">
        {props?.mode === "edit" && (
          <fieldset>
            <legend className="lease-title">Leasing Comments</legend>
            <FroalaRichText
              model={residentialComment?.commentText || ""}
              handlesModelChange={handleCommentChange}
            />
          </fieldset>
        )}

        {(props?.mode === undefined || props?.mode === "view") && (
          <fieldset>
            <legend className="lease-title">Leasing Comments</legend>
            <FroalaEditorView model={residentialComment?.commentText || ""} />
          </fieldset>
        )}
      </form>
    </div>
  );
};
