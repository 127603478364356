const leasingResidentialTemplate = `
<!DOCTYPE html>
<html>
  <head>
  
  </head>
  <body>
    <div class="row">
      <h2 class="pdf-table-heading">Leasing</h2>
      <h3>Residential</h3>
      <!-- left table -->
      <div class="column">
        <h3>{{budgetYear}} Projected Leasing Activity</h3>
        <table id="leasing-table">
          <tr>
            <td>Total Units:</td>
            <td>{{totalUnits}}</td>
          </tr>
          <tr>
            <td>Rentable SqFt:</td>
            <td>{{rentableSqft}}</td>
          </tr>
          <tr>
            <td>Projected Avg Physical Occupancy:</td>
            <td>{{physicalOccupancyPC}}</td>
          </tr>
          <tr>
            <td>Lease Renewal%:</td>
            <td>{{leaseRenewalPC}}</td>
          </tr>
        </table>
      </div>
      <!-- right table -->
      <div class="column">
        <h3>{{budgetYear}} Occupancy Schedule</h3>
        <table id="leasing-table">
          <tr>
            <td>#Unit Leased 1/1/{{budgetYear}}:</td>
            <td>{{startYearLeaseUnit}}</td>
          </tr>
          <tr>
            <td>% Leased 1/1/{{budgetYear}}:</td>
            <td>{{startYearLeasePC}}</td>
          </tr>
          <tr>
            <td>New Leases {{budgetYear}}:</td>
            <td>{{newLease}}</td>
          </tr>
          <tr>
            <td>% Leased 12/31/{{budgetYear}}:</td>
            <td>{{endYearLeasePC}}</td>
          </tr>
        </table>
      </div>
    </div>

    <div class="row">
      <!-- Lease Expirations table -->
      <h2 class="pdf-table-heading">Lease Expirations</h2>
      <table id="print-pdf-table">
        <tr>
          <th></th>
          <th>SF Expiring</th>
          <th>% Expiring</th>
          <th>% Cumulative</th>
        </tr>
        {{#each expirationsData}}
        <tr>
          <td>{{this.expiryYear}}</td>
          <td>{{this.expiringUnit}}</td>
          <td>{{this.expiringPercentage}}</td>
          <td>{{this.cumulativePercentage}}</td>
        </tr>
        {{/each}}
      </table>
    </div>
    <div class ="row">
    <br>
    <table id="comment-table">
      <tbody>
        <tr>
          <th>Comments</th>
        </tr>
        <tr>
          <td>
            {{{residentialComment.commentText}}}
          </td>
        </tr>
      </tbody>
    </table>
    </tr>
  </table>
    </div>
  </body>
</html>
`;

export default leasingResidentialTemplate;
