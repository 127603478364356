import handlebars from "handlebars";
import swotTemplate from "../templates/swotTemplate";
import { CommentKey} from "../../../constant";

export const compileSwotTemplate = (commentData:any[]): string => {
    

    const compileSwotTemplate = handlebars.compile(swotTemplate);
    const Comment: any = commentData || [];
    const SwotComment = {
        strengthComment:
        Comment?.find(
          (x) =>
            x.commentKey?.toLowerCase() ===
            CommentKey.STRENGTH.toLowerCase()
        )?.commentText || "",
        weaknessComment:
        Comment?.find(
          (x) =>
            x.commentKey?.toLowerCase() ===
            CommentKey.WEAKNESS.toLowerCase()
        )?.commentText || "",
        oppurtunitiesComment:
        Comment?.find(
          (x) =>
            x.commentKey?.toLowerCase() ===
            CommentKey.OPPURTINITIES.toLowerCase()
        )?.commentText || "",
        threatsComment:
        Comment?.find(
          (x) =>
            x.commentKey?.toLowerCase() ===
            CommentKey.THREATS.toLowerCase()
        )?.commentText || "",
      }
    return compileSwotTemplate(SwotComment);

};