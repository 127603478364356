import { baseUrl } from "config/config";
import { getApiCall, postApiCall } from "./apiHelper";

export interface viewPayload {
  userEmailId: string;
  viewId: string;
  filters: any[];
  page: number;
  pageSize: number;
  rowCount: number;
}

export interface assetPlanResponse {}

export interface TabsResponse {
  displayOrder: number;
  viewDescription: string;
  viewId: string;
  viewName: string;
}

export class DashboardService {
  getAssetPlansByView(payload: viewPayload) {
    var url = `${baseUrl}` + "DashboardViews/GetDashboardViewById";
    const res = postApiCall(url, payload).then((data: any) => data);
    return res;
  }

  getTabs(emailId: string) {
    var url = `${baseUrl}` + "DashboardViews/DapViews?emailId=" + emailId;
    const res = getApiCall<TabsResponse[]>(url).then((data) => data);
    return res;
  }

  getDashboardFilters(userId: string) {
    var url = `${baseUrl}` + "DashboardViews/GetDashboardFiltersByUserId?userId=" + userId;
    const res = postApiCall(url, userId).then((data: any) => data);
    return res;
  }

  upsertDashboardFilters(payload: any) {
    var url = `${baseUrl}` + "DashboardViews/UpsertDashboardFilter";
    const res = postApiCall(url, payload).then((data: any) => data);
    return res;
  }

  softDeleteAssetPlanByInvestmentIdBudgetYear(assetPlanId, isDeleted) {
    var url = `${baseUrl}AssetPlan/DeleteDapUpdate`;
    const res = postApiCall(url, { assetPlanId, isDeleted }).then((data: any) => data);
    return res;
  }
}
