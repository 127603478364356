import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";



type InitialState={
isChanged:boolean,
isClearPreview:boolean
}

const initialState:InitialState={
  isChanged:false,
  isClearPreview:false
}



export const onChangeSlice=createSlice(
{
    name: 'onChange',
    initialState,
    reducers:{
        changeStatus:(state,action)=>{
            state.isChanged = action.payload as boolean ;
        },
        clearPreview:(state,action)=>{
            state.isClearPreview = action.payload as boolean;
        }
    },
    
    
}
);
export const { changeStatus,clearPreview } = onChangeSlice.actions;
export default onChangeSlice.reducer;