import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { baseUrl } from "../../config/config";
import {postApiCall} from "../../services/apiHelper";


export type UserDetails = {
  id: number,
  firstName: string,
  lastName: string,
  email: string,
  status: string,
  createdBy: string,
  created: Date,
  lastModifiedBy: string,
  lastModified: Date
};

export type DapData = {
  assetPlanId: string,
  isDeleted: boolean,
  dealName: string,
  investmentId: string,
  submittedDate: Date,
  approvedDate: Date,
  fundName: string,
  yearBuilt: number,
  city: string,
  units: number,
  dateAcquired: string,
  investmentType: string,
  rentableArea: number,
  purchasedPrice: number,
  investmentSubtype:string,
  investmentDescription: string,
  appraisedValue: string,
  ownershipType: string,
  primaryLeaseType: string,
  debt: string,
  ownerShip: number,
  jvPartner: string,
  assetManager: UserDetails,
  portfolioManager: UserDetails,
  propertyManager: UserDetails,
  summary: string,
  budgetYear:string,
  status: string,
  lastBudgetUpdate: string,
  propertyType: string
} 

  type InitialState={
    dapData:DapData|null,
    description:string,
    formSubmitted: boolean;
    error:string,
    loading:boolean
}

const initialState:InitialState={
    dapData:null,
    description:'',
    formSubmitted:false,
    error:'',
    loading:false
}

export type PostData= {
  dapId: string
}

export const getInvestmentDetailsById= createAsyncThunk("getInvestmentDetails",async(payload:PostData)=>
{

  try {
    const response = await postApiCall<PostData, DapData>(`${baseUrl}AssetPlan/GetInvestmentDetailsByAssetID`, payload);
    return response;
  } catch (error: any) {
    console.error('Error in POST request:', error);
    throw error;
  }
})

const summaryFormSlice = createSlice({
  name: 'summaryForm',
    initialState,
  reducers: {
    updateFormData(state, action: PayloadAction<DapData>) {
      state.dapData = action.payload;
    
    },
    updateDescriptionData(state, action: PayloadAction<string>) {
      state.description = action.payload;
    
    },
    submitForm(state) {
      state.formSubmitted = true;
    },
    resetForm(state) {
      state.dapData = initialState.dapData;
      state.formSubmitted = initialState.formSubmitted;
    },
  },
  extraReducers:(builder)=>
        {
          // eslint-disable-next-line   
            builder.addCase(getInvestmentDetailsById.pending,(state)=>
            {
              state.loading=true;
            }),
            builder.addCase(getInvestmentDetailsById.fulfilled,(state,action:PayloadAction<DapData>)=>
            {
              state.loading=false;
              state.dapData=action.payload;
              state.error='';
            }),
            builder.addCase(getInvestmentDetailsById.rejected,(state,action)=>
            {
              state.loading=false;
              state.error=action.error.message ||'something went wrong';
            })
        }  
});

export const { updateFormData, submitForm, resetForm : resetSummaryFormAction,updateDescriptionData } = summaryFormSlice.actions;

export const selectFormData = (state) => state.summaryForm.dapData;
export const selectFormSubmitted = (state) => state.summaryForm.formSubmitted;

export default summaryFormSlice.reducer;
