import React, { useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { DashboardService } from "services/DashboardService";

const ViewTabs = (props) => {
  const initialTabIndex = 0;
  const [value, setValue] = useState(initialTabIndex);
  const dashboardService = new DashboardService();
  const emailId = "bryan.morland@nuveen.com"; //bryan.morland@nuveen.com

  const handleChange = (event, newValue) => {
    const viewId = event.target.id;
    props.sendData(viewId);
    setValue(newValue);
  };
  
  return (
    <>
      <Tabs
        sx={{
          ".Mui-selected:hover": {
            color: `white`,
          },
        }}
        value={value}
        onChange={handleChange}
        aria-label=""
      >
        {props.tabData?.map((tabTitle, index) => (
          <Tab label={tabTitle.viewName} id={tabTitle.viewId} key={index} />
        ))}
      </Tabs>
    </>
  );
};

export default ViewTabs;
