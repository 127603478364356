import React, { useState, useEffect } from "react";
import { useMediaQuery, Typography, Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import {
  Timeline,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
  TimelineDot,
  TimelineConnector,
  TimelineContent,
} from "@mui/lab";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { getApiCall } from "../../../services/apiHelper";
import { baseUrl, baseUrl2 } from "../../../config/config";
import { useAppSelector, useAppDispatch } from "../../../redux/hooks";
// import { makeStyles } from '@material-ui/core/styles';
import moment from "moment";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
});

type AbfPortfolioStatus = {
  investmentId: string;
  budgetYear: number;
  userName: string;
  dataType: string;
  yardiLastModifiedTimeStamp: Date;
  status: string;
};
// const useStyles = makeStyles({
//   boldCell: {
//     fontWeight: 'bold',
//   },
// });
//const classes = useStyles();
const AbfStatusControl = () => {
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const assetPlanId = useAppSelector(
    (state) => state.getAssetPlanId.assetPlanId
  );
  const budgetYear = useAppSelector((state) => state.summary?.dapData?.budgetYear);
  const investmentId = useAppSelector((state) => state.summary?.dapData?.investmentId);
  const getAbfStatusLogs = () => {
    var url = `${baseUrl}abf/PorfolioAbfStatus?portfolioId=${investmentId}&budgetYear=${budgetYear}`;
    console.log("url", url);
    const res = getApiCall(url).then((data: any) => {
      return data;
    });
    return res;
  };

  const [AbfStatusLogsData, setAbfStatusLogData] = React.useState<AbfPortfolioStatus[]>([]);

  useEffect(() => {
    getAbfStatusLogs().then((data: AbfPortfolioStatus[]) => {
      console.log(data)
      setAbfStatusLogData([...data]);
    });
  }, [assetPlanId]);

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          border: "1px solid #ccc",
          padding: "10px",
          overflowY: "auto",
        }}
      >
        <Box sx={{ width: "95%" }}>
          <TableContainer >
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell style={{fontWeight:900}} >Investment</TableCell>
                  <TableCell style={{fontWeight:900}}>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {AbfStatusLogsData.map((row) => (
                  <TableRow
                    key={row.investmentId}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.investmentId}
                    </TableCell>
                    <TableCell>{row.status}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default AbfStatusControl;
