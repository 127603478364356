import { InputLabel, Select, MenuItem, Checkbox } from "@mui/material";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";

import TextareaAutosize from "@mui/base/TextareaAutosize";
import { styled } from "@mui/system";
import FormControl from "@mui/material/FormControl";

import React, { useState, useEffect, useRef } from "react";
import { CapitalPlanService } from "services/CapitalPlanService";
import { useAppSelector, useAppDispatch } from "redux/hooks";
import {
  CapitalProject,
  setCapitalProjectsStore,
  getCapitalProjects,
} from "redux/slices/capitalProjectsSlice";
import {
  getCapitalPlanTableData,
  data,
  CapitalPlanTableData,
} from "redux/slices/capitalPlanTableSlice";
import { updateCapitalProject } from "redux/slices/updateDataSlice";
import { currency, numberWithComma } from "../../util/valueFormatter";
import { FroalaRichText } from "components/util/FroalaRichtext";
import { AutosaveDelay, CommentKey, CommentSectionName } from "constant";
import {
  updateDapCommentData,
  DapComment,
} from "redux/slices/updateDapCommentSlice";
import { saveDapCommentData } from "redux/slices/saveCommentSlice";
import { clearDocumentComments } from "components/util/storeManager";
import { postApiCall } from "services/apiHelper";
import { baseUrl } from "config/config";
import { GenericToast } from "components/util/ToastMessage";

interface RowWiseData {
  [key: string]: Array<string | number | number | number | number | number>;
}
export const StyledTextarea = styled(TextareaAutosize)(
  () => `
  font-family: sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 12px;
  border-radius: 12px 12px 0 12px;
`
);
const label = { inputProps: { "aria-label": "Checkbox demo" } };
export const CapitalPlanEditForm = () => {
  const rowWiseData: RowWiseData = {};
  const dispatch = useAppDispatch();

  const assetPlanId = useAppSelector(
    (state) => state.getAssetPlanId.assetPlanId
  );

  const budgetYear = Number(
    useAppSelector((state) => state.summary.dapData?.budgetYear)
  );

  const capitalPlanTableData: CapitalPlanTableData[] = useAppSelector(
    (state) => state.capitalPlanTable.capitalPlan
  );
  const capitalProjectData: CapitalProject[] = useAppSelector(
    (state) => state.capitalProjects.data
  );

  const isNoData: boolean = useAppSelector(
    (state) => state.capitalProjects.isNoData
  );

  const [yearRangeSelected, setYearRangeSelected] = useState<number>(1);
  const [dropdownItems, setDropdownItems] = useState<any[]>([]);
  const capitalPlanService = new CapitalPlanService();
  const columns: string[] = [
    "year",
    "biCapital",
    "leasingCostCommercial",
    "turnCostResidential",
    "totalCapital",
  ];
  const CPColumnNameArray: string[] = [
    "BI Capital",
    "Leasing Cost (Commercial)",
    "Turn Cost (Residential)",
    "Total Capital",
    `Projected NOI ${budgetYear}`,
  ];

  const columnNames: string[] = [
    //change the year from the elements of array with year which is comming from redux store.
    `${budgetYear} Capital`,
    `% of ${budgetYear} NOI`,
    `${budgetYear + 1} Capital`,
    `${budgetYear + 2} Capital`,
    `${budgetYear + 3} Capital`,
    `${budgetYear + 4} Capital`,
  ];

  useEffect(() => {
    dispatch(getCapitalPlanTableData({ assetPlanID: assetPlanId }));
    dispatch(getCapitalProjects({ assetPlanId: assetPlanId }));
    capitalPlanService.getDropdownItems().then((data) => {
      setDropdownItems(data);
    });
  }, [dispatch, assetPlanId, yearRangeSelected]);

  const columnDataToRowData = () => {
    var projectedNoi: number = capitalPlanTableData[0]?.projectedNOI;

    capitalPlanTableData?.forEach((obj) => {
      Object.keys(obj).forEach((key) => {
        if (!rowWiseData[key]) {
          rowWiseData[key] = [];
        }
        rowWiseData[key].push(obj[key]);
        // rowWiseData[key][0] = currency(rowWiseData[key][0])
      });
    });

    columns.forEach((name) => {
      var num = rowWiseData[name]?.at(0);
      if (num) {
        rowWiseData[name]?.splice(1, 0, (Number(num) / projectedNoi) * 100);
      }
    });

    capitalPlanTableData?.forEach((obj) => {
      Object.keys(obj).forEach((key) => {
        rowWiseData[key][0] = currency(rowWiseData[key][0]);
        rowWiseData[key][1] = numberWithComma(rowWiseData[key][1]);
      });
    });
  };
  columnDataToRowData();

  console.log(rowWiseData);
  console.log(capitalPlanTableData);

  const numToMoneyFormat = (x: number) => {
    return "$" + x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const updateProjectPriority = (e: any, id: number) => {
    // make copy of local state obj
    let capitalProjectStateCopy: any = [...capitalProjectData];
    const itemIndex = capitalProjectStateCopy.findIndex((c) => c.id == id);

    // update copy with new changes
    let filteredProject = { ...capitalProjectStateCopy[itemIndex] };
    filteredProject.projectPriority = e.target.value;
    capitalProjectStateCopy[itemIndex] = filteredProject;

    // update local state
    //setCapitalProjectData(capitalProjectStateCopy);

    // pass state to store
    dispatch(setCapitalProjectsStore(capitalProjectStateCopy));

    // send updated project to store
    sendProjectToUpdateDataSlice(filteredProject);
  };

  const updateEsgImpact = (e: any, id: number) => {
    let capitalProjectStateCopy: any = [...capitalProjectData];
    const itemIndex = capitalProjectStateCopy.findIndex((c) => c.id == id);
    let filteredProject = { ...capitalProjectStateCopy[itemIndex] };
    filteredProject.esgImpact = e.target.checked;
    capitalProjectStateCopy[itemIndex] = filteredProject;
    //setCapitalProjectData(capitalProjectStateCopy);
    dispatch(setCapitalProjectsStore(capitalProjectStateCopy));
    // send updated project to store
    sendProjectToUpdateDataSlice(filteredProject);
  };

  const updateComments = (e: any, id: number) => {
    let capitalProjectStateCopy: any = [...capitalProjectData];
    const itemIndex = capitalProjectStateCopy.findIndex((c) => c.id == id);
    let filteredProject = { ...capitalProjectStateCopy[itemIndex] };
    filteredProject.comment = e.target.value;
    capitalProjectStateCopy[itemIndex] = filteredProject;
    //setCapitalProjectData(capitalProjectStateCopy);
    dispatch(setCapitalProjectsStore(capitalProjectStateCopy));
    // send updated project to store
    sendProjectToUpdateDataSlice(filteredProject);
  };

  const sendProjectToUpdateDataSlice = (capitalProject: any) => {
    dispatch(
      updateCapitalProject({
        projectPriority: capitalProject.projectPriority,
        esgImpact: capitalProject.esgImpact,
        comment: capitalProject.comment,
        id: capitalProject.id,
      })
    );
  };

  const comments = useAppSelector((state) => state.dapComments?.data).find(
    (x) => x.commentKey.toLowerCase() === CommentKey.CAPITALPLAN.toLowerCase()
  );

  //const isMounted = useRef(false);
  const handleCommentsChange = (newValue: string) => {
    const updatedcomment: DapComment = {
      id: comments ? comments.id : 0,
      commentKey: comments ? comments.commentKey : CommentKey.CAPITALPLAN,
      commentText: newValue || "",
      sectionName: CommentSectionName.CAPITALPLAN,
      assetPlanId: assetPlanId,
    };
    dispatch(updateDapCommentData(updatedcomment));
    dispatch(saveDapCommentData(updatedcomment));

    // if (isMounted.current) {
    //   dispatch(updateDapCommentData(updatedcomment));
    //   dispatch(saveDapCommentData(updatedcomment));
    // } else {
    //   isMounted.current = true;
    // }

  };

  // const savedData = useAppSelector((state) => state.updateData);
  // const savedComment = useAppSelector(
  //   (state) => state.saveComment.updateDapComments
  // );
  // const [toastMessageOpen, setToastMessageOpen] = useState(false);
  // const [toastMessage, setToastMessage] = useState<toast>({
  //   message: "",
  //   severity: "",
  // });
  // const isMountedAutosave = useRef(false);
  // useEffect(() => {
  //   if (isMountedAutosave.current) {
  //     const promises: Promise<any>[] = [];

  //     const debouncedAutosave = setTimeout(() => {
  //       let savedDataPayload = {
  //         assetPlanId: assetPlanId,
  //         capitalProjectData: savedData.capitalProjectData,
  //         documentData: null,
  //         occupancyCommercial: null,
  //         recommendationTab: null,
  //         status: null,
  //         summary: null,
  //         userAction: "Save",
  //       };
  //       if (savedData) {
  //         console.log("saveddata", savedData);
  //         promises.push(
  //           postApiCall(`${baseUrl}UpdateData/SaveAllData`, {
  //             ...savedDataPayload,
  //           })
  //         );
  //       }
  //       if (savedComment) {
  //         console.log("com", savedComment);
  //         promises.push(
  //           postApiCall(`${baseUrl}DapComments/UpdateDapComments`, savedComment)
  //         );
  //       }
  //       clearDocumentComments(dispatch);
  //       setToastMessage({
  //         message: "Saved successfully",
  //         severity: "success",
  //       });
  //       if (promises.length > 0) {
  //         setToastMessageOpen(false);
  //         Promise.all(promises)
  //           .then((responses) => {
  //             const successResponses = responses.filter(
  //               (response: any) => response.success
  //             );
  //             if (successResponses.length === promises.length) {
  //               setToastMessage({
  //                 message: "Saved successfully",
  //                 severity: "success",
  //               });
  //               setToastMessageOpen(true);
  //             }
  //           })
  //           .catch((error) => {
  //             console.error("Error:", error);
  //             setToastMessage({
  //               message: error.message,
  //               severity: "error",
  //             });
  //             setToastMessageOpen(true);
  //           });
  //       } else {
  //         console.log("No API calls made");
  //       }
  //     }, AutosaveDelay.DELAYTIME);
  //     return () => {
  //       clearTimeout(debouncedAutosave);
  //     };
  //   } else {
  //     isMountedAutosave.current = true;
  //   }
  // }, [savedData, savedComment]);

  console.log(capitalProjectData.length === 0);
  const renderTableData = () => {
    if (capitalProjectData.length !== 0) {
      return capitalProjectData?.map((val, i) => (
        <>
          <TableRow sx={{ textAlign: "center" }} key={i}>
            <TableCell sx={{ width: "10%" }}>
              <FormControl fullWidth>
                <InputLabel>Select</InputLabel>
                <Select
                  sx={{ textAlign: "left" }}
                  //style={{ width: "350px" }}
                  //labelId="demo-simple-select-label"
                  //id="demo-simple-select1"
                  value={val.projectPriority ? val.projectPriority : " "}
                  label="Self"
                  onChange={(e) => updateProjectPriority(e, val.id)}
                >
                  {dropdownItems.map((element) => (
                    <MenuItem value={element} key={element}>
                      {element}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </TableCell>
            <TableCell sx={{ width: "5%", textAlign: "center !important" }}>
              <Checkbox
                checked={val.esgImpact}
                onChange={(e) => updateEsgImpact(e, val.id)}
              />
            </TableCell>
            <TableCell sx={{ width: "11%" }}>{val.projectId}</TableCell>
            <TableCell sx={{ width: "8.5%", textAlign: "right !important" }}>
              {currency(val.currentBudget)}
            </TableCell>
            <TableCell sx={{ width: "15.5%" }}>
              {val.projectDescription}
            </TableCell>
            <TableCell sx={{ width: "20%" }}>
              <StyledTextarea
                sx={{ width: "100%" }}
                minRows={2}
                defaultValue={val.comment}
                onChange={(e) => updateComments(e, val.id)}
              />
            </TableCell>
          </TableRow>
        </>
      ));
    }
  };
  console.log(rowWiseData);
  
  return (
    <div>
      {/* {toastMessageOpen && (
        <GenericToast
          message={toastMessage.message}
          severity={toastMessage.severity}
        />
      )} */}
      <div className="tab-heading text-center">
        <h3 className="heading">Detailed Building Capital Plan</h3>
      </div>

      <div className="responsive-table-wrapper">
        <Table id="customers" className="responsive-table">
          <TableRow>
            <th>Project Priority</th>
            <th style={{ textAlign: "center" }}>{budgetYear <= 2024? `ESG Impact `: `Contributes to SSBB feature`}</th>
            <th>Project ID</th>
            <th style={{ textAlign: "right" }}>{budgetYear} Budget</th>
            <th>Project Description</th>
            <th>Comments</th>
          </TableRow>
          <TableBody>{renderTableData()}</TableBody>
          {isNoData && (
            <TableRow>
              <TableCell colSpan={6} sx={{ textAlign: "center !important" }}>
                No rows
              </TableCell>{" "}
            </TableRow>
          )}
        </Table>
      </div>

      <div className="tab-heading text-center">
        <h3 className="heading">Capital Plan</h3>
      </div>

      {/* <div className="capitalPlanYearDropDown">
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Number Of Year</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={yearRangeSelected}
            label="numberOfYear"
            onChange={(e) => {
              console.log(e);
              setYearRangeSelected(Number(e.target.value));
            }}
          >
            <MenuItem value={1}>1</MenuItem>
            <MenuItem value={2}>2</MenuItem>
            <MenuItem value={3}>3</MenuItem>
            <MenuItem value={4}>4</MenuItem>
            <MenuItem value={5}>5</MenuItem>
          </Select>
        </FormControl>
      </div> */}
      <div>
        <table id="customers">
          <tr>
            <th></th>

            {columnNames
              ?.filter((_, i) => i < 1 + yearRangeSelected)
              .map((ele, index) => (
                <th style={{ textAlign: "right" }} key={index}>
                  {ele}
                </th>
              ))}
          </tr>
          <tbody>
            <tr>
              <th className="grey-bg">BI Capital</th>
              {rowWiseData.biCapital
                ?.filter((_, i) => i < 1 + yearRangeSelected)
                .map((ele, index) => (
                  <td style={{ textAlign: "right" }} key={index}>
                    {ele}
                  </td>
                ))}
            </tr>
            <tr>
              <th className="grey-bg">Leasing Cost (Commercial)</th>
              {rowWiseData.leasingCostCommercial
                ?.filter((_, i) => i < 1 + yearRangeSelected)
                .map((ele, index) => (
                  <td style={{ textAlign: "right" }} key={index}>
                    {ele}
                  </td>
                ))}
            </tr>
            <tr>
              <th className="grey-bg">Turn Cost (Residential)</th>
              {rowWiseData.turnCostResidential
                ?.filter((_, i) => i < 1 + yearRangeSelected)
                .map((ele, index) => (
                  <td style={{ textAlign: "right" }} key={index}>
                    {ele}
                  </td>
                ))}
            </tr>
            <tr>
              <th className="grey-bg">Total Capital</th>
              {rowWiseData.totalCapital
                ?.filter((_, i) => i < 1 + yearRangeSelected)
                .map((ele, index) => (
                  <td style={{ textAlign: "right" }} key={index}>
                    {ele}
                  </td>
                ))}
            </tr>
            <tr>
              <th className="grey-bg">Projected NOI {budgetYear}</th>
              {rowWiseData.projectedNOI
                ?.filter((_, i) => i < 1 + yearRangeSelected)
                .map((ele, index) => (
                  <td style={{ textAlign: "right" }} key={index}>
                    {ele}
                  </td>
                ))}
            </tr>
          </tbody>
        </table>
      </div>

      <form className="m-20 lease-form">
        <fieldset>
          <legend className="lease-title">Comments</legend>

          <FroalaRichText
            model={comments?.commentText || ""}
            handlesModelChange={handleCommentsChange}
          />
        </fieldset>
      </form>
    </div>
  );
};
