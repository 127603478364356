import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import FormControl from "@mui/material/FormControl";
import { UserRoleMapService } from "../../../services/common-services";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Button from "@mui/material/Button";
import { styled } from "@mui/system";
import { useForm, Controller } from "react-hook-form";
import Grid from "@mui/material/Grid";
import { GenericToast } from "components/util/ToastMessage";

interface UserRoleMap {
  firstName: string;
  lastName: string;
  email: string;
  status: string;
}

export const UserRoleMapcomponent = (props) => {
  const navigate = useNavigate();
  const params = useParams();
  const page = params.page;
  const userId = params.userRoleId;
  const Label = styled("label")({
    display: "block",
  });
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();
  const userRoleMapServices = new UserRoleMapService();
  // const [roles, setRoles] = useState([]);
  const [userDetails, setUserDetails] = useState<UserRoleMap[]>([]);
  const [toastMessageOpen, setToastMessageOpen] = useState(false);
  const [toastMessageErrorOpen, setToastMessageErrorOpen] = useState(false);
  const [selectedEmailValue, setSelectedEmailValue] = useState("");
  const [selectedRole, setSelectedRole] = useState("");
  let [selectedStatus, setSelectedStatus] = useState({ status: "Active" });
  let [userDetailById, setUserDetailById] = useState<UserRoleMap>({
    firstName: "",
    lastName: "",
    email: "",
    status: "",
  });

  useEffect(() => {
    // userRoleMapServices.getRoles().then((data: any) => {
    //   console.log(data);
    //   setRoles(data);
    // });

    userRoleMapServices.getUserDetails().then((data: any) => {
      setUserDetails(data);
    });

    if (userId) {
      const UserIdResponse = {
        userRoleId: userId,
      };
      userRoleMapServices
        .getUserDetailsBasedOnId(UserIdResponse)
        .then((data: any) => {
          console.log(data);
          setUserDetailById({
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.email,
            status: data.status,
          });
          setSelectedRole(data.role);
          setSelectedEmailValue(data.email);
          setSelectedStatus({ status: data.status });
        });
    }
  }, [userId]);

  // const rolesDropDown = roles.map((data: any) => data.role);
  const handleUserDetailChange = (event) => {
    //setSelectedEmailValue(event.email);
    console.log(event)
    if (event != null) {
      setUserDetailById({
        firstName: event.firstName,
        lastName: event.lastName,
        email: event.email,
        status: selectedStatus.status,
      });
    }

  };

  const handleRoleDetailChange = (event) => {
    setSelectedRole(event);
    console.log(event);
  };

  const handleStatusDetailChange = (event) => {
    console.log(event)
    setSelectedStatus(event);
  };

  const SubmitUserDetails = (data) => {
    setToastMessageOpen(false);
    setToastMessageErrorOpen(false)
    const SubmitedValue = {
      id: userId,
      role: selectedRole,
      // firstName: userDetailById.firstName,
      // lastName: userDetailById.lastName,
      // userName: userDetailById.firstName + " " + userDetailById.lastName,
      email: userDetailById.email,
      status: selectedStatus.status,
    };
    console.log(SubmitedValue)
    userRoleMapServices.submitUserRoleMap(SubmitedValue).then(() => setToastMessageOpen(true))
      .catch(() => setToastMessageErrorOpen(true));
  };

  return (
    <>
      {toastMessageOpen && (
        <GenericToast
          message="User Details Updated"
          severity="success"
        />
      )}
      {toastMessageErrorOpen && (
        <GenericToast
          message="Error Updating User Details. Please Try Again"
          severity="error"
        />
      )}
      <FormControl className="user-role-form">
        <Grid item xs={12} sm={12}>
          <Label>Role</Label>
          <TextField
            disabled
            value={selectedRole}
            sx={{
              width: 300
            }}
          />
          {/* <Controller
            name="roles"
            control={control}
            // rules={{ required: "roles is required." }}
            render={({ field, fieldState: { error } }) => (        
              <Autocomplete
                options={UserDashboardRoles}
                value={selectedRole}
                readOnly={page === "view"}
                onChange={(event, value) => {
                  field.onChange(value);
                  handleRoleDetailChange(value);
                }}
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} error={!!error} />
                )}
              />
            )}
          /> */}
        </Grid>
        <Grid item xs={12} sm={12}>
          <Label>User Details</Label>
          <TextField
            disabled
            value={userDetailById.firstName + " " + userDetailById.lastName}
            sx={{
              width: 300
            }}
          />
          {/* <Controller
            name="userDetails"
            control={control}
            // rules={{ required: "userDetails is required." }}
            render={({ field, fieldState: { error } }) => (
              <Autocomplete
                options={userDetails}
                value={userDetailById}
                readOnly={page === "view"}
                getOptionLabel={(option: any) => {
                  return option.firstName + " " + option.lastName;
                }}
                onChange={(event: any, value) => {
                  field.onChange(value);
                  handleUserDetailChange(value);
                }}
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} error={!!error} />
                )}
              />
            )}
          /> */}
        </Grid>
        <Grid item xs={12} sm={12}>
          <Label>Email</Label>
          <TextField
            sx={{ width: 300 }}
            // required
            disabled={page === "view"}
            onChange={(e) => setUserDetailById({
              firstName: userDetailById.firstName,
              lastName: userDetailById.lastName,
              email: e.target.value,
              status: selectedStatus.status,
            })}
            value={userDetailById.email}
            InputProps={{
              readOnly: false,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <RadioGroup
            row
            aria-labelledby="demo-radio-buttons-group-label"
            value={selectedStatus.status}
            name="radio-buttons-group"
            onChange={(event, value) => {
              handleStatusDetailChange({ status: value });
            }}
          >
            <FormControlLabel
              disabled={page === "view"}
              value="Active"
              control={<Radio />}
              label="Active"
            />
            <FormControlLabel
              disabled={page === "view"}
              value="Inactive"
              control={<Radio />}
              label="Inactive"
            />
          </RadioGroup>
        </Grid>

        <Grid item xs={12}>
          <Button
            variant="contained"
            size="medium"
            color="success"
            sx={{ mt: 1, mr: 1 }}
            type="submit"
            disabled={page === "view"}
            onClick={handleSubmit(SubmitUserDetails)}
          >
            Submit
          </Button>
          <Button
            variant="outlined"
            size="medium"
            color="error"
            sx={{
              //color: "red",
              mt: 1, ml: 2, mr: 1,
              "&.MuiButton-root:hover": { backgroundColor: '#B00020 !important', },
            }}
            type="submit"
            onClick={() => {
              navigate("/userrolemap/list");
            }}
          >
            Cancel
          </Button>
        </Grid>
      </FormControl>
    </>
  );
};
