import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from 'axios';
import { baseUrl } from "../../config/config";

type AssetPlanId =
{
    assetPlanId : string
}

type InitialState={
    assetPlanId:string,
}

const initialState:InitialState={
    assetPlanId:'',
}

export type requestAssetPlanID =
{
    investmentId: string,
    budgetYear: string,
}


const getAssetPlanIdSlice=createSlice(
    {
        name: 'getAssetPlanId',
        initialState,
        reducers:{
            getAssetPlanId(state,action){
                state.assetPlanId=action.payload;
            }
        }, 
        
    }
);
export const { getAssetPlanId } = getAssetPlanIdSlice.actions;
export default getAssetPlanIdSlice.reducer;
