import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import {postApiCall} from "../../services/apiHelper";
import { baseUrl } from "../../config/config";

export interface UserData {
  userName: string | null;
  isAdmin: boolean;
  isAnalyst: boolean;
  roles: string[];
  sectorRoleMap: SectorRoleMap[];
  success: boolean;
  error: string | null;
  userId: number | null;
}

export interface SectorRoleMap {
  sectorName: string;
  roleList: string[];
}

interface UserState {
  data: UserData;
  loading: boolean;
  error: string | null;
}

const initialState: UserState = {
  data: {
    userName: null,
    isAdmin: false,
    isAnalyst: false,
    sectorRoleMap: [],
    roles: [],
    success: true,
    error: null,
    userId: null
  },
  loading: false,
  error: null,
};


let cacheKey: string | null = null;
export const getUserData = createAsyncThunk<UserData, string, { state: RootState }>(
    "getUserData",
    async (userEmail, { getState, rejectWithValue }) => {
      const state = getState().userSectorDetail;
  
      if (state.data!=null && cacheKey !== null) {
        // Return the current data if it exists in the state and cacheKey is set
        return state.data;
      }
      if (state.data===null && cacheKey === userEmail) {
        // Return an empty array if there is no data in both the state and the cache
        return {} as UserData;
      }
  
      try {
        const request = { Email: userEmail };
        const response = await postApiCall<{Email:string}, UserData>(`${baseUrl}UpdateData/GetUserSectorDetails`, request);
        cacheKey = userEmail; // Set the cacheKey after successful API call
        return response;
      } catch (error: any) {
        console.error("Error in GET request:", error.message);
        throw error;
      }
    }
  );
  


  const userSectorDetailSlice = createSlice({
    name: 'userSectorDetail',
    initialState,
    reducers: {
      // Define your other reducers here
    },
    extraReducers: (builder) => {
      builder
        .addCase(getUserData.pending, (state) => {
          state.loading = true;
          state.error = null;
        })
        .addCase(getUserData.fulfilled, (state, action: PayloadAction<UserData>) => {
          state.loading = false;
          state.data = action.payload;
        })
        .addCase(getUserData.rejected, (state, action) => {
          state.loading = false;
          state.error = action.error.message || 'Something went wrong.';
        });
    },
  });

export default userSectorDetailSlice.reducer;
