import * as React from "react";
import {
  DataGridPro,
  GridValueFormatterParams,
  GridColDef,
  GridRowParams,
  GridActionsColDef,
  GridPaginationModel,
  GridToolbarFilterButton,
  GridAlignment,
} from "@mui/x-data-grid-pro";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import "./genericDatagrid.css";

export interface Row {
  id?: number;
  [key: string]: any;
}

export interface ColumnConfig<T> {
  field: keyof T;
  headerName: string;
  width?: number;
  flex?: number;
  type?: string;
  valueGetter?: (params: GridValueFormatterParams<T>) => any;
  valueFormatter?: (params: GridValueFormatterParams<T>) => string | number;
  hideSeparator?: boolean;
  getActions: (params: GridRowParams) => any;
  headerAlign? : GridAlignment;
  align? : GridAlignment;
}

interface GenericDataGridProps<T> {
  rows: T[];
  columnConfig: ColumnConfig<T>[];
  getRowId: (row: T) => string | number;
  hideFooter: boolean;
  rowCount?: number;
  paginationModel?: {
    page: number;
    pageSize: number;
  };
  onPaginationModelChange?: (e: GridPaginationModel) => any;
  slots?: { toolbar: typeof GridToolbarFilterButton };
  pageSizeOptions?: number[];
}
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const StyledDataGrid = styled(DataGridPro)(() => ({
  border: 0,
  WebkitFontSmoothing: 'auto',
  letterSpacing: 'normal',
  '& .MuiDataGrid-columnsContainer': {
    backgroundColor: '#1d1d1d',
  },
  '& .MuiDataGrid-iconSeparator': {
    display: 'none',
  },
  '& .MuiDataGrid-columnHeader': {
    border: `1px solid #ddd`,
    borderCollapse: 'collapse',
  },
  '& .MuiDataGrid-cell': {
    borderRight: `1px solid #ddd`,
  },
  '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
    borderBottom: `1px solid #ddd`,
  },
  '& .MuiPaginationItem-root': {
    borderRadius: 0,
  }
}));

export function GenericDataGrid<T extends Row>({
  rows,
  columnConfig,
  getRowId,
  hideFooter,
  rowCount,
  paginationModel,
  onPaginationModelChange,
  slots,
}: GenericDataGridProps<T>) {
  const columns: GridColDef[] = columnConfig.map((config, index) => {
    if (config.type === "actions") {
      const column: GridActionsColDef = {
        field: String(config.field),
        //headerName: config.headerName,
        //width: config.width || 150,
        flex: config.flex || 1,
        type: "actions",
        sortable: false,
        getActions: config.getActions,
      };
      return column;
    }

    const column: GridColDef = {
      field: String(config.field),
      headerName: config.headerName,
      //width: config.width || 150,
      flex: config.flex || 1,
      type: config.type || "string",
      sortable: false,
      headerAlign: config.headerAlign,
      align: config.align,
      renderHeader: (params) => (
        <div>
          {params.colDef.headerName}
          {!config.hideSeparator && index < columnConfig.length - 1 && (
            <div className="column-separator" />
          )}
        </div>
      ),
    };

    if (config.valueGetter) {
      column.valueGetter = config.valueGetter;
    }

    if (config.valueFormatter) {
      column.valueFormatter = (params) => {
        const value = config.valueFormatter?.(params);
        return value !== undefined ? String(value) : "";
      };
    }

    return column;
  });

  React.useEffect(() => {
    const draggableContainer = document.querySelector(
      ".MuiDataGrid-columnHeaderDraggableContainer"
    ) as HTMLElement;
    if (draggableContainer) {
      draggableContainer.style.height = "0px"; // Set the desired height for the draggable container
    }
  }, []);
  return (
    <div style={{ width: "100%"}}>

      <DataGridPro
        //className="custom-data-grid" // Set the desired height for the header rows
        sx={{
          backgroundColor: 'white',
          '& .MuiDataGrid-columnHeader': {
            backgroundColor: '#253746',
            color: 'white',
          },

      }}
        disableColumnMenu={true}
        rows={rows}
        columns={columns}
        hideFooter={hideFooter}
        getRowId={getRowId}
        pagination
        paginationMode="client"
        rowCount={rowCount}
        paginationModel={paginationModel}
        onPaginationModelChange={onPaginationModelChange}
        // pageSizeOptions={[5, 10, 25]}
        slots={slots}
        disableRowSelectionOnClick
        showCellVerticalBorder={true}
        showColumnVerticalBorder={true}
        autoHeight
      />
    </div>
  );
}
