import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { baseUrl } from "../../config/config";
import { getApiCall } from "../../services/apiHelper";

type DistinctInvestment = {
  investmentID: string;
  investmentName: string;
};

type InitialState = {
  loading: boolean;
  disctinctInvestments: Array<string>;
  error: string;
};

const initialState: InitialState = {
  loading: false,
  disctinctInvestments: [],
  error: "",
};

export const getInvestments = createAsyncThunk(
  "getDistinctInvestment",
  async () => {
    const response = await getApiCall<DistinctInvestment[]>(
      `${baseUrl}AssetPlan/GetDistinctInvestments`
    );
    return response.map((d) => d.investmentID + " - " + d.investmentName);
  }
);

export const getDistinctInvestmentSlice = createSlice({
  name: "getDistinctInvestment",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // eslint-disable-next-line
    builder.addCase(getInvestments.pending, (state) => {
      state.loading = true;
    }),
      builder.addCase(
        getInvestments.fulfilled,
        (state, action: PayloadAction<Array<string>>) => {
          state.loading = false;
          state.disctinctInvestments = action.payload;
        }
      ),
      builder.addCase(getInvestments.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "something went wrong";
      });
  },
});

export default getDistinctInvestmentSlice.reducer;
