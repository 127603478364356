export const baseUrl2 = "https://nre-dev.dap.test.nuveen.com/api/api/";
export const baseUrl1 = "https://vpce-0a619950d7503caf4-2z40ieq9.execute-api.us-east-1.vpce.amazonaws.com/api/api/";
export const headers = { "Content-Type": "application/json", "x-apigw-api-id": "frose4q0sg", Authorization: "Bearer token123" };
export const froalaKey = "RGI4oC10A8A6C5D4F3E3gKTRe2CG1PGe1DESAe1Kg1EBC1Pe2TKoF4I4B3A10A3B5A3B2A3G3==";
export const muiKey = "e7fbe3c2db0456ff00d9a55f95f9338aTz02NTE4NSxFPTE3MTQwMzQ2MzcxNDksUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=";

export const getApiBaseEndPoint = () => {
    const sessionConfig = localStorage.getItem("config");
    if (sessionConfig === null) {
        console.log("App settings (msal) are not loaded into the session store yet!");
        return "";
    }

    const appSettings = JSON.parse(sessionConfig);
    const protectedResources = appSettings?.protectedResources;    
    return protectedResources?.dapApi?.baseEndPoint;
}
  export const baseUrl = getApiBaseEndPoint();
//export const baseUrl = "https://localhost:50297/api/";
