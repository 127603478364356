const swotTemplate = `
<!DOCTYPE html>
<html>
  <head> </head>
  <body>
    <table id="table-comment"> 
    <h2 class="pdf-table-heading">SWOT Analysis</h2>
    <tr>
    <th ><h3>Asset/Market/Tenancy/Competition:</h3></th>
    </tr>
      <tr>
        <th >Strengths</th>
      </tr>
      <tr>
        <td>
        {{{strengthComment}}}
        </td>
      </tr>
      <tr>
        <th >Weakness</th>
      </tr>
      <tr>
        <td>
        {{{weaknessComment}}}
        </td>
      </tr>
      <tr>
        <th>Opportunities</th>
      </tr>
      <tr>
        <td>
        {{{oppurtunitiesComment}}}
        </td>
      </tr>
      <tr>
      <th>Threats</th>
    </tr>
    <tr>
      <td>
      {{{threatsComment}}}
      </td>
    </tr>
    </table>
  </body>  
</html>
       `;

  export default swotTemplate;