import Button from "@mui/material/Button";
import React from "react";
import Stack from "@mui/material/Stack";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import { showCreateDapPopup } from "../redux/slices/dialogFormSlice";
//import { showDialogForm } from '../components/newFormDialog/newFormDialogForm.actions';
import { NavLink } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "redux/hooks";
import { resetAssetPlanState } from "components/util/storeManager";

const headerClass: any = {
  logoHeading: {
    textAlign: "left",
    fontWeight: 800,
  },
  introButton: {
    textAlign: "right",
    padding: "8px",
    float: "right",
  },
};

// function signOutClickHandler(instance) {
//   const logoutRequest = {
//     postLogoutRedirectUri: "/",
//   };
//   instance.logoutRedirect(logoutRequest);
// }

export const Nav = () => {
  // const { instance } = useMsal();
  // const [open, setOpen] = React.useState(false);
  // const handleClickOpen = () => {
  //   setOpen(true);
  // };
  // const handleClose = () => {
  //   setOpen(false);
  // };

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const isAdmin = useAppSelector(
    (state) => state.userSectorDetail.data.isAdmin
  );
  const isAnalyst = useAppSelector(
    (state) => state.userSectorDetail.data.isAnalyst
  );

  const roles = useAppSelector((state) => state.userSectorDetail.data.roles);

  const isAssetManager = roles.includes("Asset Manager");

  const showIntroForm = () => {
    dispatch(showCreateDapPopup());
  };

  // const goToUserRoleMap = () => {
  //   navigate("/userrolemap/list");
  // };

  // const showHoldSellDocuments = () => {
  //   navigate("/documents");
  // };

  const goToDashboard = () => {
    resetAssetPlanState(dispatch);
    // dispatch(resetPropertyImagesAction());
    // dispatch(resetLatestRecomStateAction());
    // dispatch(resetDapCommentStateAction());
    // dispatch(resetSaveCommentStateAction());
    // dispatch(resetSaveDataStateAction());
    // dispatch(resetCapitalProjectsStore());
    // dispatch(resetLeaseExpirationAction());
    // dispatch(resetKeyTenantStateAction());
    // dispatch(resetSummaryFormAction());
    //navigate("/");
  };

  const path = window.location.pathname;
  const highlightedCss = !path.startsWith("/dashboard")
    ? "dropbtn-selected dropbtn"
    : "dropbtn";
  console.log(highlightedCss);

  return (
    <div className="navbar">
      <NavLink onClick={goToDashboard} to="/dashboard">
        Dashboard
      </NavLink>
      {isAdmin && (
        <div className="dropdown">
          <button className={highlightedCss}>Settings</button>
          <div className="dropdown-content">
            <NavLink to="userrolemap/list">User Role Map</NavLink>
            <NavLink to="clone/data">Clone</NavLink>
            {/* //<NavLink to="delegation/data">Delegation</NavLink> */}
          </div>
        </div>
      )}
      <div className="text-right mt-05">
        <Stack spacing={2} direction="row">
          {path === "/dashboard" &&
            (isAnalyst || isAdmin || isAssetManager) && (
              <Button
                sx={{ margin: -0.25, padding: "2px 14px" }}
                variant="contained"
                className="primary headerClass"
                onClick={showIntroForm}
              >
                <AddIcon sx={{ marginRight: "2.5px", marginLeft: "-4px" }} /> New DAP
                {/* New DAP */}
              </Button>
            )}
        </Stack>
      </div>
    </div>
  );
};
