import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { baseUrl,headers } from "../../config/config";
import { postApiCall, postApiCallWithRetryLogic} from "../../services/apiHelper";
import {PostData} from './commertialLeasingSlice';

export interface KeyTenant {
  investmentId: string;
  keyTenant: string;
  totalSF: string;
  investmentPercent: number;
  rent: number;
  expirationDate: string;
  totalSales: number;
  options: string;
  salesPeriod: string;
}

interface KeyTenantsState {
  keyTenants: KeyTenant[]|null;
  error: string;
  loading: boolean;
  hasDataBeenRequested: boolean;

}

const initialState: KeyTenantsState = {
  hasDataBeenRequested: false,
  keyTenants: null,
  error: "",
  loading: false,
};


export const getKeyTenants= createAsyncThunk("getKeyTenants",async(payload:PostData)=>
{

  try {
    const response = await postApiCallWithRetryLogic<PostData, KeyTenantsState>(`${baseUrl}Leasing/GetKeyTenants`, payload);
    return response.keyTenants;
  } catch (error: any) {
    console.error('Error in POST request:', error);
    throw error;
  }
})

const keyTenantsSlice = createSlice({
  name: "keyTenants",
  initialState,
  reducers: {
    resetState: (state) => {
      state.keyTenants = null;
      state.error = "";
      state.loading = false;
      state.hasDataBeenRequested = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getKeyTenants.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        getKeyTenants.fulfilled,
        (state, action: PayloadAction<KeyTenant[] | null>) => { // Update the action payload type
          state.loading = false;
          state.keyTenants = action.payload; // Update the state property name
          state.error='';
          state.hasDataBeenRequested = true;
        }
      )
      .addCase(getKeyTenants.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Something went wrong";
      });
  },
});

export const { resetState: resetKeyTenantStateAction} = keyTenantsSlice.actions; 
export default keyTenantsSlice.reducer;
