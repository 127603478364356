import handlebars from "handlebars";
import recommendationTemplate from "../templates/recommendationTemplate";
import recommendationTemplate2025 from "../templates/recommendationTemplate2025";
import { CommentSectionName } from "constant";

export const compileRecommendationTemplate = (recomData: any[], comments: any[], budgetYear: number): string => {

  if (budgetYear > 2024) {
    var compileRecommendationTemplate = handlebars.compile(recommendationTemplate2025);
  } else {
    compileRecommendationTemplate = handlebars.compile(recommendationTemplate);
  }

  const initialSelectedOptions: any = {};
  const recomPayLoadArray = recomData || [];
  Object.values(recomPayLoadArray).forEach((rec: any) => {
    initialSelectedOptions[rec.category + "_" + rec.key] = {
      text: rec.value,
      value: rec.value,
      assetManagerRationale: rec.assetManagerRationale,
      order: "",
    };
  });
  console.log("recomComment", comments);

  const RecommendationComment = comments?.find(
    (x) => (x.sectionName?.toLowerCase() === CommentSectionName.MISSIONSTATEMENT.toLowerCase() && x.commentKey?.toLowerCase() === initialSelectedOptions?.Investment_Key1?.text?.toLowerCase())
  );
  const templateData = {
    recomendationData: initialSelectedOptions,
    recommendationComment: RecommendationComment
  };
  return compileRecommendationTemplate(templateData);

};

