import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface CurrentUser {
    name: string | undefined,
    email: string | undefined,
    tenantId: string | undefined
}

interface InitialState {
    data: CurrentUser,
    error: string,
    loading: boolean
}
const initialState: InitialState =
{
    data: {} as CurrentUser,
    error: '',
    loading: false
}

const currentUserSlice = createSlice({
    name: "currentUser",
    initialState,
    reducers: {
        updateCurrentUser(state, action: PayloadAction<CurrentUser>) {
            state.data = action.payload;
        },
    },
});

export const { updateCurrentUser } = currentUserSlice.actions;

export default currentUserSlice.reducer;