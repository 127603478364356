import handlebars from "handlebars";
import valuationTemplate from "../templates/valuationTemplate";
import { getValuationHistory,getComments } from "../util/printapiCallhelper";
import { CommentKey } from "constant";

const eq = (a: any, b: any) => a === b;
// Register the 'eq' helper with Handlebars
handlebars.registerHelper("eq", eq);
export const formatDate = (dateStr: string) => {
  const date = new Date(dateStr);
  const month = date.toLocaleString("default", { month: "short" });
  const day = date.getDate();
  const year = date.getFullYear();
  return `${month}-${day}-${year}`;
};

// Helper function to format numbers with thousand separators
const formatNumber = (number: number) => {
  return new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
  }).format(number);
};


export const compileValuationTemplate =  (
  valuationData: any[],
  propertyType: string,
  comments:any[]
): string=> {
  const compiledValuationTemplate = handlebars.compile(valuationTemplate);

  try {

    console.log("ValuationData", valuationData);
    // Format the valuationData as needed
    const formattedData = valuationData.map((data) => ({
      appraisaL_DATE: formatDate(data.appraisaL_DATE),
      appraisaL_AMOUNT: formatNumber(parseFloat(data.appraisaL_AMOUNT)),
      peR_SQFT_APPRAISAL_AMT: formatNumber(
        parseFloat(data.peR_SQFT_APPRAISAL_AMT)
      ),
      peR_UNIT_APPRAISAL_AMT: formatNumber(
        parseFloat(data.peR_UNIT_APPRAISAL_AMT)
      ),
      implieD_CAP_RATE: formatNumber(parseFloat(data.implieD_CAP_RATE)),
      exiT_CAP_RATE: formatNumber(parseFloat(data.exiT_CAP_RATE)),
      discounT_RATE: formatNumber(parseFloat(data.discounT_RATE)),
    }));
    const valuationComment=comments?.find(
      (x) => x.commentKey?.toLowerCase() === CommentKey.VALUATION.toLowerCase()
    );
    console.log("valuationComment",valuationComment);
    // Data for template
    const templateData = {
      valuationData: formattedData,
      propertyType: propertyType,
      valuationComment:valuationComment
    };

    // Return the compiled HTML using the fetched content
    return compiledValuationTemplate(templateData);
  } catch (error) {
    console.error("Error compiling valuation template:", error);
    throw error; // Rethrow the error to be handled by the caller
  }
};


