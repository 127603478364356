import React, { useState, useEffect, useRef } from "react";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import { FroalaRichText } from "../../util/FroalaRichtext";

import { useAppSelector, useAppDispatch } from "../../../redux/hooks";
import { AutosaveDelay, CommentKey } from "../../../constant";
import { saveDapCommentData } from "../../../redux/slices/saveCommentSlice";
import {
  updateDapCommentData,
  DapComment,
} from "../../../redux/slices/updateDapCommentSlice";

// Require Editor JS files.
import "froala-editor/js/froala_editor.pkgd.min.js";
import "froala-editor/js/plugins.pkgd.min.js";
import "froala-editor/js/third_party/embedly.min.js";
// import "froala-editor/js/plugins/fullscreen.min.js"

// Require Editor CSS files.
import "froala-editor/css/froala_style.min.css";
import "froala-editor/css/froala_editor.pkgd.min.css";
import "froala-editor/css/third_party/embedly.min.css";
import Skeleton from "@mui/material/Skeleton";


// for grid
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  color: theme.palette.text.secondary,
}));

export const SwotEditForm = () => {
  const dispatch = useAppDispatch();
  const assetPlanId = useAppSelector(
    (state) => state.getAssetPlanId.assetPlanId
  );
  const swotStatus = useAppSelector((state) => state.dapComments?.loading);
  const strengthComment = useAppSelector(
    (state) => state.dapComments?.data
  ).find(
    (x) => x.commentKey.toLowerCase() === CommentKey.STRENGTH.toLowerCase()
  );
  const weaknessComment = useAppSelector(
    (state) => state.dapComments?.data
  ).find(
    (x) => x.commentKey.toLowerCase() === CommentKey.WEAKNESS.toLowerCase()
  );
  const oppurtunitiesComment = useAppSelector(
    (state) => state.dapComments?.data
  ).find(
    (x) => x.commentKey.toLowerCase() === CommentKey.OPPURTINITIES.toLowerCase()
  );
  const threatsComment = useAppSelector(
    (state) => state.dapComments?.data
  ).find(
    (x) => x.commentKey.toLowerCase() === CommentKey.THREATS.toLowerCase()
  );

  const isMountedStrength = useRef(false);
  const handlestrengthsModelChange = (model: any) => {
    const updatedcomment: DapComment = {
      id: strengthComment ? strengthComment.id : 0,
      commentKey: strengthComment
        ? strengthComment.commentKey
        : CommentKey.STRENGTH,
      commentText: model || "",
      sectionName: "Swot",
      assetPlanId: assetPlanId,
    };
    if (isMountedStrength.current) {
      dispatch(updateDapCommentData(updatedcomment));
      dispatch(saveDapCommentData(updatedcomment));
    } else {
      isMountedStrength.current = true;
    }
  };

  const isMountedWeakness = useRef(false);
  const handleweaknessModelChange = (model: any) => {
    const updatedcomment: DapComment = {
      id: weaknessComment ? weaknessComment.id : 0,
      commentKey: weaknessComment
        ? weaknessComment.commentKey
        : CommentKey.WEAKNESS,
      commentText: model || "",
      sectionName: "Swot",
      assetPlanId: assetPlanId,
    };
    if (isMountedWeakness.current) {
      dispatch(updateDapCommentData(updatedcomment));
      dispatch(saveDapCommentData(updatedcomment));
    } else {
      isMountedWeakness.current = true;
    }
  };

  const isMountedOpps = useRef(false);
  const handleopportunitiesModelChange = (model: any) => {
    const updatedcomment: DapComment = {
      id: oppurtunitiesComment ? oppurtunitiesComment.id : 0,
      commentKey: oppurtunitiesComment
        ? oppurtunitiesComment.commentKey
        : CommentKey.OPPURTINITIES,
      commentText: model || "",
      sectionName: "Swot",
      assetPlanId: assetPlanId,
    };
    if (isMountedOpps.current) {
      dispatch(updateDapCommentData(updatedcomment));
      dispatch(saveDapCommentData(updatedcomment));
    } else {
      isMountedOpps.current = true;
    }
  };

  const isMountedThreats = useRef(false);
  const handlethreatsModelChange = (model: any) => {
    const updatedcomment: DapComment = {
      id: threatsComment ? threatsComment.id : 0,
      commentKey: threatsComment
        ? threatsComment.commentKey
        : CommentKey.THREATS,
      commentText: model || "",
      sectionName: "Swot",
      assetPlanId: assetPlanId,
    };
    dispatch(updateDapCommentData(updatedcomment));
    dispatch(saveDapCommentData(updatedcomment));
    // if (isMountedThreats.current) {
    //   dispatch(updateDapCommentData(updatedcomment));
    //   dispatch(saveDapCommentData(updatedcomment));
    // } else {
    //   isMountedThreats.current = true;
    // }
  };

  // const savedComment = useAppSelector(
  //   (state) => state.saveComment.updateDapComments
  // );
  // const [toastMessageOpen, setToastMessageOpen] = useState(false);
  // const [toastMessage, setToastMessage] = useState<toast>({
  //   message: "",
  //   severity: "",
  // });
  // const isMountedAutosave = useRef(false);
  // useEffect(() => {
  //   if (isMountedAutosave.current) {
  //     const promises: Promise<any>[] = [];
  //     const debouncedAutosave = setTimeout(() => {
  //       if (savedComment) {
  //         console.log("com", savedComment);
  //         promises.push(
  //           postApiCall(`${baseUrl}DapComments/UpdateDapComments`, savedComment)
  //         );
  //       }
  //       clearDocumentComments(dispatch);
  //       setToastMessage({
  //         message: "Saved successfully",
  //         severity: "success",
  //       });
  //       if (promises.length > 0) {
  //         setToastMessageOpen(false);
  //         Promise.all(promises)
  //           .then((responses) => {
  //             const successResponses = responses.filter(
  //               (response: any) => response.success
  //             );
  //             if (successResponses.length === promises.length) {
  //               setToastMessage({
  //                 message: "Saved successfully",
  //                 severity: "success",
  //               });
  //               setToastMessageOpen(true);
  //             }
  //           })
  //           .catch((error) => {
  //             console.error("Error:", error);
  //             setToastMessage({
  //               message: error.message,
  //               severity: "error",
  //             });
  //             setToastMessageOpen(true);
  //           });
  //       } else {
  //         console.log("No API calls made");
  //       }
  //     }, AutosaveDelay.DELAYTIME);
  //     return () => {
  //       clearTimeout(debouncedAutosave);
  //     };
  //   } else {
  //     isMountedAutosave.current = true;
  //   }
  // }, [savedComment]);

  return (
    <div>
      {/* {toastMessageOpen && (
        <GenericToast
          message={toastMessage.message}
          severity={toastMessage.severity}
        />
      )} */}
      <div className="tab-heading text-center">
        <h3 className="heading">SWOT ANALYSIS</h3>
      </div>
      {/* <div className="text-center">
        <h3>SWOT ANALYSIS</h3>
      </div> */}
      <h3 className="text-left sub-heading">
        Asset/Market/Tenancy/Competition
      </h3>
      {/* <Button
            variant="contained"
            className="primary"
            size="small"
            onClick={submitSwot}
          >
            Save
          </Button> */}

      <Box sx={{ flexGrow: 1 }} className="summary-form m-20">
        <Grid container spacing={2}>
          {/* block1 */}
          <Grid item xs={12} md={3}>
            {swotStatus ? (
              <Skeleton
                variant="rounded"
                width="100%"
                height={250}
                animation="wave"
              />
            ) : (
              <Item>
                <h3 className="text-left">Strengths</h3>
                <div className="grid-item-box">
                  <FroalaRichText
                    model={strengthComment?.commentText || ""}
                    handlesModelChange={handlestrengthsModelChange}
                  />
                </div>
              </Item>
            )}
          </Grid>

          <Grid item xs={12} md={3}>
            {swotStatus ? (
              <Skeleton
                variant="rounded"
                width="100%"
                height={250}
                animation="wave"
              />
            ) : (
              <Item>
                <h3 className="text-left">Weakness</h3>
                <div className="grid-item-box">
                  <FroalaRichText
                    model={weaknessComment?.commentText || ""}
                    handlesModelChange={handleweaknessModelChange}
                  />
                </div>
              </Item>
            )}
          </Grid>

          <Grid item xs={12} md={3}>
            {swotStatus ? (
              <Skeleton
                variant="rounded"
                width="100%"
                height={250}
                animation="wave"
              />
            ) : (
              <Item>
                <h3 className="text-left">Opportunities</h3>
                <div className="grid-item-box">
                  <FroalaRichText
                    model={oppurtunitiesComment?.commentText || ""}
                    handlesModelChange={handleopportunitiesModelChange}
                  />
                </div>
              </Item>
            )}
          </Grid>

          {/* block3 */}
          <Grid item xs={12} md={3}>
            {swotStatus ? (
              <Skeleton
                variant="rounded"
                width="100%"
                height={250}
                animation="wave"
              />
            ) : (
              <Item>
                <h3 className="text-left">Threats</h3>
                <div className="grid-item-box">
                  <FroalaRichText
                    model={threatsComment?.commentText || ""}
                    handlesModelChange={handlethreatsModelChange}
                  />
                </div>
              </Item>
            )}
          </Grid>
        </Grid>
      </Box>
      {/* {swotStatus.error && (
        <GenericToast
          message={swotStatus.error}
          severity="error"
        />
      )} */}
    </div>
  );
};
