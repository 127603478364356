import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { App } from "./App";
import { store } from "./redux/store";
import { Provider } from "react-redux";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <Provider store={store}>
      <App />
  </Provider>
);

//import {  PublicClientApplication} from "@azure/msal-browser";
//import { MsalProvider } from "@azure/msal-react";
//import { msalConfig } from "./authConfig";
//import { ConfigurationService } from "./services/configuration-service";

/*
//TODO: handle config load error
const configService = new ConfigurationService();
configService.getConfigAsync()
  .then(config => {
    console.log("data", config);

    const msalInstance = new PublicClientApplication(config.msalConfig);
    const root = ReactDOM.createRoot(
      document.getElementById("root") as HTMLElement
    );
    root.render(
      <Provider store={store}>
        <MsalProvider instance={msalInstance}>
          <App config={config} />
        </MsalProvider>
      </Provider>
    );
  });
*/

