import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { FroalaRichText } from "../../util/FroalaRichtext";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  color: theme.palette.text.secondary,
}));

export default function FormDialog(props) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return props.shouldShow ? (
    <div>
      {props.showButtonTitle &&
        <Button data-testid="open-form-dialog-button" variant="outlined" onClick={handleClickOpen}>
          {props.buttonTitle}
        </Button>
      }
      <Dialog className="common-dialog-box" open={props.shouldShow} onClose={props.closeDialog}>
        <DialogTitle>{props.dialogTitle}</DialogTitle>
        <DialogContent>
          {props.dialogContent}
          {/* <Grid item xs={20} md={3}>
            <Item>
              <div style={{ marginTop: "20px", marginBottom: "20px" }}>
                <FroalaRichText
                  model=""
                />
              </div>
            </Item>
          </Grid> */}
        </DialogContent>
        <DialogActions>
          <Button className="primary" variant="contained" onClick={props.closeDialog}>Cancel</Button>
          <Button className="primary" variant="contained" data-testid="close-form-dialog-button" onClick={props.handleRequestConfirm}>Confirm</Button>
        </DialogActions>
      </Dialog>
    </div>
  ) : null;
}
