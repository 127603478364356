import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useAppSelector, useAppDispatch } from '../../redux/hooks';
import { useNavigate } from 'react-router-dom';
import { hideDuplicateResponseAlert, hideCreateDapPopup, hideDeleteResponseAlert } from '../../redux/slices/dialogFormSlice';
import { getInvestmentDetailsById, PostData } from "../../redux/slices/summaryFormSlice";
import { postApiCall } from "../../services/apiHelper";
import { baseUrl } from "../../config/config";

export const MessageBox = ({ assetPlanId, titleMessage, message, isDeleted, openDialog }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // const showDuplicateAlert = useAppSelector(
  //   (state) => state.dialog.dialogData.showDuplicateAlert
  // );
  // const showDeleteAlert = useAppSelector(
  //   (state) => state.dialog.dialogData.showDeleteAlert
  // );
  const dapData: any = useAppSelector(
    (state) => state.summary.dapData
  );
  const requestForPropertyImages: any = {
    investmentId: dapData?.investmentId,
  };
  const postData: PostData = {
    dapId: assetPlanId
  };

  // const assetPlanId = useAppSelector(
  //   (state) => state.getAssetPlanId.assetPlanId
  // );

  const handleClose = () => {
    dispatch(hideDuplicateResponseAlert());
    dispatch(hideDeleteResponseAlert());
  };

  const handleRecreate = async () => {
    console.log(postData);

    //setLoadingState(true);
    try {
      const response = await postApiCall(`${baseUrl}AssetPlan/DeleteDapUpdate`, {
        "assetPlanID": postData.dapId,
        "isDeleted": false
      })
        .then((res: any) => {
          //setLoadingState(false);

          handleClose();
          dispatch(hideCreateDapPopup());
          navigate("/dashboard/form/edit/" + assetPlanId);
         // window.location.reload()
        })
      return response;
    } catch (error: any) {
      console.error("Error in POST request:", error);
      console.log(`Error :${error}`);
      handleClose();

      //setLoadingState(false);
      throw error;
    }
  }

  const handleRedirect = () => {
    dispatch(hideDuplicateResponseAlert());
    dispatch(hideCreateDapPopup());
    dispatch(getInvestmentDetailsById(postData));
    navigate("/dashboard/form/edit/" + assetPlanId);
    //window.location.reload()
  };

//   <Dialog
//   open={openPopUp}
//   onClose={handlePopUpClose}
//   aria-labelledby="alert-dialog-title"
//   aria-describedby="alert-dialog-description"
//   className="common-dialog-box"
// >
//   <DialogContent>
//     <DialogContentText id="alert-dialog-description">
//       Do You Want To Delete This Item?
//     </DialogContentText>
//   </DialogContent>
//   <DialogActions>
//     <Button onClick={handleDeleteImage}>Yes</Button>
//     <Button onClick={handlePopUpClose}>No</Button>
//   </DialogActions>
// </Dialog>

  return (
    <div>
      <Dialog
        open={openDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title-duplicate"
        aria-describedby="alert-dialog-description-duplicate"
        id="duplicate modal alert"
        className="common-dialog-box"
      >
        <DialogTitle id="alert-dialog-title">
          {titleMessage}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} className="primary" variant="contained">Close</Button>
          {!isDeleted ? <Button onClick={handleRedirect} autoFocus className="primary" variant="contained">
            Redirect
          </Button> : null}
          {isDeleted ? <Button onClick={handleRecreate} autoFocus className="primary" variant="contained">
            Reactivate
          </Button> : null}
        </DialogActions>
      </Dialog>
    </div >
  );
}