import React, { FC, useState, useEffect } from "react";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import "./UserRoleMapDashboard.css";
import {
  DataGridPro,
  GridColDef,
  GridFilterModel,
  GridPaginationModel,
  GridToolbarFilterButton,
  GridToolbar,
  GridValueGetterParams,
  GridApi,
  GridCellParams,
  GridToolbarContainer,
  GridToolbarExport,
  GridActionsCellItem,
} from "@mui/x-data-grid-pro";
import { useNavigate } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { getUserRolesForDashboard, getUserRolesForDashboardBatch } from "../../../services/common-services";
import { TablePaginationActions } from "components/util/tablePagination";

interface userRole {
  Id: string;
  UserName: string;
  UserID: string;
  Email: string;
  Role: string;
  Status: string;
}

const UserRoleMapDashboard: FC = () => {
  const navigate = useNavigate();

  const [userRolesData, setUserRolesData] = useState<userRole[]>([]);
  const [pageSize, setPageSize] = useState<number>(25);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: pageSize,
  });
  const [filterModel, setFilterModel] = React.useState<GridFilterModel>({
    items: [],
  });
  const [rowCount, setRowCount] = useState<number>(0);
  let [pagestate, setPagestate] = useState();
  // const { isLoading, rows, pageInfo } = useQuery(paginationModel);
  // const fetechData = async (paginationModel, filterModel) => {
  //   await getUserRolesForDashboardBatch(paginationModel, filterModel).then(
  //     (data: any) => {
  //       console.log(data);
  //       setUserRolesData(data.userRoles);
  //       setRowCount(data.rowCount);
  //     }
  //   );
  // };

  const fetchData = async () => {
    await getUserRolesForDashboard().then(
      (data: any) => {
        console.log(data);
        setUserRolesData(data.userRoles);
        setRowCount(data.rowCount);
      }
    );
  };
  useEffect(() => {
    fetchData();
  }, [paginationModel, filterModel]);

  console.log(userRolesData);
  const handleRowClick = (param, event) => {
    event.stopPropagation();
  };
  const columns: GridColDef[] = [
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      filterable: false,
      disableExport: true,
      renderCell: (cellValues) => {
        const onEditClick = (e) => {
          e.stopPropagation(); // don't select this row after clicking
          navigate("/userRole/" + "edit/" + cellValues.id);
          return console.log("EDIT  >>>>", cellValues.id);
        };

        const onViewClick = (e) => {
          e.stopPropagation(); // don't select this row after clicking
          navigate("/userRole/" + "view/" + cellValues.id);
          return console.log("View  >>>>", cellValues);
        };
        return (
          <>
            <GridActionsCellItem
              sx={{
                color: "#253746",
              }}
              icon={<VisibilityIcon />}
              label="View"
              onClick={(event) => {
                onViewClick(event);
              }}
            />
            <GridActionsCellItem
              sx={{
                color: "#253746",
              }}
              icon={<EditIcon />}
              label="Edit"
              onClick={(event) => {
                onEditClick(event);
              }}
            />
          </>
        );
      },
      width: 150,
    },
    { field: "role", headerName: "Role", flex: 1.2 },
    { field: "userName", headerName: "User Name", flex: 1.2 },
    { field: "email", headerName: "Email ID", flex: 1.2 },
    { field: "status", headerName: "Status", flex: 1.2 },
  ];

  // const handelPaginationModel = (e: GridPaginationModel) => {
  //   setPaginationModel(e);
  //   fetechData(e, filterModel);
  //   console.log(e);
  // };

  // const handelFilterModelChange = (model) => {
  //   setFilterModel(model);
  //   fetechData(paginationModel, model);
  //   console.log(model);
  // };

  //for export data in csv file
  const gridCustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarExport printOptions={{ disableToolbarButton: true }} />
        <GridToolbarFilterButton />
      </GridToolbarContainer>
    );
  };

  return (
    <>
      <Box
        className="dashboard-table-wrapper"
        sx={{ height: 400, width: "100%" }}
      >
        <DataGridPro
          sx={{
            backgroundColor: "white",
            "& .MuiDataGrid-columnHeader": {
              backgroundColor: "#253746",
              color: "white",
            },
            "& .MuiDataGrid-sortIcon": {
              color: "white",
            },
            "& .MuiDataGrid-filterIcon": {
              color: "white",
            },
          }}
          rows={userRolesData}
          columns={columns}
          pagination
          initialState={{
            pagination: { paginationModel: { pageSize: 25 } },
          }}
          componentsProps={{
            pagination: {
              ActionsComponent: TablePaginationActions,
            },
          }}
          //rowCount={rowCount}
          // pageSizeOptions={[pageSize]}
          pageSizeOptions={[25, 50, 100]}
          autoHeight
          // paginationMode="server"
          // filterMode="server"
          // paginationModel={paginationModel}
          // onPaginationModelChange={handelPaginationModel}
          // filterModel={filterModel}
          // onFilterModelChange={(model) => handelFilterModelChange(model)}
          onRowClick={handleRowClick}
          slots={{
            toolbar: gridCustomToolbar,
            //noRowsOverlay: CustomDataGridNoRowOverlay,
          }}
        />
      </Box>
    </>
  );
};

export default UserRoleMapDashboard;
