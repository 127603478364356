import { useState, useEffect } from "react";
import axios from "axios";
import ReactFileUpload from "./ReactFileUpload";
import { Typography } from "@mui/material";
import * as React from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { DocumentComponent } from "../../../features/document/components/document-component";
import { useAppSelector, useAppDispatch } from "../../../redux/hooks";
import { baseUrl } from "../../../config/config";
import { AutosaveDelay, SectionNames } from "../../../constant";
import { fetchTabPageData } from "../../../redux/slices/tabPagesOptionsSlice";
import { DocumentServices } from "../../../features/document/services/document-services";
import {
  assetID,
  UploadDocument,
  FileChunk,
} from "../../../redux/slices/documentCommentSlice";
import { postApiCall } from "../../../services/apiHelper";
import { changeStatus } from "../../../redux/slices/onChangeSlice";
import { GenericToast } from "components/util/ToastMessage";
import { LoadingButton } from "@mui/lab";

const _ = require("lodash");

interface toast {
  message: any;
  severity: string;
}

let promises: Promise<any>[] = [];
let largeFiles: FileChunk[] = [];
let largeFilesArray: any[] = [];
const Input = styled("input")({
  display: "none",
});
const formatDate = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");
  return `${year}${month}${day}${hours}${minutes}${seconds}`;
};
const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

function Documents(props) {
  const dispatch = useAppDispatch();
  const [submitLoaderButton, setSubmitLoaderButton] = useState(false);
  const [toastMessageOpen, setToastMessageOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState<toast>({
    message: "",
    severity: "",
  });

  const dapData = useAppSelector((state) => state.summary.dapData);
  const [isSectionValueEmpty, setIsSectionValueEmpty] =
    useState<boolean>(false);

  const assetPlanId = useAppSelector(
    (state) => state.getAssetPlanId.assetPlanId
  );
  const docPayload: assetID = { assetPlanID: assetPlanId };

  // const sectionName = async () => {
  //   axios
  //     .get(`${baseUrl}Options/GetByKeys` + "FileSelectionDD_Key")
  //     .then((response) => {
  //       setSectionValue(response.data);
  //     });
  // };
  const [sectionValue, setSectionValue] = React.useState<string | null>();
  const docOnChangeStatus: boolean = useAppSelector(
    (state) => state.onchangeFlagForDocComponent.isChanged
  );

  // const BaseURL = baseUrl + "";
  // 👇 files is not an array, but it's iterable, spread to get an array of files
  const documentServices = new DocumentServices();
  //const [files, setFiles] = useState([]);

  const GetDocBasedOnTab = async () => {
    //alert("GetDocBasedOnTab");
    axios
      .get(`${baseUrl}Document/` + "GetDocByDapId", {
        params: {
          guid: "eb245e67-81d3-46c5-81c0-e42dcca70639_introduction",
        },
      })
      .then((response) => {
        setNewUserInfo(response.data);
      });
  };

  const UpdateFileInControl = async () => {};

  const [newUserInfo, setNewUserInfo] = useState({
    profileImages: [],
  });

  const updateUploadedFiles = (files) =>
    setNewUserInfo({ ...newUserInfo, profileImages: files });

  const [documentData, setDocumentData] = useState([]);

  var fileSize: any;
  var chunkSize = 2000000;
  var fileToBeUpload: any;
  var chunkCount: any;
  var chunk: any;
  var fileGuid: any;
  const [counter, setCounter] = useState(1);
  var beginingOfTheChunk = 0;
  var endOfTheChunk: any;
  var uploadStartResponse;
  var largeFileCount = 0;
  var arrayOfFileChunks: any = [];

  const largeFileUpload = (file: any) => {
    var arrayFileChunk: any = [];
    endOfTheChunk = chunkSize;
    // filesList.forEach((file) => {
    return new Promise((resolve) => {
      // let promise = getBase64(file).then((base64File:any)=>{
      while (beginingOfTheChunk <= file.fileAsBase64.length) {
        var chunk = file.fileAsBase64.slice(beginingOfTheChunk, endOfTheChunk);
        const chunkFile: FileChunk = {
          fileAsBase64: chunk,
          tmpDirectory:
            formatDate(new Date()) + "_" + file.fileName + "_Chunks",
          fileName: file.fileName,
          sectionName: file.sectionName,
          budgetYear: file.budgetYear,
          assetPlanID: file.assetPlanID,
        };
        arrayFileChunk.push(chunkFile);
        // let responseData = await uploadChunk(chunkFile);

        setCounter(counter + 1);
        beginingOfTheChunk = endOfTheChunk;
        endOfTheChunk = endOfTheChunk + chunkSize;
      }
      arrayOfFileChunks.push(arrayFileChunk);
      beginingOfTheChunk = 0;
      endOfTheChunk = 0;
      // uploadCompleted(uploadStartResponse,file);
      resolve(arrayOfFileChunks);
      // });
    });

    // });
  };

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        resolve(reader.result);
      };
      reader.onerror = function (error) {
        console.log("Error: ", error);
      };
    });
  };

  const handleSubmit = async (event) => {
    setSubmitLoaderButton(true);
    promises = [];
    largeFiles = [];
    let sectionName;
    let fileType;
    if (sectionValue != null) {
      sectionName = sectionValue;
    }
    let currentDate = new Date();
    const currentUtcDate = new Date(Date.parse(currentDate.toUTCString()));
    const formData = new FormData();

    // console.log("selected files >>> " + newUserInfo.profileImages);
    var asyncDocCreate = new Promise((resolve, reject) => {
      newUserInfo.profileImages.forEach((file: any, index, files: any) => {
        let promise = getBase64(file).then(async (res: any) => {
          res = res.split(",")[1];
          if (file.size > 3000000) {
            // var chunk = res.slice(0, 3000000);
            const chunkFile: FileChunk = {
              assetPlanID: assetPlanId,
              investmentId:
                dapData?.investmentId != undefined ? dapData?.investmentId : "",
              sectionName: sectionName,
              budgetYear:
                dapData?.budgetYear != undefined ? dapData?.budgetYear : "",
              fileAsBase64: res,
              tmpDirectory:
                formatDate(new Date()) + "_" + file.name + "_Chunks",
              fileName: file.name,
              fileSize: file.size,
            };
            await largeFileUpload(chunkFile);
            chunkFile.fileAsBase64 = "";
            largeFiles.push(chunkFile);
            promises.push(postApiCall(`${baseUrl}Document/start`, chunkFile));
          } else {
            const doc: UploadDocument = {
              assetPlanID: assetPlanId,
              investmentId:
                dapData?.investmentId != undefined ? dapData?.investmentId : "",
              sectionName: sectionName,
              budgetYear:
                dapData?.budgetYear != undefined ? dapData?.budgetYear : "",
              comments: "",
              fileName: file.name,
              fileSize: file.size,
              fileType: file.type,
              fileAsBase64: res,
            };
            promises.push(postApiCall(`${baseUrl}Document/AddDocument`, doc));
          }

          console.log(promises);
          if (index === files.length - 1) resolve(promises);
        });
      });
    });

    asyncDocCreate.then((x) => {
      try {
        if (sectionValue != undefined) {
          if (newUserInfo.profileImages.length > 0) {
            setToastMessageOpen(false);
            Promise.all(promises)
              .then((res: any) => {
                setSubmitLoaderButton(false);
                setToastMessageOpen(true);
                //if (res[0].result.succeeded == true) {
                setToastMessage({
                  message: "Saved successfully",
                  severity: "success",
                });
                uploadChunksFromArray(arrayOfFileChunks);
                //}
                // else {
                //   setToastMessage({
                //     message: res[0].result.errorMessage,
                //     severity: "error",
                //   });
                // }
                loadDocumentData(sectionName);
                dispatch(changeStatus(true));
                setTimeout(() => {
                  setToastMessageOpen(false);
                }, 4000);
                ///iterate loop on list of array of chunkFile and call chunk endpoint
              })
              .catch((err: any) => {
                setToastMessageOpen(true);
                setSubmitLoaderButton(false);
                //console.log(err.response.config.data);
                setToastMessage({
                  message: err.message,
                  severity: "error",
                });
                setTimeout(() => {
                  setToastMessageOpen(false);
                }, 4000);
                dispatch(changeStatus(true));
                loadDocumentData(sectionName);
              });
            handleAccordianChange(sectionName);
          } else {
            setSubmitLoaderButton(false);
            // console.log("empty file set successfully");
            setToastMessageOpen(true);
            setTimeout(() => {
              setToastMessageOpen(false);
            }, 4000);
          }
        } else {
          setSubmitLoaderButton(false);
          setIsSectionValueEmpty(true);
          setTimeout(() => {
            setIsSectionValueEmpty(false);
          }, 4000);
        }
      } catch (error: any) {
        setSubmitLoaderButton(false);
        console.error("Error in POST request:", error);
        throw error;
      }
    });

    // if(docOnChangeStatus){
    //   loadDocumentData(sectionName);
    //   dispatch(changeStatus(false));
    // }
    // axios
    //   .post(`${baseUrl}Document/` + "AddDocument", formData)
    //   .then((response) => console.log(response))
    //   .catch((error) => console.log(error));
  };

  const uploadChunksFromArray = (filesArray: any[]) => {
    promises = [];
    filesArray.forEach((chunksArray: any[], index) => {
      chunksArray.forEach(async (chunk: any, i) => {
        chunk.fileIndex = i;
        promises.push(postApiCall(`${baseUrl}Document/chunk`, chunk));
      });
    });
    Promise.all(promises)
      .then((res: any) => {
        largeFiles.forEach((largeFile: any) => {
          largeFile.fileAsBase64 = "";
          largeFile.tmpDirectory =
            largeFile.budgetYear +
            "/" +
            largeFile.assetPlanID +
            "/" +
            largeFile.sectionName +
            "/" +
            largeFile.tmpDirectory;
          postApiCall(`${baseUrl}Document/end`, largeFile);
        });
      })
      .catch((err) => {
        setToastMessageOpen(true);
        setSubmitLoaderButton(false);
        console.log(err.response.config.data);
        // var updatedFile = newUserInfo.profileImages.filter(function (n:any) {
        //   return n.name !== JSON.parse(err.response.config.data).fileName;
        // });
        setToastMessage({
          message: err.message,
          severity: "error",
        });
        setTimeout(() => {
          setToastMessageOpen(false);
        }, 4000);
        dispatch(changeStatus(true));
      });
  };

  const [expanded, setExpanded] = React.useState<string | false>("panel1");

  const loadDocumentData = async (panel: string) => {
    await documentServices
      .getDocumentsBySectionAndAssetPlanId({
        section: panel,
        assetPlanId: assetPlanId,
      })
      .then((data: any) => {
        // return data.documents;
        // console.log(panel);
        // console.log(data);
        // console.log(data.documents);
        setDocumentData(data.documents);
      });
  };

  const handleAccordianChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);

      // loadDocumentData(panel);
    };
  //logic to create new user...

  useEffect(() => {
    dispatch(fetchTabPageData(SectionNames.FileSection));
    //dispatch(getAllDocumentComment(docPayload));
  }, [dispatch]);

  const HandleSelectChange = (event: SelectChangeEvent) => {
    setSectionValue(event.target.value);
    //alert(setSectionValue);
  };

  // const investmentId = "1092";
  // const postData: PostData = {
  //   investmentId:id
  // };

  // const budgetYear = useAppSelector(
  //   (state) => state.assetPlanData.assetPlan.budgetYear
  // );

  // const savedData = useAppSelector((state) => state.updateData);
  // const savedComment = useAppSelector(
  //   (state) => state.saveComment.updateDapComments
  // );
  // const [autosaveToastMessageOpen, setAutosaveToastMessageOpen] =
  //   useState(false);
  // const [autosaveToastMessage, setAutosaveToastMessage] = useState<toast>({
  //   message: "",
  //   severity: "",
  // });
  // const isMountedAutosave = React.useRef(false);
  // useEffect(() => {
  //   if (isMountedAutosave.current) {
  //     const promises: Promise<any>[] = [];

  //     const debouncedAutosave = setTimeout(() => {
  //       const savedDataPayload = {
  //         assetPlanId: assetPlanId,
  //         capitalProjectData: null,
  //         documentData: savedData.documentData,
  //         occupancyCommercial: null,
  //         recommendationTab: null,
  //         status: null,
  //         summary: null,
  //         userAction: "Save",
  //       };
  //       if (savedData) {
  //         console.log("saveddata", savedData);
  //         promises.push(
  //           postApiCall(`${baseUrl}UpdateData/SaveAllData`, {
  //             ...savedDataPayload,
  //           })
  //         );
  //       }
  //       if (savedComment) {
  //         console.log("com", savedComment);
  //         promises.push(
  //           postApiCall(`${baseUrl}DapComments/UpdateDapComments`, savedComment)
  //         );
  //       }
  //       clearDocumentComments(dispatch);
  //       setAutosaveToastMessage({
  //         message: "Saved successfully",
  //         severity: "success",
  //       });
  //       if (promises.length > 0) {
  //         setAutosaveToastMessageOpen(false);
  //         Promise.all(promises)
  //           .then((responses) => {
  //             const successResponses = responses.filter(
  //               (response: any) => response.success
  //             );
  //             if (successResponses.length === promises.length) {
  //               setAutosaveToastMessage({
  //                 message: "Saved successfully",
  //                 severity: "success",
  //               });
  //               setAutosaveToastMessageOpen(true);
  //             }
  //           })
  //           .catch((error) => {
  //             console.error("Error:", error);
  //             setAutosaveToastMessage({
  //               message: error.message,
  //               severity: "error",
  //             });
  //             setAutosaveToastMessageOpen(true);
  //           });
  //       } else {
  //         console.log("No API calls made");
  //       }
  //     }, AutosaveDelay.DELAYTIME);
  //     return () => {
  //       clearTimeout(debouncedAutosave);
  //     };
  //   } else {
  //     isMountedAutosave.current = true;
  //   }
  // }, [savedData, savedComment]);

  console.log(props.mode);
  return (
    <div className="App document-wrap">
      {/* {autosaveToastMessageOpen && (
        <GenericToast
          message={autosaveToastMessage.message}
          severity={autosaveToastMessage.severity}
        />
      )} */}
      <div className="doc-section-width">
        {toastMessageOpen && (
          <GenericToast
            message={toastMessage.message}
            severity={toastMessage.severity}
          />
        )}
        {/* {isError && (
          <GenericToast message="Please Upload file(s)." severity="error" />
        )} */}
        {isSectionValueEmpty && (
          <GenericToast
            message="Please Select Section From Dropdown"
            severity="error"
          />
        )}

        {props.mode == "edit" && (
          <div className="select-tab">
            <div className="doc-select-box">
              <label>Select Tab</label>
              <FormControl sx={{ m: 1, minWidth: 300 }}>
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  onChange={HandleSelectChange}
                  defaultValue=""
                >
                  <MenuItem value="Recommendations">Recommendations</MenuItem>
                  <MenuItem value="SitePlan">Site Plan</MenuItem>
                  <MenuItem value="StackingPlan">Stacking Plan</MenuItem>
                  <MenuItem value="Financial">Financial</MenuItem>
                  <MenuItem value="CapitalPlan">Capital Plan</MenuItem>
                  <MenuItem value="Leasing">Leasing</MenuItem>
                  <MenuItem value="Valuation">Valuation</MenuItem>
                  <MenuItem value="Swot_ESG">Swot</MenuItem>
                  <MenuItem value="Other">Other</MenuItem>
                </Select>
              </FormControl>
            </div>

            {(sectionValue === "SitePlan" ||
              sectionValue === "StackingPlan") && (
              <span id="upload-image">Upload only images in this section</span>
            )}

            <label htmlFor="contained-button-file">
              <Input
                accept="image/*"
                id="contained-button-file"
                multiple
                type="file"
              />
            </label>
            <div>
              <label>Upload File</label>
              <p className="file-validation-msg">
                *Filename should not contain any special character except _ or -
              </p>
              <ReactFileUpload
                accept=".jpg,.png,.jpeg,.xlsx,.pdf"
                multiple
                updateFilesCb={updateUploadedFiles}
                sectionValue={sectionValue}
              />
              <LoadingButton
                disabled={
                  newUserInfo.profileImages.length === 0 ||
                  sectionValue === undefined
                }
                className={
                  newUserInfo.profileImages.length === 0 ||
                  sectionValue === undefined
                    ? "disabled-btn"
                    : "primary"
                }
                loading={submitLoaderButton}
                onClick={handleSubmit}
                variant="contained"
                component="span"
              >
                Upload Documents
              </LoadingButton>
            </div>
          </div>
        )}

        <Accordion
          expanded={expanded === "Recommendations"}
          onChange={handleAccordianChange("Recommendations")}
        >
          <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
            <Typography>Recommendations</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div>
              <DocumentComponent
                sectionName="Recommendations"
                assetPlanId={assetPlanId}
                document={documentData}
                mode={props.mode}
              ></DocumentComponent>
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "SitePlan"}
          onChange={handleAccordianChange("SitePlan")}
        >
          <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
            <Typography>Site Plan</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div>
              <DocumentComponent
                sectionName="SitePlan"
                assetPlanId={assetPlanId}
                document={documentData}
                mode={props.mode}
              ></DocumentComponent>
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "StackingPlan"}
          onChange={handleAccordianChange("StackingPlan")}
        >
          <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
            <Typography>Stacking Plan</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div>
              <DocumentComponent
                sectionName="StackingPlan"
                assetPlanId={assetPlanId}
                document={documentData}
                mode={props.mode}
              ></DocumentComponent>
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "Financial"}
          onChange={handleAccordianChange("Financial")}
        >
          <AccordionSummary aria-controls="panel5d-content" id="panel5d-header">
            <Typography>Financial</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <DocumentComponent
              sectionName="Financial"
              assetPlanId={assetPlanId}
              document={documentData}
              mode={props.mode}
            ></DocumentComponent>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "CapitalPlan"}
          onChange={handleAccordianChange("CapitalPlan")}
        >
          <AccordionSummary aria-controls="panel6d-content" id="panel6d-header">
            <Typography>Capital Plan</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <DocumentComponent
              sectionName="CapitalPlan"
              assetPlanId={assetPlanId}
              document={documentData}
              mode={props.mode}
            ></DocumentComponent>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "Leasing"}
          onChange={handleAccordianChange("Leasing")}
        >
          <AccordionSummary aria-controls="panel7d-content" id="panel7d-header">
            <Typography>Leasing</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <DocumentComponent
              sectionName="Leasing"
              assetPlanId={assetPlanId}
              document={documentData}
              mode={props.mode}
            ></DocumentComponent>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "Valuation"}
          onChange={handleAccordianChange("Valuation")}
        >
          <AccordionSummary aria-controls="panel8d-content" id="panel8d-header">
            <Typography>Valuation</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <DocumentComponent
              sectionName="Valuation"
              assetPlanId={assetPlanId}
              document={documentData}
              mode={props.mode}
            ></DocumentComponent>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "Swot_ESG"}
          onChange={handleAccordianChange("Swot_ESG")}
        >
          <AccordionSummary aria-controls="panel9d-content" id="panel9d-header">
            <Typography>Swot</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <DocumentComponent
              sectionName="Swot_ESG"
              assetPlanId={assetPlanId}
              document={documentData}
              mode={props.mode}
            ></DocumentComponent>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "Other"}
          onChange={handleAccordianChange("Other")}
        >
          <AccordionSummary
            aria-controls="panel10d-content"
            id="panel10d-header"
          >
            <Typography>Other</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <DocumentComponent
              sectionName="Other"
              assetPlanId={assetPlanId}
              document={documentData}
              mode={props.mode}
            ></DocumentComponent>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
}

export default Documents;
