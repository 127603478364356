import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { baseUrl } from "../../config/config";
import { postApiCall } from "../../services/apiHelper";

export type SectionDocuments = {
  sectionName?: string;
  documents?: DocumentComment[];
};
export type DocumentComment = {
  assetPlanID?: string;
  id?: number;
  comments?: string;
  fileKey?: string;
  displayName?: string;
  uploadedDate?: string;
  sectionName?: string;
  fileType?: string;
  fileSize?: number;
  status?: string;
  uploadStatus?: string;
};

export interface FileChunk {
  assetPlanID?: string;
  investmentId?: string;
  sectionName?: string;
  budgetYear?: string;
  fileName?: string;
  fileSize?: number;
  fileAsBase64?: string;
  fileUrl?: string;
  fileUrlExpire?: number;
  fileKey?: string;
  fileIndex?: number;
  tmpDirectory?: string;
}

export interface UploadDocument {
  assetPlanID: string;
  investmentId: string;
  sectionName: string;
  budgetYear: string;
  comments: string;
  fileName: string;
  fileSize: number;
  fileType: string;
  fileAsBase64: any;
}

export interface assetID {
  assetPlanID: string;
}

type DocumentTenantState = {
  allDocuments: { [key: string]: DocumentComment[] };
  loading: boolean;
  documentComments: DocumentComment[];
  error: string;
};

const initialState: DocumentTenantState = {
  allDocuments: {},
  loading: false,
  documentComments: [],
  error: "",
};

// export const getAllDocumentComment = createAsyncThunk("getAllDocumentComment", async (payload: any) => {
//     try {
//         const response = await postApiCall(`${baseUrl}Document/GetDocumentsByAssetPlanId`, payload).then((res: any) =>
//             res);
//         return response.documents || [];
//     } catch (error: any) {
//         console.error('Error in POST request:', error);
//         throw error;
//     }
// });

export const getAllDocumentComment = createAsyncThunk(
  "GetDocumentsBySectionAndAssetPlanId",
  async (payload: any) => {
    try {
      const response = await postApiCall(
        `${baseUrl}Document/GetDocumentsBySectionAndAssetPlanId`,
        payload
      ).then((res: any) => res);
      const section = payload.section;
      console.log(payload);
      console.log("inside getalldocs", response.documents);
      return { [section]: response.documents || [] };
      // response.documents || [];
    } catch (error: any) {
      console.error("Error in POST request:", error);
      throw error;
    }
  }
);

const DocumentCommentSlice = createSlice({
  name: "dapDocumentComments",
  initialState,
  reducers: {
    addDocumentComments: (state, action) => {
      state.documentComments = action.payload;
    },
    ensureDocumentComments: (state, action) => {
      const indexOfFileToUpdate = state.documentComments.findIndex(
        (doc) => doc.id === action.payload.id
      );
      if (indexOfFileToUpdate === -1) {
        state.documentComments.push(action.payload);
      } else {
        state.documentComments[indexOfFileToUpdate] = { ...action.payload };
      }
      const sectionDocs = state.allDocuments[action.payload.sectionName];
      const fileIndex = sectionDocs.findIndex(
        (file) => file.id === action.payload.id
      );
      if (fileIndex !== -1) {
        state.allDocuments[action.payload.sectionName][fileIndex] = {
          ...action.payload,
        };
      }
    },
    resetDocumentComments: (state) => {
        state.documentComments = [];
    },
  },

  extraReducers: (builder) => {
    // eslint-disable-next-line
    builder.addCase(getAllDocumentComment.pending, (state) => {
      state.loading = true;
    }),
      builder.addCase(getAllDocumentComment.fulfilled, (state, action) => {
        state.loading = false;
        //state.documentComments = action.payload;
        console.log(action.payload);
        const sectionName = Object.keys(action.payload)[0];
        const sectionDocs = action.payload[sectionName];
        const updatedFiles = sectionDocs.map((file) => {
          const existingFileIndex = state.documentComments.findIndex(
            (doc) => doc.id === file.id
          );

          if (existingFileIndex !== -1) {
            file.comments = state.documentComments[existingFileIndex].comments;
          }
          return file;
        });
        state.allDocuments[sectionName] = updatedFiles;
        //state.documentComments.push(action.payload);
      }),
      builder.addCase(getAllDocumentComment.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "something went wrong";
      });
  },
});

export const {
  addDocumentComments,
  resetDocumentComments,
  ensureDocumentComments,
} = DocumentCommentSlice.actions;
export default DocumentCommentSlice.reducer;
