import React, { useState, useEffect, useRef } from "react";

import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import { DataGridPro } from "@mui/x-data-grid-pro";
import FroalaEditorView from "react-froala-wysiwyg/FroalaEditorView";
import moment from "moment";
//import { Theme, styled } from '@mui/material/styles';

import valuationColumnConfig from "./valuationColumnConfig.json";
import { getApiCall, postApiCall } from "../../../services/apiHelper";
import { baseUrl } from "../../../config/config";
import { useAppSelector, useAppDispatch } from "../../../redux/hooks";
import { FroalaRichText } from "../../util/FroalaRichtext";
import Skeleton from "@mui/material/Skeleton";
import {
  AutosaveDelay,
  CommentKey,
  CommentSectionName,
} from "../../../constant";

import { saveDapCommentData } from "../../../redux/slices/saveCommentSlice";
import { getValuations } from "redux/slices/valuationsSlice";
import {
  updateDapCommentData,
  DapComment,
} from "../../../redux/slices/updateDapCommentSlice";

type appraisal1 = {
  investmentId?: string;
  appraisalDate?: string;
  amount: number;
  psf: number;
  impliedCapRate: number;
  exitCapRate: number;
  discountRate: number;
};

type appraisal = {
  investmenT_ID: string | undefined | null;
  appraisaL_DATE: string | undefined | null;
  appraisaL_AMOUNT: string | undefined | null;
  peR_SQFT_APPRAISAL_AMT: string | undefined | null;
  peR_UNIT_APPRAISAL_AMT?: string | undefined | null;
  implieD_CAP_RATE: string | undefined | null;
  exiT_CAP_RATE: string | undefined | null;
  discounT_RATE: string | undefined | null;
};

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

interface GridValueFormatterParams<T> {
  value: T;
}

type ValueFormatter<T> = (
  params: GridValueFormatterParams<T>,
  currency?: string,
  decimalPosition?: number
) => string;

const currencyFormatter: ValueFormatter<number> = (
  params,
  currency = "$",
  decimalPosition = 2
) => {
  let value = params.value;
  if (typeof value === "number") {
    return `${currency}${value.toFixed(decimalPosition).toLocaleString()}`;
  }
  value = parseFloat(value);
  if (!isNaN(value)) {
    return `${currency}${value.toFixed(decimalPosition).toLocaleString()}`;
  }
  return "";
};

const commaSeparatedFormatter: ValueFormatter<number> = (
  params,
  currency,
  decimalPosition
) => {
  let value = params.value;
  if (typeof value === "number") {
    return value.toLocaleString();
  }
  value = parseInt(value);
  if (!isNaN(value)) {
    return value.toLocaleString();
  }
  return "";
};

const decimalFormatter: ValueFormatter<number> = (
  params,
  currency,
  decimalPosition = 2
) => {
  let value = params.value;
  if (typeof value === "number") {
    return value.toFixed(decimalPosition);
  }
  value = parseFloat(value);
  if (!isNaN(value)) {
    return value.toFixed(decimalPosition);
  }
  return "";
};

const dateFormatter: ValueFormatter<string> = (params) => {
  const value = params.value;
  return moment(value).format("MMM-DD-YYYY").toString();

  // return '';
};

const columnConfig = valuationColumnConfig.map((column) => {
  const { valueFormatter, currency, decimalPosition, ...rest } = column;

  let formatter;
  if (valueFormatter === "currencyFormatter") {
    formatter = (params) =>
      currencyFormatter(params, currency, decimalPosition);
  } else if (valueFormatter === "commaSeparatedFormatter") {
    formatter = (params) =>
      commaSeparatedFormatter(params, decimalPosition?.toString());
  } else if (valueFormatter === "decimalFormatter") {
    formatter = (params) =>
      decimalFormatter(params, decimalPosition?.toString());
  } else if (valueFormatter === "dateFormatter") {
    formatter = (params) => dateFormatter(params);
  }

  return {
    ...rest,
    ...(formatter && { valueFormatter: formatter }),
  };
});

const StyledDataGrid = styled(DataGridPro)(() => ({
  border: 0,
  WebkitFontSmoothing: "auto",
  letterSpacing: "normal",
  "& .MuiDataGrid-columnsContainer": {
    backgroundColor: "#1d1d1d",
  },
  "& .MuiDataGrid-iconSeparator": {
    display: "none",
  },
  "& .MuiDataGrid-columnHeader": {
    border: `1px solid #ddd`,
    borderCollapse: "collapse",
  },
  "& .MuiDataGrid-cell": {
    borderRight: `1px solid #ddd`,
  },
  "& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell": {
    borderBottom: `1px solid #ddd`,
  },
  "& .MuiPaginationItem-root": {
    borderRadius: 0,
  },
}));

export const ValuationForm = (props) => {
  //fetch investmentid from redux store. It is required to fetch valuation history
  const investmentId = useAppSelector(
    (state) => state.summary?.dapData?.investmentId
  );
  //fetch comments from redux store

  const assetPlanId = useAppSelector(
    (state) => state.getAssetPlanId.assetPlanId
  );

  const dapData = useAppSelector((state) => state.summary.dapData);

  const comments = useAppSelector((state) => state.dapComments?.data).find(
    (x) => x.commentKey.toLowerCase() === CommentKey.VALUATION.toLowerCase()
  );
  const dispatch = useAppDispatch();

  //hide coumn based on PropertyType
  const colnameToSearch =
    dapData?.propertyType === "Residential"
      ? "peR_SQFT_APPRAISAL_AMT"
      : "peR_UNIT_APPRAISAL_AMT";
  const itemIndexToRemove = columnConfig.findIndex(
    (config) => config.field === colnameToSearch
  );
  const filteredColumnConfig = columnConfig.filter(
    (config, index) => index !== itemIndexToRemove
  );

  const isMounted = useRef(false);
  const handleCommentsChange = (newValue: string) => {
    const updatedcomment: DapComment = {
      id: comments ? comments.id : 0,
      commentKey: comments ? comments.commentKey : CommentKey.VALUATION,
      commentText: newValue || "",
      sectionName: CommentSectionName.VALUATION,
      assetPlanId: assetPlanId,
    };
    dispatch(updateDapCommentData(updatedcomment));
    dispatch(saveDapCommentData(updatedcomment));

    // if (isMounted.current) {
    //   dispatch(updateDapCommentData(updatedcomment));
    //   dispatch(saveDapCommentData(updatedcomment));
    // } else {
    //   isMounted.current = true;
    //}
  };

  //const assetPlanId = useAppSelector((state) => state.getAssetPlanId.assetPlanId.assetPlanId);
  //const assetPlanId = "74a93b5d-853f-40a0-be29-53778e214728";

  // old code -- refactored to use redux store instead of react state ------
  const getValuationHistory = () => {
    console.log("investmentid", investmentId);
    var url = `${baseUrl}Document/GetValuations?investmentid=${investmentId}`;
    console.log("url", url);
    const res = getApiCall(url).then((data: any) => {
      // console.log(typeof data);
      return data;
    });
    return res;
  };
  // const [valuationHistory, setValuationHistory] = React.useState<
  //   appraisal[] | null
  // >(null);
  // ------------

  const [isNoData, setIsNoData] = useState<boolean>(false);

  const valuationHistory = useAppSelector(
    (state) => state.valuations.valuations
  );

  const payload = { investmentId: investmentId, assetPlanId: assetPlanId };
  useEffect(() => {
    dispatch(getValuations(payload));

    // if (!valuationHistory) {
    //   dispatch(getValuations(payload));
    // }

    // getValuationHistory().then((data: appraisal[]) => {
    //   console.log(data);
    //   if (data?.length === 0) {
    //     setIsNoData(true);
    //   }
    //   setValuationHistory([...data]);
    // });
    //setValuationHistory([...valuation]);
  }, [dispatch]);

  // const savedComment = useAppSelector(
  //   (state) => state.saveComment.updateDapComments
  // );
  // const [toastMessageOpen, setToastMessageOpen] = useState(false);
  // const [toastMessage, setToastMessage] = useState<toast>({
  //   message: "",
  //   severity: "",
  // });
  // const isMountedAutosave = useRef(false);
  // useEffect(() => {
  //   if (isMountedAutosave.current) {
  //     const promises: Promise<any>[] = [];
  //     const debouncedAutosave = setTimeout(() => {
  //       if (savedComment) {
  //         console.log("com", savedComment);
  //         promises.push(
  //           postApiCall(`${baseUrl}DapComments/UpdateDapComments`, savedComment)
  //         );
  //       }
  //       clearDocumentComments(dispatch);
  //       setToastMessage({
  //         message: "Saved successfully",
  //         severity: "success",
  //       });
  //       if (promises.length > 0) {
  //         setToastMessageOpen(false);
  //         Promise.all(promises)
  //           .then((responses) => {
  //             const successResponses = responses.filter(
  //               (response: any) => response.success
  //             );
  //             if (successResponses.length === promises.length) {
  //               setToastMessage({
  //                 message: "Saved successfully",
  //                 severity: "success",
  //               });
  //               setToastMessageOpen(true);
  //             }
  //           })
  //           .catch((error) => {
  //             console.error("Error:", error);
  //             setToastMessage({
  //               message: error.message,
  //               severity: "error",
  //             });
  //             setToastMessageOpen(true);
  //           });
  //       } else {
  //         console.log("No API calls made");
  //       }
  //     }, AutosaveDelay.DELAYTIME);
  //     return () => {
  //       clearTimeout(debouncedAutosave);
  //     };
  //   } else {
  //     isMountedAutosave.current = true;
  //   }
  // }, [savedComment]);
  const getRowId = (row: any) => row.appraisaL_DATE;

  return (
    <div className="data-grid-table">
      {/* {toastMessageOpen && (
        <GenericToast
          message={toastMessage.message}
          severity={toastMessage.severity}
        />
      )} */}
      <div className="tab-heading text-center">
        <h3 className="heading">Valuation History</h3>
      </div>

      {/* <GenericDataGrid rows={valuationHistory || []} columnConfig={columnConfig} getRowId={getRowId} /> */}
      <div style={{ width: "100%" }}>
        {valuationHistory == null ? (
          <Skeleton
            variant="rounded"
            width="100%"
            height={150}
            animation="wave"
          />
        ) : (
          <>
            <DataGridPro
              sx={{
                backgroundColor: "white",
                "& .MuiDataGrid-columnHeader": {
                  backgroundColor: "#253746",
                  color: "white",
                },
              }}
              rowHeight={35}
              disableColumnFilter={true}
              disableColumnMenu={true}
              rows={valuationHistory || []}
              columns={filteredColumnConfig}
              hideFooter={true}
              getRowId={getRowId}
              showCellVerticalBorder={true}
              showColumnVerticalBorder={true}
              columnHeaderHeight={35}
              autoHeight
            />
            {/* <DataGrid
              className="custom-data-grid" // Set the desired height for the header rows
              rowHeight={30}
              disableColumnFilter={true}
              disableColumnMenu={true}
              rows={valuationHistory || []}
              columns={columnConfig}
              hideFooter={true}
              getRowId={getRowId}
            /> */}
          </>
        )}
      </div>

      <form className="m-20 lease-form">
        <fieldset>
          <legend className="lease-title">Comments</legend>
          <>
            {/* {console.log(props)} */}
            {props?.mode === "edit" && (
              <FroalaRichText
                model={comments?.commentText || ""}
                handlesModelChange={handleCommentsChange}
              />
            )}

            {(props?.mode === undefined || props?.mode === "view") && (
              <FroalaEditorView model={comments?.commentText || ""} />
            )}
          </>
        </fieldset>
      </form>
    </div>
  );
};
