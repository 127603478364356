import * as React from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { useAppSelector, useAppDispatch } from "../redux/hooks";
import { getValuations } from "redux/slices/valuationsSlice";
import { baseUrl } from "../config/config";
import moment from "moment";
import { updateStatus } from "../redux/slices/updateDataSlice";
import { useState, useEffect } from "react";
import { GenericToast } from "../components/util/ToastMessage";
import { postApiCall, getApiCall } from "../services/apiHelper";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router-dom";
import { ActionNames, Category, DapStatus } from "../constant";
import {
  shouldDisplayButton,
  handleAction,
  UpdateDapStatusRequest,
} from "./buttonVisibilityUtils";
import CommentPopup from "../components/util/commentPopup";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { rejectionCommentPopup } from "../redux/slices/dialogFormSlice";
import { getRecommmedations } from "../redux/slices/latestRecomSlice";
import AuditButton from "../components/form/Audit/auditButton";
import PrintToPDF from "../components/print/printToPdf";
import BudgetStatus from "./budgetStatus";
import Skeleton from "@mui/material/Skeleton";
import SaveIcon from "@mui/icons-material/Save";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import BlockIcon from "@mui/icons-material/Block";
import ReplayIcon from "@mui/icons-material/Replay";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import {
  resetAssetPlanState,
  clearDocumentComments,
} from "components/util/storeManager";
import { getKeyTenants } from "redux/slices/keyTenantsSlice";
import { getLeaseExpirations } from "redux/slices/leaseExpirationSlice";
import AbfStatusButton from "components/form/AbfStatus/abfStatusButton";
import FormDialog from "components/form/CommentPopup/commentPopup";
import { set } from "react-hook-form";

interface toast {
  message: any;
  severity: string;
}

export default function Title(props) {
  console.log(props.mode);

  const navigate = useNavigate();

  const dapData = useAppSelector((state) => state.summary.dapData);
  console.log("approved Date : " + dapData?.approvedDate);
  console.log("submitted Date : " + dapData?.submittedDate);
  const dapDataLoading = useAppSelector((state) => state.summary.loading);
  const savedData = useAppSelector((state) => state.updateData);
  const recomData = useAppSelector((state) => state.latestRecom.data);
  const userSectorDetail = useAppSelector(
    (state) => state.userSectorDetail.data
  );
  const currentStatus = dapData?.status || "";
  const currentSector = dapData?.investmentType || "";
  const sectorRoleMapping = userSectorDetail?.sectorRoleMap?.find(
    (rec) => rec.sectorName === currentSector
  );
  const isBelongToCurrentSector = sectorRoleMapping ? true : false;
  const userRolesList = sectorRoleMapping?.roleList;
  const isAdmin = userSectorDetail.isAdmin;
  const isAnalyst = userSectorDetail.isAnalyst;
  const savedComment = useAppSelector(
    (state) => state.saveComment.updateDapComments
  );

  const showRejectionCommentPopup = useAppSelector(
    (state) => state.dialog.dialogData.rejectionCommentPopup
  );

  const rejectionStatus = useAppSelector(
    (state) => state.dialog.dialogData.rejectionStatus
  );

  const rejectionToast = useAppSelector(
    (state) => state.dialog.dialogData.rejectionToast
  );
  const isValuationData = useAppSelector((state) => state.valuations.hasDataBeenRequested);
  const isKeyTenantData = useAppSelector((state) => state.keyTenants.hasDataBeenRequested);
  const isLeaseExpData = useAppSelector((state) => state.leaseExpirations.hasDataBeenRequested);

  const dispatch = useAppDispatch();
  const [toastMessageOpen, setToastMessageOpen] = useState(false);
  const [submitToastMessageOpen, setSubmitToastMessageOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState<toast>({
    message: "",
    severity: "",
  });

  const [saveLoaderButton, setSaveLoaderButton] = useState(false);
  const [recallLoaderButton, setRecallLoaderButton] = useState(false);
  const [submitLoaderButton, setSubmitLoaderButton] = useState(false);
  const [rejectBudgetLoaderButton, setRejectBudgetLoaderButton] =
    useState(false);
  const [approveLoaderButton, setApproveLoaderButton] = useState(false);
  const [revokeLoaderButton, setRevokeLoaderButton] = useState(false);
  const [rejectLoaderButton, setRejectLoaderButton] = useState(false);
  const assetPlanId = useAppSelector(
    (state) => state.getAssetPlanId.assetPlanId
  );

  useEffect(() => {
    if (assetPlanId !== "" && recomData.length === 0)
      dispatch(getRecommmedations(assetPlanId));
  }, [dispatch, assetPlanId]);

  const [isClosedClicked, setIsClosedClicked] = useState(false);

  function makeConditionalAPICalls(
    savedData: any,
    savedComment: any,
    userAction: string
  ) {
    const promises: Promise<any>[] = [];

    if (savedData) {
      if (userAction === "Save") {
        promises.push(
          postApiCall(`${baseUrl}UpdateData/SaveAllData`, {
            ...savedData,
            assetPlanId: dapData?.assetPlanId,
            useraction: userAction,
            recommendationTab: {
              assetPlanId: dapData?.assetPlanId,
              recommendations: recomData,
            },
          })
        );
      } else if (userAction === "Submit") {
        // check if rapid data in slices -> if no proceed w/ submit make 3 calls to endpoints w/ dispatch
        // need to add retry in case of fail -> added to valuationSlice... should be finished just need to test
        const payload = { investmentId: dapData?.investmentId || "", assetPlanId: dapData?.assetPlanId || "" };
        if (isValuationData === false) {
          dispatch(getValuations(payload));
        }
        if (isKeyTenantData === false) {
          dispatch(getKeyTenants(payload));
        }
        if (isLeaseExpData === false) {
          dispatch(getLeaseExpirations(payload));
        }
        promises.push(
          postApiCall(`${baseUrl}UpdateData/SubmitAllData`, {
            ...savedData,
            assetPlanId: dapData?.assetPlanId,
            investmendId: dapData?.investmentId,
            useraction: userAction,
            recommendationTab: {
              assetPlanId: dapData?.assetPlanId,
              recommendations: recomData,
            },
          })
        );
      }
    }
    if (savedComment) {
      promises.push(
        postApiCall(`${baseUrl}DapComments/UpdateDapComments`, savedComment)
      );
    }
    if (promises.length > 0) {
      setToastMessageOpen(false);
      Promise.all(promises)
        .then((responses) => {
          const errorMessage = responses.filter(
            (response: any) => response.errorMessage
          );
          const successResponses = responses.filter(
            (response: any) => response.success
          );

          if (successResponses.length === promises.length) {
            if (userAction === "Save") {
              setToastMessage({
                message: "Saved successfully",
                severity: "success",
              });
              clearDocumentComments(dispatch);
            }
            if (userAction === "Submit") {
              setToastMessage({
                message: "Submitted successfully",
                severity: "success",
              });
              goToDashboard();
            }
            setSubmitLoaderButton(false);
            setToastMessageOpen(true);
            setSaveLoaderButton(false);
          } else {
            setToastMessage({
              message: errorMessage,
              severity: "error",
            });
            setSubmitLoaderButton(false);
            setToastMessageOpen(true);
            setSaveLoaderButton(false);
          }
        })
        .catch((error) => {
          // Handle any errors
          console.error("Error:", error);
          setToastMessage({
            message: error.message,
            severity: "error",
          });
          setSubmitLoaderButton(false);
          setToastMessageOpen(true);
          setSaveLoaderButton(false);
        });
    } else {
      // Handle the case where no API calls are made
      console.log("No API calls made");
    }
  }

  const handleClick = async () => {
    setSubmitToastMessageOpen(false);
    setSaveLoaderButton(true);
    dispatch(updateStatus("Saved"));
    makeConditionalAPICalls(
      savedData,
      savedComment,
      ActionNames.SAVE.toString()
    );
  };

  const handleWorkFlowClick = (actionName: string): void => {
    const request: UpdateDapStatusRequest = {
      Email: userSectorDetail?.userName ?? undefined,
      UserAction: actionName,
      AssetPlanId: dapData?.assetPlanId,
      Comments: "",
    };

    // Call the handleAction function passing the action name and request object
    handleAction(actionName, request).then((response) => {
      console.log("actionName", actionName);
      setRecallLoaderButton(false);
      setRejectBudgetLoaderButton(false);
      setRevokeLoaderButton(false);
      setApproveLoaderButton(false);
      if (response.success) {
        if (actionName === "Recall") {
          setToastMessage({
            message: "Recalled sucessfully",
            severity: "success",
          });
        } else if (actionName === "RejectBudget") {
          setToastMessage({
            message: "Budget rejected Successfully",
            severity: "success",
          });
        } else {
          setToastMessage({
            message: actionName + "d sucessfully",
            severity: "success",
          });
        }
        setToastMessageOpen(true);
        setTimeout(() => {
          setToastMessageOpen(false);
          goToDashboard();
        }, 2000);
      } else {
        setToastMessageOpen(true);
        setToastMessage({
          message: response.errormessage,
          severity: "error",
        });
        setTimeout(() => {
          setToastMessageOpen(false);
        }, 4000);
      }
    });
  };

  const handleRejectClick = async () => {
    dispatch(rejectionCommentPopup(true));
  };

  const [openSubmitDialog, setOpenSubmitDialog] = useState(false);

  const handleSubmitConfirm = async () => {
    setSubmitLoaderButton(true);

    if (recomData) {
      const sustainbilityRecords = recomData?.filter((rec) => {
        return rec.category === Category.SUSTAINABILITYCATEGORY;
      });
      if ((Number(dapData?.budgetYear) > 2024 && sustainbilityRecords?.length < 5) || (Number(dapData?.budgetYear) <= 2024 && sustainbilityRecords?.length < 4)) {
        setSubmitToastMessageOpen(true);
        setSubmitLoaderButton(false);
        setTimeout(() => {
          setSubmitToastMessageOpen(false);
        }, 4000);
        return;
      }

      makeConditionalAPICalls(
        savedData,
        savedComment,
        ActionNames.SUBMIT.toString()
      );
    } else {
      setSubmitToastMessageOpen(true);
      setSubmitLoaderButton(false);
      setTimeout(() => {
        setSubmitToastMessageOpen(false);
      }, 4000);
    }
    setOpenSubmitDialog(false);
  }


  const handleSubmitClick = async () => {
    setSubmitLoaderButton(true);
    if (recomData) {
      const sustainbilityRecords = recomData?.filter((rec) => {
        return rec.category === Category.SUSTAINABILITYCATEGORY;
      });
      if (sustainbilityRecords?.length < 4) {
        setSubmitToastMessageOpen(true);
        setSubmitLoaderButton(false);
        setTimeout(() => {
          setSubmitToastMessageOpen(false);
        }, 4000);
        return;
      }
      makeConditionalAPICalls(
        savedData,
        savedComment,
        ActionNames.SUBMIT.toString()
      );
    } else {
      setSubmitToastMessageOpen(true);
      setSubmitLoaderButton(false);
      setTimeout(() => {
        setSubmitToastMessageOpen(false);
      }, 4000);
    }
  };

  const handleCloseClicked = () => {
    setIsClosedClicked(true);
  };

  const handleClose = () => {
    setIsClosedClicked(false);
  };

  const handleConfirm = () => {
    goToDashboard();
  };

  const goToDashboard = () => {
    resetAssetPlanState(dispatch);
    setTimeout(() => {
      navigate("/");
    }, 1000);
  };
  const isDefaultDateTime = (date: Date | undefined): boolean => {
    if (!date) {
      return true; // If the date is undefined, consider it as default
    }

    const defaultDateTime = moment("0001-01-01T00:00:00");
    const inputDate = moment(date);
    return inputDate.isSame(defaultDateTime);
  };



  return (
    <div className="wrapper head-section">
      {dapDataLoading ? (
        <Skeleton variant="rounded" width={400} height={40} animation="wave" />
      ) : (
        <div className="text-left">
          <h2 className="title">{dapData?.dealName}</h2>
          <BudgetStatus />
        </div>
      )}

      <div className="text-right">
        {dapDataLoading ? (
          <Skeleton
            variant="rounded"
            width={200}
            height={40}
            animation="wave"
          />
        ) : (
          <h2 className="title1 mb-0">Budget Year: {dapData?.budgetYear}</h2>
        )}
        {dapDataLoading ? (
          <Skeleton
            variant="rounded"
            width={200}
            height={30}
            animation="wave"
          />
        ) : (
          <p className="txt">
            {dapData?.submittedDate && <b>Submitted Date : </b>}
            {dapData?.submittedDate &&
              moment(dapData.submittedDate).isValid() &&
              !isDefaultDateTime(dapData.submittedDate) &&
              moment(dapData.submittedDate).format("MMM-DD-YYYY").toString()}
          </p>
        )}
        {dapDataLoading ? (
          <Skeleton
            variant="rounded"
            width={200}
            height={30}
            animation="wave"
          />
        ) : (
          <p className="txt">
            {dapData?.approvedDate &&
              dapData?.status === "Approved" &&
              !isDefaultDateTime(dapData.approvedDate) && (
                <b>Approved Date : </b>
              )}
            {dapData?.approvedDate &&
              moment(dapData.approvedDate).isValid() &&
              dapData?.status === "Approved" &&
              !isDefaultDateTime(dapData.approvedDate) &&
              moment(dapData.approvedDate).format("MMM-DD-YYYY").toString()}
          </p>
        )}
        {dapDataLoading ? (
          <Skeleton
            variant="rounded"
            width={200}
            height={30}
            animation="wave"
          />
        ) : (
          <p className="txt">
            <b>Status : </b>
            {dapData?.status}
          </p>
        )}
      </div>
      <div className="clear"></div>
      <hr />
      {dapDataLoading ? (
        <Skeleton variant="rounded" width="100%" height={40} animation="wave" />
      ) : (
        <div className="head-action-btn">
          {shouldDisplayButton(
            userRolesList,
            currentStatus,
            isBelongToCurrentSector,
            ActionNames.RECALL,
            isAdmin,
            isAnalyst
          ) && (
              <Stack direction="row" spacing={2} className="text-left">
                <LoadingButton
                  disabled={
                    submitLoaderButton ||
                    rejectBudgetLoaderButton ||
                    revokeLoaderButton ||
                    approveLoaderButton ||
                    saveLoaderButton
                  }
                  loading={recallLoaderButton}
                  variant="contained"
                  className="primary"
                  size="small"
                  onClick={() => {
                    setRecallLoaderButton(true);
                    handleWorkFlowClick(ActionNames.RECALL);
                  }}
                  sx={{ lineHeight: 2.2 }}
                >
                  <ReplayIcon
                    sx={{ mr: "2.5px" }}
                  // sx={{ mt: "0", fontSize: "1.25rem", mr: "2.5px" }}
                  />
                  Recall
                </LoadingButton>
                <div></div>
              </Stack>
            )}
          <Stack direction="row" spacing={2} className="text-left">
            {shouldDisplayButton(
              userRolesList,
              currentStatus,
              isBelongToCurrentSector,
              ActionNames.SAVE,
              isAdmin,
              isAnalyst
            ) && (
                <Stack direction="row" spacing={2} className="text-left">
                  {props.mode === "edit" && (
                    <LoadingButton
                      disabled={
                        submitLoaderButton ||
                        rejectBudgetLoaderButton ||
                        revokeLoaderButton ||
                        approveLoaderButton ||
                        recallLoaderButton
                      }
                      loading={saveLoaderButton}
                      variant="contained"
                      className="primary"
                      onClick={handleClick}
                    >
                      <SaveIcon
                        sx={{ mr: "2.5px" }}
                      // sx={{ mt: "0", fontSize: "1.25rem", mr: "2.5px" }}
                      />{" "}
                      Save
                    </LoadingButton>
                  )}
                </Stack>
              )}

            {shouldDisplayButton(
              userRolesList,
              currentStatus,
              isBelongToCurrentSector,
              ActionNames.SUBMIT,
              isAdmin,
              isAnalyst
            ) && (
                <Stack direction="row" spacing={2} className="text-left">
                  {props.mode === "edit" && (
                    <LoadingButton
                      disabled={
                        recallLoaderButton ||
                        rejectBudgetLoaderButton ||
                        revokeLoaderButton ||
                        approveLoaderButton ||
                        saveLoaderButton
                      }
                      loading={submitLoaderButton}
                      variant="contained"
                      className="primary"
                      size="small"
                      // onClick={handleSubmitClick}
                      onClick={() => {
                        if (currentStatus == DapStatus.Saved) {
                          setOpenSubmitDialog((prev) => !prev);
                        }
                        else {
                          handleSubmitClick()
                        }
                      }}
                    >
                      <DoneIcon
                        sx={{ mr: "2.5px" }}
                      // sx={{ mt: "0", fontSize: "1.25rem", mr: "2.5px" }}
                      />{" "}
                      Submit
                    </LoadingButton>
                  )}
                </Stack>
              )}

            {shouldDisplayButton(
              userRolesList,
              currentStatus,
              isBelongToCurrentSector,
              ActionNames.REJECT_BUDGET,
              isAdmin,
              isAnalyst
            ) && (
                <Stack direction="row" spacing={2} className="text-left">
                  <LoadingButton
                    disabled={
                      recallLoaderButton ||
                      submitLoaderButton ||
                      revokeLoaderButton ||
                      approveLoaderButton ||
                      saveLoaderButton
                    }
                    loading={rejectBudgetLoaderButton}
                    variant="contained"
                    className="primary"
                    size="small"
                    onClick={() => {
                      setRejectBudgetLoaderButton(true);
                      handleWorkFlowClick(ActionNames.REJECT_BUDGET);
                    }}
                  >
                    <BlockIcon
                      sx={{ mr: "2.5px", whiteSpace: "nowrap" }}
                    // sx={{ mt: "0", fontSize: "1.25rem", mr: "2.5px" }}
                    />
                    Reject Budget
                  </LoadingButton>
                </Stack>
              )}
          </Stack>
          {shouldDisplayButton(
            userRolesList,
            currentStatus,
            isBelongToCurrentSector,
            ActionNames.REVOKE,
            isAdmin,
            isAnalyst
          ) && (
              <Stack direction="row" spacing={2} className="text-left">
                <LoadingButton
                  disabled={
                    recallLoaderButton ||
                    submitLoaderButton ||
                    rejectBudgetLoaderButton ||
                    approveLoaderButton ||
                    saveLoaderButton
                  }
                  loading={revokeLoaderButton}
                  variant="contained"
                  className="primary"
                  size="small"
                  onClick={() => {
                    setRevokeLoaderButton(true);
                    handleWorkFlowClick(ActionNames.REVOKE);
                  }}
                  sx={{ lineHeight: 2.2 }}
                >
                  <KeyboardReturnIcon
                    sx={{ mr: "2.5px" }}
                  // sx={{ mt: "0", fontSize: "1.25rem", mr: "2.5px" }}
                  />
                  Revoke
                </LoadingButton>
              </Stack>
            )}

          {shouldDisplayButton(
            userRolesList,
            currentStatus,
            isBelongToCurrentSector,
            ActionNames.APPROVE,
            isAdmin,
            isAnalyst
          ) && (
              <Stack direction="row" spacing={2} className="text-left">
                <LoadingButton
                  disabled={
                    recallLoaderButton ||
                    submitLoaderButton ||
                    rejectBudgetLoaderButton ||
                    revokeLoaderButton ||
                    saveLoaderButton
                  }
                  loading={approveLoaderButton}
                  variant="contained"
                  className="primary"
                  size="small"
                  onClick={() => {
                    setApproveLoaderButton(true);
                    handleWorkFlowClick(ActionNames.APPROVE);
                  }}
                >
                  <ThumbUpAltIcon
                    sx={{ mr: "2.5px" }}
                  // sx={{ mt: "0", fontSize: "1.25rem", mr: "2.5px" }}
                  />{" "}
                  Approve
                </LoadingButton>

                <LoadingButton
                  disabled={
                    recallLoaderButton ||
                    submitLoaderButton ||
                    rejectBudgetLoaderButton ||
                    revokeLoaderButton ||
                    saveLoaderButton ||
                    approveLoaderButton
                  }
                  variant="contained"
                  className="primary"
                  size="small"
                  onClick={handleRejectClick}
                  sx={{ lineHeight: 2.2 }}
                >
                  <BlockIcon
                    sx={{ mr: "2.5px" }}
                  // sx={{ mt: "0", fontSize: "1.25rem", mr: "2.5px" }}
                  />
                  Reject
                </LoadingButton>
                <div></div>
              </Stack>
            )}

          <Stack direction="row" spacing={2} className="text-right">
            {/* <PrintComponent /> */}
            <PrintToPDF />
            <AuditButton />
            {dapData?.investmentId.toLowerCase().includes('portfolio') ? <AbfStatusButton /> : null}
            <Button
              sx={{ lineHeight: 2.2 }}
              variant="contained"
              className="primary"
              size="small"
              onClick={handleCloseClicked}
            >
              <CloseIcon
                sx={{ mr: "2.5px" }}
              // sx={{ mt: "0", fontSize: "1.25rem", mr: "2.5px" }}
              />{" "}
              Close
            </Button>
          </Stack>
          <div className="clear"></div>
        </div>
      )}
      {submitToastMessageOpen && (
        <GenericToast
          message="Please complete sustainability Recommendation !"
          severity="error"
        />
      )}
      {toastMessageOpen && (
        <GenericToast
          message={toastMessage.message}
          severity={toastMessage.severity}
        />
      )}

      {showRejectionCommentPopup && <CommentPopup />}
      {rejectionToast && (
        <GenericToast
          message={rejectionStatus.message}
          severity={rejectionStatus.severity}
        />
      )}

      <FormDialog
        shouldShow={openSubmitDialog}
        dialogContent={"The budget is not submitted yet. Do you still want to submit the plan?"}
        dialogTitle={"Submit Confirmation"}
        handleRequestConfirm={() => { handleSubmitConfirm() }}
        closeDialog={() => setOpenSubmitDialog(false)}
      />

      <Dialog
        open={isClosedClicked}
        onClose={handleClose}
        className="common-dialog-box"
      >
        <DialogTitle id="alert-dialog-title">{"Exit Confirmation"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to close the form?
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button className="primary" variant="contained" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            className="primary"
            variant="contained"
            onClick={handleConfirm}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
