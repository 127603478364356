
import moment from "moment";

export function currency(inputValue: any, currency: string = '$', decimalPlaces: number = 2): string {

    let value = inputValue;
    if (typeof value === "number") {
        return `${currency}${formatValue(value)}`;
    }
    if(value != null){
        value = value.toString().replace(/,/g, "").replace(/\$/g,"");
    }
    value = parseFloat(value);
    if (!isNaN(value)) {
        return `${currency}${formatValue(value)}`;
    }
    return "";
};

export const formatValue = (input: number, decimalPlaces: number = 2) => {
    return input.toLocaleString('en-US', {
        minimumFractionDigits: decimalPlaces,
        maximumFractionDigits: decimalPlaces
      })
};

export function percentage(inputValue: any, decimalPlaces: number = 2): string {

    let value = inputValue;
    if (typeof value === "number") {
        return `${value.toFixed(decimalPlaces)}`;
    }    
    value = parseFloat(value);
    if (!isNaN(value)) {
        return `${value.toFixed(decimalPlaces)}`;
    }
    return "";
};

export function numberWithComma(inputValue: any, decimalPlaces: number = 2): string {

    let value = inputValue;
    
    if (typeof value === "number") {
        return formatValue(value);
    }
    if(value != null){
        value = value.toString().replace(/,/g, "")
    }
    //return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    value = parseFloat(value);
    return isNaN(value) ? "" : formatValue(value);
};

export const formatYearBuilt = (year: number | undefined) => {
    if (year === undefined) return "";
    if (year === 0) return "-";
    return year;
  }

export const numToMoneyFormat = (x: number) => {
    return "$" + x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
export  const numWithComma = (x: number) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  export const dateTimeToDateFormat=(value:string)=>
  {
    if(value===null || value===undefined)
      {
          return "";
      }
    if (typeof value === "string") {
          var formattedDate = moment(value?.trim()).format("MMM-DD-YYYY");
           console.log("check formatted date", formattedDate);
           return formattedDate;
        }
      
      return "";
  }
