import React, { FC, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
// import TextField from '@mui/material/TextField';
// import Autocomplete from '@mui/material/Autocomplete';
import {
  SelectOption,
  Option,
} from "../../../redux/slices/dropdownOptionSlice";
import {
  SectionNames,
  RecommendDropdown,
  Category,
  Key,
  CommentSectionName,
} from "../../../constant";
import GroupedAutocomplete from "../../util/GroupedAutocomplete";
import { useAppSelector, useAppDispatch } from "../../../redux/hooks";
import {
  getRecommmedations,
  RecommendationData,
  updateRecommendationData,
} from "../../../redux/slices/latestRecomSlice";
import {
  setMissionGroup,
  updateMissionOption,
} from "../../../redux/slices/missionGroupSlice";

import {
  fetchTabPageData,
  TabPage,
} from "../../../redux/slices/tabPagesOptionsSlice";
import { createTheme } from "@mui/material/styles";
import FroalaEditorView from "react-froala-wysiwyg/FroalaEditorView";
import Skeleton from "@mui/material/Skeleton";
import { GenericToast } from "../../util/ToastMessage";
import { saveDapCommentData } from "../../../redux/slices/saveCommentSlice";
import {
  DapComment,
} from "../../../redux/slices/updateDapCommentSlice";

export const RecommendationViewTestForm = () => {
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "left",
    color: theme.palette.text.secondary,
  }));

  const theme = createTheme({
    palette: {
      mode: "light", // or "light"
    },
    typography: {
      // Typography styles
    },
  });

  const [selectedOptions, setSelectedOptions] = useState<{
    [key: string]: Option | null;
  }>({});
  const [assetManagerRationale, setAssetManagerRationale] = useState<{
    [key: string]: string | null;
  }>({});
  const [groups, setGroups] = useState<SelectOption[]>([]);
  const [sustainabilityGroupWidth, setSustainabilityGroupWidth] = useState<number>(3);

  const recomData = useAppSelector((state) => state.recommendations?.data);
  const recomDataStatus = useAppSelector((state) => state.recommendations);
  const missionGroup = useAppSelector(
    (state) => state.missionGroups?.missionStatement
  );
  const comment = useAppSelector((state) => state.dapComments?.data)?.find(
    (x) =>
      x.sectionName.toLowerCase() ===
      CommentSectionName.MISSIONSTATEMENT.toLowerCase()
  );

  console.log("missionGroup", missionGroup)


  const dispatch = useAppDispatch();

  const assetPlanId = useAppSelector(
    (state) => state.getAssetPlanId?.assetPlanId
  );
  const sectionOptions = useAppSelector((state) =>
    state.dropdownAllOptions.tabData?.find(
      (tab) => tab.tabPageName === SectionNames.RECOMMENDATION
    )
  ) as TabPage;

  const budgetYear = Number(
    useAppSelector((state) => state.summary.dapData?.budgetYear)
  );

  useEffect(() => {
    setSustainabilityGroupWidth(budgetYear >= 2025 ? 2.4 : 3);
  }, [recomDataStatus.loading]);

  useEffect(() => {
    if (!sectionOptions)
      dispatch(fetchTabPageData(SectionNames.RECOMMENDATION));
  }, [dispatch, SectionNames.RECOMMENDATION]);

  useEffect(() => {
    if (sectionOptions) {
      const transformedGroups: SelectOption[] = Object.values(
        sectionOptions.data
      ).flatMap((groupData: any) => {
        return groupData?.map((group: any) => {
          const { key, options } = group;
          const transformedOptions: Option[] = options
            ? options.map((option: any) => {
              const { text, value } = option;
              return { text, value };
            })
            : [];
          return { key, options: transformedOptions };
        });
      });
      setGroups(transformedGroups);
    }
  }, [dispatch, sectionOptions]);


  useEffect(() => {
    if (groups?.length > 0 && missionGroup?.length === 0) {
      const missionStatementGroup = groups?.find(
        (group) => group.key === "MissionStatement"
      );
      if (missionStatementGroup) {
        const updatedOptions = missionStatementGroup.options.map((option) => {
          if (comment && option.text === comment.commentKey) {
            return { ...option, value: comment.commentText };
          }
          return option;
        });

        const updatedMissionStatementGroup = {
          ...missionStatementGroup,
          options: updatedOptions,
        };

        dispatch(setMissionGroup(updatedMissionStatementGroup?.options));
      }
    }
  }, [groups, missionGroup, dispatch]);

  useEffect(() => {
    if (assetPlanId !== '') {
      dispatch(getRecommmedations(assetPlanId)).then((recomData) => {
        const initialSelectedOptions: { [key: string]: Option | null } = {};
        const initialAssetManagerRationales: { [key: string]: string | null } =
          {};
        const recomPayLoadArray = recomData.payload || [];
        Object.values(recomPayLoadArray).forEach((rec) => {
          initialAssetManagerRationales[rec.category + "_" + rec.key] =
            rec.assetManagerRationale || null;
          initialSelectedOptions[rec.category + "_" + rec.key] = {
            text: rec.value,
            value: rec.value,
            order: "",
          };
        });
        setAssetManagerRationale(initialAssetManagerRationales);
        setSelectedOptions(initialSelectedOptions);
      });
    }
  }, [dispatch, assetPlanId]);

  const handleSelect = (dropdownId: string, option: Option) => {
    const updatedSelectedOptions: { [key: string]: Option | null } = {
      ...selectedOptions,
      [dropdownId]: option,
    };
    setSelectedOptions(updatedSelectedOptions);

    const selectedRecomData = recomData?.find((rec) => {
      const categoryKey = rec.category + "_" + rec.key;
      return categoryKey === dropdownId;
    });
    const updatedRecomData: RecommendationData = {
      id: selectedRecomData ? selectedRecomData.id : 0,
      category: dropdownId.split("_")[0],
      key: dropdownId.split("_")[1],
      value: option.value,
      assetManagerRationale: assetManagerRationale[dropdownId] || "",
    };

    dispatch(updateRecommendationData(updatedRecomData));
  };

  const handleModelChange = (model: string, dropdownId: string) => {
    const updatedSelectedOptions: { [key: string]: string | null } = {
      ...assetManagerRationale,
      [dropdownId]: model,
    };
    setAssetManagerRationale(updatedSelectedOptions);

    const selectedRecomData = recomData?.find((rec) => {
      const categoryKey = rec.category + "_" + rec.key;
      return categoryKey === dropdownId;
    });
    const updatedRecomData: RecommendationData = {
      id: selectedRecomData ? selectedRecomData.id : 0,
      category: dropdownId.split("_")[0],
      key: dropdownId.split("_")[1],
      value: selectedOptions[dropdownId]?.value || "",
      assetManagerRationale: model,
    };

    // Dispatch the action to update the recommendation data
    dispatch(updateRecommendationData(updatedRecomData));
  };

  const handleTextareaChange = (model: string, text: string) => {
    const updatedOption: Option = {
      text: text,
      value: model,
      order: "0",
    };
    dispatch(updateMissionOption(updatedOption));
    // const updatedMissionStatement = missionGroup.map((option) => {
    //   if (option.text === text) {
    //     return updatedOption;
    //   }
    //   return option;
    // });
    const updatedcomment: DapComment = {
      id: comment ? comment.id : 0,
      commentKey: text,
      commentText: model || "",
      sectionName: CommentSectionName.MISSIONSTATEMENT,
      assetPlanId: assetPlanId,
    };
    dispatch(saveDapCommentData(updatedcomment));
  };

  console.log(budgetYear);


  return (
    <>
      <Box sx={{ flexGrow: 1 }} className="summary-form m-20">
        <h4 className="text-left">Investment Recommendations</h4>
        <Grid container spacing={2}>
          {/* block1 */}
          <Grid item xs={12} md={4}>
            {recomDataStatus.loading ? (
              <Skeleton
                variant="rounded"
                width="100%"
                height={280}
                animation="wave"
              />
            ) : (
              <Paper
                style={{
                  backgroundColor:
                    theme.palette.mode === "dark" ? "#1A2027" : "#fff",
                  ...theme.typography.body2,
                  padding: theme.spacing(1),
                  textAlign: "left",
                  color: theme.palette.text.secondary,
                }}
              >
                <div>
                  <h4 className="text-left">Classification</h4>
                  <GroupedAutocomplete
                    readOnly={true}
                    group={
                      groups?.find(
                        (group) =>
                          group.key === RecommendDropdown.INVESTMENT_KEY1
                      ) || { key: "", options: [] }
                    }
                    selectedOption={
                      selectedOptions[
                      `${Category.INVESTMENTCATEGORY}_${Key.Key1}`
                      ] || null
                    }
                    onSelect={(option) =>
                      handleSelect(
                        `${Category.INVESTMENTCATEGORY}_${Key.Key1}`,
                        option
                      )
                    }
                    label="Classification"
                  />
                  <h4 className="text-left">Asset Manager Rationale</h4>
                  <div style={{ marginTop: "74px" }}></div>
                  <FroalaEditorView
                    model={
                      assetManagerRationale[
                      `${Category.INVESTMENTCATEGORY}_${Key.Key1}`
                      ] || ""
                    }
                  />
                </div>
              </Paper>
            )}
          </Grid>

          {/* block2 */}

          <Grid item xs={12} md={4}>
            {recomDataStatus?.loading ? (
              <Skeleton
                variant="rounded"
                width="100%"
                height={280}
                animation="wave"
              />
            ) : (
              <Paper
                style={{
                  backgroundColor:
                    theme.palette.mode === "dark" ? "#1A2027" : "#fff",
                  ...theme.typography.body2,
                  padding: theme.spacing(1),
                  textAlign: "left",
                  color: theme.palette.text.secondary,
                }}
              >
                <div>
                  <h4 className="text-left">Hold Period</h4>
                  <GroupedAutocomplete
                    readOnly={true}
                    group={
                      groups?.find(
                        (group) =>
                          group.key === RecommendDropdown.INVESTMENT_HOLDPERIOD
                      ) || { key: "", options: [] }
                    }
                    selectedOption={
                      selectedOptions[
                      `${Category.INVESTMENTCATEGORY}_${Key.Key2}`
                      ] || null
                    }
                    onSelect={(option) =>
                      handleSelect(
                        `${Category.INVESTMENTCATEGORY}_${Key.Key2}`,
                        option
                      )
                    }
                    label="Hold Period"
                  />
                  <h4 className="text-left">Asset Manager Rationale</h4>

                  <div className="CustomPaper">
                    <div style={{ marginTop: "74px" }}></div>
                    <FroalaEditorView
                      model={
                        assetManagerRationale[
                        `${Category.INVESTMENTCATEGORY}_${Key.Key2}`
                        ] || ""
                      }
                    />
                  </div>
                </div>
              </Paper>
            )}
          </Grid>

          {/* block3 */}

          <Grid item xs={12} md={4}>
            {recomDataStatus.loading ? (
              <Skeleton
                variant="rounded"
                width="100%"
                height={280}
                animation="wave"
              />
            ) : (
              <Paper
                style={{
                  backgroundColor:
                    theme.palette.mode === "dark" ? "#1A2027" : "#fff",
                  ...theme.typography.body2,
                  padding: theme.spacing(1),
                  textAlign: "left",
                  color: theme.palette.text.secondary,
                }}
              >
                <div>
                  <h4 className="text-left">Actions</h4>
                  <GroupedAutocomplete
                    readOnly={true}
                    group={
                      groups?.find(
                        (group) =>
                          group.key === RecommendDropdown.INVESTMENT_KEY3
                      ) || { key: "", options: [] }
                    }
                    selectedOption={
                      selectedOptions[
                      `${Category.INVESTMENTCATEGORY}_${Key.Key3}`
                      ] || null
                    }
                    onSelect={(option) =>
                      handleSelect(
                        `${Category.INVESTMENTCATEGORY}_${Key.Key3}`,
                        option
                      )
                    }
                    label="Actions"
                  />
                  <h4 className="text-left">Asset Manager Rationale</h4>
                  <div style={{ marginTop: "74px" }}></div>
                  <FroalaEditorView
                    model={
                      assetManagerRationale[
                      `${Category.INVESTMENTCATEGORY}_${Key.Key3}`
                      ] || ""
                    }
                  />
                </div>
              </Paper>
            )}
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ flexGrow: 1 }} className="summary-form m-20">
        <h4 className="text-left">Sustainability Recommendations</h4>
        <Grid container spacing={2}>
          {/* block1 */}
          <Grid item xs={12} md={sustainabilityGroupWidth}>
            {recomDataStatus.loading ? (
              <Skeleton
                variant="rounded"
                width="100%"
                height={280}
                animation="wave"
              />
            ) : (
              <Paper
                style={{
                  backgroundColor:
                    theme.palette.mode === "dark" ? "#1A2027" : "#fff",
                  ...theme.typography.body2,
                  padding: theme.spacing(1),
                  textAlign: "left",
                  color: theme.palette.text.secondary,
                }}
              >
                <div>
                  <h4 className="text-left">Physical Risk Considerations</h4>
                  <GroupedAutocomplete
                    readOnly={true}
                    group={
                      groups?.find(
                        (group) =>
                          group.key === RecommendDropdown.SUSTAIN_PHYSICALRISK
                      ) || { key: "", options: [] }
                    }
                    selectedOption={
                      selectedOptions[
                      `${Category.SUSTAINABILITYCATEGORY}_${Key.Key1}`
                      ] || null
                    }
                    onSelect={(option) =>
                      handleSelect(
                        `${Category.SUSTAINABILITYCATEGORY}_${Key.Key1}`,
                        option
                      )
                    }
                    label="Physical Risk Considerations"
                  />
                  <h4 className="text-left">
                    {budgetYear <= 2024 ? `Summarize the exposure and mitigation plan if applicable` : `If exposed to a risk, describe how the risk is being evaluated and mitigated`}

                  </h4>
                  <div style={{ marginTop: "74px" }}></div>
                  <FroalaEditorView
                    model={
                      assetManagerRationale[
                      `${Category.SUSTAINABILITYCATEGORY}_${Key.Key1}`
                      ] || ""
                    }
                  />
                </div>
              </Paper>
            )}
          </Grid>
          {/* block2 */}
          <Grid item xs={12} md={sustainabilityGroupWidth}>
            {recomDataStatus.loading ? (
              <Skeleton
                variant="rounded"
                width="100%"
                height={280}
                animation="wave"
              />
            ) : (
              <Paper
                style={{
                  backgroundColor:
                    theme.palette.mode === "dark" ? "#1A2027" : "#fff",
                  ...theme.typography.body2,
                  padding: theme.spacing(1),
                  textAlign: "left",
                  color: theme.palette.text.secondary,
                }}
              >
                <div>
                  <h4 className="text-left">Regulatory Risk Considerations</h4>

                  <GroupedAutocomplete
                    readOnly={true}
                    group={
                      groups?.find(
                        (group) =>
                          group.key === RecommendDropdown.SUSTAIN_REGULATORYRISK
                      ) || { key: "", options: [] }
                    }
                    selectedOption={
                      selectedOptions[
                      `${Category.SUSTAINABILITYCATEGORY}_${Key.Key2}`
                      ] || null
                    }
                    onSelect={(option) =>
                      handleSelect(
                        `${Category.SUSTAINABILITYCATEGORY}_${Key.Key2}`,
                        option
                      )
                    }
                    label="Regulatory Risk Considerations"
                  />

                  <h4 className="text-left">
                    {budgetYear <= 2024 ? `Summarize the status of compliance if applicable` : `If exposed to a risk, describe how the risk is being evaluated and the status of compliance`}

                  </h4>
                  <div style={{ marginTop: "74px" }}></div>
                  <FroalaEditorView
                    model={
                      assetManagerRationale[
                      `${Category.SUSTAINABILITYCATEGORY}_${Key.Key2}`
                      ] || ""
                    }
                  />
                </div>
              </Paper>
            )}
          </Grid>

          {budgetYear > 2024 ? <Grid item xs={12} md={sustainabilityGroupWidth}>
            {recomDataStatus.loading ? (
              <Skeleton
                variant="rounded"
                width="100%"
                height={280}
                animation="wave"
              />
            ) : (
              <Paper
                style={{
                  backgroundColor:
                    theme.palette.mode === "dark" ? "#1A2027" : "#fff",
                  ...theme.typography.body2,
                  padding: theme.spacing(1),
                  textAlign: "left",
                  color: theme.palette.text.secondary,
                }}
              >
                <div>
                  <h4 className="text-left">Net Zero Carbon Audit</h4>
                  <GroupedAutocomplete
                    readOnly={true}
                    group={
                      groups?.find(
                        (group) =>
                          group.key === RecommendDropdown.SUSTAIN_NETZERO
                      ) || { key: "", options: [] }
                    }
                    selectedOption={
                      selectedOptions[
                      `${Category.SUSTAINABILITYCATEGORY}_${Key.Key5}`
                      ] || null
                    }
                    onSelect={(option) =>
                      handleSelect(
                        `${Category.SUSTAINABILITYCATEGORY}_${Key.Key5}`,
                        option
                      )
                    }
                    label="Net Zero Carbon Audit"
                  />

                  <div style={{ marginTop: "74px" }}></div>
                  {/* <FroalaRichText
                    model={
                      assetManagerRationale[
                      `${Category.SUSTAINABILITYCATEGORY}_${Key.Key5}`
                      ] || ""
                    }
                    handlesModelChange={(model: any) =>
                      handleModelChange(
                        model,
                        `${Category.SUSTAINABILITYCATEGORY}_${Key.Key5}`
                      )
                    }
                  /> */}

                  <FroalaEditorView
                    model={
                      assetManagerRationale[
                      `${Category.SUSTAINABILITYCATEGORY}_${Key.Key5}`
                      ] || ""
                    }
                  />
                </div>
              </Paper>
            )}
          </Grid> : null}

          <Grid item xs={12} md={sustainabilityGroupWidth}>
            {recomDataStatus.loading ? (
              <Skeleton
                variant="rounded"
                width="100%"
                height={280}
                animation="wave"
              />
            ) : (
              <Paper
                style={{
                  backgroundColor:
                    theme.palette.mode === "dark" ? "#1A2027" : "#fff",
                  ...theme.typography.body2,
                  padding: theme.spacing(1),
                  textAlign: "left",
                  color: theme.palette.text.secondary,
                }}
              >
                <div>
                  <h4 className="text-left">
                    {budgetYear <= 2024 ? `SSBB Minimum Standards` : `SSBB/Sustainability projects budgeted`}
                  </h4>
                  <GroupedAutocomplete
                    readOnly={true}
                    group={
                      groups?.find(
                        (group) =>
                          group.key === RecommendDropdown.SUSTAIN_TWREPORT
                      ) || { key: "", options: [] }
                    }
                    selectedOption={
                      selectedOptions[
                      `${Category.SUSTAINABILITYCATEGORY}_${Key.Key3}`
                      ] || null
                    }
                    onSelect={(option) =>
                      handleSelect(
                        `${Category.SUSTAINABILITYCATEGORY}_${Key.Key3}`,
                        option
                      )
                    }
                    label="SSBB Minimum Standards"
                  />

                  <h4 className="text-left">
                    {budgetYear <= 2024 ? `Summarize rationale and/or planned improvements` : `List any planned projects specific to this asset.`}

                  </h4>
                  <div style={{ marginTop: "74px" }}></div>
                  <FroalaEditorView
                    model={
                      assetManagerRationale[
                      `${Category.SUSTAINABILITYCATEGORY}_${Key.Key3}`
                      ] || ""
                    }
                  />
                </div>
              </Paper>
            )}
          </Grid>

          <Grid item xs={12} md={sustainabilityGroupWidth}>
            {recomDataStatus.loading ? (
              <Skeleton
                variant="rounded"
                width="100%"
                height={280}
                animation="wave"
              />
            ) : (
              <Paper
                style={{
                  backgroundColor:
                    theme.palette.mode === "dark" ? "#1A2027" : "#fff",
                  ...theme.typography.body2,
                  padding: theme.spacing(1),
                  textAlign: "left",
                  color: theme.palette.text.secondary,
                }}
              >
                <div>
                  <h4 className="text-left">
                    Tenant/Resident Feedback Action Plan
                  </h4>
                  <GroupedAutocomplete
                    readOnly={true}
                    group={
                      groups?.find(
                        (group) =>
                          group.key ===
                          RecommendDropdown.SUSTAIN_TENANT_FEEDBACK
                      ) || { key: "", options: [] }
                    }
                    selectedOption={
                      selectedOptions[
                      `${Category.SUSTAINABILITYCATEGORY}_${Key.Key4}`
                      ] || null
                    }
                    onSelect={(option) =>
                      handleSelect(
                        `${Category.SUSTAINABILITYCATEGORY}_${Key.Key4}`,
                        option
                      )
                    }
                    label="Tenant/Resident Feedback Action Plan"
                  />

                  <h4 className="text-left">
                    {budgetYear <= 2024 ? `Summarize action plan` : `Summarize the asset action plan from the satisfaction survey.`}
                  </h4>

                  <div style={{ marginTop: "74px" }}></div>
                  <FroalaEditorView
                    model={
                      assetManagerRationale[
                      `${Category.SUSTAINABILITYCATEGORY}_${Key.Key4}`
                      ] || ""
                    }
                  />
                </div>
              </Paper>
            )}
          </Grid>
        </Grid>
      </Box>

      <form className="m-20 mission-form">
        <fieldset>
          <legend className="legend">Mission Statement</legend>
          <FroalaEditorView
            model={
              missionGroup?.find(
                (option: Option) =>
                  option.text ===
                  selectedOptions[`${Category.INVESTMENTCATEGORY}_${Key.Key1}`]
                    ?.value
              )?.value || ""
            }
          />
        </fieldset>
      </form>
      {recomDataStatus.error && (
        <GenericToast message={recomDataStatus.error} severity="error" />
      )}
    </>
  );
};
