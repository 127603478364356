import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import {
  postApiCall,
} from "../../../services/apiHelper";
import { baseUrl } from "../../../config/config";
import DialogTitle from "@mui/material/DialogTitle";
import { Autocomplete, IconButton, TextField, Typography } from "@mui/material";
import { useAppSelector, useAppDispatch } from "../../../redux/hooks";
import { MessageBox } from "../../notification/messageBox";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect } from "react";
import {
  hideCreateDapPopup,
  storeSelectedYear,
  storeSelectedInvestmentId,
  showDuplicateResponseAlert,
  showDeleteResponseAlert
} from "../../../redux/slices/dialogFormSlice";
import { getInvestments } from "../../../redux/slices/distinctInvestmentSlice";
import { getYear } from "../../../redux/slices/displayedYearSlice";
import {
  getAssetPlanId,
  requestAssetPlanID,
} from "../../../redux/slices/assetPlanIdSlice";
import CircularProgress from "@mui/material/CircularProgress";
import {
  getInvestmentDetailsById,
  PostData,
} from "../../../redux/slices/summaryFormSlice";
import {
  DashboardService,
  viewPayload,
} from "services/DashboardService";

export default function NewFormDialog() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [openInvestment, setOpenInvestment] = React.useState(false);
  const [openYear, setOpenYear] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState<any>();
  const [createNewDeleted, setCreateNewDeleted] = React.useState(false);

  const dashboardService = new DashboardService();
  const userEmailId =
    useAppSelector((state) => state.currentUser.data.email) ?? "";
  const payload: viewPayload = {
    userEmailId: userEmailId,
    viewId: "fff7c3fa-32f4-4c95-a3b1-a528f4ec6b10",
    filters: [],
    page: 0,
    pageSize: 10000,
    rowCount: 2000,
  };

  const showDuplicateAlert = useAppSelector(
    (state) => state.dialog.dialogData.showDuplicateAlert
  );
  const showDeleteAlert = useAppSelector(
    (state) => state.dialog.dialogData.showDeleteAlert
  );
  const showPopupForm = useAppSelector(
    (state) => state.dialog.dialogData.showPopupForm
  );
  const selectedInvestmentID = useAppSelector(
    (state) => state.dialog.dialogData.selectedInvestmentID
  );
  const selectedYear = useAppSelector(
    (state) => state.dialog.dialogData.selectedYear
  );
  const investmentIds = useAppSelector(
    (state) => state.distinctInvestment.disctinctInvestments
  );
  const investmentIdsStatus = useAppSelector(
    (state) => state.distinctInvestment
  );
  const yearSet = useAppSelector(
    (state) => state.getDisplayedYear.displayedYear
  );
  const yearSetStatus = useAppSelector((state) => state.getDisplayedYear);
  const assetPlanIdDetails = useAppSelector((state) => state.getAssetPlanId);
  const dapData: any = useAppSelector((state) => state.summary.dapData);

  const handleClose = () => {
    dispatch(hideCreateDapPopup());
  };

  const onInvestmentIdChange = (e: any, value: any) => {
    dispatch(storeSelectedInvestmentId(value.split(" -")[0]));
  };

  const onDateChange = (e: any, value: any) => {
    dispatch(storeSelectedYear(value));
  };
  const isAdmin = useAppSelector(
    (state) => state.userSectorDetail.data.isAdmin
  );
  const isAnalyst = useAppSelector(
    (state) => state.userSectorDetail.data.isAnalyst
  );
  const roles = useAppSelector((state) => state.userSectorDetail.data.roles);
  const isAssetManager = roles.includes("Asset Manager");
  const [sectorInvestmentIds, setSectorInvestmentIds] = React.useState<
    string[]
  >([]);
  useEffect(() => {
    if (showPopupForm) {
      dispatch(getInvestments());
      dispatch(getYear());
      dashboardService.getAssetPlansByView(payload).then((res) => {
        setSectorInvestmentIds(
          res?.data?.sort((a, b) =>
            a.investmentId.localeCompare(b.investmentId)
          )
        );
      });
    }
  }, [showPopupForm]);
  console.log(sectorInvestmentIds);

  const request: requestAssetPlanID = {
    investmentId: selectedInvestmentID,
    budgetYear: selectedYear,
  };
  const requestForPropertyImages: any = {
    investmentId: selectedInvestmentID,
  };

  const createNewDeletedPlan = () => {
    setCreateNewDeleted(true);
  }

  const handleCreateNewDap = async () => {
    setLoading(true);
    const response = await postApiCall(`${baseUrl}AssetPlan/Create`, request)
      .then((res: any) => res)
      .then((data) => {
        setLoading(false);
        //resetAssetPlanState(dispatch); //TODO Check with dev who is familiar with this flow
        setData(data.assetPlanId);
        dispatch(getAssetPlanId(data.assetPlanId));
        if (data.isDeleted) {
          dispatch(showDeleteResponseAlert());
        } else if (data.isDublicate) {
          dispatch(showDuplicateResponseAlert());
        } else {
          const postData: PostData = {
            dapId: data.assetPlanId,
          };
          dispatch(hideCreateDapPopup());
          dispatch(getInvestmentDetailsById(postData));
          navigate("/dashboard/form/edit/" + data.assetPlanId);
        }
      });
  };

  const allInputPresent = selectedInvestmentID && selectedYear;
  return (
    <Typography>
      <Dialog
        open={showPopupForm}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="dashboard-dialog-box"
      >
        <DialogTitle>
          Create DAP
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
            className="close-btn"
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Autocomplete
              getOptionLabel={(option: any) => option || ""}
              open={openYear}
              onOpen={() => {
                setOpenYear(true);
              }}
              onClose={() => {
                setOpenYear(false);
              }}
              loading={openYear && yearSetStatus.loading}
              id="year-dropdown"
              sx={{ width: 'auto', margin: 2 }}
              options={yearSet}
              renderInput={(params) => (
                <Typography>
                  <TextField
                    {...params}
                    label="Budget Year"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {openYear && yearSetStatus.loading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                </Typography>
              )}
              onChange={(e, value) => onDateChange(e, value)}
            />

            <Autocomplete
              getOptionLabel={(option: any) => option || ""}
              open={openInvestment}
              onOpen={() => {
                //getAssetPlanData(payload);
                setOpenInvestment(true);
              }}
              onClose={() => {
                setOpenInvestment(false);
              }}
              loading={openInvestment && investmentIdsStatus.loading}
              id="investmentID_dropdown"
              sx={{ width: 'auto', margin: 2 }}
              options={investmentIds}
              renderInput={(params) => (
                <Typography>
                  <TextField
                    {...params}
                    label="ID"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {openInvestment && investmentIdsStatus.loading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                </Typography>
              )}
              onChange={(e, value) => onInvestmentIdChange(e, value)}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCreateNewDap}
            disabled={!allInputPresent}
            className="primary-outline"
          >
            Create
          </Button>
          <Button onClick={handleClose} autoFocus className="primary-outline">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      {showDuplicateAlert && !loading && <MessageBox assetPlanId={data} titleMessage={"Duplicate"} message={"Asset plan already exists! "} isDeleted={false} openDialog={showDuplicateAlert}  />}
      {showDeleteAlert && !loading && <MessageBox assetPlanId={data} titleMessage={`The Asset Plan is in Deleted state`} message={`You can restore the asset plan ${request.investmentId} for the budget year ${request.budgetYear} by clicking on Reactivate.`} isDeleted={true} openDialog={showDeleteAlert}  />}
    </Typography>
  );
}
