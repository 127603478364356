import { assetPlanResponse } from './../../../services/DashboardService';
import { getApiCall, getApiCallWithRetryLogic, postApiCall, postApiCallWithRetryLogic } from "../../../services/apiHelper";
import { baseUrl } from "../../../config/config";
import { AssetPlanParams } from "../../../types/assetplanid";
import { LeasingResidentialState } from "../../../redux/slices/leasingResidentialSlice";
import { DapComment } from "redux/slices/updateDapCommentSlice";


export interface KeyTenant {
    investmentId: string;
    keyTenant: string;
    totalSF: string;
    investmentPercent: number;
    rent: number;
    expirationDate: string;
    totalSales: number;
    options: string;
    salesPeriod: string;
  }
  
  interface KeyTenantsResponse{
    keyTenants: KeyTenant[]|null;
    error: string;
    loading: boolean;
  }

export interface LeaseExpiration {
    investmentId: string;
    expiryYear: number;
    expiringUnit: number;
    expiringPercentage: number;
    cumulativePercentage: number;
}
interface LeaseExpirationResponse {
    data: LeaseExpiration[]; // Replace 'any[]' with the appropriate type for the 'data' property
    errorMessage: string;
    isSuccess: boolean;
}
interface LeaseExpirationRequest {
    assetPlanId: string;
    investmentId: string;
    budgetYear: string;
}

export type PostData = {
    investmentId: string;
};

export const getFinancials = (assetPlanId: string) => {
    var url = `${baseUrl}${assetPlanId}/financials`;
    console.log("url", url);
    const res = getApiCall(url).then((data: any) => {
        // console.log(typeof data);
        return data;
    });
    return res;
};

//Get residenatial leasing data
export const getResidential =async (payload: AssetPlanParams) => {

    try {
        const response =await  postApiCall<AssetPlanParams, LeasingResidentialState>(
            `${baseUrl}Leasing/GetOccupancyResidential`,
            payload
        );
        return response;
    } catch (error: any) {
        console.error("Error in POST request:", error);
        throw error;
    }
}

export const getCommertial =async (payload: AssetPlanParams) => {

    try {
        const response =await  postApiCall<AssetPlanParams, LeasingResidentialState>(
            `${baseUrl}Leasing/GetOccupancyCommercial`,
            payload
        );
        console.log("check response", response);
        return response;
    } catch (error: any) {
        console.error("Error in POST request:", error);
        throw error;
    }
}


// Function to fetch lease expirations data
export function getLeaseExpirations(assetPlanId: string, investmentId: string, budgetYear: string): Promise<LeaseExpirationResponse> {
    const url = `${baseUrl}Leasing/GetLeaseExpirations`;

    const requestData: LeaseExpirationRequest = {
        assetPlanId: assetPlanId,
        investmentId: investmentId,
        budgetYear: budgetYear,
    };

    return postApiCallWithRetryLogic<LeaseExpirationRequest, LeaseExpirationResponse>(url, requestData);
}

export function getKeyTenants(assetPlanId: string, investmentId: string, budgetYear: string): Promise<KeyTenantsResponse> {
    const url = `${baseUrl}Leasing/GetKeyTenants`;

    const requestData: LeaseExpirationRequest = {
        assetPlanId: assetPlanId,
        investmentId: investmentId,
        budgetYear: budgetYear,
    };

    return postApiCallWithRetryLogic<LeaseExpirationRequest, KeyTenantsResponse>(url, requestData);
}




//valuation
//KeyProvion
///KeyTents-- store
//Financials



export const getComments = async (assetPlanId: string): Promise<DapComment[]> => {
    try {
        const response = await getApiCall<{ data: DapComment[] }>(`${baseUrl}DapComments/GetDapCommentsByAssetPlanId?assetPlanId=${assetPlanId}`);
        return response?.data;
      }
      // Return cachedApiData if it exists or an empty array if it's null
     catch (error) {
      console.error('Error in getComments:', error);
      throw error;
    }
  };


export const getRecommendationData = async (assetPlanId: string) => {
    const recommendationData: any = await getApiCall(`${baseUrl}${assetPlanId}/Recommendations`)
    return recommendationData?.recommendations;
};

export const getValuationHistory = (investmentId: string, assetPlanId: string) => {
    const payload ={investmentId: investmentId, assetPlanId: assetPlanId}
    var url = `${baseUrl}Document/GetValuations?investmentid=${payload.investmentId}&assetPlanId=${payload.assetPlanId}`;
    const res = getApiCallWithRetryLogic(url).then((data: any) => {
        // console.log(typeof data);
        return data;
    });
    return res;
};

export const getPropertyImages = async (investmentId: string) => {
    const payload = { investmentId: investmentId };
    const responsePropertyImages = await postApiCall(
        `${baseUrl}Document/GetPropertyImagesPresignedUrls`,
        payload
    ).then((res: any) => {
        console.log(res)
        return res;
    });
    return responsePropertyImages;
};

export const getSiteStackImages = async (assetPlanId: string, sectionName: string) => {
    const payload = {
        assetPlanId: assetPlanId,
        section: sectionName
    };
    const responseForSite = await postApiCall(
        `${baseUrl}Document/GetImagesForSitePlan`,
        payload
    ).then((res: any) => {
        return res;
    });
    return responseForSite;
};

export const getCapitalProjects = (assetPlanId: string) => {
    var url=`${baseUrl}${assetPlanId}/CapitalProjects`;
    const response = getApiCall(url).then((data: any) => {
        return data;
    });
    return response;
};

//api call for 2nd table
export const getCapitalPlanTableData = async(assetPlanId:string)=>
{
    const payload={assetPlanID:assetPlanId};
    const response= await postApiCall(`${baseUrl}AssetPlan/CapitalPlan`,payload).then((res:any) => 
    res);
    console.log(response.capitalPlanTableProperties);
    return response.capitalPlanTableProperties || [];
};


export const getSummaryData = async (assetPlanId: string) => {
    const payload = {
        dapId: assetPlanId,
    };
    
        const response:any = await postApiCall(`${baseUrl}AssetPlan/GetInvestmentDetailsByAssetID`, payload).then((res: any) => {
            return res;
        });
        return response;
};



export const fetchAssetPlanData = async ({ assetPlanId: assetPlanId, investmentId: investmentId, budgetYear: budgetYear,propertyType:propertyType }): Promise<any> => {
    var promiseArray: any[] = [];
    promiseArray.push(getFinancials(assetPlanId));
    promiseArray.push(getPropertyImages(investmentId));
    promiseArray.push(getSiteStackImages(assetPlanId , "SitePlan"));
    promiseArray.push(getSiteStackImages(assetPlanId , "StackingPlan"));
    promiseArray.push(getSummaryData(assetPlanId));
    promiseArray.push(getRecommendationData(assetPlanId));
    promiseArray.push(getComments(assetPlanId));
    promiseArray.push(getLeaseExpirations(assetPlanId, investmentId,budgetYear));
    promiseArray.push(getKeyTenants(assetPlanId, investmentId,budgetYear));
    promiseArray.push(getResidential({assetPlanId:assetPlanId}));
    promiseArray.push(getCommertial({assetPlanId:assetPlanId}));
    promiseArray.push(getValuationHistory(investmentId, assetPlanId));
    promiseArray.push(getCapitalProjects(assetPlanId));
    promiseArray.push(getCapitalPlanTableData(assetPlanId));



    return Promise.all(promiseArray)
        .then(([financials, propertyImages, sitePlans, stackingPlans, summaryData, recomData, comments,leaseExpirations,keyTenants,residential,commertial,valuationData,capitalPlanTable1,capitalPlanTable2]) => {
           console.log("inside pi commertial", commertial);
            return {
                financials: financials,
                propertyImages: propertyImages,
                sitePlans: sitePlans,
                stackingPlans: stackingPlans,
                summaryData: summaryData,
                recomData: recomData,
                comments: comments,
                leaseExpirations:leaseExpirations,
                keyTenants:keyTenants,
                residential:residential,
                commertial:commertial,
                valuationData:valuationData,
                capitalPlanTable1:capitalPlanTable1,
                capitalPlanTable2:capitalPlanTable2
            }

        })
        .catch((error) => {
            console.error("Error fetching data:", error);
            return {
                error: error
            }

        });
};

