import React, { useState } from "react";
import Button from "@mui/material/Button";
import AuditDialog from "./auditDialog";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";

const AuditButton: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isButtonClicked, setIsButtonClicked] = useState(false);

  const handleButtonClick = () => {
    setIsOpen(true);
    setIsButtonClicked(true);
  };

  const handleClose = () => {
    setIsOpen(false);
    setIsButtonClicked(false);
  };

  return (
    <div style={{ position: "relative", display: "inline-block" }}>
      <Button
        data-testid="open-audit-button"
        variant="contained"
        className="primary"
        size="small"
        onClick={handleButtonClick}
        sx={{ lineHeight: 2.2 }}
      >
        {/* <ManageSearchIcon sx={{ mt: "0", fontSize: "1.25rem", mr: "2.5px",lineHeight: 2.2 }} /> */}
        <ManageSearchIcon sx={{ mr: "2.5px" }} />
        Audit
      </Button>
      {isOpen && (
        <div style={{ position: "absolute", top: "100%", left: 0 }}>
          <AuditDialog data-testid="close-audit-button"
            isOpen={isOpen} onClose={handleClose} />
        </div>
      )}
    </div>
  );
};

export default AuditButton;
