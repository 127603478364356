import React, { useEffect, useState } from "react";

import Skeleton from "@mui/material/Skeleton";

import { TableColumn, TableRowData } from "../tableComponent";
import TableComponent from "../tableComponent";
import { GenericToast } from "../../../util/ToastMessage";
import { useAppSelector, useAppDispatch } from "../../../../redux/hooks";
import { updateLeasingCommercial } from "../../../../redux/slices/updateDataSlice";
import { LeasingCommercial,updateLeasingCommercialState } from "../../../../redux/slices/leasingCommercialSlice";
import {
  percentage,
  numberWithComma,
} from "../../../util/valueFormatter";

const CommertialOccupancyNew: React.FC<{ mode: string }> = (props) => {
  const [tableData, setTableData] = useState<TableRowData>({});
  const occupancy = useAppSelector((state) => state.leasingCommercial.data);
  const updatedOccupancyState = useAppSelector((state) => state.updateData.occupancyCommercial); 
  const occupancyStatus = useAppSelector((state) => state.leasingCommercial);
  const budgetYear = useAppSelector(
    (state) => state.summary?.dapData?.budgetYear
  );

  const occupancyColumnsEdit: TableColumn[] = [
    {
      key: "startYearGLALeased",
      label: `GLA Leased 1/1/${budgetYear}`,
      editable: true,
      valueCellClassName: "number-right",
    },
    {
      key: "totalGLA",
      label: "Total GLA",
      editable: false,
      valueCellClassName: "number-right",
    },
    {
      key: "startYearLeasedPC",
      label: `% Leased 1/1/${budgetYear}`,
      editable: false,
      valueCellClassName: "number-right",
    },
    {
      key: "endYearGLALeased",
      label: "YE GLA Leased",
      editable: false,
      valueCellClassName: "number-right",
    },
    {
      key: "endYearLeasedPC",
      label: `% Leased 12/31/${budgetYear}`,
      editable: false,
      valueCellClassName: "number-right",
    },
    {
      key: "netAbsorptionOcc",
      label: "Net Absorption",
      editable: false,
      valueCellClassName: "number-right",
    },
  ];

  const occupancyColumnsView: TableColumn[] = [
    {
      key: "startYearGLALeased",
      label: `GLA Leased 1/1/${budgetYear}`,
      editable: false,
      valueCellClassName: "number-right",
    },
    {
      key: "totalGLA",
      label: "Total GLA",
      editable: false,
      valueCellClassName: "number-right",
    },
    {
      key: "startYearLeasedPC",
      label: `% Leased 1/1/${budgetYear}`,
      editable: false,
      valueCellClassName: "number-right",
    },
    {
      key: "endYearGLALeased",
      label: "YE GLA Leased",
      editable: false,
      valueCellClassName: "number-right",
    },
    {
      key: "endYearLeasedPC",
      label: `% Leased 12/31/${budgetYear}`,
      editable: false,
      valueCellClassName: "number-right",
    },
    {
      key: "netAbsorptionOcc",
      label: "Net Absorption",
      editable: false,
      valueCellClassName: "number-right",
    },
  ];

  const [validationError, setValidationError] = useState<string>("");
  // const id = "1092";
  // const postData: PostData = {
  //   investmentId: id,
  // };
  const dispatch = useAppDispatch();

  const formattedLeasingData = (inputData?: any) => {
    console.log(inputData);
    inputData.startYearGLALeased = numberWithComma(
      inputData?.startYearGLALeased
    );
    console.log("inputData.startYearGLALeased",inputData.startYearGLALeased);
    inputData.totalGLA = numberWithComma(inputData?.totalGLA, 0);
    inputData.startYearLeasedPC = percentage(inputData?.startYearLeasedPC);
    inputData.endYearGLALeased = numberWithComma(inputData?.endYearGLALeased, 0);
    inputData.endYearLeasedPC = percentage(inputData?.endYearLeasedPC);
    inputData.netAbsorptionOcc = numberWithComma(inputData?.netAbsorptionOcc, 0);
    console.log(inputData);
    return inputData;
  };

  //const tableData= undefined;
  const handleCellChange = (newValue: string, key: string) => {

    console.log("newValue",newValue);
    if (key === "startYearGLALeased") {
      // Validate the entered value
      const numericValue = newValue.replace(/[^0-9.]/g, ""); // Remove non-numeric characters except decimal point
      const numValue = Number(numericValue);
      console.log("numericValue",numericValue);
      if (isNaN(numValue)) {
        // Display validation error message
        setValidationError("Please enter numeric values only.");
      } else {
        setValidationError("");

        const startYearGLALeased = numValue;
        console.log("startYearGLALeased1",startYearGLALeased);
        const totalGLA = parseFloat(occupancy?.totalGLA?.toString() || "0");
        const startYearLeasedPC =
          totalGLA == 0 ? 0 : (startYearGLALeased / totalGLA) * 100;
        const endYearLeasedPC = occupancy?.endYearLeasedPC || 0; // Handle the case when leasedPercentageEOY is undefined
        const endYearGLALeased = Math.round((totalGLA * endYearLeasedPC) / 100);

        const updatedTableData: TableRowData = {
          ...tableData,
          startYearGLALeased: numericValue,
          startYearLeasedPC: startYearLeasedPC?.toString(),
          endYearGLALeased: endYearGLALeased?.toString(),
        };
        
        console.log("startYearGLALeased2",startYearGLALeased);
        const updatedData: LeasingCommercial = {
          ...occupancy,
          startYearGLALeased: startYearGLALeased,
          startYearLeasedPC: startYearLeasedPC,
          endYearGLALeased: endYearGLALeased,
        } as LeasingCommercial;
        console.log("updatedData",updatedData);

        dispatch(updateLeasingCommercial(updatedData));
        dispatch(updateLeasingCommercialState(updatedData));
        setTableData(formattedLeasingData(updatedTableData));
      }
    }
  };

  useEffect(() => {
    if (occupancy!==null) 
    {
      const startYearGLALeased = occupancy.startYearGLALeased || "";
      const totalGLA = occupancy.totalGLA || "";
      const startYearLeasedPC = occupancy.startYearLeasedPC || "";
      const endYearGLALeased = occupancy.endYearGLALeased || "";
      const endYearLeasedPC = occupancy.endYearLeasedPC || "";
      const netAbsorptionOcc = occupancy.netAbsorptionOcc || "";

      const preFilledData: TableRowData = {
        startYearGLALeased,
        totalGLA,
        startYearLeasedPC,
        endYearGLALeased,
        endYearLeasedPC,
        netAbsorptionOcc,
      };

      setTableData(formattedLeasingData(preFilledData));
    }
  }, [dispatch, occupancy]);

  console.log(props);

  return (
    <>
      {props?.mode === "edit" && (
        <div>
          {validationError && (
            <div className="validation-error">{validationError}</div>
          )}
          {occupancyStatus.loading ? (
            <Skeleton
              variant="rounded"
              width="100%"
              height={150}
              animation="wave"
            />
          ) : (
            <TableComponent
              tableData={tableData}
              columns={occupancyColumnsEdit}
              onCellChange={handleCellChange}
            />
          )}

          <style>
            {`
        .validation-error {
          color: red;
          margin-bottom: 10px;
        }
      `}
          </style>
          {occupancyStatus.error && (
            <GenericToast message={occupancyStatus.error} severity="error" />
          )}
        </div>
      )}
      {(props?.mode === undefined || props?.mode === "view") && (
        <div>
          {validationError && (
            <div className="validation-error">{validationError}</div>
          )}
          {occupancyStatus.loading ? (
            <Skeleton
              variant="rounded"
              width="100%"
              height={150}
              animation="wave"
            />
          ) : (
            <TableComponent
              tableData={tableData}
              columns={occupancyColumnsView}
              onCellChange={handleCellChange}
            />
          )}

          <style>
            {`
        .validation-error {
          color: red;
          margin-bottom: 10px;
        }
      `}
          </style>
          {occupancyStatus.error && (
            <GenericToast message={occupancyStatus.error} severity="error" />
          )}
        </div>
      )}
    </>
  );
};

export default CommertialOccupancyNew;
