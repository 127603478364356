import React, { useEffect, useState, useRef } from "react";
import { useAppSelector, useAppDispatch } from "redux/hooks";
import { useNavigate } from "react-router-dom";
import moment from "moment";

import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import NewFormDialog from "../form/newFormDialog/newFormDialog";
import {
  DataGridPro,
  GridActionsCellItem,
  GridPaginationModel,
  GridToolbarFilterButton,
  useGridApiRef,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid-pro";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from '@mui/icons-material/Delete';

import dashboardViewsColumnConfig from "components/appContent/dashboardViewsColumn.json";
import ViewTabs from "components/appContent/viewTabs";
import {
  DashboardService,
  viewPayload,
  TabsResponse,
} from "services/DashboardService";
import { saveSelectedAssetPlanId } from "redux/slices/assetPlanSlice";
import { getAssetPlanId } from "../../redux/slices/assetPlanIdSlice";
import { GenericToast } from "../../components/util/ToastMessage";
import { getUserData } from "redux/slices/userSectorDetailSlice";
import { resetAssetPlanState } from "components/util/storeManager";
import { TablePaginationActions } from "components/util/tablePagination";
import { CurrentUser } from "../../redux/slices/currentUserSlice";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import Button from '@mui/material/Button';
import { TransitionProps } from '@mui/material/transitions';
import { Typography } from "@mui/material";

import { postApiCall } from "../../services/apiHelper";
import { baseUrl } from "../../config/config";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const AssetPlanDashboard = () => {

  const [openDeletePopup, setOpenDeletePopup] = React.useState(false);
  const [dapToDelete, setDapToDelete] = React.useState({ id: "", budgetYear: 0, status: "", assetId: "" });

  const handleClickOpenDeletePopup = (dets) => {
    setDapToDelete({ id: dets.investmentId, budgetYear: dets.budgetYear, status: dets.status, assetId: dets.dapId }); setOpenDeletePopup(true);
  };

  const handleCloseDeletePopup = () => {

    setOpenDeletePopup(false);
  };

  const showForm = useAppSelector(
    (state) => {
      return state.dialog.dialogData.showPopupForm
    }
  );

  const [assetPlans, setAssetPlans] = useState<any[] | null>(null);

  const [error, setError] = useState(false);
  const [filterError, setFilterError] = useState(false);
  const dispatch = useAppDispatch();
  const nav = useNavigate();

  const dashboardService = new DashboardService();

  const [tabViewId, setTabViewId] = useState<string>(
    "f638a648-75da-46d5-91e9-143cbd2e5af9"
  );
  const [tabFilterId, setTabFilterId] = useState<string>(""); // need to have separate state to store viewId for filters to prevent flashing of filters applied to 'historical plans' view
  const [pageSize, setPageSize] = useState<number>(5);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: pageSize,
  });
  const [loadingState, setLoadingState] = useState<boolean>();
  const [filtersLoadingState, setFiltersLoadingState] = useState<boolean>();
  const [dashboardRowCount, setDashboardRowCount] = useState<number>(0);
  const [tabs, setTabs] = useState<TabsResponse[]>([]);


  const userEmailId =
    useAppSelector((state) => {
      return state.currentUser.data.email
    }) ?? "";

  //New?
  const userSectoDetails = useAppSelector((state) => { return state.userSectorDetail });
  //New

  const userId = useAppSelector(
    (state) => state.userSectorDetail?.data?.userId
  );
  const getPayload = (viewId) => {
    let payload: viewPayload = {
      userEmailId: userEmailId,
      viewId: viewId,
      filters: [],
      page: 0,
      pageSize: 10000,
      rowCount: dashboardRowCount,
    };
    return payload;
  };


  const getAssetPlanData = (payload) => {
    setLoadingState(true);
    dashboardService
      .getAssetPlansByView(payload)
      .then((res) => {
        if (res.data != undefined) {
          // var filterIsDeleted = res.data.filter(assetPlan => !assetPlan.isDeleted);
          setAssetPlans(res.data);
          setDashboardRowCount(res.rowCount);
        } else {
          setError(true);
          setLoadingState(false);
        }
      })
      .finally(() => {
        setLoadingState(false);
      });
  };


  const tabChange = (id) => {
    setLoadingState(true);
    const payload = getPayload(id);
    setTabViewId(id);
    setTabFilterId(id);
    getAssetPlanData(payload);
  };

  useEffect(() => {
    dispatch(getUserData(userEmailId));
    setLoadingState(true);

    dashboardService
      .getTabs(userEmailId)
      .then((data) => {
        console.log(data);

        setTabViewId(data[0].viewId);
        setTabFilterId(data[0].viewId);
        console.log(tabViewId);
        setTabs(data);
        console.log("Tabs :", data);
        const payload = getPayload(data[0].viewId);
        console.log("Payload :", payload);
        getAssetPlanData(payload);
      })
      .finally(() => {
        setLoadingState(false);
      });
  }, []);


  const handleRowNavigateEdit = (assetPlanId: string) => {
    resetAssetPlanState(dispatch);
    dispatch(getAssetPlanId(assetPlanId));
    nav("form/edit/" + assetPlanId);
    let filteredData: any = assetPlans?.filter(
      (assetPlan: any) => assetPlan.dapId == assetPlanId
    )[0];
    dispatch(saveSelectedAssetPlanId(filteredData));
  };

  const handleRowNavigateView = (assetPlanId: string) => {
    resetAssetPlanState(dispatch);
    dispatch(getAssetPlanId(assetPlanId));
    nav("form/view/" + assetPlanId);
    let filteredData: any = assetPlans?.filter(
      (assetPlan: any) => assetPlan.dapId == assetPlanId
    )[0];
    dispatch(saveSelectedAssetPlanId(filteredData));
  };

  interface GridValueFormatterParams<T> {
    value: T;
  }

  type ValueFormatter<T> = (
    params: GridValueFormatterParams<T>,
    currency?: string,
    decimalPosition?: number
  ) => string;

  const dateFormatter: ValueFormatter<string> = (params) => {
    const value = params.value; if (typeof value === "string") {
      const formattedDate = moment(value).format("MMM-DD-YYYY HH:mm:ss");
      return formattedDate;
    }
    return "";
  };

  const columnConfig = dashboardViewsColumnConfig[tabViewId].colDef.map((c: any) => {
    let { valueFormatter, ...rest } = c; let formatter;
    if (valueFormatter === "dateFormatter") {
      formatter = dateFormatter;
    }
    return { ...rest, ...(formatter && { valueFormatter: formatter }) };
  }
  );

  const sendSoftDeleteData = async () => {
    setLoadingState(true);
    dashboardService
      .softDeleteAssetPlanByInvestmentIdBudgetYear(dapToDelete.assetId, true)
      .then((res) => {
        getAssetPlanData(getPayload(tabViewId));
      })
      .finally(() => {
        setLoadingState(false);
        handleCloseDeletePopup();
      });
  }

  columnConfig.unshift({
    headerName: "Actions",
    field: "Actions",
    type: "actions",
    flex: 1.3,
    getActions: ({ id, ...rest }) => {
      return [
        <>
          <GridActionsCellItem
            sx={{
              color: "#253746",
            }}
            icon={<VisibilityIcon />}
            label="View"
            onClick={() => handleRowNavigateView(id)}
          />

          <GridActionsCellItem
            sx={{
              color: "#253746",
            }}
            disabled={rest.row.isEditable === false}
            icon={<EditIcon />}
            label="Edit"
            onClick={(e) => handleRowNavigateEdit(id)}
          />

          {userSectoDetails.data.isAdmin ? <GridActionsCellItem
            sx={{
              color: "#253746",
            }}
            icon={<DeleteIcon />}
            label="Edit"
            onClick={(e) => handleClickOpenDeletePopup(rest.row)}
          /> : null}
        </>,
      ];
    },
  });

  //UNUSED
  const handlePaginationModelChange = (e: GridPaginationModel) => {
    const payload = getPayload(tabViewId);
    setPaginationModel(e);
    payload.page = e.page;
    payload.pageSize = e.pageSize;
    getAssetPlanData(payload);
    console.log(e);
  };

  //UNUSED
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  // local storage soln. --------
  const filterJSON = localStorage.getItem(tabViewId);
  const initialFilterModel = filterJSON
    ? JSON.parse(filterJSON)
    : { items: [] };
  const [filterModel, setFilterModel] = useState<any>(initialFilterModel);
  // ---------

  // database soln. -------
  let userFilterModels = {
    "f8150d5b-a0cb-4625-aa63-c89049b841f1": { items: [] },
    "fff7c3fa-32f4-4c95-a3b1-a528f4ec6b10": { items: [] },
    "8f50d1e1-6b2a-42fc-9ec7-50c15586ec76": { items: [] },
    "943453f0-fc46-4ab2-91a1-75bab8cf9866": { items: [] },
    "32da3f91-0380-4cd7-867d-6f89cf820d46": { items: [] },
    "cf4d3531-8f4d-472c-a59f-65b5af7eac9e": { items: [] },
    "f638a648-75da-46d5-91e9-143cbd2e5af9": { items: [] },
  };

  const [tabFilter, setTabFilter] = useState({ items: [] });

  const [filters, setFilters] = useState<any>(userFilterModels);
  const [userFilterModelState, setUserFilterModelState] =
    useState(userFilterModels);

  const filterPayload = {
    userId: userId,
    filterModel: userFilterModelState,
  };

  useEffect(() => {
    if (userId) {
      getFilters(userId);
    }
  }, [userId, tabFilterId]);

  const getFilters = (userId) => {
    setFiltersLoadingState(true);
    dashboardService
      .getDashboardFilters(userId)
      .then((res) => {
        if (res.filterModel !== null) {
          setTabFilter(res.filterModel[tabFilterId]);
          setUserFilterModelState(res.filterModel);
        }
      })
      .finally(() => {
        setFiltersLoadingState(false);
      });
  };

  const ref = useGridApiRef();
  function handleFilterChange(e) {
    ref.current = e;
    console.log(ref);
    let tempUserFilters = userFilterModelState;
    tempUserFilters[tabFilterId] = e;
    setUserFilterModelState(tempUserFilters);
    filterPayload.filterModel = userFilterModelState;
    setTabFilter(e);
  }

  const isMounted = useRef(false);
  useEffect(() => {
    if (isMounted.current) {
      const debounceUpdateFilter = setTimeout(() => {
        filterPayload.filterModel = userFilterModelState;
        dashboardService.upsertDashboardFilters(filterPayload).then((val) => {
          if (val != undefined) {
            console.log("filters saved", val);
          } else {
            setFilterError(true);
          }
        });
      }, 1750);
      return () => clearTimeout(debounceUpdateFilter);
    } else {
      isMounted.current = true;
    }
  }, [ref.current]);

  //for export data in csv file
  const gridCustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarExport printOptions={{ disableToolbarButton: true }} />
        <GridToolbarFilterButton />
      </GridToolbarContainer>
    );
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <div className="wrapper">
        <h2 className="text-left title">DAP Dashboard</h2>
        <div className="clear"></div>
      </div>

      <div style={{ width: "100%" }}>
        <ViewTabs sendData={tabChange} tabData={tabs} />
        <Box
          className="dashboard-table-wrapper"
          sx={{ height: 400, width: "100%" }}
        >
          <div style={{ width: "100%" }}>
            <DataGridPro
              sx={{
                backgroundColor: "white",
                "& .MuiDataGrid-columnHeader": {
                  backgroundColor: "#253746",
                  color: "white",
                },
                "& .MuiDataGrid-sortIcon": {
                  color: "white",
                },
                "& .MuiDataGrid-filterIcon": {
                  color: "white",
                },
              }}
              // filterModel={JSON.parse(
              //   localStorage.getItem(tabViewId) || '{"items": []}'
              // )}
              // onFilterModelChange={(newModel) => {
              //   localStorage.setItem(tabViewId, JSON.stringify(newModel));
              //   setFilterModel(newModel);
              // }}

              // filterModel={filters[tabViewId] || { items: [] }}
              filterModel={tabFilter}
              onFilterModelChange={(newModel) => handleFilterChange(newModel)}
              rowHeight={30}
              disableColumnMenu={true}
              rows={assetPlans || []}
              columns={columnConfig}
              loading={
                loadingState || filtersLoadingState || assetPlans == null
              }
              autoHeight
              hideFooter={false}
              getRowId={(row) => row.dapId}
              pagination
              initialState={{
                pagination: { paginationModel: { pageSize: 25 } },
              }}
              componentsProps={{
                pagination: {
                  ActionsComponent: TablePaginationActions,
                },
              }}
              pageSizeOptions={[25, 50, 100]}
              slots={{
                toolbar: gridCustomToolbar,
                //noRowsOverlay: CustomDataGridNoRowOverlay,
              }}
              disableRowSelectionOnClick
              showCellVerticalBorder={true}
              showColumnVerticalBorder={true}
            />
          </div>
        </Box>
      </div>

      {showForm && <NewFormDialog />}
      {error && <GenericToast message="No records found" severity="info" />}
      {filterError && (
        <GenericToast message="Error updating filters" severity="info" />
      )}

      <Dialog
        open={openDeletePopup}
        onClose={handleCloseDeletePopup}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="common-dialog-box"
      >
        <DialogTitle id="alert-dialog-title">
          {"Do you want to delete the selected Asset Plan?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {/* <Typography> Are you sure you want to delete {dapToDelete.id} for the budget year {dapToDelete.budgetYear}</Typography> */}
            <Typography style={{ fontWeight: 900 }}>ID - {dapToDelete.id}</Typography>
            <Typography style={{ fontWeight: 900 }}>Budget Year - {dapToDelete.budgetYear}</Typography>
            <Typography style={{ fontWeight: 900 }}>Status - {dapToDelete.status}</Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button className="primary-outline" onClick={handleCloseDeletePopup}>No</Button>
          <Button autoFocus className="primary" variant="contained" onClick={sendSoftDeleteData} >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
