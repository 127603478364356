import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl, headers } from "../../config/config";
import { postApiCall } from "../../services/apiHelper";
import {PostData} from './commertialLeasingSlice';

export interface OccupancySchedule {
  investmentId: number;
  glaLeased: string;
  glaTotal: number;
  leasedPercentageSOY: number;
  yeGlaLeased: string;
  leasedPercentageEOY: number;
  netAbsorption: number;
}

interface ApiResponse {
  occupancySchedule: OccupancySchedule;
  errorMessage: string;
  isSuccess: boolean;
}

interface CommertialOccupancyState {
  data: OccupancySchedule | null;
  error: string;
  loading: boolean;
}

const initialState: CommertialOccupancyState = {
  data: null,
  error: "",
  loading: false,
};

export const getCommertialOccupancy = createAsyncThunk(
  "getcommertialOccupancy",
  async (payload: PostData) => {
    try {
      const response = await postApiCall<PostData, ApiResponse>(
        `${baseUrl}Leasing/GetCommertialOccupancy`,
        payload,
        headers
      );
      console.log("response from ", response);
      return response.occupancySchedule;
    } catch (error: any) {
      console.error("Error in POST request:", error);
      throw error;
    }
  }
);


const commertialOccupancySlice = createSlice({
  name: "commertialOccupancy",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCommertialOccupancy.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        getCommertialOccupancy.fulfilled,
        (state, action: PayloadAction<OccupancySchedule>) => {
          state.loading = false;
          state.data = action.payload;
        }
      )
      .addCase(getCommertialOccupancy.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Something went wrong";
      });
  },
});

export default commertialOccupancySlice.reducer;
