// src/components/templates/postClosing1.hbs

const postClosingTemplate = `
<!DOCTYPE html>
<html>
  <head> </head>
  <body>
  <h2 class="pdf-table-heading">Comments</h2>
  <table id="table-comment"> 
      <tr>
        <th>Comments</th>
      </tr>
      <tr>
        <td>
          {{{postClosingComment}}}
        </td>
      </tr>
    </table>
  </body>
</html>
`;

export default postClosingTemplate;
