import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type AssetPlan = {
investmentId: number,
dapId: string,
investmentName: string,
dealName: string,
investmentType: string,
budgetYear: number,
status: string,
};

type InitialState = {
  assetPlan: AssetPlan;
};

const initialState: InitialState = {
  assetPlan: {} as AssetPlan,
};

//Gets a single Asset Data that was Viewed or Edited and showcases it on a separate page (DapIntroductionForm)

const assetPlanSlice = createSlice({
  name: "assetPlan",
  initialState,
  reducers: {
    saveSelectedAssetPlanId(state, action: PayloadAction<AssetPlan>) {
      console.log(action + "......");
      state.assetPlan.investmentId = action.payload.investmentId;
      state.assetPlan.dapId = action.payload.dapId;
      state.assetPlan.dealName = action.payload.dealName;
      state.assetPlan.investmentType = action.payload.investmentType;
      state.assetPlan.budgetYear = action.payload.budgetYear;
      state.assetPlan.status = action.payload.status;
    },

  },
});

export const { saveSelectedAssetPlanId } = assetPlanSlice.actions;

export default assetPlanSlice.reducer;
