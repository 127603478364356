import { useEffect, useRef, useState } from "react";

import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { FroalaRichText } from "../../util/FroalaRichtext";
import { AutosaveDelay, CommentKey } from "../../../constant";
import { saveDapCommentData } from "../../../redux/slices/saveCommentSlice";
import {
  updateDapCommentData,
  DapComment,
} from "../../../redux/slices/updateDapCommentSlice";
import { useAppSelector, useAppDispatch } from "../../../redux/hooks";
import FroalaEditorView from "react-froala-wysiwyg/FroalaEditorView";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  color: theme.palette.text.secondary,
}));

export default function PostCLosing(props) {
  const dispatch = useAppDispatch();
  const assetPlanId = useAppSelector(
    (state) => state.getAssetPlanId.assetPlanId
  );
  const comment = useAppSelector((state) => state.dapComments?.data).find(
    (x) => x.commentKey.toLowerCase() === CommentKey.POSTCLOSING.toLowerCase()
  );

  // const isMounted = useRef(false);
  const handlePostModelChange = (model: any) => {
    const updatedcomment: DapComment = {
      id: comment ? comment.id : 0,
      commentKey: comment ? comment.commentKey : CommentKey.POSTCLOSING,
      commentText: model || "",
      sectionName: "Postclosing",
      assetPlanId: assetPlanId,
    };
    dispatch(updateDapCommentData(updatedcomment));
    dispatch(saveDapCommentData(updatedcomment));

    // if (isMounted.current) {
    //   dispatch(updateDapCommentData(updatedcomment));
    //   dispatch(saveDapCommentData(updatedcomment));
    // } else {
    //   isMounted.current = true;
    // }
  };

  // const savedComment = useAppSelector(
  //   (state) => state.saveComment.updateDapComments
  // );
  // const [toastMessageOpen, setToastMessageOpen] = useState(false);
  // const [toastMessage, setToastMessage] = useState<toast>({
  //   message: "",
  //   severity: "",
  // });
  // const isMountedAutosave = useRef(false);
  // // useEffect(() => {
  // //   if (isMountedAutosave.current) {
  // //     const debouncedAutosave = setTimeout(() => {
  // //       Autosave( assetPlanId, savedComment);
  // //       clearDocumentComments(dispatch);
  // //     }, AutosaveDelay.DELAYTIME);
  // //     return () => clearTimeout(debouncedAutosave);
  // //   } else {
  // //     isMountedAutosave.current = true;
  // //   }
  // // }, [savedComment]);
  // useEffect(() => {
  //   if (isMountedAutosave.current) {
  //     const promises: Promise<any>[] = [];
  //     const debouncedAutosave = setTimeout(() => {
  //       if (savedComment) {
  //         console.log("com", savedComment);
  //         promises.push(
  //           postApiCall(`${baseUrl}DapComments/UpdateDapComments`, savedComment)
  //         );
  //       }
  //       clearDocumentComments(dispatch);
  //       setToastMessage({
  //         message: "Saved successfully",
  //         severity: "success",
  //       });
  //       if (promises.length > 0) {
  //         setToastMessageOpen(false);
  //         Promise.all(promises)
  //           .then((responses) => {
  //             const successResponses = responses.filter(
  //               (response: any) => response.success
  //             );
  //             if (successResponses.length === promises.length) {
  //               setToastMessage({
  //                 message: "Saved successfully",
  //                 severity: "success",
  //               });
  //               setToastMessageOpen(true);
  //             }
  //           })
  //           .catch((error) => {
  //             console.error("Error:", error);
  //             setToastMessage({
  //               message: error.message,
  //               severity: "error",
  //             });
  //             setToastMessageOpen(true);
  //           });
  //       } else {
  //         console.log("No API calls made");
  //       }
  //     }, AutosaveDelay.DELAYTIME);
  //     return () => {
  //       clearTimeout(debouncedAutosave);
  //     };
  //   } else {
  //     isMountedAutosave.current = true;
  //   }
  // }, [savedComment]);
  return (
    <div>
      {/* {toastMessageOpen && (
        <GenericToast
          message={toastMessage.message}
          severity={toastMessage.severity}
        />
      )} */}
      <div className="tab-heading text-center">
        <h3 className="heading">Comments</h3>
      </div>

      <Box sx={{ width: "100%" }}>
        {/* <Grid container rowSpacing={10} columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
        <Grid item xs={12}> */}
        <Item>
          <div style={{ marginTop: "50px", marginBottom: "50px" }}>
            {props?.mode === "edit" && (
              <FroalaRichText
                model={comment?.commentText}
                handlesModelChange={handlePostModelChange}
              />
            )}
            {(props?.mode === undefined || props?.mode === "view") && (
              <FroalaEditorView model={comment?.commentText} />
            )}
          </div>
        </Item>
        {/* </Grid>
      </Grid> */}
      </Box>
    </div>
  );
}
