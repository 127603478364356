import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";

import { baseUrl } from "config/config";
import { getApiCall } from "../../services/apiHelper";
import { RootState } from "../store";

export type CapitalProject = {
  projectPriority: string;
  esgImpact: boolean;
  projectId: string;
  currentBudget: number;
  futureBudget: number;
  totalBudget: number;
  projectDescription: string;
  assetPlanId: string;
  comment: string,
  id: number;
};

interface InitialState {
  data: CapitalProject[],
  error: string,
  loading: boolean,
  cacheKey: string | undefined,
  isNoData: boolean
};

const initialState: InitialState = {
  data: [],
  error: '',
  loading: false,
  cacheKey: undefined,
  isNoData: false
};

const capitalProjectsSlice = createSlice({
  name: "capitalProjects",
  initialState,
  reducers: {
    setCapitalProjectsStore(state, action: PayloadAction<CapitalProject[]>) {
      state.data = action.payload;
    },
    resetState: (state) => {
      state.data = [];
      state.error = "";
      state.loading = false;
      state.cacheKey = undefined;
      state.isNoData = false;
    },
  },
  extraReducers: (builder) => {
    // eslint-disable-next-line   
    builder.addCase(getCapitalProjects.pending, (state) => {
      state.loading = true;
    }),
      builder.addCase(getCapitalProjects.fulfilled, (state, action: PayloadAction<CapitalProject[]>) => {
        state.loading = false;
        state.data = action.payload;
        state.cacheKey = 'capitalCache';
        if (state.data.length === 0) {(state.isNoData = true)};
        console.log('capital projects data', state.data);
      }),
      builder.addCase(getCapitalProjects.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'something went wrong';
      })
  }
});

//export const getCapitalProjects = createAsyncThunk("getCapitalProjects", async (payload: any) => {
export const getCapitalProjects = createAsyncThunk
  <
    CapitalProject[],
    any,
    { state: RootState }
  >
  ("getCapitalProjects", async (payload: any, { getState, rejectWithValue }) => {
    const state = getState().capitalProjects;

    if (state.cacheKey === 'capitalCache') {
      // Return the current data if it exists in the state and cacheKey is set
      return state.data;
    }

    const response = await getApiCall(`${baseUrl}${payload.assetPlanId}/CapitalProjects`).then((res: any) =>
      res);
    console.log(response.capitalProjects);
    return response.capitalProjects || [];
  });

export const { setCapitalProjectsStore, resetState: resetCapitalProjectsStore } = capitalProjectsSlice.actions;

export default capitalProjectsSlice.reducer;
