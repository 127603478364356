import { baseUrl } from "../config/config";
import { postApiCall, getApiCall } from "./apiHelper";
import { GridFilterModel } from "@mui/x-data-grid";

export class UserRoleMapService {

    getRoles(){
        var url = `${baseUrl}Roles/GetAllRoles`
        return getApiCall(url).then((data) => {return data;})
        
    }
    getUserDetails(){
        var url =  `${baseUrl}User/GetAllUsers`
        return getApiCall(url).then((data) => {return data;})

    }

    submitUserRoleMap(userRoleMapData){
        var url = `${baseUrl}UserRoleMap/SubmitUserDetails`
        var data = userRoleMapData
        return postApiCall(url, data)

    }
    getUserDetailsBasedOnId(id){
        var url = `${baseUrl}UserRoleMap/GetById`
        var data = id
        return postApiCall(url, data).then((res) => {return res})
    }
}


interface UserRoleMapDashboardPayload {
  filters: GridFilterModel[];
  page: number;
  pageSize: number;
}

interface Swot {
  assetPlanID: string;
  strengths: string;
  weakness: string;
  opportunities: string;
  threats: string;
  physicalClimateChange: string;
  climateRegulation: string;
  sectorFundESGPriorities: string;
  tenantResidentFeedbackActionPlan: string;
}

export function getUserRolesForDashboardBatch(
  paginationModel: { page: number; pageSize: number },
  filterModel
) {
  var url = `${baseUrl}UserRoleMap/GetUserRoleDashboardBatch`;
  var data: UserRoleMapDashboardPayload = {
    page: paginationModel.page,
    pageSize: paginationModel.pageSize,
    filters: filterModel.items,
  };
  return postApiCall(url, data).then((resData) => {
    return resData;
  });
}


export function getUserRolesForDashboard() {
  var url = `${baseUrl}UserRoleMap/GetAllUserRoleDashboardItems`;
  return getApiCall(url).then((resData) => {
    return resData;
  });
}

export function saveSwot(swot: Swot) {
  var url = `${baseUrl}Swot/Save`;
  return postApiCall(url, swot).then((res) => {
    return res;
  });
}
