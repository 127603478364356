import handlebars from "handlebars";
import imagesWithHeaderTemplate from "../templates/imagesWithHeader";
import imagesTemplate from "../templates/images";
import { sliceIntoChunks } from "../util/helper";
import noImages from "../templates/noImages";

export const compileSiteStackTemplate = (
    images: any[], sectionName: string
): any[] => {
    const compiledImagesWithHeaderTemplate = handlebars.compile(imagesWithHeaderTemplate);
    const compiledImagesTemplate = handlebars.compile(imagesTemplate);

    const compiledTemplates: any[] = [];
    const formmattedImages = formatImagesData(images);
    if (images == null || images.length === 0) {
        const headerName = (sectionName === "SitePlan") ? "Site Plan / Photos" : "Stacking Plan";
        const compiledNoImagesTemplate = handlebars.compile(noImages);
        compiledTemplates.push(compiledNoImagesTemplate({headerName: headerName}));
        return compiledTemplates;
    };

    if (formmattedImages.length > 0) {
        //split array into chunks of size 2 (number of images per page in pdf)
        const chunks = sliceIntoChunks(formmattedImages, 2);

        const headerName = (sectionName === "SitePlan") ? "Site Plan / Photos" : "Stacking Plan";

        compiledTemplates.push(compiledImagesWithHeaderTemplate({ headerName: headerName, images: [...chunks[0]] }));

        for (var index = 1; index < chunks.length; index++) {
            compiledTemplates.push(compiledImagesTemplate({ images: [...chunks[index]] }));
        }
    }

    // Return the compiled HTML
    return compiledTemplates;
};

const formatImagesData = (images) => {
    return images.map((element) => {
        return `data:image/jpeg;base64,${element.image}`;
    });
};