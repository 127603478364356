import React, { useState } from "react";

// import Avatar from "@mui/material/Avatar";
import { Avatar } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {
  useMsal
} from "@azure/msal-react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CloseIcon from "@mui/icons-material/Close";
import DialogContentText from "@mui/material/DialogContentText";
import LogoutIcon from "@mui/icons-material/Logout";
import ListItemIcon from "@mui/material/ListItemIcon";

import { useAppSelector } from "../../redux/hooks";
import { CurrentUser } from "../../redux/slices/currentUserSlice";

function signOutClickHandler(instance) {
  const logoutRequest = {
    postLogoutRedirectUri: "/",
  };
  instance.logoutRedirect(logoutRequest);
}

export const UserDetails = () => {
  const details: CurrentUser = useAppSelector(
    (state) => state.currentUser.data
  );

  // function stringToColor(string: string) {
  //   let hash = 0;
  //   let i;

  //   /* eslint-disable no-bitwise */
  //   for (i = 0; i < string.length; i += 1) {
  //     hash = string.charCodeAt(i) + ((hash << 5) - hash);
  //   }

  //   let color = "#";

  //   for (i = 0; i < 3; i += 1) {
  //     const value = (hash >> (i * 8)) & 0xff;
  //     color += `00${value.toString(16)}`.slice(-2);
  //   }
  //   /* eslint-enable no-bitwise */

  //   return color;
  // }

  function stringAvatar(name: string) {
    let username =
      name.indexOf(" ") > 0
        ? `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`
        : `${name.substring(0, 2)}`;
    username = username.toUpperCase();
    return {
      sx: {
        bgcolor: "#2b8818", // stringToColor(name),
        fontSize: "16px",
        marginRight: "-10px",
      },
      children: `${username}`,
    };
  }
  const { instance } = useMsal();
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const openProfileDropdown = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const closeProfileDropdown = () => {
    setAnchorEl(null);
  };

  const handleSignOutOpen = () => {
    setOpen(true);
  };
  const handleSignOutClose = () => {
    setOpen(false);
  };

  if (details?.name) {
    return (
      <div>
        {/* <p>{JSON.stringify(details)}</p> */}
        {/* <Avatar {...stringAvatar(details.name as string)} />
          {details.name} */}

        {/* <CardHeader
          avatar={<Avatar {...stringAvatar(details.name as string)} />}
          title={details.name}
          sx={{
            color: "white",
            fontSize: "1rem",
            marginRight: "10px",
            padding: "10px",
          }}
        /> */}

        <IconButton
          sx={{
            color: "white",
            fontSize: "1rem",
            marginRight: "10px",
            padding: "0px",
            "&:hover": { backgroundColor: "transparent", cursor: "default" },
          }}
          disableRipple
        >
          <Avatar
            style={{
              margin: "10px",
              width: "40px",
              height: "40px",
            }}
            {...stringAvatar(details.name as string)}
          />
          {details.name}
        </IconButton>
        <IconButton
          sx={{
            color: "white",
            fontSize: "1rem",
            marginRight: "10px",
            padding: "0px",
            "&:hover": { backgroundColor: "transparent" },
          }}
          disableRipple
          onClick={openProfileDropdown}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          // sx={{
          //   top: '0px',
          //   left: '0px',
          //   "& .MuiPaper-root": {
          //     top: "62px",
          //     left: "900px",
          //   },
          // }}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 2.5,
              ml: 2,
              //width: 150,
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={closeProfileDropdown}
        >
          <MenuItem onClick={handleSignOutOpen}>
            <ListItemIcon>
              <LogoutIcon sx={{mr: '-5px'}}fontSize="small"/>
            </ListItemIcon>
            Sign Out 
          </MenuItem>
        </Menu>
        <Dialog open={open} onClose={closeProfileDropdown}>
          <DialogTitle sx={{ m: 0, p: 2 }}>
            Sign Out
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleSignOutClose}
              aria-label="close"
              className="close-btn"
            >
              <CloseIcon sx={{ mt: "-6px" }} onClick={handleSignOutClose} />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            {" "}
            <DialogContentText>
              {"Are you sure you want to sign out?"}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              data-testid="confirm-signout-btn"
              className="primary-outline"
              onClick={() => signOutClickHandler(instance)}
              autoFocus
            >
              Yes
            </Button>
            <Button
              data-testid="cancel-signout-btn"
              className="primary-outline"
              autoFocus
              onClick={handleSignOutClose}
            >
              No
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  } else {
    return null;
  }
};
