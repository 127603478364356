import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// //import { RootState } from './store';
interface toast {
  message: any;
  severity: string;
}

type DialogData = {
    open: boolean,
    showPopupForm: boolean,
    investmentIds?: any[],
    selectedInvestmentID: string,
    dataForSelectedInvestmentID: string,
    yearSet: number[],
    selectedYear: string,
    showDuplicateAlert: boolean,
    showDeleteAlert:boolean,
    rejectionCommentPopup: boolean,
    rejectionComment: string,
    rejectionStatus: toast,
    rejectionToast:boolean,
  };

  type InitialState={
    dialogData:DialogData,
}

const initialState:InitialState={
    dialogData:{} as DialogData
}


const dialogFormSlice = createSlice({
  name: 'dialogForm',
  initialState,
  reducers: {
    saveInvestmentId(state, action: PayloadAction<DialogData>) {
      state.dialogData.investmentIds = action.payload.investmentIds;
    },
    saveDataforSelectedIds(state, action: PayloadAction<DialogData>) {
      state.dialogData.dataForSelectedInvestmentID=action.payload.dataForSelectedInvestmentID;
    },
    showDuplicateResponseAlert(state) {
      state.dialogData.showDuplicateAlert =true;
    },
    hideDuplicateResponseAlert(state) {
      state.dialogData.showDuplicateAlert =false;
    },
    showDeleteResponseAlert(state) {
      state.dialogData.showDeleteAlert =true;
    },
    hideDeleteResponseAlert(state) {
      state.dialogData.showDeleteAlert =false;
    },
    storeSelectedYear(state,action) {
      state.dialogData.selectedYear =action.payload;
    },
    storeSelectedInvestmentId(state, action) {
      state.dialogData.selectedInvestmentID = action.payload;
    },
    showCreateDapPopup(state){
      state.dialogData.showPopupForm = true;
    },
    hideCreateDapPopup(state){
      state.dialogData.showPopupForm = false;
    },
    rejectionCommentPopup(state,action) {
      state.dialogData.rejectionCommentPopup =action.payload;
    },
    rejectionComment(state,action) {
      state.dialogData.rejectionComment =action.payload;
    },
    rejectionStatus(state,action) {
      state.dialogData.rejectionStatus =action.payload;
    },
    rejectionToast(state,action) {
      state.dialogData.rejectionToast =action.payload;
    },
  },
});

export const { saveInvestmentId, storeSelectedInvestmentId, saveDataforSelectedIds,showDuplicateResponseAlert,hideDuplicateResponseAlert,showDeleteResponseAlert,hideDeleteResponseAlert,storeSelectedYear,showCreateDapPopup,hideCreateDapPopup,rejectionCommentPopup,rejectionComment,rejectionStatus,rejectionToast } = dialogFormSlice.actions;


export default dialogFormSlice.reducer;
