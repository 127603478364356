import handlebars from "handlebars";
import summaryTemplate from "../templates/summaryTemplate";
import { CommentKey } from "constant";
import { formatYearBuilt, numToMoneyFormat, numWithComma } from "components/util/valueFormatter";
import moment from 'moment'
import {DapData} from "../../../redux/slices/summaryFormSlice";
import {dateTimeToDateFormat} from "../../util/valueFormatter";

export const compileSummaryTemplate = (summaryData:DapData,comments:any[]): string => {

    const compileSummaryTemplate = handlebars.compile(summaryTemplate);
    const summaryComment=comments?.find(
        (x) => x.commentKey?.toLowerCase() === CommentKey.SUMMARY_KEY.toLowerCase()
      );
      
      const formattedData = {
        yearBuilt : formatYearBuilt(summaryData?.yearBuilt),
        dateAcquired: dateTimeToDateFormat(summaryData?.dateAcquired),
        purchasedPrice: numToMoneyFormat(summaryData?.purchasedPrice),
        appraisedValue: numToMoneyFormat(parseInt(summaryData?.appraisedValue)),
        rentableArea: numWithComma(summaryData?.rentableArea),
        units: numWithComma(summaryData?.units),
        ownerShip: (summaryData?.ownerShip)?.toFixed(2),
        debt: summaryData?.debt === "1" ? "Yes" : "No"};

    const templateData = {
        formattedData : formattedData,
        summaryData: summaryData,
        summaryComment:summaryComment
      };

    return compileSummaryTemplate(templateData);

};

