import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { baseUrl } from "../../config/config";
import { getApiCall } from "../../services/apiHelper";
import { RootState } from "../store";

export interface DapComment {
  id: number;
  assetPlanId: string;
  sectionName: string;
  commentKey: string;
  commentText: string;
}

interface InitialState {
  data: DapComment[]; // Set initial state of data as null
  error:string,
  loading:boolean,
  cacheKey:string|undefined
}

const initialState: InitialState = {
  data: [],
  error:'',
  loading:false,
  cacheKey:undefined

};


export const getDapComments = createAsyncThunk<
DapComment[],
  string,
  { state: RootState }
>("getDapComments", async (assetPlanId, { getState, rejectWithValue }) => {
  const state = getState().dapComments;

  if (state.data.length > 0 && state.cacheKey ==='commentCache') {
    // Return the current data if it exists in the state and cacheKey is set
    return state.data;
  }
  // if (state.data.length === 0 && cacheKey === assetPlanId) {
  //   // Return an empty array if there is no data in both the state and the cache
  //   return [];
  // }

  try {
    const response:any = await getApiCall(`${baseUrl}DapComments/GetDapCommentsByAssetPlanId?assetPlanId=${assetPlanId}`)
    return response?.data;
  } catch (error: any) {
    console.error("Error in GET request:", error.message);
    return rejectWithValue("something went wrong");
  }
});


export const updateDapCommentSlice = createSlice({
  name: "updateDapComment",
  initialState,
  reducers: {
    resetState: (state) => {
      state.data = [];
      state.error = "";
      state.loading = false;
      state.cacheKey=undefined;
    },
    updateDapCommentData(state, action: PayloadAction<DapComment>) {
      const comment = action.payload;
      console.log("from slice", comment);

        let existingIndex=-1||undefined;
      if(comment.sectionName.toLowerCase()==='missionstatement')
      {
          existingIndex=state.data?.findIndex((record) => record.id === comment.id 
          && record.sectionName.toLowerCase() === comment.sectionName.toLowerCase());
      }
      else
      {
      existingIndex =
        comment.id === 0
          ? state.data?.findIndex(
              (record) =>
                record.commentKey.toLowerCase() === comment.commentKey.toLowerCase() &&
                record.sectionName.toLowerCase() === comment.sectionName.toLowerCase()
            )
          : state.data?.findIndex((record) => record.id === comment.id);
      }

      if (existingIndex !== undefined && existingIndex !== -1) {
        state.data[existingIndex] = {
          ...state.data[existingIndex],
          ...comment,
        };
      } else {
        if(comment.id===0 && comment.commentText==='')
        {
          return;
        }
        state.data.push(comment);
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDapComments.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        getDapComments.fulfilled,
        (state, action: PayloadAction<DapComment[]>) => {
          state.loading = false;
          state.data = action.payload;
          state.cacheKey='commentCache';
          state.error='';
        }
      )
      .addCase(getDapComments.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "something went wrong";
      });
  },
});

export const { resetState: resetDapCommentStateAction, updateDapCommentData } = updateDapCommentSlice.actions;
export default updateDapCommentSlice.reducer;
