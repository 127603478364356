import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { baseUrl } from "../../config/config";
import {postApiCall} from "../../services/apiHelper";

export type CapitalPlanTableData =  {
  year:number;
  biCapital:number;
  leasingCostCommercial:number;
  turnCostResidential:number;
  totalCapital:number;
  projectedNOI:number;
}

export interface data {
  capitalPlanTableProperties: CapitalPlanTableData[];
}

interface assetID {
  assetPlanID:string;
}

type InitialState={
  loading:boolean,
  capitalPlan:CapitalPlanTableData[],
  error:string
}

const initialState:InitialState={
  loading:false,
  capitalPlan:[],
  error:''
}
export const getCapitalPlanTableData = createAsyncThunk("getCapitalPlanTableData",async(payload:any)=>
{
    const response= await postApiCall(`${baseUrl}AssetPlan/CapitalPlan`,payload).then((res:any) => 
    res);
    console.log(response.capitalPlanTableProperties);
    return response.capitalPlanTableProperties || [];
});

const capitalPlanTableSlice = createSlice({
  name: 'getCapitalPlanTable',
  initialState,
  reducers: {},
  extraReducers:(builder)=>
        {
          // eslint-disable-next-line   
            builder.addCase(getCapitalPlanTableData.pending,(state)=>
            {
              state.loading=true;
            }),
            builder.addCase(getCapitalPlanTableData.fulfilled,(state,action:PayloadAction<CapitalPlanTableData[]>)=>
            {
              state.loading=false;
              state.capitalPlan=action.payload;
            }),
            builder.addCase(getCapitalPlanTableData.rejected,(state,action)=>
            {
              state.loading=false;
              state.error=action.error.message ||'something went wrong';
            })
           
        }  
});


export default capitalPlanTableSlice.reducer;
