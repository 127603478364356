import React from 'react';
import { UserDetails } from '../components/util/UserDetails';
// import Button from '@mui/material/Button';
//  import logo from "logo.svg";

export const Header = () => {
  return (
    <div className="header">
      <h2 className="app-title">
        <div className="logo"></div> <span>| Digital Asset Plan</span>
      </h2>
      <div className="header-right">
        <UserDetails />
      </div>
    </div>
  );
};
