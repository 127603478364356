import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from 'axios';
import { baseUrl } from "../../config/config";
import {postApiCall,getApiCall} from "../../services/apiHelper";

type DisplayedYear =
{
    displayedYear : string,

}

type InitialState={
    loading:boolean,
    displayedYear:Array<string>,
    error:string
}

const initialState:InitialState={
    loading:false,
    displayedYear:[],
    error:''
}

export const getYear = createAsyncThunk("getDisplayedYear",async()=>
{
    const response= await getApiCall<Array<string>>(`${baseUrl}AssetPlan/GetDisplayedYear`);    
    return response;
})

export const getDisplayedYearSlice=createSlice(
    {
        name: 'getDisplayedYear',
        initialState,
        reducers:{},
        extraReducers:(builder)=>
        {
          // eslint-disable-next-line   
            builder.addCase(getYear.pending,(state)=>
            {
              state.loading=true;
            }),
            builder.addCase(getYear.fulfilled,(state,action:PayloadAction<Array<string>>)=>
            {
              state.loading=false;
              state.displayedYear=action.payload;
            }),
            builder.addCase(getYear.rejected,(state,action)=>
            {
                state.loading=false;
                state.error=action.error.message ||'something went wrong';
            })
        }  
        
    }
);

export default getDisplayedYearSlice.reducer;