const capitalPlanTemplate2025 = `
<!DOCTYPE html>
<html>
  <head>
    
  </head>
  <body>
    <!-- print table -->
    <table id="print-pdf-table" class="capital-plan-table-1">
      <h2 class="pdf-table-heading">Detailed Building Capital Plan</h2>
      <tr>
        <th>Project Priority</th>
        <th>Contributes to SSBB feature</th>
        <th>Project ID</th>
        <th>{{budgetYear}} Budget</th>
        <th>Project Description</th>
        <th>Comments</th>
      </tr>
      {{#each table1Data}}
      <tr>
        <td>{{this.projectPriority}}</td>
        <td>{{this.esgImpact}}</td>
        <td>{{this.projectId}}</td>
        <td>{{this.currentBudget}}</td>
        <td>{{this.projectDescription}}</td>
        <td>{{this.comment}}</td>
      </tr>
      {{/each}}
    </table>

    <table id="print-pdf-table" class="capital-plan-table">
      <h2 class="pdf-table-heading">Capital Plan</h2>
      <tr>
        <th></th>
        <th>{{budgetYear}} Capital</th>
        <th>% of {{budgetYear}} NOI</th>
      </tr>

      <tr>
        <td>BI Capital</td>
        <td>{{firstYearRecord.biCapital}} </td>
        <td>{{dividedValues.biCapital}}</td>
      </tr>

      <tr>
        <td>Leasing Cost (Commercial)</td>
        <td>{{firstYearRecord.leasingCostCommercial}}</td>
        <td>{{dividedValues.leasingCostCommercial}}</td>
      </tr>
      <tr>
        <td>Turn Cost (Residential)</td>
        <td>{{firstYearRecord.turnCostResidential}}</td>
        <td>{{dividedValues.turnCostResidential}}</td>
      </tr>
      <tr>
        <td>Total Capital</td>
        <td>{{firstYearRecord.totalCapital}}</td>
        <td>{{dividedValues.totalCapital}}</td>
      </tr>
      <tr>
        <td>Projected NOI {{budgetYear}}</td>
        <td>{{firstYearRecord.projectedNOI}}</td>
        <td>{{dividedValues.projectedNOI}}</td>
      </tr>
    </table>
    <br>
    <table id="comment-table">
        <tbody>
          <tr>
            <th>Comments</th>
          </tr>
          <tr>
            <td>
              {{{capitalPlanComment.commentText}}}
            </td>
          </tr>
        </tbody>
      </table>
  </body>
</html>
`;

export default capitalPlanTemplate2025;