import React, { useRef } from "react";

import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { useAppSelector, useAppDispatch } from "../../../redux/hooks";
import { useState, useEffect } from "react";
import { getLeasingCommercial } from "../../../redux/slices/leasingCommercialSlice";
import { FroalaRichText } from "../../util/FroalaRichtext";
import FroalaEditorView from "react-froala-wysiwyg/FroalaEditorView";
import CommertialOccupancyNew from "./commertial/commercialOccupancy-new";
import CommertialLeaseExpirations from "./commertial/commertialLeaseExpirations";
import CommertialKeyTenant from "./commertial/commertialKeyTenant";
import "./DynamicTable.css";

import "./commertialLeasingActivity.css";
import "./commertiialOccupancy.css";
import Skeleton from "@mui/material/Skeleton";
import {
  updateDapCommentData,
  DapComment,
} from "../../../redux/slices/updateDapCommentSlice";
import { AutosaveDelay, CommentKey } from "../../../constant";
import { saveDapCommentData } from "../../../redux/slices/saveCommentSlice";
import { AssetPlanParams } from "../../../types/assetplanid";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export const LeasingCommercialEditFormNew = (props) => {
  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const [age, setAge] = React.useState("");
  const dispatch = useAppDispatch();
  const handleChange1 = (event: any) => {
    setAge(event.target.value as string);
  };
  const dapData = useAppSelector((state) => state.summary.dapData);
  const id = "1092";

  const commertialComment = useAppSelector(
    (state) => state.dapComments?.data
  ).find(
    (x) => x.commentKey.toLowerCase() === CommentKey.COMMERTIAL.toLowerCase()
  );
  const assetPlanId = useAppSelector(
    (state) => state.getAssetPlanId.assetPlanId
  );
  const postData: AssetPlanParams = {
    assetPlanId: assetPlanId,
  };

  const leasingCommercial = useAppSelector(
    (state) => state.leasingCommercial.data
  );
  const leasingCommercialStatus = useAppSelector(
    (state) => state.leasingCommercial
  );

  const isMounted = useRef(false);
  const handleCommentChange = (model: any) => {
    const updatedcomment: DapComment = {
      id: commertialComment ? commertialComment.id : 0,
      commentKey: commertialComment
        ? commertialComment.commentKey
        : CommentKey.COMMERTIAL,
      commentText: model || "",
      sectionName: "Leasing",
      assetPlanId: assetPlanId,
    };
    dispatch(updateDapCommentData(updatedcomment));
    dispatch(saveDapCommentData(updatedcomment));

    // if (isMounted.current) {
    //   dispatch(updateDapCommentData(updatedcomment));
    //   dispatch(saveDapCommentData(updatedcomment));
    // } else {
    //   isMounted.current = true;
    // }
  };

  const budgetYear = Number(
    useAppSelector((state) => state.summary.dapData?.budgetYear)
  );

  useEffect(() => {
    if (leasingCommercial === null) {
      dispatch(getLeasingCommercial(postData));
    }
  }, [assetPlanId]);

  // const savedData = useAppSelector((state) => state.updateData);
  // const [toastMessageOpen, setToastMessageOpen] = useState(false);
  // const [toastMessage, setToastMessage] = useState<toast>({
  //   message: "",
  //   severity: "",
  // });
  // const savedComment = useAppSelector(
  //   (state) => state.saveComment.updateDapComments
  // );
  // const isMountedAutosave = useRef(false);
  // useEffect(() => {
  //   if (isMountedAutosave.current) {
  //     const promises: Promise<any>[] = [];

  //     const debouncedAutosave = setTimeout(() => {
  //       let savedDataPayload = {
  //         assetPlanId: assetPlanId,
  //         capitalProjectData: null,
  //         documentData: null,
  //         occupancyCommercial: savedData.occupancyCommercial,
  //         recommendationTab: null,
  //         status: null,
  //         summary: null,
  //         userAction: "Save",
  //       };
  //       if (savedData) {
  //         console.log("saveddata", savedData);
  //         promises.push(
  //           postApiCall(`${baseUrl}UpdateData/SaveAllData`, {
  //             ...savedDataPayload,
  //           })
  //         );
  //       }
  //       if (savedComment) {
  //         console.log("com", savedComment);
  //         promises.push(
  //           postApiCall(`${baseUrl}DapComments/UpdateDapComments`, savedComment)
  //         );
  //       }
  //       clearDocumentComments(dispatch);
  //       setToastMessage({
  //         message: "Saved successfully",
  //         severity: "success",
  //       });
  //       if (promises.length > 0) {
  //         setToastMessageOpen(false);
  //         Promise.all(promises)
  //           .then((responses) => {
  //             const successResponses = responses.filter(
  //               (response: any) => response.success
  //             );
  //             if (successResponses.length === promises.length) {
  //               setToastMessage({
  //                 message: "Saved successfully",
  //                 severity: "success",
  //               });
  //               setToastMessageOpen(true);
  //             }
  //           })
  //           .catch((error) => {
  //             console.error("Error:", error);
  //             setToastMessage({
  //               message: error.message,
  //               severity: "error",
  //             });
  //             setToastMessageOpen(true);
  //           });
  //       } else {
  //         console.log("No API calls made");
  //       }
  //     }, AutosaveDelay.DELAYTIME);
  //     return () => {
  //       clearTimeout(debouncedAutosave);
  //     };
  //   } else {
  //     isMountedAutosave.current = true;
  //   }
  // }, [savedData, savedComment]);

  return (
    <div>
      {/* {toastMessageOpen && (
        <GenericToast
          message={toastMessage.message}
          severity={toastMessage.severity}
        />
      )} */}
      <div className="tab-heading text-center">
        <h3 className="heading">Commercial</h3>
      </div>

      <Box sx={{ width: "100%" }}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
          <Grid item xs={6}>
            <Item>
              <div className="text-center">
                <h2 className="mt-0 table-sub-heading">
                  {budgetYear} Projected Leasing Activity
                </h2>
              </div>
              {leasingCommercialStatus.loading ? (
                <Skeleton
                  variant="rounded"
                  width="100%"
                  height={100}
                  animation="wave"
                />
              ) : (
                <table id="leasingActivity">
                  <tr>
                    <th>Leasing Activity</th>
                    <th>Total SQFT</th>
                  </tr>
                  <tr>
                    <td>
                      New Leases{" "}
                      <span className="text-right">
                        {leasingCommercial && leasingCommercial.newLease
                          ? leasingCommercial.newLease.toLocaleString()
                          : ""}
                      </span>
                    </td>
                    <td>
                      {leasingCommercial && leasingCommercial.newLeaseTotalSqft
                        ? leasingCommercial.newLeaseTotalSqft.toLocaleString()
                        : ""}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Renewals{" "}
                      <span className="text-right">
                        {" "}
                        {leasingCommercial && leasingCommercial.renewLease
                          ? leasingCommercial.renewLease.toLocaleString()
                          : ""}
                      </span>
                    </td>
                    <td>
                      {leasingCommercial &&
                      leasingCommercial.renewLeaseTotalSqft
                        ? leasingCommercial.renewLeaseTotalSqft.toLocaleString()
                        : ""}
                    </td>
                  </tr>
                </table>
              )}
              <div style={{ marginTop: "20px" }}></div>
              <p className="note">
                <b>Note:</b> Due to report constraints, GLA New Leases and GLA
                Renewals will be under-reported if the lease commencement date
                is after December 1st.
              </p>
            </Item>
          </Grid>
          {/* 2nd block */}
          <Grid item xs={6}>
            <Item>
              <div className="text-center">
                <h2 className="mt-0 table-sub-heading">
                  {budgetYear} Occupancy Schedule
                </h2>
              </div>
              <div className="occupancy-container">
                <CommertialOccupancyNew {...props} />
              </div>
              <div className="note">
                <p>
                  <b>Note:</b> GLA new Leases should be ≥ Net Absorption.
                  However, Net Absorption may exceed GLA New leases due to Total
                  GLA changing over time, and in instances where GLA New Leases
                  is under-reported.
                </p>
              </div>
            </Item>
          </Grid>
          {/* 3rd block */}
        </Grid>
      </Box>
      {dapData?.propertyType !== "Mixed" && (
        <>
          <div className="mt-20">
            <div className="tab-heading text-center">
              <h3 className="heading">Lease Expirations</h3>
            </div>

            <CommertialLeaseExpirations />
          </div>
        </>
      )}

      <div className="mt-20">
        <div className="tab-heading text-center">
          <h3 className="heading">Key Tenants</h3>
        </div>

        <CommertialKeyTenant />
        <form className="m-20 lease-form">
          {props?.mode === "edit" && (
            <fieldset>
              <legend className="lease-title">Leasing Comments</legend>
              <FroalaRichText
                model={commertialComment?.commentText || ""}
                handlesModelChange={handleCommentChange}
              />
            </fieldset>
          )}
          {(props?.mode === undefined || props?.mode === "view") && (
            <fieldset>
              <legend className="lease-title">Leasing Comments</legend>
              <FroalaEditorView model={commertialComment?.commentText || ""} />
            </fieldset>
          )}
        </form>
      </div>
    </div>
  );
};
