import handlebars from "handlebars";
const eq = (a: any, b: any) => a === b;

// Register the 'eq' helper with Handlebars
handlebars.registerHelper("eq", eq);

const financialsTemplate = `
<!DOCTYPE html>
<html>  
  <body>
    <!-- print table -->
    <table id="financial-table">
      <h2 class="pdf-table-heading">Financials</h2>
      <h3 class="pdf-table-sub-heading">Summary of Budget</h3>
      <tr>
        <th></th>
        <th>{{budgetYearMinus3}} Actual</th>
        <th>{{budgetYearMinus2}} Actual</th>
        <th>{{budgetYearMinus1}} Budget</th>
        <th>{{budgetYearMinus1}} Reforecast</th>
        <th>{{budgetYear}} Budget</th>
      </tr>
      {{#each financials}}
      {{#if (eq this.rowType 'mainHeader')}}
        <tr id="row-main-header">
            <td colspan="6">{{this.accountDescription}}</td>
        </tr>
      {{else if (eq this.rowType 'subHeader')}}
        <tr id="row-sub-header">
          <td colspan="6">{{this.accountDescription}}</td>
        </tr>
      {{else}}
        {{#if (eq this.rowType 'mainTotal')}}
          <tr id="row-main-total">
        {{else if (eq this.rowType 'subTotal')}}
          <tr id="row-sub-total">
        {{else}}
          <tr>
        {{/if}}
            <td>{{this.accountDescription}}</td>
            <td>{{this.yearMinus3Actual}}</td>
            <td>{{this.yearMinus2Actual}}</td>
            <td>{{this.yearMinus1Budget}}</td>
            <td>{{this.yearMinus1Forecast}}</td>
            <td>{{this.yearBudget}}</td>
        </tr>
      {{/if}}
      {{/each}}      
    </table>
    <br>
    <table id="comment-table">
        <tbody>
          <tr>
            <th>Comments</th>
          </tr>
          <tr>
            <td>
              {{{financialsComment.commentText}}}
            </td>
          </tr>
        </tbody>
      </table>
  </body>
</html>
`;

export default financialsTemplate;
