import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { baseUrl, headers } from "../../config/config";
import { postApiCall } from "../../services/apiHelper";
import { AssetPlanParams } from "../../types/assetplanid";

export interface LeasingCommercial {
  newLease: number;
  newLeaseTotalSqft: number;
  renewLease: number;
  renewLeaseTotalSqft: number;
  startYearGLALeased: number;
  totalGLA: number;
  startYearLeasedPC: number;
  endYearGLALeased: number;
  endYearLeasedPC: number;
  netAbsorptionOcc: number;
}

interface LeasingCommercialState {
  data: LeasingCommercial | null;
  error: string;
  loading: boolean;
}

const initialState: LeasingCommercialState = {
  data: null,
  error: "",
  loading: false,
};


export const getLeasingCommercial = createAsyncThunk(
  "getLeasingCommercial",
  async (payload: AssetPlanParams) => {
    try {
      const response = await postApiCall<AssetPlanParams, LeasingCommercialState>(
        `${baseUrl}Leasing/GetOccupancyCommercial`,
        payload,
        headers
      );
      return response.data;
    } catch (error: any) {
      console.error("Error in POST request:", error);
      throw error;
    }
  }
);

const leasingCommercialSlice = createSlice({
  name: "leasingCommercial",
  initialState,
  reducers: {
    resetState: (state) => {
      state.data = null;
      state.error = "";
      state.loading = false;
    },
    updateLeasingCommercialState: (state, action: PayloadAction<LeasingCommercial>) => {
      state.data = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLeasingCommercial.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        getLeasingCommercial.fulfilled,
        (state, action: PayloadAction<LeasingCommercial | null>) => {
          state.loading = false;
          state.data = action.payload;
        }
      )
      .addCase(getLeasingCommercial.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Something went wrong";
      });
  },
});
export const { resetState: resetLeaseCommercialAction,updateLeasingCommercialState} = leasingCommercialSlice.actions;
export default leasingCommercialSlice.reducer;
