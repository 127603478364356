import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { baseUrl } from "config/config";
import { getApiCall, getApiCallWithRetryLogic } from "services/apiHelper";

export interface Valuations {
  investmentId: string;
  valuations: string;
}

interface ValuationsState {
  valuations: Valuations[] | null;
  error: string;
  loading: boolean;
  hasDataBeenRequested: boolean;
}

const initialState: ValuationsState = {
  hasDataBeenRequested: false,
  valuations: null,
  error: "",
  loading: false,
};

const ids = { investmentId: "", assetPlanId: "" };

export const getValuations = createAsyncThunk(
  "getValuations",
  async (ids: any) => {
    try {
      const response = await getApiCallWithRetryLogic<any>(
        `${baseUrl}Document/GetValuations?investmentid=${ids.investmentId}&assetPlanId=${ids.assetPlanId}`
      );
      console.log("VALUATIONS", response);
      return response;
    } catch (error: any) {
      console.error("Error in GET request:", error);
      throw error;
    }
  }
);

const valuationsSlice = createSlice({
  name: "valuations",
  initialState,
  reducers: {
    resetState: (state) => {
      state.valuations = null;
      state.error = "";
      state.loading = false;
      state.hasDataBeenRequested = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getValuations.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        getValuations.fulfilled,
        (state, action: PayloadAction<Valuations[] | null>) => {
          state.loading = false;
          state.valuations = action.payload;
          state.error = "";
          state.hasDataBeenRequested = true;

        }
      )
      .addCase(getValuations.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Something went wrong";
      });
  },
});

export const { resetState: resetValuationsStateAction } =
  valuationsSlice.actions;
export default valuationsSlice.reducer;
