import React, { useState, useEffect } from "react";
import { useMediaQuery, Typography, Box } from "@mui/material";
import {
  Timeline,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
  TimelineDot,
  TimelineConnector,
  TimelineContent,
} from "@mui/lab";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { getApiCall } from "../../../services/apiHelper";
import { baseUrl, baseUrl2 } from "../../../config/config";
import { useAppSelector, useAppDispatch } from "../../../redux/hooks";
import moment from "moment";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
});

type audit = {
  id: string | undefined | null;
  assetPlanId: string | undefined | null;
  userName: string | undefined | null;
  action: string | undefined | null;
  updatedTime: Date;
  comments: string | undefined | null;
  role: string | undefined | null;
};

const AuditControl = () => {
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const assetPlanId = useAppSelector(
    (state) => state.getAssetPlanId.assetPlanId
  );

  const getAuditLogs = () => {
    var url = `${baseUrl}Audit/GetAuditLogByAssetPlanId?assetPlanId=${assetPlanId}`;
    console.log("url", url);
    const res = getApiCall(url).then((data: any) => {
      return data;
    });
    return res;
  };

  const [auditLogsData, setAuditLogData] = React.useState<audit[]>([]);

  useEffect(() => {
    getAuditLogs().then((data: audit[]) => {
      data.sort(
        (objA, objB) => new Date(objB.updatedTime).valueOf() - new Date(objA.updatedTime).valueOf()
      );
      console.log(data)
      setAuditLogData([...data]);
    });
  }, [assetPlanId]);

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          border: "1px solid #ccc",
          padding: "10px",
          overflowY: "auto",
        }}
      >
        <Box sx={{ width: "95%" }}>
          <Timeline>
            {auditLogsData?.map((item) => (
              <TimelineItem key={item.id}>
                <TimelineOppositeContent sx={{ flex: 1, textAlign: "right" }}>
                  <Typography color="textSecondary">
                    {moment(item.updatedTime).format("MMM-DD-YYYY hh:mm A")}
                  </Typography>
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot color="secondary" sx={{ bgcolor: "#2b8818" }} />
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent
                  sx={{
                    flex: 1,
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <Typography
                    color="textSecondary"
                    variant="caption"
                    sx={{ mb: "4px" }}
                  >
                    {item.action}
                  </Typography>
                  <Typography>{item.userName}</Typography>
                  <Typography>{item.comments}</Typography>
                </TimelineContent>
              </TimelineItem>
            ))}
          </Timeline>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default AuditControl;
